import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePaymentHistoryQueryProps {
  premiseId: IdType;
}

export const usePaymentHistoryQuery = (props: UsePaymentHistoryQueryProps) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.paymentHistory(premiseId),
    queryKey: PremiseQueryKeys.paymentHistory(premiseId),
  });
};
