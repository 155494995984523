import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardSubheader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { deviceInformationCardTranslations } from "@portal/components/DeviceInformationCard/DeviceInformationCard.en.i18n";
import {
  DeviceModelOrId,
  StyledContainer,
  StyledDeviceInfoRow,
  StyledDeviceSettingsContainer,
  StyledDeviceStatus,
  StyledImageWrapper,
  StyledInfoContainer,
  StyledLegalDocLinkContainer,
  StyledLinkGroup,
  StyledTextButton,
} from "@portal/components/DeviceInformationCard/DeviceInformationCard.styled";
import { chooseYourDeviceBrandStepAtom } from "@portal/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.atoms";
import {
  enDeviceTos,
  esDeviceTos,
} from "@portal/components/DevicesEnrollTermsForm/deviceTermsDocuments";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { useDeviceStatus } from "@portal/hooks/useDeviceStatus";
import { ReactComponent as Thermostat } from "@portal/images/Thermostat.svg";
import { devicesEnrollStepPath } from "@portal/routes/routePaths";
import { DeviceStatus, DeviceType } from "@portal/types/devicesTypes";
import { useSetAtom } from "jotai";
import React from "react";

interface DeviceInformationCardProps {
  credit: string | undefined;
  device: DeviceType;
  premiseCount: number;
}

export const DeviceInformationCard = (props: DeviceInformationCardProps) => {
  const { device, credit, premiseCount } = props;
  const { deviceBrand, deviceId, deviceModel, deviceName, deviceStatus } =
    device;
  const { addModal } = useModals();
  const deviceStatusText = useDeviceStatus();
  const setChooseYourDeviceBrand = useSetAtom(chooseYourDeviceBrandStepAtom);

  const [locale] = useLocaleAtom();

  const { translate, translateJsx } = useTranslations();

  const {
    tDeviceInformationCardChangeDeviceAddress,
    tDeviceInformationCardConnectDevice,
    tDeviceInformationCardDeviceName,
    tDeviceInformationCardDeviceSettings,
    tDeviceInformationCardDeviceStatus,
    tDeviceInformationCardDeviceType,
    tDeviceInformationCardDisconnectDevice,
    tDeviceInformationCardModelNumber,
    tDeviceInformationCardTitle,
    tDeviceInformationCardYourDocuments,
    tDeviceInformationCardYourDocumentsLinkText,
  } = translate(deviceInformationCardTranslations);

  const {
    tDeviceInformationCardSubheader,
    tDeviceInformationCardDeviceTypeThermostat,
  } = translateJsx({
    tDeviceInformationCardDeviceTypeThermostat: {
      brand: deviceBrand,
    },
    tDeviceInformationCardSubheader: {
      credit,
    },
  });

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{tDeviceInformationCardTitle}</PortalCardTitle>
        <PortalCardSubheader>
          {tDeviceInformationCardSubheader}
        </PortalCardSubheader>
      </PortalCardHeader>
      <StyledContainer>
        <StyledImageWrapper>
          <Thermostat />
        </StyledImageWrapper>

        <StyledInfoContainer>
          <StyledDeviceInfoRow>
            <div>
              <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
                {tDeviceInformationCardDeviceName}
              </RhTypography>
              <RhTypography variant="body1">{deviceName}</RhTypography>
            </div>
            <div>
              <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
                {tDeviceInformationCardDeviceType}
              </RhTypography>
              <RhTypography variant="body1">
                {tDeviceInformationCardDeviceTypeThermostat}
              </RhTypography>
            </div>
          </StyledDeviceInfoRow>
          <StyledDeviceInfoRow>
            <div title={deviceModel || deviceId}>
              <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
                {tDeviceInformationCardModelNumber}
              </RhTypography>
              <DeviceModelOrId>{deviceModel || deviceId}</DeviceModelOrId>
            </div>
            <div>
              <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
                {tDeviceInformationCardDeviceStatus}
              </RhTypography>
              <StyledDeviceStatus $status={deviceStatus}>
                {deviceStatusText[deviceStatus]}
              </StyledDeviceStatus>
            </div>
          </StyledDeviceInfoRow>
          <StyledDeviceInfoRow>
            <div>
              <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
                {tDeviceInformationCardYourDocuments}
              </RhTypography>
              <StyledLegalDocLinkContainer>
                <RhAnchor
                  href={locale === "en" ? enDeviceTos : esDeviceTos}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {tDeviceInformationCardYourDocumentsLinkText}
                </RhAnchor>
              </StyledLegalDocLinkContainer>
            </div>
            {deviceStatus !== DeviceStatus.Unavailable ? (
              <StyledDeviceSettingsContainer>
                <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
                  {tDeviceInformationCardDeviceSettings}
                </RhTypography>
                <StyledLinkGroup>
                  {premiseCount >= 1 ? (
                    <StyledTextButton
                      type="button"
                      onClick={() => addModal({ updateDevicePremise: true })}
                    >
                      {tDeviceInformationCardChangeDeviceAddress}
                    </StyledTextButton>
                  ) : null}
                  <StyledTextButton
                    type="button"
                    onClick={() => addModal({ disconnectDevice: true })}
                  >
                    {tDeviceInformationCardDisconnectDevice}
                  </StyledTextButton>
                </StyledLinkGroup>
              </StyledDeviceSettingsContainer>
            ) : (
              <div>
                <RhLinkButton
                  href={devicesEnrollStepPath("terms-and-conditions")}
                  onClick={() => setChooseYourDeviceBrand(device.deviceBrand)}
                  data-tracking-click={{
                    action: "clickedConnectDevice",
                    event: "click",
                  }}
                >
                  {tDeviceInformationCardConnectDevice}
                </RhLinkButton>
              </div>
            )}
          </StyledDeviceInfoRow>
        </StyledInfoContainer>
      </StyledContainer>
    </PortalCard>
  );
};
