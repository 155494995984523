import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  ANNOUNCEMENT_HEIGHT,
  OFFER_CONTAINER_WIDTH_3CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

interface Props {
  $isSummaryPage: boolean;
  $maxWidth: number;
}

export const StyledSignUpPageLayoutContainer = styled.div<Props>`
  margin-top: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT + 1)};
  max-width: ${({ $maxWidth }) => $maxWidth ?? OFFER_CONTAINER_WIDTH_3CARD}px;
  padding-bottom: ${({ $isSummaryPage }) => ($isSummaryPage ? "10em" : "2em")};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XXS}px) {
    margin-top: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT + 3)};
  }
`;

export const StyledBackButtonContainer = styled.div`
  @media (max-width: ${RhythmBreakpoints.SM}px) {
    padding-top: ${rhSpacingPx(1)};
    width: 100%;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    left: 0;
    padding-left: ${rhSpacingPx(10)};
    position: absolute;
    top: 320px;
    transform: translateY(-50%);
  }
`;

export const StyledBackButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const StyledFlexColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
