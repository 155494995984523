import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { MiniOfferSnapshotCard } from "@portal-shared/components/MiniOfferSnapshotCard/MiniOfferSnapshotCard";
import styled from "styled-components";

export const SelectContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
    margin: 0 ${rhSpacingPx(3)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  width: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const BalancedText = styled(RhTypography)`
  text-align: center;
  text-wrap: balance;
`;

export const StyledRhSelect = styled(RhSelect)`
  margin-bottom: 0;
`;

export const StyledRhSelectLabel = styled(RhSelectLabel)`
  width: 100%;
`;

export const MiniCard = styled(MiniOfferSnapshotCard)`
  flex: 1;
`;

export const EmptyFlexItem = styled.div`
  flex: 1;
`;
