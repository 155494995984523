import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledSVGWrapper = styled.div`
  svg {
    height: 10rem;
    width: 10rem;
  }
`;

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  padding: ${rhSpacingPx(1)};
  text-align: center;
`;

export const StyledLinkButton = styled(RhLinkButton)`
  padding: ${rhSpacingPx(2.5)} ${rhSpacingPx(10)};
`;

export const StyledTitle = styled(RhTypography).attrs({ variant: "h2" })<{
  $thermostatRequired: boolean;
}>`
  ${(props) =>
    props.$thermostatRequired &&
    `
    color: ${CSSVariables.COLOR_ERROR_MAIN};
  `}
`;
