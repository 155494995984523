import { IdType, UUIDType } from "@common/types/apiTypes";

export const ProductContractUrls = {
  base: (premiseId: IdType) =>
    `/api/portal/premises/${premiseId}/product-contracts`,
  cancel: (premiseId: IdType) =>
    `${ProductContractUrls.base(premiseId)}/cancel`,
  claimOptions: (premiseId: IdType, productContractId: UUIDType) =>
    `${ProductContractUrls.base(premiseId)}/${productContractId}/claim-options`,
  claims: (premiseId: IdType) =>
    `${ProductContractUrls.base(premiseId)}/claims`,
  create: (premiseId: IdType) => `${ProductContractUrls.base(premiseId)}`,
  list: (premiseId: IdType) => ProductContractUrls.base(premiseId),
};

export const ProductUrls = {
  BASE: "/api/portal/product-tiers/" as const,
  list: () => ProductUrls.BASE,
};

export const AcquisitionCampaignUrls = {
  retrieveBySlug: (slug: string) =>
    `/api/portal/acquisition-campaigns/${slug}/`,
};
