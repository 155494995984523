import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { MyAccountAddOnsOfferCard } from "@portal/components/MyAccountAddOnsOfferCard/MyAccountAddOnsOfferCard";
import { ErrorLoading } from "@portal/components/MyAccountProductAddOnsCard/ErrorLoading";
import { NoContractsCardContent } from "@portal/components/MyAccountProductAddOnsCard/NoContractsCardContent";
import { MyAccountProductAddOnsOptIn } from "@portal/components/MyAccountProductAddOnsOptIn/MyAccountProductAddOnsOptIn";
import { useProductContracts } from "@portal/hooks/queries/useProductContracts";
import { usePremise } from "@portal/hooks/usePremise";
import React, { PropsWithChildren } from "react";

interface TitleWrapperProps {}

const TitleWrapper = ({ children }: PropsWithChildren<TitleWrapperProps>) => {
  const { t } = useRhIntl();
  const title = t("ProductAddOnsCard.title");

  return (
    <PortalCard role="region" aria-label={title}>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>
      {children}
    </PortalCard>
  );
};

export const MyAccountProductAddOnsCard = () => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalProductAddOns, portalProductAddOnsOptIn } =
    featureFlagClient.getFlags([
      ["portalProductAddOns", false],
      ["portalProductAddOnsOptIn", false],
    ]);
  const { premise, error: premiseError } = usePremise();

  const contractsQuery = useProductContracts(premise?.id ?? "", {
    enabled: !!premise,
  });

  if (!portalProductAddOns.value) {
    return null;
  }

  if (contractsQuery.isError || premiseError) {
    return (
      <TitleWrapper>
        <ErrorLoading />
      </TitleWrapper>
    );
  }
  if (contractsQuery.isPending || !premise) {
    return (
      <TitleWrapper>
        <RhCircularProgress />
      </TitleWrapper>
    );
  }

  const noContractsFound = contractsQuery.data.count === 0;

  if (noContractsFound) {
    return (
      <TitleWrapper>
        <NoContractsCardContent />
        {portalProductAddOnsOptIn.value && <MyAccountProductAddOnsOptIn />}
      </TitleWrapper>
    );
  }

  return (
    <TitleWrapper>
      <MyAccountAddOnsOfferCard
        productContracts={contractsQuery.data.results}
        premiseId={premise.id}
      />
    </TitleWrapper>
  );
};
