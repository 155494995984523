import { ZUORA_URL } from "@portal-shared/settings/config";
import { getPortalEnvApplicationSettings } from "@portal-shared/settings/getPortalEnvApplicationSettings";

const portalEnvApplicationSettings = getPortalEnvApplicationSettings();

export const getZuoraParams = (locale: string, isAddBankAccount = false) => ({
  id: isAddBankAccount
    ? portalEnvApplicationSettings.zuoraAddBankAccountPageId
    : portalEnvApplicationSettings.zuoraCreditCardPaymentPageId,
  locale,
  param_supportedTypes: "AmericanExpress,JCB,Visa,MasterCard,Discover,Dankort",
  style: "inline",
  submitEnabled: "true",
  url: `${ZUORA_URL}/apps/PublicHostedPageLite.do`,
});
