import { BillFactorBillingCycle } from "@portal/components/BillFactors/BillFactorBillingCycle";
import { BillFactorContractChanges } from "@portal/components/BillFactors/BillFactorContractChanges";
import { BillFactorPastDue } from "@portal/components/BillFactors/BillFactorPastDue";
import { StyledBillFactorsContainer } from "@portal/components/BillFactors/BillFactors.styled";
import { BillFactorTDSPChange } from "@portal/components/BillFactors/BillFactorTDSPChange";
import { BillFactorUsage } from "@portal/components/BillFactors/BillFactorUsage";
import { BillFactorWeather } from "@portal/components/BillFactors/BillFactorWeather";
import { BillFactorsType } from "@portal/types/billBreakdownTypes";
import React from "react";

interface BillFactorsProps {
  billFactors: BillFactorsType;
}

export const BillFactors = ({ billFactors }: BillFactorsProps) => {
  const {
    contractChanges,
    pastDueBalance,
    usage,
    servicePeriod,
    weather,
    tdspMwhRateChange,
  } = billFactors;

  return (
    <StyledBillFactorsContainer>
      {weather ? (
        <BillFactorWeather
          numberOfColdDays={weather.numberOfColdDays}
          numberOfHotDays={weather.numberOfHotDays}
        />
      ) : null}
      {usage ? (
        <BillFactorUsage differenceInKwh={usage.differenceInKwh} />
      ) : null}
      {tdspMwhRateChange ? (
        <BillFactorTDSPChange
          utilityName={tdspMwhRateChange.utilityName}
          differencePercentage={tdspMwhRateChange.differencePercentage}
        />
      ) : null}
      {servicePeriod ? (
        <BillFactorBillingCycle
          differenceInDays={servicePeriod.differenceInDays}
        />
      ) : null}
      {contractChanges ? (
        <BillFactorContractChanges
          isVariableRate={contractChanges.isVariableRate}
          previousContractEndDate={contractChanges.previousContractEndDate}
        />
      ) : null}
      {pastDueBalance ? (
        <BillFactorPastDue pastDueAmount={pastDueBalance.amount} />
      ) : null}
    </StyledBillFactorsContainer>
  );
};
