import { IdType } from "@common/types/apiTypes";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardSubheader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { DeviceConnectionActivity } from "@portal/components/DeviceConnectionActivity/DeviceConnectionActivity";
import { deviceConnectionActivityCardTranslations } from "@portal/components/DeviceConnectionActivityCard/DeviceConnectionActivityCard.en.i18n";
import React from "react";

interface DeviceConnectionActivityCardProps {
  deviceEligibilityHours: number | undefined;
  deviceId: IdType;
  premiseId: IdType;
}

export const DeviceConnectionActivityCard = (
  props: DeviceConnectionActivityCardProps
) => {
  const { deviceId, premiseId, deviceEligibilityHours } = props;
  const { translate, translateJsx } = useTranslations();
  const { tDeviceConnectionActivityCardTitle } = translate(
    deviceConnectionActivityCardTranslations
  );

  const { tDeviceConnectionActivityCardDescription } = translateJsx({
    tDeviceConnectionActivityCardDescription: { hours: deviceEligibilityHours },
  });

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{tDeviceConnectionActivityCardTitle}</PortalCardTitle>
        {deviceEligibilityHours ? (
          <PortalCardSubheader>
            {tDeviceConnectionActivityCardDescription}
          </PortalCardSubheader>
        ) : null}
      </PortalCardHeader>
      <DeviceConnectionActivity deviceId={deviceId} premiseId={premiseId} />
    </PortalCard>
  );
};
