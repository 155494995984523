import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal/api/invoiceApi";
import { InvoiceQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceTemperatureQueryOptions {
  invoiceId: IdType;
}

export const useInvoiceTemperatureQuery = (
  options: UseInvoiceTemperatureQueryOptions
) => {
  const { invoiceId } = options;

  const query = useQuery({
    queryFn: () => invoiceApi.temperature(invoiceId),
    queryKey: InvoiceQueryKeys.temperature(invoiceId),
  });

  return query;
};
