import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RH_HEADER_HEIGHT } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledNavContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: sticky;
  top: ${rhSpacingPx(RH_HEADER_HEIGHT + 2.5)};
`;

export const StyledMyAccountLinkContainer = styled(RhTypography)`
  &.MuiTypography-root {
    padding-bottom: ${rhSpacingPx(2)};

    a {
      display: flex;
      justify-content: space-between;
    }
  }
`;
