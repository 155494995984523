import { ajax } from "@common/services/ajax";
import { ZuoraIdType } from "@enroll-utils/types/apiTypes";
import { PaymentMethodRetrieveType } from "@enroll-utils/types/paymentMethodTypes";

const BILLING_BASE_URL = "/api/billing";

export const billingApi = {
  paymentMethods: {
    retrieve: (id: ZuoraIdType): Promise<PaymentMethodRetrieveType> => {
      return ajax.get(`${BILLING_BASE_URL}/payment_methods/${id}/`);
    },
  },
};
