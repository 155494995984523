import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@portal/forms/validators";
import { SignUpEnrollmentFormValues } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentFormValuesTypes";

export const signUpEnrollmentFormValidations = {
  enrollmentType: [isRequired],
  serviceStartDate: [isRequired],
};

export const signUpEnrollmentFormValidator =
  generateValidationErrorCollector<SignUpEnrollmentFormValues>(
    signUpEnrollmentFormValidations
  );
