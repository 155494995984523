import {
  StyledContent,
  StyledDialog,
} from "@design-system/components/RhBorderedDialog/RhBorderedDialog.style";
import { RhDialogCloseButton } from "@design-system/components/RhDialogCloseButton/RhDialogCloseButton";
import { DialogProps, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC } from "react";

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

interface RhBorderedDialogProps
  extends Omit<DialogProps, "fullscreen" | "TransitionComponent" | "onClose"> {
  onClose: () => void;
}

export const RhBorderedDialog: FC<
  React.PropsWithChildren<RhBorderedDialogProps>
> = ({ onClose, fullScreen, children, ...props }) => {
  return (
    <StyledDialog
      TransitionComponent={Transition}
      fullScreen
      onClose={onClose}
      {...props}
    >
      <RhDialogCloseButton onClose={onClose} />
      <StyledContent>{children}</StyledContent>
    </StyledDialog>
  );
};
