import { StyledPaper } from "@design-system/components/RhPaper/RhPaper.styled";
import { PaperProps } from "@mui/material";
import React, { FC } from "react";

type RhPaperProps = Omit<PaperProps, "elevation">;

export const RhPaper: FC<RhPaperProps> = (props) => {
  return (
    <StyledPaper variant="outlined" {...props}>
      {props.children}
    </StyledPaper>
  );
};
