import { intlErrorTranslations } from "@portal-shared/components/IntlError/IntlError.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { BaseErrorPage } from "@portal-shared/pages/BaseErrorPage/BaseErrorPage";
import React from "react";

export const IntlError = () => {
  const { translate } = useTranslations();
  const { tIntlErrorErrorHeader, tIntlErrorErrorMessage } = translate(
    intlErrorTranslations
  );

  return (
    <BaseErrorPage header={tIntlErrorErrorHeader} showLinkToHome={false}>
      {tIntlErrorErrorMessage}
    </BaseErrorPage>
  );
};
