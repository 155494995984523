import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { StyledCardHeader } from "@design-system/components/RhCard/RhCard.styles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledCard = styled(RhCard)`
  ${StyledCardHeader} {
    margin-bottom: 0;
  }
`;

export const StyledFeature = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(2)};
`;

export const StyledPlanTitle = styled(RhTypography)`
  &.MuiTypography-root {
    padding-bottom: ${rhSpacingPx(2)};
    padding-right: 0;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      font-size: 20px;
      padding-bottom: 0;
      padding-right: ${rhSpacingPx(4.5)};
    }
  }
`;

export const StyledFeatures = styled.div`
  border-top: 1px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${rhSpacingPx(2)};
  justify-content: space-evenly;
  padding-top: ${rhSpacingPx(2)};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    border-left: 1px solid ${CSSVariables.COLOR_GREY_100};
    border-top: none;
    flex-direction: row;
    padding-left: ${rhSpacingPx(4.5)};
    padding-top: 0;
    width: unset;
  }
`;

export const StyledBody = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  padding-top: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledFeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
`;

export const StyledBreakdown = styled.div`
  display: flex;
  gap: ${rhSpacingPx(1)};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
