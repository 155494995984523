import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal/api/invoiceApi";
import { InvoiceQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillBreakdownCompareQueryProps {
  invoiceId: IdType;
}

export const useBillBreakdownCompareQuery = (
  props: UseBillBreakdownCompareQueryProps
) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.compare(invoiceId),
    queryKey: InvoiceQueryKeys.compare(invoiceId),
  });
};
