import { browserLocaleAtom } from "@portal-shared/components/BrowserLocaleListener/browserLocaleAtom";
import { Locale } from "@portal-shared/types/LocaleTypes";
import { getLocale } from "@portal-shared/utils/getBrowserLocale";
import { atom, useAtom } from "jotai";

const visitorLocaleAtom = atom<Locale | null>(null);

export const localeAtom = atom(
  (get) => {
    const locale = get(visitorLocaleAtom) || get(browserLocaleAtom);

    return locale;
  },
  (get, set, newLocale: Locale) => {
    set(visitorLocaleAtom, getLocale(newLocale));
  }
);

export const useLocaleAtom = () => {
  return useAtom(localeAtom);
};
