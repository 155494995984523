import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import React, { PropsWithChildren } from "react";

interface CustomerHomeSectionInfoMessageProps {}

export const CustomerHomeSectionInfoMessage = ({
  children,
}: PropsWithChildren<CustomerHomeSectionInfoMessageProps>) => (
  <RhTypography variant="subtitle1" color="textSecondary">
    {children}
  </RhTypography>
);
