import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import {
  StyledBillComparisonCard,
  StyledLoadingContainer,
} from "@portal/components/BillComparisonCard/BillComparisonCard.styled";
import React from "react";

export const BillComparisonCardSkeleton = () => (
  <StyledBillComparisonCard>
    <StyledLoadingContainer>
      <Skeleton />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
    </StyledLoadingContainer>
  </StyledBillComparisonCard>
);
