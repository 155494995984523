import { prospectApi } from "@enroll-data/api/prospectApi";
import { prospectQueryKeys } from "@enroll-data/queryKeys/prospectQueryKeys";
import { ProspectCreateRequest } from "@enroll-utils/types/prospectTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UseCreateProspectMutationArgs {
  mutationOptions: {
    retry?: number;
  };
}

export const useCreateProspectMutation = (
  props: UseCreateProspectMutationArgs
) => {
  const {
    mutationOptions: { retry = 2 },
  } = props;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: ProspectCreateRequest) => {
      return prospectApi.create(data);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: prospectQueryKeys.retrieve(),
      });
    },
    retry,
  });

  return mutation;
};
