export const renewalSummaryPlanInfoTranslations = {
  tRenewalSummaryPlanInfoAddress: "Address",
  tRenewalSummaryPlanInfoAveragePrice:
    "Avg price per kWh: {averagePrice} @2000 kWh",
  tRenewalSummaryPlanInfoContractEndDate: "Contract End Date",
  tRenewalSummaryPlanInfoContractStartDate: "Contract Start Date",
  tRenewalSummaryPlanInfoMonths: "{months} month contract",
  tRenewalSummaryPlanInfoName: "Name",
  tRenewalSummaryPlanInfoPlan: "Plan",
};
