import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { StyledPaymentMethodRadioFieldLabel } from "@portal/components/PaymentMethodRadioField/PaymentMethodRadioField.styled";
import { PaymentMethodView } from "@portal/components/PaymentMethodView/PaymentMethodView";
import React from "react";
import { useField } from "react-final-form";

interface CreditCardFieldProps {
  isOneTimePayment?: boolean;
  name: string;
  paymentMethod: PaymentMethod;
}

export const PaymentMethodRadioField = ({
  name,
  isOneTimePayment,
  paymentMethod,
}: CreditCardFieldProps) => {
  const { input } = useField<string>(name, {
    type: "radio",
    value: paymentMethod.id,
  });

  return (
    <StyledPaymentMethodRadioFieldLabel htmlFor={paymentMethod.id}>
      <input
        id={paymentMethod.id}
        {...input}
        hidden
        data-testid="PaymentMethodRadioField__hidden_input"
      />
      <PaymentMethodView
        paymentMethod={paymentMethod}
        highlighted={input.checked}
        isOneTimePayment={isOneTimePayment}
      />
    </StyledPaymentMethodRadioFieldLabel>
  );
};
