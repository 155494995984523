import { StyledToggleSwitch } from "@design-system/components/RhToggle/RhToggle.styled";
import React, { ChangeEvent, FocusEvent } from "react";

interface RhToggleProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  onBlur?: (event: FocusEvent<HTMLButtonElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const RhToggle = ({
  id = "",
  checked = false,
  name = "",
  disabled = false,
  onChange = () => {},
  className = "",
  onBlur,
}: RhToggleProps) => {
  return (
    <StyledToggleSwitch
      className={className}
      data-testid="RhToggle__Switch"
      id={id}
      name={name}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
