export enum GlobalAnnouncementTypes {
  Error = "error",
  Info = "info",
  Notice = "notice",
}

export type DefaultGlobalAnnouncement = {
  dismissible: boolean;
  expires: string;
  textEn: string;
  textEs: string;
  type: GlobalAnnouncementTypes;
};
