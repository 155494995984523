enum AddBankAccountFormError {
  accountNumberInvalid = "accountNumberInvalid",
  fieldEmpty = "fieldEmpty",
  genericError = "genericError",
  routingNumberInvalid = "routingNumberInvalid",
}

export class ZuoraAddBankAccountFormError {
  constructor(
    public errorType: string,
    public errorCode: string,
    public errorDescription: string
  ) {}

  private get accountNumberInvalid() {
    return (
      this.errorType === "error" &&
      this.errorDescription.toLowerCase().includes("account number")
    );
  }

  private get anyFieldEmpty() {
    return this.errorCode === "001";
  }

  private get routingNumberInvalid() {
    const possibleRoutingErrors = ["Invalid ECP Account Route", "E8"];

    return (
      this.errorType === "error" &&
      possibleRoutingErrors.some((str) => this.errorDescription.includes(str))
    );
  }

  get error() {
    if (this.anyFieldEmpty) {
      return AddBankAccountFormError.fieldEmpty;
    } else if (this.routingNumberInvalid) {
      return AddBankAccountFormError.routingNumberInvalid;
    } else if (this.accountNumberInvalid) {
      return AddBankAccountFormError.accountNumberInvalid;
    }

    return AddBankAccountFormError.genericError;
  }
}
