import { UUIDType } from "@common/types/apiTypes";
import { atomWithReset } from "jotai/utils";

export interface GoogleAnalyticsLoggedOutData {
  prospectOfferSnapshotUuid?: UUIDType;
  prospectUuid: UUIDType;
  type: "loggedOut";
}

export interface GoogleAnalyticsLoggedInData {
  customerUuid: UUIDType;
  premiseCurrentOrderUuid?: UUIDType;
  premiseUuid: UUIDType;
  type: "loggedIn";
}

export interface GoogleAnalyticsTokenAuthData {
  customerUuid: UUIDType;
  premiseUuid: UUIDType;
  type: "tokenAuth";
}

export type GoogleAnalyticsData =
  | GoogleAnalyticsLoggedOutData
  | GoogleAnalyticsLoggedInData
  | GoogleAnalyticsTokenAuthData
  | null;

export const googleAnalyticsDataAtom = atomWithReset<GoogleAnalyticsData>(null);
