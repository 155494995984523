import { customerApi } from "@portal/api/customerApi";
import { customerQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseCustomerQueryQueryProps {
  queryOptions: {
    enabled?: boolean;
  };
}
export const useCustomerQuery = (props: UseCustomerQueryQueryProps) => {
  const { queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => customerApi.me(),
    queryKey: customerQueryKeys.me(),
  });
};
