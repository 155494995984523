import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import {
  loadReduxSlice,
  watchPersistedReduxSlice,
} from "@common/services/persistReduxSlice";
import {
  analyticsReducer,
  analyticsSliceName,
  initialAnalyticsState,
} from "@portal/slices/analyticsSlice";
import { billingDetailsReducer } from "@portal/slices/billingDetailsSlice";
import { customerPreferencesReducer } from "@portal/slices/customerPreferencesSlice";
import { premiseReducer } from "@portal/slices/premiseSlice";
import { productAddOnsSlice } from "@portal/slices/productAddOnSlice";
import { rewardsReducer } from "@portal/slices/rewardsSlice";
import {
  initialSignUpState,
  signUpReducer,
  signUpSliceName,
} from "@portal/slices/signUpSlice";
import { rootSaga } from "@portal/store/root.saga";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers<PortalStoreState>({
  analytics: analyticsReducer,
  billingDetails: billingDetailsReducer,
  customerPreferences: customerPreferencesReducer,
  premise: premiseReducer,
  productAddOns: productAddOnsSlice.reducer,
  rewards: rewardsReducer,
  signUp: signUpReducer,
});

export const store = configureStore({
  devTools: !isProduction(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState: {
    analytics: loadReduxSlice(analyticsSliceName, initialAnalyticsState),
    signUp: loadReduxSlice(signUpSliceName, initialSignUpState),
  },
  reducer: rootReducer,
});

watchPersistedReduxSlice(signUpSliceName, store);
watchPersistedReduxSlice(analyticsSliceName, store);
sagaMiddleware.run(rootSaga);

export type PortalState = ReturnType<typeof store.getState>;
