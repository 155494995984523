import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const MyAccountMailingAddressFieldsContainer = styled.div`
  .MuiFormControl-root {
    margin-bottom: ${rhSpacingPx(2.5)};
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    & > * {
      flex: 1 0 50%;
      flex-grow: initial;
    }

    & > *:nth-child(odd) {
      padding-right: ${rhSpacingPx(2)};
    }

    display: flex;
    flex-wrap: wrap;
  }
`;

export const MyAccountMailingAddressFieldsStateContainer = styled.div`
  margin-bottom: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    margin-bottom: 0;
  }
`;

export const MyAccountMailingAddressSelectStateLabel = styled(RhSelectLabel)`
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(0) translateY(-2px);
  transform-origin: top left;
`;

export const MyAccountMailingAddressSelectState = styled(RhSelect)`
  margin-bottom: 0;
  margin-top: 16px;
  padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(1.5)};
`;

export const MyAccountMailingAddressInputStyled = styled(RhTextField).attrs({
  InputProps: {
    sx: {
      paddingBottom: "2px !important",
    },
  },
})``;
