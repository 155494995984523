import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { ZuoraAddBankAccountForm } from "@portal/components/ZuoraAddBankAccountForm/ZuoraAddBankAccountForm";
import { ZuoraCardForm } from "@portal/components/ZuoraCardForm/ZuoraCardForm";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { signUpPaymentPageTranslations } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage.en.i18n";
import { StyledZuoraContainer } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage.styled";
import React from "react";

interface AddCardOrBankAccountZuoraFormProps {
  isAddBank: boolean;
}

export const AddCardOrBankAccountZuoraForm = ({
  isAddBank,
}: AddCardOrBankAccountZuoraFormProps) => {
  const { translate } = useTranslations();
  const {
    tSignUpPaymentPageCreditCardPlease,
    tSignUpPaymentPageTakeCareOfThisNow,
  } = translate(signUpPaymentPageTranslations);

  const { signUpClickNextStepHandler } = useSignUpFlow();

  const {
    featureFlagClient: { trackMetric },
  } = useFeatureFlagClient();

  const handleZuoraSuccessResponse = (
    response: ZuoraAddPaymentResponseType
  ) => {
    trackMetric(FeatureFlagMetric.PortalProspectAddedPaymentMethod);

    signUpClickNextStepHandler({
      nextStep: "summary",
      signUpData: { billingPaymentMethodId: response.refId },
      track: true,
    });
  };

  return (
    <>
      <LoggedOutPageHeader
        headerText={tSignUpPaymentPageCreditCardPlease}
        subHeaderText={tSignUpPaymentPageTakeCareOfThisNow}
      />
      <StyledZuoraContainer>
        {isAddBank ? (
          <ZuoraAddBankAccountForm onSuccess={handleZuoraSuccessResponse} />
        ) : (
          <ZuoraCardForm onSuccess={handleZuoraSuccessResponse} />
        )}
      </StyledZuoraContainer>
    </>
  );
};
