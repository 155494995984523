import {
  SharedModalsAtomType,
  initialSharedModalsAtom,
  sharedModalsStateAtom,
} from "@portal-shared/components/SharedModalsManager/SharedModalsManager.atoms";
import { useBaseModals } from "@portal-shared/hooks/useBaseModals";

export const useSharedModals = () => {
  return useBaseModals<SharedModalsAtomType>(
    sharedModalsStateAtom,
    initialSharedModalsAtom
  );
};
