import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import styled from "styled-components";

export const StyledSwiperCarousel = styled.div<{ $hasPagination: boolean }>`
  swiper-container {
    --swiper-theme-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    --swiper-navigation-top-offset: calc(100% - 20px);
    --swiper-navigation-sides-offset: 10px;
    --swiper-pagination-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    --swiper-pagination-bullet-border-radius: 6px;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 4px;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-inactive-color: ${CSSVariables.COLOR_GREY_200};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bottom: 0rem;
    --swiper-preloader-color: var(--swiper-theme-color);
  }

  swiper-container::part(container) {
    padding-bottom: ${({ $hasPagination }) => ($hasPagination ? "3rem" : 0)};
  }

  swiper-container::part(pagination) {
    /* styles */
    bottom: 0.75rem;
    z-index: 0;
  }

  swiper-container::part(button-prev),
  swiper-container::part(button-next) {
    /* styles */
    z-index: 1;
  }
`;
