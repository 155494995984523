import { IdType, UUIDType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { ProductContractQueryKeys } from "@portal/constants/querykeys.constants";
import { ProductContract } from "@portal/models/ProductContract.model";
import { ProductContractStatusFilter } from "@portal/types/productContractTypes";
import { useQuery } from "@tanstack/react-query";

interface UseProductContractsOptions {
  contractIds?: UUIDType[];
  enabled?: boolean;
  status?: ProductContractStatusFilter;
}

export const useProductContracts = (
  premiseId: IdType,
  options: UseProductContractsOptions = {}
) => {
  const { enabled = true, ...params } = options;
  const { status = ProductContractStatusFilter.ACTIVE } = params;
  const contractIds = params.contractIds ?? [];

  return useQuery({
    enabled,
    queryFn: () =>
      portalApi.premise.productContract.list(premiseId, { ...params, status }),
    queryKey: ProductContractQueryKeys.list.active(premiseId, contractIds),
    select: (data) => {
      return {
        ...data,
        results: data.results.map(
          (productContractType) => new ProductContract(productContractType)
        ),
      };
    },
  });
};
