export const paymentExtensionSuccessTranslations = {
  tPaymentExtensionSuccessBackHome: "Back to Home",
  tPaymentExtensionSuccessConfirmation: "Confirmation {paymentNumber}",
  tPaymentExtensionSuccessDownPayment:
    "Down payment of {amountPaid} has been submitted.",
  tPaymentExtensionSuccessTitle: "Your Payment Extension has been set up!",
  tPaymentExtensionSuccessYouOwe: "You owe {amountExtended} by {extendToDate}.",
  tPaymentExtensionSuccessYouOweImageTitle:
    "Person reading in a chair shaped like a checkmark",
};
