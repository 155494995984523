import { AppBrands } from "@common/enums/appBrands.enum";
import { GroupedCompetitorPlans } from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import {
  CompetitorNames,
  CompetitorPlan,
} from "@portal-shared/types/competitorTypes";

const logoUrlMap: { [key: string]: string } = {
  [CompetitorNames.DirectEnergy]: "assets/images/DirectEnergyLogo.png",
  [CompetitorNames.GreenMountain]: "assets/images/GreenMountainLogo.png",
  [CompetitorNames.Reliant]: "assets/images/ReliantLogo.png",
  [CompetitorNames.SparkEnergy]: "assets/images/SparkEnergyLogo.png",
  [CompetitorNames.TXU]: "assets/images/TxuLogo.png",
  [CompetitorNames.GexaEnergy]: "assets/images/GexaEnergyLogo.png",
  [CompetitorNames.ShellEnergy]: "assets/images/ShellEnergyLogo.png",
  [AppBrands.RHYTHM]: "assets/images/RhythmLogoDarkPurple.png",
  [AppBrands.ENERGY_TEXAS]: "assets/images/et_header_logo.png",
};

export const getLogoUrl = (text?: string | number) => {
  return logoUrlMap[text as string] || "";
};

const competitorsToShow: CompetitorNames[] = [
  CompetitorNames.DirectEnergy,
  CompetitorNames.GexaEnergy,
  CompetitorNames.GreenMountain,
  CompetitorNames.Reliant,
  CompetitorNames.ShellEnergy,
  CompetitorNames.SparkEnergy,
  CompetitorNames.TXU,
];

export const groupByCompetitorAndCalculateAverage = (
  competitorPlans: CompetitorPlan[]
) => {
  const groupedPlans: { [key in CompetitorNames]?: CompetitorPlan[] } = {};

  competitorPlans.forEach((plan) => {
    if (competitorsToShow.includes(plan.competitorName)) {
      if (groupedPlans[plan.competitorName]) {
        groupedPlans[plan.competitorName]?.push(plan);
      } else {
        groupedPlans[plan.competitorName] = [plan];
      }
    }
  });

  return Object.keys(groupedPlans).map((competitorName) => {
    const plans = groupedPlans[competitorName as CompetitorNames];

    if (!plans || plans.length === 0) {
      return {
        averagePrice: 0,
        competitorName: competitorName as CompetitorNames,
        plans: [],
      };
    }
    const total = plans.reduce(
      (sum, plan) => sum + Number(plan.price2000Kwh),
      0
    );

    return {
      averagePrice: total / plans.length,
      competitorName: competitorName as CompetitorNames,
      plans,
    } satisfies GroupedCompetitorPlans;
  });
};

export const getTopCompetitors = ({
  competitors,
  offerSnapshot,
  count = 4,
}: {
  competitors: GroupedCompetitorPlans[];
  count?: number;
  offerSnapshot: OfferSnapshot;
}) => {
  return (
    competitors
      // get rid of any competitor that is less expensive because reasons as well
      .filter(
        (competitor) => offerSnapshot.displayPrice() <= competitor.averagePrice
      )
      .sort((a, b) => a.averagePrice - b.averagePrice)
      .slice(-Math.min(count, competitors.length))
  );
};
