import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledChartWrap = styled.div`
  padding: ${rhSpacingPx(3)} 0;
`;

export const StyledInnerChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding-bottom: ${rhSpacingPx(1)};
`;
