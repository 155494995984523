import { ProspectContext } from "@portal/components/ProspectProvider/ProspectProvider";
import { useContext } from "react";

export const useProspectFromContext = () => {
  const prospect = useContext(ProspectContext);

  if (!prospect) {
    throw new Error("ProspectContext: No value provided");
  }

  return prospect;
};
