import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { Slider } from "@mui/material";
import styled from "styled-components";

export const StyledSlider = styled(Slider)`
  & .MuiSlider-mark {
    background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    height: 6px;
    width: 1px;
  }

  &.MuiSlider-markActive {
    background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    opacity: 1;
  }

  & .MuiSlider-markLabelActive {
    color: ${CSSVariables.COLOR_GREY_500};
  }

  & .MuiSlider-rail {
    border: 1px solid ${CSSVariables.COLOR_PRIMARY_DARK};
    height: 0;
  }

  & .MuiSlider-thumb {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    height: 16px;
    transition: none;
    width: 16px;
  }

  &.MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(69, 0, 165, 0.16);
  }

  & .Mui-active {
    box-shadow: 0px 0px 0px 14px rgba(69, 0, 165, 0.16);
  }

  & .Mui-focusVisible {
    box-shadow: none;
  }

  & .MuiSlider-track {
    background-color: transparent;
    border: none;
    height: 0;
  }

  & .MuiSlider-valueLabel {
    background: transparent;
    color: ${CSSVariables.COLOR_GREY_600};
    top: 42px;
  }
`;
