import { EnvTypes } from "@common/enums/envTypes.enum";
import type { EnvSettings } from "@portal-shared/settings/envSettingsType";

export const rhythmEnvApplicationSettings: Record<EnvTypes, EnvSettings> = {
  local: {
    appBaseUrl: "https://local.gotrhythm.com:3000",
    axiosBaseUrl: "",
    axiosTimeoutMs: 60000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    faviconPath: "/favicon-red.ico",
    fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
    fingerPrintJsUrl: "https://fp.gotrhythm.com",
    googleTagManagerId: "GTM-TZMBBZX",
    launchDarklyClientId: "600b2fc3a99bda0a5faa967f",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingEnergyTexasUrl: "http://localhost:4000",
    marketingRhythmUrl: "http://localhost:1337",
    oktaClientId: "0oamxpidyk9CtuGXw1d5",
    oktaIdentityProviderId: "0oa80qs1ygC40Uvtp1d7",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://local.gotrhythm.com:3000/implicit/callback",
    purecloudOrgId: 10047,
    purecloudOrgName: "entrustenergy",
    purecloudQueueName: "Rhythm Chat/Email TEST",
    rhapsodyBaseUrl: "https://local.gotrhythm.com:3001",
    sentryDSN: "",
    sentryRelease: "",
    sentryTracesSampleRate: 0,
    zuoraAddBankAccountPageId: "8ad096ca8988344e018988a7ec5d3e9b",
    zuoraBaseUrl: "https://apisandbox.zuora.com",
    zuoraCreditCardPaymentPageId: "2c92c0f87304c86701730ae0018914d3",
  },
  localStaging: {
    appBaseUrl: "https://app.staging.gotrhythm.com",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    faviconPath: "/favicon-yellow.ico",
    fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
    fingerPrintJsUrl: "https://fp.gotrhythm.com",
    googleTagManagerId: "GTM-TZMBBZX",
    launchDarklyClientId: "600b2fd042af4409f41221d8",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingEnergyTexasUrl: "https://staging.energytexas.com",
    marketingRhythmUrl: "https://staging.gotrhythm.com",
    oktaClientId: "0oamxpidyk9CtuGXw1d5",
    oktaIdentityProviderId: "0oa80qylgayN6OvSx1d7",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://local.gotrhythm.com:3000/implicit/callback",
    purecloudOrgId: 10047,
    purecloudOrgName: "entrustenergy",
    purecloudQueueName: "Rhythm Chat/Email TEST",
    rhapsodyBaseUrl: "https://ops.staging.gotrhythm.com",
    sentryDSN: "",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
    zuoraAddBankAccountPageId: "8ad096ca8988344e018988a7ec5d3e9b",
    zuoraBaseUrl: "https://apisandbox.zuora.com",
    zuoraCreditCardPaymentPageId: "2c92c0f87304c86701730ae0018914d3",
  },
  production: {
    appBaseUrl: "https://app.gotrhythm.com",
    axiosBaseUrl: "https://api.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.gotrhythm.com",
    faviconPath: "/favicon.ico",
    fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
    fingerPrintJsUrl: "https://fp.gotrhythm.com",
    fullStoryOrgId: "ZPQE0",
    googleTagManagerId: "GTM-PZNH4K6",
    launchDarklyClientId: "600b2e71df705609ed1d1e0a",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingEnergyTexasUrl: "https://energytexas.com",
    marketingRhythmUrl: "https://www.gotrhythm.com",
    oktaClientId: "0oa17m8ta3OQSmDmr4x7",
    oktaIdentityProviderId: "0oaclqul8882XAIPM4x7",
    oktaIssuer: "https://login.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://app.gotrhythm.com/implicit/callback",
    purecloudOrgId: 10047,
    purecloudOrgName: "entrustenergy",
    purecloudQueueName: "Rhythm Organic Chat/Email",
    rhapsodyBaseUrl: "https://ops.gotrhythm.com",
    sentryDSN:
      "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
    sentryOrgSlug: "rhythm",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.2,
    zuoraAddBankAccountPageId: "8a1286298a02c0b0018a050b820e00b1",
    zuoraBaseUrl: "https://zuora.com",
    zuoraCreditCardPaymentPageId: "2c92a0fd756acf9001756b6968ad7813",
  },
  staging: {
    appBaseUrl: "https://app.staging.gotrhythm.com",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    cdnUrl: "https://cdn.staging.gotrhythm.com",
    faviconPath: "/favicon-yellow.ico",
    fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
    fingerPrintJsUrl: "https://fp.gotrhythm.com",
    googleTagManagerId: "GTM-TZMBBZX",
    launchDarklyClientId: "600b2fd042af4409f41221d8",
    mapboxToken:
      "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
    marketingEnergyTexasUrl: "https://staging.energytexas.com",
    marketingRhythmUrl: "https://staging.gotrhythm.com",
    oktaClientId: "0oaj5ilgwWg4oAcF51d5",
    oktaIdentityProviderId: "0oa80qylgayN6OvSx1d7",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://app.staging.gotrhythm.com/implicit/callback",
    purecloudOrgId: 10047,
    purecloudOrgName: "entrustenergy",
    purecloudQueueName: "Rhythm Chat/Email TEST",
    rhapsodyBaseUrl: "https://ops.staging.gotrhythm.com",
    sentryDSN:
      "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
    zuoraAddBankAccountPageId: "8ad09408899cc66801899d5b300936f9",
    zuoraBaseUrl: "https://apisandbox.zuora.com",
    zuoraCreditCardPaymentPageId: "8ad085e289de67b00189dfff063f4de5",
  },
};
