export const disconnectDeviceModalTranslations = {
  tDisconnectDeviceModalAgreementText:
    "I agree that, by disconnecting, I am forgoing my {credit}/month bill credit moving forward, effective immediately.",
  tDisconnectDeviceModalCancel: "Nevermind",
  tDisconnectDeviceModalConfirm: "Disconnect Device",
  tDisconnectDeviceModalError: "Error disconnecting device. Please try again.",
  tDisconnectDeviceModalScheduledDisconnectionNotice:
    "Device scheduled for disconnection with {brand}",
  tDisconnectDeviceModalTitle: "Disconnect Device",
};
