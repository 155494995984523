import { captureException, withScope } from "@sentry/react";

export enum CreditCardFormError {
  expirationDateInvalid = "expirationDateInvalid",
  expiredCard = "expiredCard",
  fieldEmpty = "fieldEmpty",
  genericDecline = "genericDecline",
  insufficientFunds = "insufficientFunds",
  lostCard = "lostCard",
  nameInvalid = "nameInvalid",
  numberInvalid = "numberInvalid",
  securityCodeInvalid = "securityCodeInvalid",
  stolenCard = "stolenCard",
  tooManyTries = "tooManyTries",
  unknownError = "unknownError",
  zipCodeInvalid = "zipCodeInvalid",
}

/**
 * Truly this is a horrible piece of code, I tried nested if statements, combined if statements,
 * switch statement and flatten statements... and don't you dare to suggest regex... all of them look horrible
 *
 * Class dealing with each case as a private method is the most readable IMO... still horrible... murder me slowly
 *
 *
 * Documenting samples of zuora error so you better understand this horrible class.
 *
 * ["cardSecurityCode","001","Required"] - Happens when cvv is empty
 * ["cardSecurityCode","002","Invalid"] - Happens when cvv is invalid, like adding letters  (doesn't even go the the BE to check)
 * ["creditCardExpirationMonth","001","Required"] - Happens when month or year are empty
 * ["creditCardHolderName","001","Required"] - Happens when name is empty
 * ["creditCardNumber","001","Required"] - Happens when card number is empty
 * ["creditCardNumber","002","Invalid"] - Happens when card number is invalid, meaning a card number like that one is impossible (doesn't even go the the BE to check)
 * ["creditCardPostalCode","001","Required"] - Happens when zip code is empty
 * ["error","unknown","[Attempt_Exceed_Limitation]&nbsp; Attempt exceed the limitation, refresh page to try again."] - Submit button is permanently disabled after user tries 3 unsuccessful tries
 * ["error","unknown","[BusinessValidationError]&nbsp; Expiration date must be a future date."] - Expiration date is in the past
 * ["error","unknown","[BusinessValidationError]&nbsp; Invalid Month"] - Expiration month is in the past
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/card_declined/generic_decline] Your card was declined."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/card_declined/insufficient_funds] Your card has insufficient funds."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/card_declined/lost_card] Your card was declined."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/card_declined/stolen_card] Your card was declined."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/expired_card] Your card has expired."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/incorrect_cvc] Your card's security code is incorrect."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/invalid_cvc] Your card's security code is invalid."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/invalid_expiry_year] Your card's expiration year is invalid."]
 * ["error","unknown","[GatewayTransactionError]&nbsp; Transaction declined.402 - [card_error/processing_error] An error occurred while processing your card. Try again in a little bit."]
 */

export class ZuoraInlineFormError {
  constructor(
    public errorType: string,
    public errorCode: string,
    public errorDescription: string
  ) {}

  private get anyFieldEmpty() {
    return this.errorDescription === "Required";
  }

  private get securityCodeInvalid() {
    return (
      (this.errorType === "cardSecurityCode" &&
        this.errorDescription === "Invalid") ||
      this.errorDescription.includes("card_error/incorrect_cvc") ||
      this.errorDescription.includes("card_error/invalid_cvc")
    );
  }

  private get expirationDateInvalid() {
    return (
      (this.errorType === "creditCardExpirationMonth" &&
        this.errorDescription === "Invalid") ||
      this.errorDescription.includes("Expiration date must be a future date") ||
      this.errorDescription.includes("Invalid Month") ||
      this.errorDescription.includes("card_error/invalid_expiry_year")
    );
  }

  private get nameInvalid() {
    return (
      this.errorType === "creditCardHolderName" &&
      this.errorDescription === "Invalid"
    );
  }

  private get numberInvalid() {
    return (
      this.errorType === "creditCardNumber" &&
      this.errorDescription === "Invalid"
    );
  }

  private get zipCodeInvalid() {
    return (
      this.errorType === "creditCardPostalCode" &&
      this.errorDescription === "Invalid"
    );
  }

  private get tooManyTries() {
    return this.errorDescription.includes("Attempt_Exceed_Limitation");
  }

  private get genericDecline() {
    return this.errorDescription.includes(
      "card_error/card_declined/generic_decline"
    );
  }

  private get insufficientFunds() {
    return this.errorDescription.includes(
      "card_error/card_declined/insufficient_funds"
    );
  }

  private get lostCard() {
    return this.errorDescription.includes("card_error/card_declined/lost_card");
  }

  private get stolenCard() {
    return this.errorDescription.includes(
      "card_error/card_declined/stolen_card"
    );
  }

  private get expiredCard() {
    return this.errorDescription.includes("card_error/expired_card");
  }

  private get unknownError() {
    return this.errorDescription.includes("card_error/processing_error");
  }

  get error() {
    if (this.anyFieldEmpty) {
      return CreditCardFormError.fieldEmpty;
    } else if (this.securityCodeInvalid) {
      return CreditCardFormError.securityCodeInvalid;
    } else if (this.expirationDateInvalid) {
      return CreditCardFormError.expirationDateInvalid;
    } else if (this.nameInvalid) {
      return CreditCardFormError.nameInvalid;
    } else if (this.numberInvalid) {
      return CreditCardFormError.numberInvalid;
    } else if (this.zipCodeInvalid) {
      return CreditCardFormError.zipCodeInvalid;
    } else if (this.tooManyTries) {
      return CreditCardFormError.tooManyTries;
    } else if (this.genericDecline) {
      return CreditCardFormError.genericDecline;
    } else if (this.insufficientFunds) {
      return CreditCardFormError.insufficientFunds;
    } else if (this.lostCard) {
      return CreditCardFormError.lostCard;
    } else if (this.stolenCard) {
      return CreditCardFormError.stolenCard;
    } else if (this.expiredCard) {
      return CreditCardFormError.expiredCard;
    } else if (this.unknownError) {
      return CreditCardFormError.unknownError;
    }

    withScope((scope) => {
      const errorToCapture = new Error(
        "Unknown Zuora Credit Card inline form error"
      );
      const errorData = {
        errorCode: this.errorCode,
        errorDescription: this.errorDescription,
        errorType: this.errorType,
      };

      errorToCapture.name = "Zuora Credit Card Inline Form Error";

      scope.setContext("Zuora CC Form Error", errorData);
      captureException(errorToCapture);
    });

    return CreditCardFormError.unknownError;
  }
}
