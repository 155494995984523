import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePremise } from "@portal/hooks/usePremise";
import { createTrackEvent } from "@portal/services/segment.service";

export function useTrackMyAccountEvents() {
  const { premise } = usePremise();
  const customer = useCustomerFromContext();

  const trackEvent = createTrackEvent({
    customerId: customer?.id,
    premiseId: premise?.id,
  });

  return trackEvent;
}
