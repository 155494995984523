import { ZuoraIdType } from "@common/types/apiTypes";
import { billingApi } from "@enroll-data/api/billingApi";
import { billingQueryKeys } from "@enroll-data/queryKeys/billingQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UsePaymentMethodQueryProps {
  paymentMethodId: ZuoraIdType;
  queryOptions: {
    enabled?: boolean;
  };
}
export const usePaymentMethodQuery = (props: UsePaymentMethodQueryProps) => {
  const { paymentMethodId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => billingApi.paymentMethods.retrieve(paymentMethodId),
    queryKey: billingQueryKeys.retrieve(paymentMethodId),
  });
};
