import { ReactComponent as TandemBikeLeft } from "@portal-shared/images/TandemBikeLeft.svg";
import { ReactComponent as TandemBikeRight } from "@portal-shared/images/TandemBikeRight.svg";
import {
  PortalPageLayoutStyledPageBackground,
  PortalPageLayoutStyledTandemBikeLeft,
  PortalPageLayoutStyledTandemBikeRight,
} from "@portal-shared/styled/portalPageLayout.styled";
import React from "react";

export const EnrollOffersPageBackground = () => {
  return (
    <>
      <PortalPageLayoutStyledTandemBikeLeft>
        <TandemBikeLeft />
      </PortalPageLayoutStyledTandemBikeLeft>
      <PortalPageLayoutStyledTandemBikeRight>
        <TandemBikeRight />
      </PortalPageLayoutStyledTandemBikeRight>
      <PortalPageLayoutStyledPageBackground />
    </>
  );
};
