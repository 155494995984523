import { isLocal } from "@common/services/getEnvApplicationSettings.service";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { GlobalBrandedThemeStyles } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { CssReset } from "@design-system/components/CssReset/CssReset";
import { RhAnnouncementProvider } from "@design-system/components/RhAnnouncement/RhAnnouncementProvider";
import { RhFlashProvider } from "@design-system/components/RhFlashProvider/RhFlashProvider";
import { muiTheme } from "@design-system/theme/mui.theme";
import isPropValid from "@emotion/is-prop-valid";
import {
  CacheLocation,
  FingerprintJSPro,
  FpjsProvider,
} from "@fingerprintjs/fingerprintjs-pro-react";
import { ThemeProvider } from "@mui/material";
import { BrowserLocaleListener } from "@portal-shared/components/BrowserLocaleListener/BrowserLocaleListener";
import { GoogleAnalyticsTracking } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking";
import { PageAnalytics } from "@portal-shared/components/PageAnalytics/PageAnalytics";
import { PortalAnnouncement } from "@portal-shared/components/PortalAnnouncement/PortalAnnouncement";
import { PortalDevTools } from "@portal-shared/components/PortalDevTools/PortalDevTools";
import { PortalErrorBoundary } from "@portal-shared/components/PortalErrorBoundary/PortalErrorBoundary";
import { PortalHelmet } from "@portal-shared/components/PortalHelmet/PortalHelmet";
import { PortalIntlProvider } from "@portal-shared/components/PortalIntlProvider/PortalIntlProvider";
import { ScrollToTop } from "@portal-shared/components/ScrollToTop/ScrollToTop";
import {
  FINGERPRINT_JS_API_KEY,
  FINGERPRINT_JS_URL,
} from "@portal-shared/settings/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { PropsWithChildren, StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import {
  StyleSheetManager,
  ThemeProvider as StyledComponentsThemProvider,
} from "styled-components";

const queryClient = new QueryClient();

interface PortalSharedProvidersProps {}

function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === "string") {
    if (isLocal() && !isPropValid(propName)) {
      // eslint-disable-next-line no-console
      console.log({ propName, target });
    }
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

export const PortalSharedProviders = (
  props: PropsWithChildren<PortalSharedProvidersProps>
) => {
  const { children } = props;

  return (
    <StrictMode>
      <CssReset />
      <BrowserRouter>
        <PortalIntlProvider>
          <PortalErrorBoundary>
            <ThemeProvider theme={muiTheme}>
              <StyledComponentsThemProvider theme={brandTheme}>
                <ScrollToTop />
                <FpjsProvider
                  cacheLocation={CacheLocation.LocalStorage}
                  loadOptions={{
                    apiKey: FINGERPRINT_JS_API_KEY,
                    endpoint: [
                      FINGERPRINT_JS_URL,
                      FingerprintJSPro.defaultEndpoint,
                    ],
                    scriptUrlPattern: [
                      `${FINGERPRINT_JS_URL}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                      FingerprintJSPro.defaultScriptUrlPattern,
                    ],
                  }}
                >
                  <RhAnnouncementProvider>
                    <RhFlashProvider>
                      <QueryClientProvider client={queryClient}>
                        <StyleSheetManager
                          shouldForwardProp={shouldForwardProp}
                        >
                          <HelmetProvider>
                            <GlobalBrandedThemeStyles />
                            <BrowserLocaleListener />
                            <GoogleAnalyticsTracking />
                            <PortalAnnouncement />
                            <PortalHelmet />
                            <PortalDevTools />
                            <PageAnalytics />

                            {children}
                          </HelmetProvider>
                        </StyleSheetManager>
                      </QueryClientProvider>
                    </RhFlashProvider>
                  </RhAnnouncementProvider>
                </FpjsProvider>
              </StyledComponentsThemProvider>
            </ThemeProvider>
          </PortalErrorBoundary>
        </PortalIntlProvider>
      </BrowserRouter>
    </StrictMode>
  );
};
