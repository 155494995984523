import { IdType } from "@common/types/apiTypes";

const SMART_THERMOSTATS_BASE_URL = "/api/portal/smart-thermostats";

export const smartThermostatsUrls = {
  destroy: (smartThermostatsId: IdType) =>
    `${SMART_THERMOSTATS_BASE_URL}/${smartThermostatsId}/`,
  swapPremise: (smartThermostatId: IdType) =>
    `${SMART_THERMOSTATS_BASE_URL}/${smartThermostatId}/swap-premise/`,
};
