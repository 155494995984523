export const SignInPageTranslations = {
  tSignInPageAreYouAnEntrustCustomer:
    "Are you a former Entrust or Power of Texas customer? <link>Please log in here</link>",
  tSignInPageForgotPassword: "Forgot password?",
  tSignInPageGoogleAuthCta: "Log in with Google",
  tSignInPageHtmlDescription:
    "Sign in to your Rhythm electricity account to view your energy plan, change your billing date and more.",
  tSignInPageHtmlTitle: "Sign into Your Account - Rhythm Electricity",
  tSignInPageLogIn: "Log in",
};
