import { useDetectMultipleSignUpFlowTabs } from "@portal/hooks/useDetectMultipleSignUpFlowTabs";
import { useFingerPrintJS } from "@portal/hooks/useFingerPrintJS";
import {
  useFeatureFlagIdentifyCredit,
  useFingerPrintIdentify,
  useRedirectIfCsrOrOps,
  useSendCustomerLoginEvent,
} from "@portal/hooks/useGlobalLogic";
import { useLanguagePreference } from "@portal/hooks/useLanguagePreference";
import { useRenewalReminderModal } from "@portal/hooks/useRenewalReminderModal";
import { useSwitchEBillToPaperlessModal } from "@portal/hooks/useSwitchEBillToPaperlessModal";
import { useSwitchPrintToPaperlessModal } from "@portal/hooks/useSwitchPrintToPaperlessModal";

export const GlobalLogic = () => {
  useFeatureFlagIdentifyCredit();
  useSendCustomerLoginEvent();
  useRedirectIfCsrOrOps();
  useFingerPrintJS();
  useFingerPrintIdentify();
  useLanguagePreference();
  useDetectMultipleSignUpFlowTabs();
  useSwitchEBillToPaperlessModal();
  useSwitchPrintToPaperlessModal();
  useRenewalReminderModal();

  return null;
};
