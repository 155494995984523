import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreatePaymentExtensionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: premiseApi.paymentExtensions,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: PremiseQueryKeys.retrieve(premiseId),
      });
    },
  });

  return mutation;
};
