import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledBillSummaryContainer = styled.div`
  width: 100%;
`;

export const StyledHeader = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(2.5)};
    text-align: center;
  }
`;

export const StyledAutoPayInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rhSpacingPx(3)};
`;

export const StyledBillInvoicesContainer = styled.div`
  margin-bottom: ${rhSpacingPx(3)};
`;

export const StyledAmountDue = styled.div`
  border-bottom: ${borders[100]};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rhSpacingPx(3)};
  padding-bottom: ${rhSpacingPx(2.5)};
`;
