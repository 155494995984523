export const offerSnapshotFAQsMonthToMonthTranslations = {
  tOfferSnapshotFAQsMonthToMontWhatFeesAnswer:
    "We try to be upfront about the fees we charge so they don't come as a surprise to you. We charge late fees if your payment is past due or if there are insufficient funds in your account. And, if your wires provider charges fees for use of the power grid, we pass those fees along to you without markup. That's it. Those are the fees.",
  tOfferSnapshotFAQsMonthToMontWhatFeesQuestion:
    "What kind of fees can I expect with Rhythm?",
  tOfferSnapshotFAQsMonthToMonthAfterMonthEndsAnswer:
    "You will continue to enjoy the freedom on being on a month-to-moth plan at the most current rate that is available to Rhythm's new and existing customers looking for a flexible plan option without a contract.",
  tOfferSnapshotFAQsMonthToMonthAfterMonthEndsQuestion:
    "What happens after my month is up?",
};
