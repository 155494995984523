export const billEnergyUsageTemperatureCardTranslations = {
  tBillEnergyUsageTemperatureCardAverage: "Average",
  tBillEnergyUsageTemperatureCardAverageTemp: "Average Temperature",
  tBillEnergyUsageTemperatureCardCost: "Cost:",
  tBillEnergyUsageTemperatureCardDailyUsage: "Daily Usage (kWh)",
  tBillEnergyUsageTemperatureCardDate: "Date:",
  tBillEnergyUsageTemperatureCardEnergyUsage: "Energy Usage + Temperature",
  tBillEnergyUsageTemperatureCardError:
    "There is no usage data to show for this invoice.",
  tBillEnergyUsageTemperatureCardHeresHowMother:
    "Here's how Mother Nature behaved during your last billing period, and how it may have impacted your usage.",
  tBillEnergyUsageTemperatureCardHigh: "High",
  tBillEnergyUsageTemperatureCardHighLow: "High/Low Temperature",
  tBillEnergyUsageTemperatureCardHighTemp: "High Temperature",
  tBillEnergyUsageTemperatureCardLow: "Low",
  tBillEnergyUsageTemperatureCardLowTemp: "Low Temperature",
  tBillEnergyUsageTemperatureCardTemperature: "Temperature",
  tBillEnergyUsageTemperatureCardTemperatureFahrenheit: "Temperature (°F)",
  tBillEnergyUsageTemperatureCardUsage: "Usage",
  tBillEnergyUsageTemperatureCardUsagekWh: "Usage (kWh)",
};
