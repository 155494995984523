import { UUIDType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CheckIcon2 } from "@design-system/icons/CheckIcon2.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import {
  ProductAddonsOptInConfirmationAddOnsContainer,
  ProductAddonsOptInConfirmationModalCloseButton,
  ProductAddonsOptInConfirmationModalCloseButtonContainer,
  ProductAddonsOptInConfirmationModalHeader,
} from "@portal/components/ProductAddonsOptInConfirmationModal/ProductAddonsOptInConfirmationModal.style";
import { SingleProductTiePriceDisplay } from "@portal/components/ProductAddonsOptInConfirmationModal/SingleProductTiePriceDisplay";
import { useProductContracts } from "@portal/hooks/queries/useProductContracts";
import { usePremise } from "@portal/hooks/usePremise";
import React from "react";

export interface ProductAddonsOptInConfirmationModalProps {
  contractIdsAdded: UUIDType[];
  handleClose(): void;
}

export const ProductAddonsOptInConfirmationModal = (
  props: ProductAddonsOptInConfirmationModalProps
) => {
  const { contractIdsAdded, handleClose } = props;

  const { premise } = usePremise();
  const { t, tJsx } = useRhIntl();

  const query = useProductContracts(premise?.id ?? "", {
    contractIds: contractIdsAdded || [],
    enabled: Boolean(premise) && contractIdsAdded.length > 0,
  });

  if (contractIdsAdded.length === 0) {
    handleClose();

    return null;
  }

  if (!premise === null) {
    return null;
  }

  if (query.isPending) {
    return <RhCircularProgress />;
  }

  if (query.isError) {
    handleClose();
    return null;
  }

  const {
    data: { results: productContracts },
  } = query;

  const tHeader = t("ProductAddonsOptInConfirmationModal.header");
  const tMonth = t("ProductAddonsOptInConfirmationModal.month");
  // This is technically wrong, given the that the contract itself can be months or years.
  // However I confirmed with Bessy (PM) and she mentioned there is currently to plans to add anything other that month terms
  // This allows us to handle translations, as opposed to the English word coming from the BE.
  const tMonths = t("ProductAddonsOptInConfirmationModal.months");

  const selectedProductPricesDisplay = productContracts.map(
    (productContract) => {
      const tProductPriceTermLength = tJsx(
        "ProductAddonsOptInConfirmationModal.numTermLength",
        {
          termLength: productContract.termLength,
          termPeriod: tMonths,
        }
      ) as string;

      return (
        <SingleProductTiePriceDisplay
          key={productContract.id}
          productContractTermLength={tProductPriceTermLength}
          productContractRate={productContract.getFormattedPricePerFrequency(
            tMonth
          )}
          productContractDescription={productContract.tierDescription}
          productContractName={productContract.tierName}
        />
      );
    }
  );

  const tModalAriaLabel = t(
    "ProductAddonsOptInConfirmationModal.modalAriaLabel"
  );
  const tCloseAriaLabel = t(
    "ProductAddonsOptInConfirmationModal.closeAriaLabel"
  );
  const tCloseBottonCta = t(
    "ProductAddonsOptInConfirmationModal.closeButtonCta"
  );

  return (
    <RhModal
      ariaLabel={tModalAriaLabel}
      ariaCloseLabel={tCloseAriaLabel}
      handleClose={() => {
        handleClose();
      }}
      size={ModalWidths.sm}
    >
      <ProductAddonsOptInConfirmationModalHeader>
        <CheckIcon2 width={rhSpacingPx(8)} />
        <RhTypography variant="h2">{tHeader}</RhTypography>
      </ProductAddonsOptInConfirmationModalHeader>
      <ProductAddonsOptInConfirmationAddOnsContainer>
        {selectedProductPricesDisplay}
      </ProductAddonsOptInConfirmationAddOnsContainer>
      <ProductAddonsOptInConfirmationModalCloseButtonContainer>
        <ProductAddonsOptInConfirmationModalCloseButton
          data-tracking-click={{
            event: "Customer closing Product addon added confirmation modal",
          }}
          color="primary"
          onClick={() => {
            handleClose();
          }}
        >
          {tCloseBottonCta}
        </ProductAddonsOptInConfirmationModalCloseButton>
      </ProductAddonsOptInConfirmationModalCloseButtonContainer>
    </RhModal>
  );
};
