import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useFetchRSASignatureQuery } from "@portal-shared/queries/useFetchRSASignatureQuery";
import { getZuoraParams } from "@portal-shared/utils/baseZuoraParams.util";
import { ZuoraAddBankAccountTranslations } from "@portal/components/ZuoraAddBankAccountForm/ZuoraAddBankAccountForm.en.i18n";
import { ZuoraAddBankAccountFormError } from "@portal/components/ZuoraAddBankAccountForm/ZuoraAddBankAccountFormError.service";
import { useUpdatePremisePaymentMethodMutation } from "@portal/hooks/mutations/useUpdatePremisePaymentMethod.mutation";
import { selectCustomerPremisePreference } from "@portal/selectors/customerPreferencesSelectors";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface ZuoraAddBankAccountFormProps {
  onFailure?(error: string): void;
  onSuccess(response: ZuoraAddPaymentResponseType): void;
  paymentOptions?: PaymentMethodOptionsType;
}

export const ZuoraAddBankAccountForm = ({
  onFailure,
  onSuccess,
  paymentOptions = {
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  },
}: ZuoraAddBankAccountFormProps) => {
  const [isZuoraFormLoading, setIsZuoraFormLoading] = useState(true);
  const { translate } = useTranslations();
  const translations = translate(ZuoraAddBankAccountTranslations);
  const premiseId = useSelector(selectCustomerPremisePreference);
  const flash = useRhFlash();
  const zuoraParams = getZuoraParams(dayjs().locale(), true);
  const fetchRSASignatureQuery = useFetchRSASignatureQuery(zuoraParams.id);
  const updatePaymentMethodMutation = useUpdatePremisePaymentMethodMutation();

  const debounceErrorMessageCallback = debounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraAddBankAccountErrorService = new ZuoraAddBankAccountFormError(
        errorType,
        errorCode,
        errorDescription
      );

      const { error } = zuoraAddBankAccountErrorService;
      const errorMessage = translations[`formError.${error}`];

      flash.error(errorMessage);
    },
    250
  );

  const handleZuoraResponse = (response: ZuoraAddPaymentResponseType) => {
    const errorMessage = translations.errorAddingBankAccount;

    if (!response.success) {
      onFailure?.(errorMessage);

      return Promise.resolve();
    }

    if (premiseId) {
      updatePaymentMethodMutation.mutate(
        {
          paymentMethodId: response.refId,
          premiseId,
          ...paymentOptions,
        },
        {
          onError: () => onFailure?.(errorMessage),
          onSuccess: () => onSuccess(response),
        }
      );
    } else {
      onSuccess(response);
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (fetchRSASignatureQuery.isPending) {
      return;
    }

    zuora.setEventHandler("onloadCallback", () => {
      setIsZuoraFormLoading(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...zuoraParams,
        ...fetchRSASignatureQuery.data,
      },
      {},
      handleZuoraResponse,
      debounceErrorMessageCallback
    );
  }, [fetchRSASignatureQuery.isPending]);

  if (fetchRSASignatureQuery.isError) {
    onFailure?.(translations.authorizationFailed);
    return null;
  }

  return (
    <>
      {isZuoraFormLoading && <RhCircularProgress marginBottom={4} />}
      <div id="zuora_payment" />
    </>
  );
};
