import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const LOGGED_OUT_FIELD_SPACING = 2;
export const LOGGED_OUT_CONTAINER_SPACING = 4;

interface Props {
  $noMargin: boolean;
}

export const StyledSignUpFieldsLayoutContainer = styled.div<Props>`
  margin-bottom: ${({ $noMargin }) =>
    $noMargin ? "0" : rhSpacingPx(LOGGED_OUT_CONTAINER_SPACING)};
  margin-top: ${({ $noMargin }) =>
    $noMargin ? "0" : rhSpacingPx(LOGGED_OUT_CONTAINER_SPACING)};
`;
