export const signUpSSNRequiredPageTranslations = {
  tSignUpSSNRequiredPageCreditScoreApiError:
    "We are having issues verifying your credit. Please try again.",
  tSignUpSSNRequiredPageNext: "Next",
  tSignUpSSNRequiredPageRunCreditCheck: "Run credit check using SSN.",
  tSignUpSSNRequiredPageSkipCreditCheck: "Skip credit check and pay deposit.",
  tSignUpSSNRequiredPageSocialSecurityNumber: "Social Security Number",
  tSignUpSSNRequiredPageTimeForQuickCreditCheck:
    "Time for a quick credit check.",
  tSignUpSSNRequiredPageVerifyYourIdentity:
    "In order to start service, we need to verify your identity. Your credit won't be affected by this check.",
};
