import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledRewardsHistoryTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledRewardsHistoryRow = styled.div`
  align-items: center;
  border-bottom: ${borders[100]};
  display: flex;
  justify-content: space-between;
  padding-bottom: ${rhSpacingPx(2)};
  padding-top: ${rhSpacingPx(2)};
`;

export const StyledRowElement = styled(RhTypography)`
  &.MuiTypography-root {
    display: flex;
    padding-right: ${rhSpacingPx(2)};
  }
`;

export const StyledDate = styled(StyledRowElement)`
  flex-basis: 10%;
`;

export const StyledDescription = styled(StyledRowElement)`
  flex-basis: 50%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-basis: 60%;
  }
`;

export const StyledPointsAmount = styled(StyledRowElement)`
  color: ${CSSVariables.COLOR_GREY_500};
  flex-basis: 20%;
  justify-content: flex-end;
  text-align: right;
`;

export const StyledPointsValue = styled(StyledRowElement)`
  flex-basis: 20%;
  justify-content: flex-end;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-basis: 10%;
  }
`;
