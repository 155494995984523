import { IdType } from "@common/types/apiTypes";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardSubheader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { DeviceDemandResponseActivity } from "@portal/components/DeviceDemandResponseActivity/DeviceDemandResponseActivity";
import { deviceDemandResponseActivityCardTranslations } from "@portal/components/DeviceDemandResponseActivityCard/DeviceDemandResponseActivityCard.en.i18n";
import React from "react";

interface DeviceDemandResponseActivityCardProps {
  deviceEligibilityEvents: number | undefined;
  deviceId: IdType;
  premiseId: IdType;
}

export const DeviceDemandResponseActivityCard = (
  props: DeviceDemandResponseActivityCardProps
) => {
  const { deviceId, premiseId, deviceEligibilityEvents } = props;
  const { translate, translateJsx } = useTranslations();
  const { tDeviceDemandResponseActivityCardTitle } = translate(
    deviceDemandResponseActivityCardTranslations
  );

  const { tDeviceDemandResponseActivityCardSubheader } = translateJsx({
    tDeviceDemandResponseActivityCardSubheader: {
      events: deviceEligibilityEvents,
    },
  });

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>
          {tDeviceDemandResponseActivityCardTitle}
        </PortalCardTitle>
        {deviceEligibilityEvents ? (
          <PortalCardSubheader>
            {tDeviceDemandResponseActivityCardSubheader}
          </PortalCardSubheader>
        ) : null}
      </PortalCardHeader>
      <DeviceDemandResponseActivity deviceId={deviceId} premiseId={premiseId} />
    </PortalCard>
  );
};
