import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledRewardsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 66.667%;
`;

export const StyledRewardsPageContainerInner = styled.div`
  margin-bottom: ${rhSpacingPx(2.5)};
`;

export const StyledDescriptionTextContainer = styled.div`
  margin-top: ${rhSpacingPx(1)};
`;
