import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhListItemText } from "@design-system/components/RhListItemText/RhListItemText.styled";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const StyledListItemText = styled(RhListItemText)`
  color: ${CSSVariables.COLOR_GREY_500};

  & .MuiListItemText-primary {
    font-family: ${fontFamily};
  }
`;

export const StyledOptOut = styled.div`
  border-top: ${borders[100]};
  margin-top: ${rhSpacingPx(2)};
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
`;
