import { RhCard } from "@design-system/components/RhCard/RhCard";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledSectionContainer = styled(RhCard)`
  &.MuiCard-root {
    max-width: 100%;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      width: 350px;
    }
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`;

export const StyledBalanceDetailsBox = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(2)};
  padding: 0 ${rhSpacingPx(2)};
`;

export const StyledLinkContainer = styled.div`
  margin: ${rhSpacingPx(4)} 0 ${rhSpacingPx(2.5)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    margin: 0 0 ${rhSpacingPx(2.5)};
  }
`;
