import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhModalTitle } from "@design-system/components/RhModal/RhModal.styled";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledCardContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledRhInnerCard = styled(RhInnerCard)`
  cursor: pointer;
  min-height: 0;
`;

export const StyledTitle = styled(RhModalTitle)`
  padding: ${rhSpacingPx(2)};
  text-align: center;
`;
