import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsDiscountPlanTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsDiscountPlan/OfferSnapshotFAQsDiscountPlan.en.i18n";
import { OfferSnapshotFAQsTitleWrapper } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface OfferSnapshotFAQsDiscountPlanProps {
  monthlyDiscountAmount: string;
}

export const OfferSnapshotFAQsDiscountPlan = (
  props: OfferSnapshotFAQsDiscountPlanProps
) => {
  const { monthlyDiscountAmount } = props;

  const { translate, translateJsx } = useTranslations();

  const {
    tOfferSnapshotFAQsDiscountPlanWhatDoIDoDescription,
    tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffTitle,
    tOfferSnapshotFAQsDiscountTitle,
  } = translate(offerSnapshotFAQsDiscountPlanTranslations);

  const {
    tOfferSnapshotFAQsDiscountPlanWhatDoIDoTitle,
    tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffDescription,
  } = translateJsx({
    tOfferSnapshotFAQsDiscountPlanWhatDoIDoTitle: {
      discountAmount: formatCurrency(monthlyDiscountAmount),
    },
    tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffDescription: {
      discountAmount: formatCurrency(monthlyDiscountAmount),
    },
  });

  return (
    <OfferSnapshotFAQsTitleWrapper title={tOfferSnapshotFAQsDiscountTitle}>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsDiscountPlanWhatDoIDoTitle}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsDiscountPlanWhatDoIDoDescription}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffTitle}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffDescription}
        </RhTypography>
      </PortalAccordion>
    </OfferSnapshotFAQsTitleWrapper>
  );
};
