import { portalApi } from "@portal/api/api";
import { ProductContractQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateProductContract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: portalApi.premise.productContract.create,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: ProductContractQueryKeys.list.DEFAULT(premiseId),
      });
    },
  });
};
