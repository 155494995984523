import { useDynamicIntl } from "@portal-shared/hooks/useDynamicIntl";
import React, { PropsWithChildren } from "react";
import { RawIntlProvider } from "react-intl";

interface PortalIntlProviderProps {}

export const PortalIntlProvider = (
  props: PropsWithChildren<PortalIntlProviderProps>
) => {
  const { children } = props;
  const intl = useDynamicIntl();

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};
