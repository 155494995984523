import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { IdType } from "@common/types/apiTypes";
import { oneClickRenewalApi } from "@portal-guest/api/oneClickRenewalApi";
import { OneClickRenewalQueryKeys } from "@portal-guest/queryKeys/OneClickRenewalQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseOneClickRenewalDefaultPaymentMethodQueryProps {
  queryOptions?: {
    enabled?: boolean;
  };
  renewalToken: IdType;
}
export const useOneClickRenewalDefaultPaymentMethodQuery = (
  props: UseOneClickRenewalDefaultPaymentMethodQueryProps
) => {
  const { renewalToken, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => oneClickRenewalApi.defaultPaymentMethod(renewalToken),
    queryKey:
      OneClickRenewalQueryKeys.oneClickRenewalDefaultPaymentMethod(
        renewalToken
      ),
    select: (data) => {
      return {
        ...data,
        paymentMethod: data.paymentMethod
          ? new PaymentMethod(data.paymentMethod)
          : null,
      };
    },
  });
};
