export const competitorPlanComparisonTranslations = {
  tCompetitorPlanComparisonMiniCardLearnMore: "Learn More",
  tCompetitorPlanComparisonMonthToMonth: "Month-to-month",
  tCompetitorPlanComparisonRhythmPlans: "Rhythm Plans",
  tCompetitorPlanComparisonSubTitle:
    "The rates below reflect the average prices of our top competitors, based on 2,000 kWh usage. Interact with the chart to see estimated Annual Savings with Rhythm.",
  tCompetitorPlanComparisonTermLength: "Term Length",
  tCompetitorPlanComparisonTitle: "See how our rates stack up.",
  tCompetitorPlanComparisonXMonths: "{termMonths} months",
};
