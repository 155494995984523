import { RhToggle } from "@design-system/components/RhToggle/RhToggle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  StyledPreferencesSwitchInputCard,
  StyledPreferencesSwitchInputDetail,
  StyledPreferencesSwitchInputLabelText,
  StyledPreferencesSwitchInputTextWrap,
  StyledSwitchContainer,
} from "@portal/components/PreferencesSwitchInput/PreferencesSwitchInput.styled";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

interface PreferencesSwitchInputProps {
  checked: boolean;
  detail: ReactElement | string;
  disabled: boolean;
  label: string;
  name: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const PreferencesSwitchInput = ({
  checked,
  detail,
  disabled,
  label,
  name,
  onChange,
}: PreferencesSwitchInputProps) => {
  const { formatMessage } = useIntl();

  const onOffText = formatMessage({
    id: checked ? "preferencesSwitchInput.on" : "preferencesSwitchInput.off",
  });

  return (
    <StyledPreferencesSwitchInputCard>
      <StyledPreferencesSwitchInputTextWrap>
        <RhTypography
          color="textPrimary"
          variant="subtitle2"
          fontWeight={FontWeight.Semibold}
        >
          {label}
        </RhTypography>
        <StyledPreferencesSwitchInputDetail
          color="textSecondary"
          variant="body1"
        >
          {detail}
        </StyledPreferencesSwitchInputDetail>
      </StyledPreferencesSwitchInputTextWrap>

      <StyledSwitchContainer>
        <StyledPreferencesSwitchInputLabelText
          component="label"
          htmlFor={name}
          data-testid={name}
        >
          {onOffText}
        </StyledPreferencesSwitchInputLabelText>
        <RhToggle
          id={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name={name}
        />
      </StyledSwitchContainer>
    </StyledPreferencesSwitchInputCard>
  );
};
