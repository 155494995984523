import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledCustomerHomeNewSignupSection = styled(RhFlexBox)`
  align-items: center;
  flex-direction: column;
  margin-right: 0;
  padding-bottom: ${rhSpacingPx(2.5)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-direction: row;
    margin-right: ${rhSpacingPx(2.5)};
  }
`;

export const StyledWelcomeImageContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${rhSpacingPx(3)};
  width: 180px;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    padding: 0 ${rhSpacingPx(3)} 0 0;
  }
`;

export const StyledHeader = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      text-align: left;
    }
  }
`;

export const StyledSubheader = styled.div`
  color: ${CSSVariables.COLOR_GREY_500};
  margin-top: ${rhSpacingPx(2)};
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    text-align: left;
  }
`;
