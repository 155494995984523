import { api } from "@common/api/api";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useOktaAuth } from "@okta/okta-react";
import {
  createPasswordPath,
  expiredPasswordPath,
} from "@portal/routes/routePaths";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const ActivationController = () => {
  const params = useParams<{
    activationToken: string;
  }>();

  const activationToken = params.activationToken as string;
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    api.customers.password
      .getStateToken({ activationToken })
      .then(({ stateToken }) => {
        dispatch(setSignUpInfo({ stateToken }));
        navigate(createPasswordPath());
      })
      .catch(() => {
        dispatch(setSignUpInfo({}));
        navigate(expiredPasswordPath());
      });
  }, [oktaAuth, activationToken, dispatch, navigate]);

  return <RhCircularProgress />;
};
