import { RhApiError } from "@common/types/errorTypes";
import { invoiceFileDownload } from "@common/utils/invoiceFileDownload";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { AutoPayInfoV2 } from "@portal/components/AutoPayInfo/AutoPayInfoV2";
import { BalanceDetails } from "@portal/components/BalanceDetails/BalanceDetails";
import { billingSectionTranslations } from "@portal/components/BillingSection/BillingSection.en.i18n";
import { BillingSectionSkeleton } from "@portal/components/BillingSection/BillingSection.skeleton";
import {
  StyledBalanceDetailsBox,
  StyledLinkContainer,
  StyledSectionContainer,
} from "@portal/components/BillingSection/BillingSection.styled";
import { useActivePaymentExtensionQuery } from "@portal/hooks/queries/useActivePaymentExtension.query";
import { useBillingStatus } from "@portal/hooks/queries/useBillingStatus";
import { usePaymentExtensionQualifiedQuery } from "@portal/hooks/queries/usePaymentExtensionQualified.query";
import { usePremise } from "@portal/hooks/usePremise";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { payBillPath, paymentExtensionPath } from "@portal/routes/routePaths";
import { MyAccountEvents } from "@portal/services/segment.service";
import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

interface BillingSectionProps {
  className?: string;
  showBillBreakdownLink?: boolean;
}

export const BillingSection = ({
  className,
  showBillBreakdownLink,
}: BillingSectionProps) => {
  const { premise } = usePremise();
  const {
    data: billingStatus,
    isError,
    isPending,
  } = useBillingStatus({ premiseId: premise?.id ?? "" });
  const paymentExtensionQualifiedQuery = usePaymentExtensionQualifiedQuery({
    premiseId: premise?.id || "",
  });
  const activePaymentExtensionQuery = useActivePaymentExtensionQuery({
    premiseId: premise?.id || "",
  });

  const flash = useRhFlash();
  const { translate } = useTranslations();
  const track = useTrackMyAccountEvents();

  const {
    tBillingSectionErrorViewingBill,
    tBillingSectionMakePayment,
    tBillingSectionViewBill,
    tBillingSectionViewLatestBill,
    tBillingSectionSetupPaymentExtension,
  } = translate(billingSectionTranslations);

  if (
    isPending ||
    activePaymentExtensionQuery.isPending ||
    paymentExtensionQualifiedQuery.isPending
  ) {
    return (
      <StyledSectionContainer className={className}>
        <BillingSectionSkeleton />
      </StyledSectionContainer>
    );
  }

  if (!billingStatus || isError || !premise) {
    return (
      <StyledSectionContainer className={className}>
        {tBillingSectionErrorViewingBill}
      </StyledSectionContainer>
    );
  }

  const { autopay, billingAccountNumber } = premise;
  const {
    billingSummary: { latestInvoice },
  } = billingStatus;

  const viewBillCTA = billingStatus.noBalanceDue
    ? tBillingSectionViewLatestBill
    : tBillingSectionViewBill;

  const handleDownload: MouseEventHandler = () => {
    if (!latestInvoice) {
      return;
    }

    invoiceFileDownload({
      billingAccountNumber,
      invoiceDate: latestInvoice.invoiceDate,
      invoiceId: latestInvoice.id,
      premiseId: premise.id,
    }).catch((error: RhApiError) => {
      flash.error(tBillingSectionErrorViewingBill);
    });

    track({
      event: MyAccountEvents.downloadBill,
      label: "View bill",
    });
  };

  return (
    <StyledSectionContainer className={className}>
      <StyledBalanceDetailsBox>
        <BalanceDetails
          activePaymentExtension={activePaymentExtensionQuery.data}
          billingStatus={billingStatus}
          showBillBreakdownLink={showBillBreakdownLink}
        />
        <RhLinkButton
          data-tracking-click={{ event: "Customer going to Pay Bill page" }}
          href={payBillPath()}
          color="primary"
          size="medium"
        >
          {tBillingSectionMakePayment}
        </RhLinkButton>
        <RhButton
          data-tracking-click={{
            event: "Customer downloading bill",
          }}
          onClick={handleDownload}
          variant="outlined"
          color="primary"
          fullWidth
          disabled={Boolean(!latestInvoice)}
        >
          {viewBillCTA}
        </RhButton>
        {paymentExtensionQualifiedQuery.data?.qualified ? (
          <StyledLinkContainer>
            <Link to={paymentExtensionPath()}>
              <RhTypography variant="body2">
                {tBillingSectionSetupPaymentExtension}
              </RhTypography>
            </Link>
          </StyledLinkContainer>
        ) : null}

        {!activePaymentExtensionQuery.data &&
        !billingStatus.hasPastDueBalance ? (
          <StyledLinkContainer>
            <AutoPayInfoV2
              autopayEnabled={autopay}
              invoiceToPay={latestInvoice}
            />
          </StyledLinkContainer>
        ) : null}
      </StyledBalanceDetailsBox>
    </StyledSectionContainer>
  );
};
