import {
  rafPointsAmount,
  rafPointsValue,
} from "@common/constants/rewards.constant";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@mui/material";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { CopyReferralLinkButton } from "@portal/components/CopyReferralLinkButton/CopyReferralLinkButton";
import { referAFriendSectionTranslations } from "@portal/components/ReferAFriendSection/ReferAFriendSection.en.i18n";
import React from "react";

export const ReferAFriendSection = () => {
  const { translate, translateJsx } = useTranslations();
  const { tReferAFriendSectionReferAFriend } = translate(
    referAFriendSectionTranslations
  );
  const { tReferAFriendSectionIncentiveText } = translateJsx({
    tReferAFriendSectionIncentiveText: {
      linkChunk: (content: string) => (
        <RhAnchor
          href={brandInformation.urlsFaqsRewards}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </RhAnchor>
      ),
      pointsAmount: rafPointsAmount,
      pointsValue: rafPointsValue,
    },
  });

  return (
    <RhCard title={tReferAFriendSectionReferAFriend}>
      <Box marginBottom={2}>
        <RhTypography variant="subtitle2">
          {tReferAFriendSectionIncentiveText}
        </RhTypography>
      </Box>
      <CopyReferralLinkButton />
    </RhCard>
  );
};
