import { RhButton } from "@design-system/components/RhButton/RhButton";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const SingleProductTiePriceDisplayWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rhSpacingPx(3)};
`;

export const SingleProductTierPriceContainer = styled.div`
  display: grid;
  flex-basis: 80vw;
  grid-template-columns: 1fr;
  max-width: 330px;

  & > :nth-child(2n-1) {
    justify-self: start;
    margin-bottom: ${rhSpacingPx(0.5)};
  }
  & > :nth-child(2n) {
    margin-bottom: ${rhSpacingPx(2)};
  }
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    grid-template-columns: 2fr 3fr;
  }
`;

export const ProductAddonsOptInConfirmationModalHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${rhSpacingPx(3)};
  margin-top: ${rhSpacingPx(4)};
  & > h2 {
    margin-top: ${rhSpacingPx(3)};
    text-align: center;
  }
  & > svg {
    height: ${rhSpacingPx(8)};
    margin-top: ${rhSpacingPx(1)};
    width: ${rhSpacingPx(8)};
  }
`;

export const ProductAddonsOptInConfirmationModalCloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rhSpacingPx(4)};
`;

export const ProductAddonsOptInConfirmationModalCloseButton = styled(RhButton)`
  &.MuiButton-root {
    width: 60%;
  }
`;

export const ProductAddonsOptInConfirmationAddOnsContainer = styled.div`
  margin-bottom: ${rhSpacingPx(4)};
`;
