export const signUpDepositRequiredPageTranslations = {
  tSignUpDepositRequiredPageCouldBe:
    "This could be due to a low credit score, a frozen file, unconfirmed identity or a credit report that could not be found.",
  tSignUpDepositRequiredPageDepositAlternativeAmount:
    "<bold>{depositAlternativeAmount} Deposit Alternative Fee</bold> (non-refundable)",
  tSignUpDepositRequiredPageDepositAmount:
    "<bold>{depositAmount} Deposit</bold> (refundable), which will be fully reimbursed after 12 consecutive on-time payments, with interest at the rate approved by the PUCT.",
  tSignUpDepositRequiredPageDepositReturned:
    "The deposit will be returned after 12 consecutive on-time payments, with interest at the rate approved by the PUCT.",
  tSignUpDepositRequiredPageErrorProcessingCard:
    "We were unable to process your card. Please try again or pick a different card.",
  tSignUpDepositRequiredPageForMoreInfoAboutYourDeposit:
    "For more information about your deposit, check the <link>FAQ page</link>.",
  tSignUpDepositRequiredPageSorryWeNeedADepositV2:
    "It looks like we'll need a deposit to get you enrolled.",
  tSignUpDepositRequiredPageSorryWeNeedDeposit:
    "We'll need a {deposit} deposit to get you set up.",
  tSignUpDepositRequiredPageTwoDepositOptions:
    "There are two options for your deposit:",
};
