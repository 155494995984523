import { RhCard } from "@design-system/components/RhCard/RhCard";
import styled from "styled-components";

export const StyledCardHeader = styled(RhCard)`
  margin-bottom: 0;

  & .MuiCardHeader-root {
    margin-bottom: 0;
  }
`;
