import { OfferSnapshotsSearchOptions } from "@portal-shared/types/offerSnapshotTypes";

export const OneClickRenewalQueryKeys = {
  oneClickRenewal: (renewalToken: string) => [renewalToken, "oneClickRenewal"],
  oneClickRenewalDefaultPaymentMethod: (renewalToken: string) => [
    renewalToken,
    "defaultPaymentMethod",
  ],
  oneClickRenewalOfferSnapshots: (renewalToken: string) => [
    renewalToken,
    "oneClickRenewalOfferSnapshots",
  ],
  utilities: {
    search: (options: OfferSnapshotsSearchOptions) => [
      "utilities",
      "search",
      options,
    ],
  },
};
