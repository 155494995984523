import { maskCurrency } from "@common/forms/currency.mask";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { AmountType } from "@portal/enums/AmountType.enum";
import { StyledCustomPaymentField } from "@portal/pages/PayBillPage/CustomPaymentField/CustomPaymentField.styled";
import { PayBillPageFormValues } from "@portal/pages/PayBillPage/PayBillPageFormValuesType";
import React, { useEffect } from "react";
import { useField, useFormState } from "react-final-form";

export const CustomPaymentField = () => {
  const { values } = useFormState<PayBillPageFormValues>({
    subscription: { values: true },
  });
  const {
    input: { onChange },
  } = useField<string>("maskedCustomAmount");

  const isCustomAmount = values.amountType === AmountType.Custom;

  useEffect(() => {
    if (isCustomAmount) {
      return;
    }

    const stringifiedTotal = values.totalAmount?.toString() ?? "0";

    onChange(maskCurrency(stringifiedTotal));
  }, [isCustomAmount, onChange, values.totalAmount]);

  return isCustomAmount ? (
    <StyledCustomPaymentField>
      <RhTextField
        autoFocus
        name="maskedCustomAmount"
        format={maskCurrency}
        styles={{ paddingRight: 0, textAlign: "end" }}
        inputMode="numeric"
      />
    </StyledCustomPaymentField>
  ) : null;
};
