import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { PaymentMethodsSection } from "@portal/components/PaymentMethodsSection/PaymentMethodsSection";
import { translations } from "@portal/pages/PayBillPage/PayBillPage.en.i18n";
import { PayBillPageFormValues } from "@portal/pages/PayBillPage/PayBillPageFormValuesType";
import React from "react";
import { useForm } from "react-final-form";

interface PayBillPagePaymentMethodsSectionProps {
  oneTimePaymentMethodId?: string;
  setOneTimePaymentMethodId?: (oneTimePaymentMethodId?: string) => void;
}

export const PayBillPagePaymentMethodsSection = (
  props: PayBillPagePaymentMethodsSectionProps
) => {
  const { oneTimePaymentMethodId, setOneTimePaymentMethodId } = props;
  const form = useForm<PayBillPageFormValues>();

  const { translate } = useTranslations();
  const { tPayBillPagePayFrom } = translate(translations);

  return (
    <PaymentMethodsSection
      allowMethodChange
      sectionTitleText={tPayBillPagePayFrom}
      variant="subtitle1"
      showPaymentOptions
      onPaymentMethodAdded={(paymentMethodId: string) => {
        form.change("selectedPaymentMethodId", paymentMethodId);
      }}
      oneTimePaymentMethodId={oneTimePaymentMethodId}
      setOneTimePaymentMethodId={setOneTimePaymentMethodId}
    />
  );
};
