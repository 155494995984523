import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { StyledInvoicesRow } from "@portal/components/InvoicesRow/InvoicesRow.styled";
import styled from "styled-components";

export const StyledBillInvoiceRow = styled(StyledInvoicesRow)`
  &:first-child {
    border-top: ${borders[100]};
  }
  align-items: flex-start;
  padding-bottom: ${rhSpacingPx(2)};
  padding-top: ${rhSpacingPx(2)};
`;

export const StyledLateFeeMessage = styled(RhTypography)`
  &.MuiTypography-root {
    margin-top: ${rhSpacingPx(0.5)};
  }
`;
