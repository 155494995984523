export const offerSnapshotFAQsTimeOfUseTranslations = {
  tOfferSnapshotFAQsTimeOfUsWhoBenefitsAnswer:
    "Anyone who is willing to make a few slight adjustments in their day-to-day energy usage can save a lot of money. With technology where it's at today, for example if you own a smart thermostat or an EV charging app, it's simple to just set up energy adjustments on your phone (turning your AC up a few degrees during evening on-peak hours then back down afterwards). Also, the grid and the planet benefit. You're contributing to a cleaner future, because you're using more power during periods of the day that are supplied by a higher percentage of renewable energy sources like wind and solar.",
  tOfferSnapshotFAQsTimeOfUsWhoBenefitsQuestion:
    "Who benefits from a plan like this?",
  tOfferSnapshotFAQsTimeOfUseHowToSaveMoneyAnswer:
    "If you can shift your energy consumption off of on-peak hours, your bill will go down. It's as simple as that. And depending on which plan you choose, those hours will occur at different times with different energy rates incentives in each time slot.",
  tOfferSnapshotFAQsTimeOfUseHowToSaveMoneyQuestion:
    "What has to happen to save money?",
  tOfferSnapshotFAQsTimeOfUseTitle: "Time of Use",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerOffPeak:
    "Off-peak: Low-demand time when electricity costs less. You're encouraged to use electricity-heavy items during this period for cost savings, environmental benefits, and grid efficiency.",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerOnPeak:
    "On-peak: High-demand time when electricity costs more. Shift usage outside this window based on your plan's structure (morning, evening, or both) to save money.",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerSeasonalPeak:
    "Seasonal on-peak: Seasonally high-demand time when electricity costs more, like winter mornings when people are heating their homes. Limit heavy appliance usage and reduce AC/heat (especially electric) during this period.",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksAnswerUltraOffPeak:
    "Ultra off-peak: Cheapest-priced period with lowest electricity demand, usually overnight. Utilize this time to charge your EV, use AC, or heat your space.",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksQuestion:
    "What do on-peak, seasonal on-peak, off-peak, ultra off-peak mean?",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksWhenToReduceUsage:
    "When to reduce your usage",
  tOfferSnapshotFAQsTimeOfUseWhatArePeaksWhenYouCanGoNuts:
    "When you can go nuts",
  tOfferSnapshotFAQsTimeOfUseWhatIsTimeOfUsePlanAnswer:
    "Time-of-use plans charge different rates for energy during different periods throughout the day, which gives you the ability to shift your energy consumption into the periods of the day with lower energy rates. Shifting your energy consumption can help save you money, help alleviate grid pressure, and help the planet.",
  tOfferSnapshotFAQsTimeOfUseWhatIsTimeOfUsePlanQuestion:
    "What is a time-of-use plan?",
  tOfferSnapshotFAQsTimeOfUseWhereIsBreakdownAnswer:
    "Energy charge breakdowns by hour specific to your plan can be found in the Learn More section of plan card or on EFL.",
  tOfferSnapshotFAQsTimeOfUseWhereIsBreakdownQuestion:
    "Where can I find a breakdown of my energy charges on the Time-of-Use plans?",
};
