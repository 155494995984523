import { StyledIconButton } from "@design-system/components/RhPasswordField/RhPasswordField.styled";
import {
  InputStyles,
  RhTextField,
} from "@design-system/components/RhTextField/RhTextField";
import { ReactComponent as EyeIcon } from "@design-system/icons/EyeIcon.svg";
import { InputAdornment } from "@mui/material";
import { FieldValidator } from "final-form";
import React, { FC, useState } from "react";

export interface RhPasswordFieldProps<Type> {
  autoComplete?: string;
  autoFocus?: boolean;
  labels?: {
    hidePassword?: string;
    showPassword?: string;
  };
  name: string;
  placeholder?: string;
  styles?: InputStyles;
  validate?: FieldValidator<Type>;
}

export const RhPasswordField: FC<
  React.PropsWithChildren<RhPasswordFieldProps<string>>
> = ({
  children,
  labels = { hidePassword: "Hide Password", showPassword: "Show Password" },
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <RhTextField
      {...restProps}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <StyledIconButton
              onClick={toggleShowPassword}
              aria-label={
                showPassword ? labels.hidePassword : labels.showPassword
              }
            >
              <EyeIcon />
            </StyledIconButton>
          </InputAdornment>
        ),
      }}
    >
      {children}
    </RhTextField>
  );
};
