import { usePremiseQuery } from "@portal/hooks/queries/usePremise.query";
import { Premise } from "@portal/models/Premise.model";
import { selectCustomerPremisePreference } from "@portal/selectors/customerPreferencesSelectors";
import React, { PropsWithChildren, createContext } from "react";
import { useSelector } from "react-redux";

export const PremiseContext = createContext<Premise | null>(null);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PremiseProviderProps {}

export const PremiseProvider = ({
  children,
}: PropsWithChildren<PremiseProviderProps>) => {
  const premiseId = useSelector(selectCustomerPremisePreference);

  const premiseQuery = usePremiseQuery({
    premiseId,
  });

  if (premiseQuery.isPending || premiseQuery.isError) {
    return null;
  }

  const premise = premiseQuery.data;

  return (
    <PremiseContext.Provider value={premise}>
      {children}
    </PremiseContext.Provider>
  );
};
