import { EnergyUsageGraphV2 } from "@common/components/EnergyUsageGraphV2/EnergyUsageGraphV2";
import { ChartType, GraphTemperatureValue } from "@common/types/usageTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  PortalRadioButton,
  PortalRadioLabel,
} from "@portal-shared/components/PortalRadioButton/PortalRadioButton";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { billEnergyUsageTemperatureCardTranslations } from "@portal/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.en.i18n";
import { BillEnergyUsageTemperatureCardSkeleton } from "@portal/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.skeleton";
import {
  StyledBillEnergyUsageTemperatureErrorContainer,
  StyledEnergyGraphContainer,
  StyledRadiosContainer,
} from "@portal/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.styled";
import { useInvoiceKwhUsageQuery } from "@portal/hooks/queries/useInvoiceKwhUsage.query";
import { useInvoiceTemperatureQuery } from "@portal/hooks/queries/useInvoiceTemperature.query";
import React, { useState } from "react";

interface BillEnergyUsageTemperatureCardBodyProps {
  invoiceId: string;
}

export const BillEnergyUsageTemperatureCardBody = (
  props: BillEnergyUsageTemperatureCardBodyProps
) => {
  const { invoiceId } = props;

  const [temperature, setTemperature] = useState(GraphTemperatureValue.Average);

  const { translate } = useTranslations();
  const {
    tBillEnergyUsageTemperatureCardHighLow,
    tBillEnergyUsageTemperatureCardAverageTemp,
    tBillEnergyUsageTemperatureCardTemperature,
    tBillEnergyUsageTemperatureCardHigh,
    tBillEnergyUsageTemperatureCardAverage,
    tBillEnergyUsageTemperatureCardLow,
    tBillEnergyUsageTemperatureCardCost,
    tBillEnergyUsageTemperatureCardDate,
    tBillEnergyUsageTemperatureCardUsage,
    tBillEnergyUsageTemperatureCardHighTemp,
    tBillEnergyUsageTemperatureCardLowTemp,
    tBillEnergyUsageTemperatureCardTemperatureFahrenheit,
    tBillEnergyUsageTemperatureCardUsagekWh,
    tBillEnergyUsageTemperatureCardDailyUsage,
    tBillEnergyUsageTemperatureCardError,
  } = translate(billEnergyUsageTemperatureCardTranslations);

  const invoiceUsageQuery = useInvoiceKwhUsageQuery({ invoiceId });

  const invoiceTemperatureQuery = useInvoiceTemperatureQuery({ invoiceId });

  const chartLabels = {
    costTooltipLabel: tBillEnergyUsageTemperatureCardCost,
    dateTitle: tBillEnergyUsageTemperatureCardDate,
    temperatureLabels: {
      average: tBillEnergyUsageTemperatureCardAverage,
      averageTemp: tBillEnergyUsageTemperatureCardAverageTemp,
      high: tBillEnergyUsageTemperatureCardHigh,
      highTemp: tBillEnergyUsageTemperatureCardHighTemp,
      low: tBillEnergyUsageTemperatureCardLow,
      lowTemp: tBillEnergyUsageTemperatureCardLowTemp,
      temperature: tBillEnergyUsageTemperatureCardTemperature,
      temperatureAxisTitle:
        tBillEnergyUsageTemperatureCardTemperatureFahrenheit,
    },
    usageAxisTitle: tBillEnergyUsageTemperatureCardUsagekWh,
    usageLabelTitle: tBillEnergyUsageTemperatureCardDailyUsage,
    usageTitle: tBillEnergyUsageTemperatureCardUsage,
    usageTooltipLabel: `${tBillEnergyUsageTemperatureCardUsage} :`,
  };

  if (invoiceUsageQuery.isError || invoiceTemperatureQuery.isError) {
    return (
      <StyledBillEnergyUsageTemperatureErrorContainer>
        <RhTypography>{tBillEnergyUsageTemperatureCardError}</RhTypography>
      </StyledBillEnergyUsageTemperatureErrorContainer>
    );
  }

  if (invoiceTemperatureQuery.isPending || invoiceUsageQuery.isPending) {
    return <BillEnergyUsageTemperatureCardSkeleton />;
  }

  return (
    <>
      <StyledRadiosContainer>
        <PortalRadioLabel>
          <PortalRadioButton
            type="radio"
            onChange={() => setTemperature(GraphTemperatureValue.Average)}
            checked={temperature === GraphTemperatureValue.Average}
          />
          {tBillEnergyUsageTemperatureCardAverageTemp}
        </PortalRadioLabel>
        <PortalRadioLabel>
          <PortalRadioButton
            type="radio"
            onChange={() => setTemperature(GraphTemperatureValue.HighLow)}
            checked={temperature === GraphTemperatureValue.HighLow}
          />
          {tBillEnergyUsageTemperatureCardHighLow}
        </PortalRadioLabel>
      </StyledRadiosContainer>
      <StyledEnergyGraphContainer>
        <EnergyUsageGraphV2
          usageData={invoiceUsageQuery.data}
          chartType={ChartType.daily}
          chartDirection="horizontal"
          showGeneration={false}
          showConsumption
          showEarned={false}
          labels={chartLabels}
          latestAvailableDate=""
          temperatures={invoiceTemperatureQuery.data.temperatures}
          selectedTemperatureValue={temperature}
        />
      </StyledEnergyGraphContainer>
    </>
  );
};
