import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { TemperatureType, UsageType } from "@common/types/usageTypes";
import { invoiceUrls } from "@portal/api/invoiceUrls";
import {
  BillComparisonType,
  BillFactorsType,
} from "@portal/types/billBreakdownTypes";
import { InvoiceOrderType } from "@portal/types/invoiceOrderTypes";
import { InvoiceType } from "@portal/types/invoiceTypes";

export const invoiceApi = {
  compare: (invoiceId: IdType) => {
    return ajax.get<BillComparisonType>(invoiceUrls.compare(invoiceId));
  },
  factors: (invoiceId: IdType) => {
    return ajax.get<BillFactorsType>(invoiceUrls.factors(invoiceId));
  },
  orders: (invoiceId: IdType) => {
    return ajax.get<InvoiceOrderType[]>(invoiceUrls.orders(invoiceId));
  },
  retrieve: (invoiceId: IdType) => {
    return ajax.get<InvoiceType>(invoiceUrls.retrieve(invoiceId));
  },
  temperature: (invoiceId: IdType) => {
    return ajax.get<{ temperatures: TemperatureType[] }>(
      invoiceUrls.temperature(invoiceId)
    );
  },
  usage: (invoiceId: IdType) => {
    return ajax.get<UsageType[]>(invoiceUrls.usage(invoiceId));
  },
};
