import { ajax } from "@common/services/ajax";
import {
  EnrollProspectType,
  ProspectCreateRequest,
  ProspectUpdateRequestType,
} from "@enroll-utils/types/prospectTypes";
import { RawAxiosRequestConfig } from "axios";

const PORTAL_PROSPECT_BASE_URL = "/api/portal/prospects";

export const prospectApi = {
  create: <R = void>(body: ProspectCreateRequest): Promise<R> => {
    return ajax.post(`${PORTAL_PROSPECT_BASE_URL}/`, body);
  },
  retrieve: <R = EnrollProspectType>(axiosConfig?: RawAxiosRequestConfig) => {
    return ajax.get<R>(`${PORTAL_PROSPECT_BASE_URL}/me`, axiosConfig);
  },
  update: <R = void>({
    axiosConfig,
    data,
  }: {
    axiosConfig?: RawAxiosRequestConfig;
    data: ProspectUpdateRequestType;
  }) => {
    return ajax.patch<R>(`${PORTAL_PROSPECT_BASE_URL}/me`, data, axiosConfig);
  },
};
