import { IdType, UUIDType } from "@common/types/apiTypes";
import { PremiseListItemType } from "@common/types/premiseTypes";
import { Locale, allLocales } from "@portal-shared/types/LocaleTypes";
import { CustomerType } from "@portal/types/customerTypes";

export class Customer {
  public dateOfBirth: string;
  public email: string;
  public featureFlagUserId: string | null;
  public firstName: string;
  public fullName: string;
  public id: IdType;
  public languagePreference: Locale;
  public lastName: string;
  public phone: string;
  public premises: PremiseListItemType[];
  public referralToFriendCode: string;
  public uuid: UUIDType;

  public constructor(customer: CustomerType) {
    this.dateOfBirth = customer.dateOfBirth;
    this.email = customer.email;
    this.featureFlagUserId = customer.featureFlagUserId;
    this.firstName = customer.firstName;
    this.fullName = customer.fullName;
    this.id = customer.id;
    this.languagePreference = customer.languagePreference || allLocales.en;
    this.lastName = customer.lastName;
    this.phone = customer.phone;
    this.premises = customer.premises;
    this.referralToFriendCode = customer.referralToFriendCode;
    this.uuid = customer.uuid;
  }
}
