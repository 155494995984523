import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const EnrollOffersPageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rhSpacingPx(2)} auto 0;
  max-width: 40ch;
  text-align: center;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    margin-top: 0;
    max-width: 100%;
    place-self: center left;
    text-align: inherit;
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-flow: column wrap;
  gap: ${rhSpacingPx(1)};
`;
