import { Order } from "@common/models/Order.model";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  StyledContainer,
  StyledHighFiveContainer,
} from "@portal-shared/components/RenewalSuccess/RenewalSuccess.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { ReactComponent as HighFives } from "@portal-shared/images/HighFives.svg";
import dayjs from "dayjs";
import React from "react";

interface RenewalSuccessProps {
  ctaPath: string;
  ctaText: string;
  order: Order;
}

export const RenewalSuccess = ({
  ctaPath,
  ctaText,
  order,
}: RenewalSuccessProps) => {
  const { t, tJsx } = useRhIntl();
  const highFivesImageTitle = t("RenewalSuccess.twoFriendsHighFiving");
  const allSet = t("RenewalSuccess.allSet");
  const newContractInfo = tJsx("RenewalSuccess.newContractInfo", {
    startDate: dayjs(order.startDate).format("MMMM DD, YYYY"),
  });

  return (
    <StyledContainer>
      <StyledHighFiveContainer>
        <HighFives title={highFivesImageTitle} />
      </StyledHighFiveContainer>
      <RhTypography variant="h2">{allSet}</RhTypography>
      <RhTypography variant="subtitle2">{newContractInfo}</RhTypography>
      <RhRouterLink to={ctaPath}>
        <RhButton
          data-tracking-click={{
            event: "Customer closing renewal flow after renewing",
          }}
          color="primary"
          fullWidth
        >
          {ctaText}
        </RhButton>
      </RhRouterLink>
    </StyledContainer>
  );
};
