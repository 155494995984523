import { api } from "@common/api/api";
import {
  RhythmCampaignSlugs,
  powerToChooseCampaignSlugs,
} from "@common/constants/campaigns.constant";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { determineCreditEvaluationDefinition } from "@common/services/creditEvaluation.service";
import {
  BillingErrors,
  INVALID_PHONE,
  INVALID_START_DATE,
  MTM_SIGNUP_ERROR,
  RhApiError,
} from "@common/types/errorTypes";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { usePaymentMethodQuery } from "@enroll-data/hooks/queries/usePaymentMethod.query";
import {
  SignUpStepType,
  enrollOffersPath,
  signUpStepPath,
} from "@enroll-utils/constants/routePaths";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { useMediaQuery, useTheme } from "@mui/material";
import { EFLLink } from "@portal-shared/components/EFLLink/EFLLink";
import { TOSLink } from "@portal-shared/components/TOSLink/TOSLink";
import { YRACLink } from "@portal-shared/components/YRACLink/YRACLink";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { Locale } from "@portal-shared/types/LocaleTypes";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { PortalSubmitButton } from "@portal/components/PortalSubmitButton/PortalSubmitButton";
import { PowerToChooseLegal } from "@portal/components/PowerToChooseLegal/PowerToChooseLegal";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { SignUpSummaryInfo } from "@portal/components/SignUpSummaryInfo/SignUpSummaryInfo";
import { SimpliSafeLegalText } from "@portal/components/SimpliSafeLegalText/SimpliSafeLegalText";
import { useSelectedProductTiers } from "@portal/hooks/useSelectedProductTiers";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { signUpSummaryPageTranslations } from "@portal/pages/SignUpSummaryPage/SignUpSummaryPage.en.i18n";
import {
  AllRightsReservedContainer,
  LoadingContainer,
  SignUpSummaryContainer,
  SimpliSafeContainer,
  StyledAgreeToTerms,
  StyledByCheckingThisBox,
  StyledCheckbox,
  StyledFormWrapper,
  StyledSignUpButtonContainer,
  StyledSignUpSummaryPageWrapper,
} from "@portal/pages/SignUpSummaryPage/SignUpSummaryPage.styled";
import { CREATE_PASSWORD } from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import { SignUpStateType, setSignUpInfo } from "@portal/slices/signUpSlice";
import { toCreateCustomerRequest } from "@portal/transformers/toCreateCustomerRequest";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import { Show500Page } from "@portal/utils/errors";
import { FORM_ERROR } from "final-form";
import React, { ReactNode, useCallback, useEffect } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export interface SignUpSummaryFormValues {
  acknowledgedContractDocuments: boolean;
  acknowledgedPowerToChooseLegal?: boolean;
}

const trackingLocation = "Enrollment Summary";

function makeLegalDocsLinkComponent({
  type,
  locale,
  offerSnapshot,
}: { locale: Locale } & (
  | {
      offerSnapshot: OfferSnapshot;
      type: "efl" | "tos";
    }
  | {
      offerSnapshot?: never;
      type: "yrac";
    }
)): (chunk: ReactNode[]) => ReactNode {
  switch (type) {
    case "efl": {
      if (!offerSnapshot) {
        throw new Show500Page(
          "Legal document link type is efl but offer snapshot id is missing"
        );
      }
      return (chunk) => (
        <EFLLink
          locale={locale}
          offerId={offerSnapshot.id}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </EFLLink>
      );
    }
    case "tos": {
      if (!offerSnapshot) {
        throw new Show500Page(
          "Legal document link type is tos but offer snapshot id is missing"
        );
      }
      return (chunk) => (
        <TOSLink
          dataTrackingClick={{ location: trackingLocation }}
          tosUrl={offerSnapshot ? offerSnapshot.rhythmTosLink(locale) : "#"}
        >
          {chunk}
        </TOSLink>
      );
    }
    case "yrac": {
      return (chunk) => (
        <YRACLink
          locale={locale}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </YRACLink>
      );
    }
    default: {
      return (chunk) => chunk;
    }
  }
}

const makeErrorMessageLinkComponent =
  (to: string, announcement: ReturnType<typeof useRhAnnouncement>) =>
  (message: ReactNode[]) => {
    return (
      <Link to={to} onClick={announcement.clearAnnouncement}>
        {message}
      </Link>
    );
  };

export const SignUpSummaryPage = () => {
  const dispatch = useDispatch();
  const { signUpClickNextStepHandler, trackEvent, visitedSteps } =
    useSignUpFlow();
  const flash = useRhFlash();
  const signUpState = useSelector<PortalStoreState, SignUpStateType>(
    selectSignUpState
  );
  const [locale] = useLocaleAtom();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const announcement = useRhAnnouncement();
  const navigate = useNavigate();
  const selectedProductTiers = useSelectedProductTiers();
  const { featureFlagClient } = useFeatureFlagClient();
  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: signUpState.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(signUpState.offerSnapshotUuid),
    },
  });

  const { billingPaymentMethodId } = signUpState;

  const { translate, translateJsx } = useTranslations();
  const { removeCookie } = useEnrollCookies();

  const isPaymentMethodQueryEnabled = Boolean(billingPaymentMethodId);

  const paymentMethodQuery = usePaymentMethodQuery({
    paymentMethodId: billingPaymentMethodId || "",
    queryOptions: {
      enabled: isPaymentMethodQueryEnabled,
    },
  });

  useEffect(() => {
    if (offerSnapshotQuery.error) {
      const { tSignUpSummaryPageErrorGettingSelectedOffer } = translateJsx({
        tSignUpSummaryPageErrorGettingSelectedOffer: {
          link: makeErrorMessageLinkComponent(enrollOffersPath(), announcement),
        },
      });

      announcement.announceError(
        <div>{tSignUpSummaryPageErrorGettingSelectedOffer}</div>
      );
      throw new Show500Page("Could not get selected offerSnapshot");
    }
  }, [announcement, offerSnapshotQuery.error]);

  const isFormSubmittable = useCallback(
    (values: SignUpSummaryFormValues): boolean => {
      if (
        signUpState.campaignSlug &&
        powerToChooseCampaignSlugs.includes(signUpState.campaignSlug)
      ) {
        return (
          Boolean(values.acknowledgedPowerToChooseLegal) &&
          Boolean(values.acknowledgedContractDocuments)
        );
      }

      return values.acknowledgedContractDocuments;
    },
    [signUpState.campaignSlug]
  );

  if (
    offerSnapshotQuery.isPending ||
    offerSnapshotQuery.isError ||
    (isPaymentMethodQueryEnabled &&
      (paymentMethodQuery.isPending || paymentMethodQuery.isError))
  ) {
    return (
      <SignUpPageLayout>
        <LoadingContainer>
          <RhCircularProgress />
        </LoadingContainer>
      </SignUpPageLayout>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const {
    tSignUpSummaryPageAllRightsReserved,
    tSignUpSummaryPageAgreeToTerms,
    tSignUpSummaryPageAcknowledgeCheckboxLabel,
    tSignUpSummaryPageAcknowledgePTCCheckboxLabel,
    tSignUpSummaryPageSubmitCTAWithDeposit,
    tSignUpSummaryPageApiErrorSigningUpCustomer,
    tSignUpSummaryPageSubmitCTA,
  } = translate(signUpSummaryPageTranslations);

  const { tSignUpSummaryPageAllDone, tSignUpSummaryPageByCheckingThisBox } =
    translateJsx({
      tSignUpSummaryPageAllDone: {
        firstName: signUpState.firstName,
      },
      tSignUpSummaryPageByCheckingThisBox: {
        efl: makeLegalDocsLinkComponent({
          locale,
          offerSnapshot,
          type: "efl",
        }),
        tos: makeLegalDocsLinkComponent({
          locale,
          offerSnapshot,
          type: "tos",
        }),
        yrac: makeLegalDocsLinkComponent({
          locale,
          type: "yrac",
        }),
      },
    });

  const handleFormSubmit = (values: SignUpSummaryFormValues) => {
    if (!isFormSubmittable(values)) {
      document
        .querySelector("#signUpSummaryConsentFormAnchor")
        ?.scrollIntoView({
          behavior: "smooth",
        });

      return { [FORM_ERROR]: "Form Submit Failed" };
    }

    const customerData = {
      ...signUpState,
      languagePreference: locale,
    };

    const data = toCreateCustomerRequest(customerData);

    return api.customers
      .create(data)
      .then((confirmedCustomer) => {
        signUpClickNextStepHandler({
          nextStep: CREATE_PASSWORD,
          signUpData: { ...confirmedCustomer, campaignPromo: null },
          track: true,
        });
        announcement.clearAnnouncement();

        featureFlagClient.trackMetric(FeatureFlagMetric.PortalProspectEnrolled);

        if (customerData.productPrices.length > 0) {
          featureFlagClient.trackMetric(
            FeatureFlagMetric.PortalProspectEnrolledWithProductAddOnPlan
          );

          trackEvent({
            action: ActionType.clickedEnrollmentSubmit,
            customer: confirmedCustomer.id,
            label: "enrolledWithProductAddOns",
            productTierId: customerData.productPrices[0],
            prospectId: customerData?.prospectId ?? "",
            zipCode: customerData.serviceAddress.zipCode,
          });
        }

        const creditEvaluation = determineCreditEvaluationDefinition({
          creditEvaluation: signUpState?.creditEvaluation,
          depositAmount: signUpState.depositAmount,
          ssnRequired: signUpState.ssnRequired,
        });

        const [premiseId] = confirmedCustomer.premiseIds;

        trackEvent({
          action: ActionType.clickedEnrollmentSubmit,
          autopay: signUpState.autopay,
          campaignSlug:
            signUpState?.campaignSlug ?? RhythmCampaignSlugs.Default,
          city: signUpState.serviceAddress.city,
          creditEvaluation,
          customer: confirmedCustomer.id,
          depositAmount: Number(signUpState.depositAmount),
          ebill: !signUpState.invoiceByPrint,
          email: signUpState.email,
          firstName: signUpState.firstName,
          label: "confirmationSignUp",
          lastName: signUpState.lastName,
          phone: signUpState.phone,
          planPrice: Number(offerSnapshot.price),
          planTitle: offerSnapshot.title,
          premiseId: String(premiseId),
          prospectId: signUpState?.prospectId ?? "",
          state: signUpState.serviceAddress.state,
          streetAddress: signUpState.serviceAddress.addressLine1,
          zipCode: signUpState.serviceAddress.zipCode,
        });

        removeCookie("rhProspectUuid");
        removeCookie("rhFeatureFlagUserId");
      })
      .catch((error: RhApiError) => {
        if (
          error.data.errorCode === BillingErrors.ZUORA_DEPOSIT_PAYMENT_ERROR
        ) {
          const { tSignUpSummaryPageTryDifferentPaymentMethod } = translateJsx({
            tSignUpSummaryPageTryDifferentPaymentMethod: {
              link: makeErrorMessageLinkComponent(
                signUpStepPath("deposit"),
                announcement
              ),
            },
          });

          announcement.announceError(
            <div>{tSignUpSummaryPageTryDifferentPaymentMethod}</div>
          );

          return;
        }

        const zuoraErrors = [
          BillingErrors.ZUORA_PAYMENT_FAILED,
          BillingErrors.ZUORA_CONNECTION_ERROR,
          BillingErrors.ZUORA_DEPOSIT_ALTERNATIVE_FAILED,
          BillingErrors.ZUORA_DEPOSIT_ERROR,
        ];

        if (zuoraErrors.includes(error.data.errorCode as BillingErrors)) {
          const nextStep: SignUpStepType = "call-us";
          const newVisitedSteps = [...visitedSteps, nextStep];

          dispatch(setSignUpInfo({ visitedSteps: newVisitedSteps }));
          navigate(signUpStepPath(nextStep));
          return;
        }

        if (error.data.errorCode === MTM_SIGNUP_ERROR) {
          const nextStep: SignUpStepType = "provide-additional-information";
          const newVisitedSteps = [...visitedSteps, nextStep];

          dispatch(setSignUpInfo({ visitedSteps: newVisitedSteps }));
          navigate(signUpStepPath(nextStep));
          return;
        }

        const generalErrors = [
          INVALID_START_DATE,
          INVALID_PHONE,
          BillingErrors.ZUORA_PAYMENT_METHOD_NOT_FOUND,
        ];

        if (generalErrors.includes(error.data.errorCode as string)) {
          const { tSignUpSummaryPageGeneral } = translateJsx({
            tSignUpSummaryPageGeneral: {
              phoneLink: () => (
                <a
                  style={{
                    color: CSSVariables.COLOR_WHITE,
                    textDecoration: "underline",
                  }}
                  href={`tel:${brandInformation.phoneNumbersOpsNumber}`}
                >
                  {brandInformation.phoneNumbersOpsNumber}
                </a>
              ),
            },
          });

          flash.error(<div>{tSignUpSummaryPageGeneral}</div>);

          return {
            [FORM_ERROR]: [tSignUpSummaryPageGeneral],
          };
        }

        flash.error(tSignUpSummaryPageApiErrorSigningUpCustomer);

        return {
          [FORM_ERROR]: [tSignUpSummaryPageApiErrorSigningUpCustomer],
        };
      });
  };

  const submitCTA = signUpState.depositAmount
    ? tSignUpSummaryPageSubmitCTAWithDeposit
    : tSignUpSummaryPageSubmitCTA;

  return (
    <SignUpPageLayout isSummaryPage>
      <StyledSignUpSummaryPageWrapper>
        <LoggedOutPageHeader headerText={tSignUpSummaryPageAllDone} />
        <SignUpSummaryContainer>
          <SignUpSummaryInfo
            selectedProductTiers={selectedProductTiers}
            signUpData={signUpState}
            paymentMethodDetails={paymentMethodQuery.data}
            offerSnapshot={offerSnapshot}
          />
          <Form<SignUpSummaryFormValues>
            initialValues={{
              acknowledgedContractDocuments: false,
              acknowledgedPowerToChooseLegal: false,
            }}
            onSubmit={handleFormSubmit}
            render={({
              handleSubmit,
              hasSubmitErrors,
              values,
              modifiedSinceLastSubmit,
            }) => {
              return (
                <StyledFormWrapper
                  $hasSubmitErrors={
                    hasSubmitErrors && !isFormSubmittable(values)
                  }
                  onSubmit={handleSubmit}
                  onChange={(event) => {
                    const {
                      target: { checked },
                    } = event as unknown as React.ChangeEvent<HTMLInputElement>;

                    if (checked) {
                      trackEvent({
                        action: ActionType.click,
                        label: "legalCheckBox",
                      });
                    }
                  }}
                >
                  <div
                    id="signUpSummaryConsentFormAnchor"
                    style={{ position: "absolute", top: "-100px" }}
                  />
                  {signUpState.campaignSlug &&
                    powerToChooseCampaignSlugs.includes(
                      signUpState.campaignSlug
                    ) && (
                      <StyledByCheckingThisBox flex="auto" mb={4}>
                        <StyledCheckbox
                          label=""
                          aria-label={
                            tSignUpSummaryPageAcknowledgePTCCheckboxLabel
                          }
                          name="acknowledgedPowerToChooseLegal"
                        />

                        <PowerToChooseLegal />
                      </StyledByCheckingThisBox>
                    )}

                  <StyledByCheckingThisBox flex="auto">
                    <StyledCheckbox
                      label=""
                      aria-label={tSignUpSummaryPageAcknowledgeCheckboxLabel}
                      name="acknowledgedContractDocuments"
                    />

                    <div>
                      <RhTypography>
                        {tSignUpSummaryPageByCheckingThisBox}
                      </RhTypography>
                      {offerSnapshot.isSimpliSafeOffer && (
                        <SimpliSafeContainer>
                          <RhTypography>
                            <SimpliSafeLegalText />
                          </RhTypography>
                        </SimpliSafeContainer>
                      )}
                    </div>
                  </StyledByCheckingThisBox>
                  <StyledSignUpButtonContainer>
                    <StyledAgreeToTerms>
                      <div>
                        {hasSubmitErrors && !isFormSubmittable(values) && (
                          <RhTypography
                            color="error"
                            variant="caption"
                            fontWeight={FontWeight.Regular}
                          >
                            {tSignUpSummaryPageAgreeToTerms}
                          </RhTypography>
                        )}
                      </div>

                      <PortalSubmitButton
                        data-tracking-click={{
                          event: "Prospect attempting to enroll",
                        }}
                        fullWidth
                        disabled={
                          modifiedSinceLastSubmit && !isFormSubmittable(values)
                        }
                        size={isMobile ? "medium" : "large"}
                      >
                        {submitCTA}
                      </PortalSubmitButton>
                    </StyledAgreeToTerms>
                    <AllRightsReservedContainer>
                      <RhTypography
                        color="textSecondary"
                        align="left"
                        variant="body3"
                      >
                        {tSignUpSummaryPageAllRightsReserved}
                      </RhTypography>
                    </AllRightsReservedContainer>
                  </StyledSignUpButtonContainer>
                </StyledFormWrapper>
              );
            }}
          />
        </SignUpSummaryContainer>
      </StyledSignUpSummaryPageWrapper>
    </SignUpPageLayout>
  );
};
