export const myAccountHeaderTranslations = {
  tMyAccountHeaderAccountSettings: "Account Settings",
  tMyAccountHeaderDevices: "Device Management",
  tMyAccountHeaderHelp: "Help",
  tMyAccountHeaderHome: "Home",
  tMyAccountHeaderLogout: "Logout",
  tMyAccountHeaderMapPinIcon: "Map Pin Icon",
  tMyAccountHeaderMyAccount: "My Account",
  tMyAccountHeaderNotifications: "Notifications",
  tMyAccountHeaderReferAFriend: "Refer a Friend",
  tMyAccountHeaderRewards: "Rewards",
};
