import { RH_CARD_HEADER_HEIGHT_PX } from "@design-system/components/RhCard/constants";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledContainer = styled(RhCard)`
  position: relative;
`;

export const StyledFade = styled(Box)`
  background: linear-gradient(
    90deg,
    #ffffff 30.21%,
    rgba(255, 255, 255, 0) 100%
  );
  bottom: 0;
  height: calc(100% - ${RH_CARD_HEADER_HEIGHT_PX}px);
  left: calc(100% - ${rhSpacingPx(9)});
  position: absolute;
  transform: rotate(-180deg);
  width: ${rhSpacingPx(9)};
`;
