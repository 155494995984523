import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import {
  LOGGED_OUT_PAGE_DENSE_WIDTH,
  LOGGED_OUT_TOP_MARGIN,
  LOGGED_OUT_TOP_MARGIN_MOBILE,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { FC } from "react";

export const LoggedOutPageLayout: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  return (
    <RhFlexBox
      alignItems="center"
      flexDirection="column"
      maxWidth={isMobile ? "100%" : LOGGED_OUT_PAGE_DENSE_WIDTH}
      marginTop={
        isMobile ? LOGGED_OUT_TOP_MARGIN_MOBILE : LOGGED_OUT_TOP_MARGIN
      }
    >
      {children}
    </RhFlexBox>
  );
};
