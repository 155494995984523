export enum DocumentType {
  ContractExpiration = "CONTRACT_EXPIRATION",
  DeferredPayment = "DEFERRED_PAYMENT",
  DemandLetter = "DEMAND_LETTER",
  DisconnectionNotice = "DISCONNECT_NOTICE",
  WelcomeKit = "WELCOME_KIT",
}

export interface DocumentHistoryType {
  documentType: DocumentType;
  generatedDate: string;
  url: string;
}
