import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import React, { PropsWithChildren } from "react";

interface ProductContractTOSLinkProps {
  dataTrackingClick: {
    [key: string]: string;
    location: string;
  };
  tosUrl: string;
}

export const ProductContractTosLink = (
  props: PropsWithChildren<ProductContractTOSLinkProps>
) => {
  const { dataTrackingClick, tosUrl, children } = props;

  return (
    <RhAnchor
      data-tracking-click={JSON.stringify({
        event: "Product Contract TOS Download",
        ...dataTrackingClick,
      })}
      href={tosUrl}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </RhAnchor>
  );
};
