export const translations = {
  tPayBillSuccessPageGoHomeCTA: "Back to Home",
  tPayBillSuccessPagePayBillSuccessImageTitle:
    "Person reading in a chair shaped like a checkmark",
  tPayBillSuccessPageReconnectionNoContactNeeded:
    "There’s no need to contact our Customer Care Team.",
  tPayBillSuccessPageReconnectionRequestSent:
    "Your reconnection request has been sent. Reconnections typically take 3-4 hours for the utility to process.",
  tPayBillSuccessPageReconnectionUpdates:
    "In the meantime, we will keep you informed with timely status updates via email and SMS.",
  tPayBillSuccessPageThankYou: "Thank you for your payment!",
  tPayBillSuccessPageYourRemainingBalance:
    "Your remaining balance is {balance}",
};
