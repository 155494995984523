import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { averagedBillingDialogTranslations } from "@portal/components/AveragedBillingDialog/AveragedBillingDialog.en.i18n";
import React from "react";

interface AveragedBillingDialogProps {
  averagedBillingMonthlyCharge?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const AveragedBillingDialog = ({
  averagedBillingMonthlyCharge,
  isOpen,
  onClose,
  onSubmit,
}: AveragedBillingDialogProps) => {
  const { translate, translateJsx } = useTranslations();

  const {
    tAveragedBillingDialogCancelButtonCta,
    tAveragedBillingDialogEnrollButtonCta,
    tAveragedBillingDialogEnroll,
  } = translate(averagedBillingDialogTranslations);

  const { tAveragedBillingDialogEstimatedMonthlyAverage } = translateJsx({
    tAveragedBillingDialogEstimatedMonthlyAverage: {
      amount: () => <strong>{`$${averagedBillingMonthlyCharge}`}</strong>,
    },
  });

  return (
    <RhDialog open={isOpen} onClose={onClose} size="medium">
      <RhDialogTitle>
        <RhFlexBox marginBottom={1} justifyContent="center" width="100%">
          <RhTypography variant="h2">
            {tAveragedBillingDialogEnroll}
          </RhTypography>
        </RhFlexBox>
        <RhFlexBox alignItems="center" justifyContent="center">
          <RhTypography color="textSecondary" variant="body1">
            {tAveragedBillingDialogEstimatedMonthlyAverage}
          </RhTypography>
        </RhFlexBox>
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          "data-tracking-click": {
            event: "Customer enrolling in average billing",
          },
          label: tAveragedBillingDialogEnrollButtonCta,
          onClick: onSubmit,
        }}
        secondaryButton={{
          "data-tracking-click": {
            event: "Customer cancelling enrolling in average billing",
          },
          label: tAveragedBillingDialogCancelButtonCta,
          onClick: onClose,
        }}
      />
    </RhDialog>
  );
};
