import { UUIDType } from "@common/types/apiTypes";

export const QuickPayUrls = {
  BASE: "/api/portal/quick-pay" as const,
  addPaymentMethod: (token: UUIDType) =>
    `${QuickPayUrls.BASE}/${token}/update-payment-method`,
  createPayment: (token: UUIDType) => `${QuickPayUrls.BASE}/${token}/payments`,
  getAccountBalance: (token: UUIDType) =>
    `${QuickPayUrls.BASE}/${token}/get-account-balance`,
  getPaymentMethod: (token: UUIDType, paymentMethodId: string) =>
    `${QuickPayUrls.BASE}/${token}/payment-method/${paymentMethodId}`,
  getPaymentMethods: (token: UUIDType) =>
    `${QuickPayUrls.BASE}/${token}/get-payment-methods`,
  resend: (token: UUIDType) => `${QuickPayUrls.BASE}/${token}/new-payment-link`,
};
