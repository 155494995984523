import { ProductSelection } from "@portal/components/ProductAddOnsOptIn/ProductSelection/ProductSelection";
import { PortalProductAddOnsOptInConfirmationPage } from "@portal/pages/PortalProductAddOnsOptInConfirmationPage/PortalProductAddOnsOptInConfirmationPage";
import React from "react";
import { Route, Routes } from "react-router-dom";

export const ProductAddOnsOptInRoutes = () => {
  return (
    <Routes>
      <Route index element={<ProductSelection />} />
      <Route
        path="/confirmation"
        element={<PortalProductAddOnsOptInConfirmationPage />}
      />
    </Routes>
  );
};
