import { RhListItemText } from "@design-system/components/RhListItemText/RhListItemText.styled";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { List, ListItem, ListItemIcon } from "@mui/material";
import styled from "styled-components";

const SVG_ICON_SIZE = "20px";

export const StyledList = styled(List)`
  &.MuiList-root {
    padding: 0;
  }
`;

export const StyledListItemText = styled(RhListItemText)`
  & .MuiTypography-root {
    font-family: ${fontFamily};
  }
`;

export const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    padding-bottom: ${rhSpacingPx(1)};
    padding-left: 0;
    padding-top: 0;
    position: relative;

    & span {
      line-height: 1.25;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

export const StyledHasIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 30px;

    & > svg {
      height: ${SVG_ICON_SIZE};
      width: ${SVG_ICON_SIZE};
    }
  }
`;
