export const priceScheduleBreakdownTranslations = {
  tPriceScheduleBreakdownBuybackRate: "Buyback Rate:",
  tPriceScheduleBreakdownChargeRate: "Charge Rate:",
  tPriceScheduleBreakdownEnergyCharge:
    "Energy Charge & Solar Buyback Period & Hours",
  tPriceScheduleBreakdownEnergyChargeDescription:
    "This shows you the Energy Charge & Solar Buyback Periods broken down by hour. The Energy Charge Rate (for energy consumed) & Solar Buyback Credit Rate (for surplus energy exported) may vary by period.",
  tPriceScheduleBreakdownEnergyChargeRate:
    "Energy Charge Rate & Solar Buyback Credit Rate (¢ per kWh)",
  tPriceScheduleBreakdownEnergyChargeRateDescription:
    "Here is a breakdown of your Energy Charge Rate for the corresponding Energy Charge Period when you consume energy and Solar Buyback Credit Rate for the corresponding Solar Buyback Credit Period when you may export any surplus generation.",
  tPriceScheduleBreakdownEnergySchedule: "Energy Charge Schedule",
  tPriceScheduleBreakdownSolarBuybackCreditSchedule:
    "Solar Buyback Credit Rate Schedule",
};
