export const customerHomeRenewalSectionTranslations = {
  tCustomerHomeRenewalSectionRenewNow: "Renew now",
  tCustomerHomeRenewalSectionRenewalWelcomeImageAltText:
    "Lady plugging an electric cord to a cloud in the sky",
  tCustomerHomeRenewalSectionSubtitleExpireToday:
    "But fret not. You can enjoy price peace of mind by renewing on a fixed-rate plan in seconds.",
  tCustomerHomeRenewalSectionSubtitleExpired:
    "We noticed your contract expired and you’re now on a variable-rate plan. Switch back to a fixed-rate plan in seconds.",
  tCustomerHomeRenewalSectionSubtitleExpires:
    "Your contract expires in <bold>{days} days</bold>. \n Lucky for you, we have great fixed-rate plans you can renew in seconds.",
  tCustomerHomeRenewalSectionSwitchToFixedRate: "Switch to fixed-rate",
  tCustomerHomeRenewalSectionViewRenewalOptions: "View renewal options",
  tCustomerHomeRenewalSectionWelcomeMessageRenewal:
    "{name}, today’s a great day to renew your contract.",
  tCustomerHomeRenewalSectionWelcomeMessageRenewalExpired:
    "{name}, your fixed-rate contract has expired.",
  tCustomerHomeRenewalSectionWelcomeMessageRenewalToday:
    "{name}, your contract expires today.",
};
