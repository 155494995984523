export const billBreakdownCardTranslations = {
  tBillBreakdownCardEnergyCharge: "Energy Charge",
  tBillBreakdownCardHeresBreakdown:
    "Here’s a breakdown of what goes into your bill.",
  tBillBreakdownCardLearnMore: "Learn more",
  tBillBreakdownCardLearnMoreAbout: "Learn more about how to read your bill.",
  tBillBreakdownCardOrderError:
    "Oops! Something went wrong while getting your order. Please try again later.",
  tBillBreakdownCardThisIsAmountOfElectricity:
    "This is the amount of electricity you consume each month, which tends to be at its highest during the hot summer and cold winter months.",
  tBillBreakdownCardThisIsWhatYourLocalUtility:
    "This is what your local utility (the company in charge of the poles, wires, and getting power from the grid to your home) charges each month.",
  tBillBreakdownCardTransmission:
    "Transmission and Distribution Utility (TDU) Charges",
  tBillBreakdownCardUsage: "Usage",
  tBillBreakdownCardYourPlanFixed:
    "Your current plan, {planName}, is a fixed-rate plan, ensuring your energy charge per kWh remains consistent throughout your contract.",
  tBillBreakdownCardYourPlanVariable:
    "Your current plan, {planName}, is a variable-rate plan, so your energy charge can fluctuate month to month. You can switch to a fixed-rate plan in My Account.",
};
