import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OfferSnapshotFAQsTitleWrapper } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { offerSnapshotFAQsTwoMonthsFreeTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsTwoMonthsFree/OfferSnapshotFAQsTwoMonthsFree.en.i18n";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React, { ReactNode } from "react";

const generateLink = (url: string) => (message: ReactNode) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {message}
    </a>
  );
};

export const OfferSnapshotFAQsTwoMonthsFree = () => {
  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotFAQsTwoMonthsFreeBillCreditsOnCancelAnswer,
    tOfferSnapshotFAQsTwoMonthsFreeBillCreditsOnCancelQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeHowDoesThisWorkAnswer,
    tOfferSnapshotFAQsTwoMonthsFreeHowDoesThisWorkQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeLeaveBeforeContractEndAnswer,
    tOfferSnapshotFAQsTwoMonthsFreeLeaveBeforeContractEndQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeWhenDoIReceiveCreditAnswer,
    tOfferSnapshotFAQsTwoMonthsFreeWhenDoIReceiveCreditQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeWhoIsEligibleAnswer,
    tOfferSnapshotFAQsTwoMonthsFreeWhoIsEligibleQuestion,
    tOfferSnapshotFAQsTwoMonthsFreeTitle,
  } = translate(offerSnapshotFAQsTwoMonthsFreeTranslations);
  const {
    tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesAnswer,
    tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsAnswer,
  } = translateJsx({
    tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesAnswer: {
      link: generateLink(brandInformation.urlsFaqsEtfReimbursement),
    },
    tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsAnswer: {
      link: generateLink(brandInformation.urlsFaqsReferAFriend),
    },
  });

  return (
    <OfferSnapshotFAQsTitleWrapper title={tOfferSnapshotFAQsTwoMonthsFreeTitle}>
      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeHowDoesThisWorkQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeHowDoesThisWorkAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeWhenDoIReceiveCreditQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeWhenDoIReceiveCreditAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeWhoIsEligibleQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeWhoIsEligibleAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeLeaveBeforeContractEndQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeLeaveBeforeContractEndAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsAnswer}
        </RhTypography>
      </PortalAccordion>

      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsTwoMonthsFreeBillCreditsOnCancelQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsTwoMonthsFreeBillCreditsOnCancelAnswer}
        </RhTypography>
      </PortalAccordion>
    </OfferSnapshotFAQsTitleWrapper>
  );
};
