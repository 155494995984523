import { StyledPortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout.styled";
import React, { FC } from "react";

export const PortalPageLayout: FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <StyledPortalPageLayout className={className}>
      {children}
    </StyledPortalPageLayout>
  );
};
