import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  ANNOUNCEMENT_HEIGHT,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledContainer = styled.div<{ $maxWidth?: number }>`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT + 1)};
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "400px")};
  padding-bottom: 4rem;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XXS}px) {
    margin-top: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT + 3)};
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
  margin: 0 auto;
  max-width: 80ch;
  padding-bottom: 1rem;
  text-align: center;
  text-wrap: balance;
`;

export const StyledButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  width: 100%;

  > button {
    min-width: 250px;
  }
`;

export const StyledBackLink = styled(Link)`
  font-size: 1rem;
`;
