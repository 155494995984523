export const offerRateAndEstimateTranslations = {
  tOfferRateAndEstimateAutopayPaperlessAndThermostatDiscountTooltip:
    "This is the Average Price per kWh with Auto Pay & Paperless and Connected Thermostat credits.",
  tOfferRateAndEstimateAutopayPaperlessDiscountTooltip:
    "This is the Average Price per kWh with Auto Pay & Paperless.",
  tOfferRateAndEstimateAverage: "average monthly bill",
  tOfferRateAndEstimateBasedOn: "based on {amount} kWh usage",
  tOfferRateAndEstimatePerKWH: "per kWh",
  tOfferRateAndEstimatePerKilowattHour: "per kilowatt hour",
  tOfferRateAndEstimateSolarEligible:
    "energy charge per kWh plus TDSP charges = {totalPerKwh} based on {kwh} kwh average usage. The solar buyback rate = {solarBuybackRate} per kWh. A monthly base charge of {baseCharge} will be applied.",
  tOfferRateAndEstimateThermostatDiscountTooltip:
    "This is the Average Price per kWh with Connected Thermostat credits.",
  tOfferRateAndEstimateTimeOfUseTooltip:
    'Click "Learn More" below for an hourly breakdown of energy charges.',
  tOfferRateAndEstimateTooltipLabel: "Additional information",
};
