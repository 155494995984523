import { IdType, UUIDType } from "@common/types/apiTypes";

export enum ProductPriceTermPeriod {
  MONTHS = "MONTHS",
}

export enum ProductPriceFrequency {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
}

// Check the choices for ProductTierFeatureIcons on the backend
export const featureIconValues = [
  "calendar",
  "check",
  "guarantee",
  "person_2",
  "price_tag",
  "save_money",
] as const;

export type ProductTierFeatureIconType = (typeof featureIconValues)[number];

export interface ProductTierFeatureType {
  description: string;
  iconName: ProductTierFeatureIconType;
  priority: number;
}

// Check the choices for ProductGroupIcons on the backend
export const groupIconValues = ["protection_plans", "save_money"] as const;

export type ProductTierPresentationGroupIconType =
  (typeof groupIconValues)[number];

export interface ProductTierPresentationGroupType {
  groupPriority?: number;
  icon: ProductTierPresentationGroupIconType;
  name: string;
  presentationGroupId: UUIDType;
  tierPriority: number;
}

export type ProductTierCategoryType = string;

export interface ProductTierType {
  categories: ProductTierCategoryType[];
  description: string;
  features: ProductTierFeatureType[];
  icon: string;
  id: UUIDType;
  name: string;
  presentationGroups: ProductTierPresentationGroupType[];
  prices: ProductPriceType[];
  productId: UUIDType;
}

export interface ProductPriceType {
  frequency: ProductPriceFrequency;
  id: UUIDType;
  rate: string;
  termLength: string;
  termPeriod: ProductPriceTermPeriod;
}

export interface ProductCreateRequestType {
  premiseId: IdType;
  priceId: UUIDType;
  startDate: string;
  termLength: string;
  termPeriod: ProductPriceTermPeriod;
}

export const ProductPriceFrequencyPrettyNames: Record<
  ProductPriceFrequency,
  string
> = {
  [ProductPriceFrequency.MONTHLY]: "month",
  [ProductPriceFrequency.ANNUALLY]: "year",
};

export const ProductPriceFrequencyPrettyNamesMobile: Record<
  ProductPriceFrequency,
  string
> = {
  [ProductPriceFrequency.MONTHLY]: "mo",
  [ProductPriceFrequency.ANNUALLY]: "yr",
};

export const ProductPriceTermPeriodPrettyNames: Record<
  ProductPriceTermPeriod,
  string
> = {
  [ProductPriceTermPeriod.MONTHS]: "months",
};
