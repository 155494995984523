import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhRoundTabs } from "@design-system/components/RhRoundTabs/RhRoundTabs";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  OFFER_CONTAINER_WIDTH_3CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const FilterTabsContainer = styled.div`
  max-width: 100%;
  padding: 0 ${rhSpacingPx(3)};
  padding-bottom: ${rhSpacingPx(4)};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    padding-bottom: ${rhSpacingPx(2)};
    width: ${OFFER_CONTAINER_WIDTH_3CARD};
  }
`;

export const SelectContainer = styled.div`
  margin: 0 auto;
  max-width: 310px;
`;

export const FilterTabs = styled(RhRoundTabs)`
  &.MuiTabs-root {
    & button {
      color: ${CSSVariables.COLOR_PRIMARY_MAIN};
      font-family: ${fontFamily};
      font-size: 16px;
      font-weight: ${FontWeight.Regular};
      letter-spacing: normal;
      text-transform: none;
      &.selected {
        background-color: transparent;
        font-weight: ${FontWeight.Semibold};
      }
    }
  }
`;
