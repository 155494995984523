import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsMonthToMonthTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsMonthToMonth/OfferSnapshotFAQsMonthToMonth.en.i18n";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const OfferSnapshotFAQsMonthToMonth = () => {
  const { translate } = useTranslations();
  const {
    tOfferSnapshotFAQsMonthToMonthAfterMonthEndsAnswer,
    tOfferSnapshotFAQsMonthToMonthAfterMonthEndsQuestion,
    tOfferSnapshotFAQsMonthToMontWhatFeesAnswer,
    tOfferSnapshotFAQsMonthToMontWhatFeesQuestion,
  } = translate(offerSnapshotFAQsMonthToMonthTranslations);

  return (
    <>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsMonthToMontWhatFeesQuestion}</summary>
        <RhTypography>
          {tOfferSnapshotFAQsMonthToMontWhatFeesAnswer}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>
          {tOfferSnapshotFAQsMonthToMonthAfterMonthEndsQuestion}
        </summary>
        <RhTypography>
          {tOfferSnapshotFAQsMonthToMonthAfterMonthEndsAnswer}
        </RhTypography>
      </PortalAccordion>
    </>
  );
};
