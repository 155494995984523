import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { Popper } from "@mui/material";
import styled from "styled-components";

export const StyledPopper = styled(Popper)`
  & .MuiTooltip-tooltip {
    background-color: transparent;
    color: ${CSSVariables.COLOR_GREY_700};
    font-size: 10px;
    font-weight: ${FontWeight.Bold};
    margin: ${rhSpacingPx(1)};
    padding: 0;
    transform: translateX(-22px) !important;
  }
`;

export const StyledLabelText = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_600};
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: ${FontWeight.Bold};

    &span {
      background-color: transparent;
      color: ${CSSVariables.COLOR_GREY_700};
      font-size: 10px;
      font-weight: ${FontWeight.Bold};
      margin: ${rhSpacingPx(1)} !important;
      padding: 0;
      transform: translateX(-22px) !important;
    }
  }
`;
