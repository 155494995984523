import { IdType } from "@common/types/apiTypes";
import { AddressType } from "@common/types/customerTypes";

export enum DeviceBrands {
  Amazon = "amazon",
  Honeywell = "honeywell",
}

export enum DeviceStatus {
  Offline = "OFFLINE",
  Online = "ONLINE",
  Unavailable = "UNAVAILABLE",
}

export enum DeviceConnectionEventTypes {
  Authorized = "Device Authorized",
  Offline = "Device Offline",
  Online = "Device Online",
  OptOut = "Demand Response Opt Out",
  Unauthorized = "Device Unauthorized",
}

export interface DeviceType {
  alreadyConnected: boolean;
  deviceBrand: DeviceBrands;
  deviceId: string;
  deviceModel: string;
  deviceName: string;
  deviceStatus: DeviceStatus;
  premiseId: IdType | null;
  smartThermostatId: IdType;
}

export interface DevicesType {
  thermostats: Array<DeviceType>;
}

export interface DeviceSelected {
  deviceId: IdType;
  premiseId: IdType;
}

export interface DeviceAuthUrl {
  redirectUrl: string;
}

export interface ResideoDeviceResponse {
  thermostats: DeviceType[];
  userId: IdType;
}

export interface ResideoDeviceListStorageType extends ResideoDeviceResponse {
  deviceBrand: DeviceBrands | null;
}

export interface ResideoAttachDevicesType {
  deviceBrand: DeviceBrands;
  thermostats: DeviceSelected[];
  userId: IdType;
}

export interface DeviceWithPremise {
  device: DeviceType;
  premise: {
    address: AddressType | null | undefined;
    id: IdType;
  };
}

export interface DeviceConnectionActivityType {
  eventType: DeviceConnectionEventTypes;
  occurredAt: string;
}

export enum DeviceDemandResponseActivityEventStatusTypes {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
  Finished = "FINISHED",
  OptedOut = "OPTED_OUT",
  PhaseChanged = "PHASE_CHANGED",
  Ready = "READY",
  Scheduled = "SCHEDULED",
  Sent = "SENT",
  Started = "STARTED",
}

export enum DeviceDemandResponseActivityEventPhaseActionTypes {
  CoolDelta = "coolDelta",
  CoolSetpoint = "coolSetpoint",
  HeatDelta = "heatDelta",
  HeatSetpoint = "heatSetpoint",
}

export interface DeviceDemandResponseActivityPhaseType {
  action: DeviceDemandResponseActivityEventPhaseActionTypes;
  duration: string;
  index: number;
  value: string;
}

export interface DeviceDemandResponseActivityType {
  endAt: string;
  eventId: IdType;
  phases: DeviceDemandResponseActivityPhaseType[];
  startAt: string;
  status: DeviceDemandResponseActivityEventStatusTypes;
}
