import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { myAccountNavItemValues } from "@portal/components/MyAccountNav/myAccountNaConfig";
import {
  StyledMyAccountLinkContainer,
  StyledNavContainer,
} from "@portal/components/MyAccountNav/MyAccountNav.styled";
import { NavHashLink } from "@portal/components/NavHashLink/NavHashLink";
import { signOutPath } from "@portal/routes/routePaths";
import React from "react";
import { Link } from "react-router-dom";

export const MyAccountNav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const { t } = useRhIntl();

  return (
    <div role="navigation">
      <StyledNavContainer data-testid="myAccount__nav">
        {myAccountNavItemValues.map(({ anchorTag, translationId }) => {
          const title = t(translationId);

          return (
            <StyledMyAccountLinkContainer key={anchorTag}>
              <NavHashLink
                smooth
                to={anchorTag}
                scroll={scrollToOffsetPosition}
              >
                {title}
                {isMobile && <RightChevron title={title} />}
              </NavHashLink>
            </StyledMyAccountLinkContainer>
          );
        })}
        <StyledMyAccountLinkContainer>
          <Link to={signOutPath()}>
            {t("MyAccountNav.logout")}
            {isMobile && <RightChevron title={t("MyAccountNav.logout")} />}
          </Link>
        </StyledMyAccountLinkContainer>
      </StyledNavContainer>
    </div>
  );
};
