import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { NotificationOptionSwitches } from "@portal/components/NotificationOptionSwitches/NotificationOptionSwitches";
import { notificationsPageTranslations } from "@portal/pages/NotificationsPage/NotificationsPage.en.i18n";
import {
  StyledNotificationsCoulmn,
  StyledNotificationsHeader,
  StyledNotificationsWrapper,
} from "@portal/pages/NotificationsPage/NotificationsPage.styled";
import React from "react";
import { Helmet } from "react-helmet-async";

export const NotificationsPage = () => {
  const { translate } = useTranslations();

  const { tNotificationPagePageTitle, tNotificationPageNotifications } =
    translate(notificationsPageTranslations);

  return (
    <>
      <Helmet>
        <title>{`${tNotificationPageNotifications} - ${tNotificationPagePageTitle}`}</title>
      </Helmet>
      <PortalPageLayout>
        <StyledNotificationsWrapper>
          <StyledNotificationsCoulmn>
            <StyledNotificationsHeader>
              <RhTypography variant="h1">
                {tNotificationPageNotifications}
              </RhTypography>
            </StyledNotificationsHeader>
            <NotificationOptionSwitches />
          </StyledNotificationsCoulmn>
        </StyledNotificationsWrapper>
      </PortalPageLayout>
    </>
  );
};
