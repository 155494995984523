import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal/api/invoiceApi";
import { InvoiceQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceQueryProps {
  invoiceId: IdType;
}

export const useInvoiceQuery = (props: UseInvoiceQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.retrieve(invoiceId),
    queryKey: InvoiceQueryKeys.retrieve(invoiceId),
  });
};
