import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";

const EDI_BASE_URL = "/api/edi";

export const ediApi = {
  getServiceStartDates: (offerSnapshotId: IdType): Promise<string[]> => {
    return ajax.get(`${EDI_BASE_URL}/service/start_dates`, {
      params: { offerSnapshotId },
    });
  },
};
