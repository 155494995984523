import { boxShadows } from "@design-system/constants/boxShadows";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const OfferSnapshotMiniCardActions = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    align-self: initial;
  }
`;

export const OfferSnapshotMiniCardBody = styled.div`
  & > div {
    margin-bottom: ${rhSpacingPx(0.5)};
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const OfferSnapshotMiniCardContent = styled.div`
  flex: 1;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    text-align: center;
  }
`;

export const OfferSnapshotMiniCardPriceSpan = styled.span`
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
`;

export const OfferSnapshotMiniCardRoot = styled.div`
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 0;
  max-width: 300px;
  padding: 20px;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: column;
    gap: 0.25rem;
    padding: ${rhSpacingPx(2.5)};
  }
`;

export const OfferSnapshotMiniCardSubtitle = styled.p`
  font-size: 14px;
  font-weight: ${FontWeight.Regular};
  margin: 0;
`;

export const OfferSnapshotMiniCardTitle = styled.p`
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const OfferSnapshotMiniCardTitleWrapper = styled.div`
  margin-bottom: ${rhSpacingPx(0.75)};
`;
