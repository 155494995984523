import styled from "styled-components";

export const StyledLoadingContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
  padding: 4rem;
  text-align: center;
`;
