import { prospectApi } from "@common/api/prospectApi";
import { maskSSN, unmaskSSN } from "@common/forms/ssn.mask";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  CreditEvaluationDefinition,
  determineCreditEvaluationDefinition,
} from "@common/services/creditEvaluation.service";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { RhApiError } from "@common/types/errorTypes";
import { ProspectCreditCheckRequestType } from "@common/types/prospectTypes";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { LoggedOutFieldsLayout } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { isValidSSN } from "@portal/forms/validators";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { RunCreditCheckRadioGroup } from "@portal/pages/SignUpSSNRequiredPage/RunCreditCheckRadioGroup.component";
import { RunCreditCheckType } from "@portal/pages/SignUpSSNRequiredPage/RunCreditCheckType.enum";
import { signUpSSNRequiredPageTranslations } from "@portal/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage.en.i18n";
import {
  selectCreditCheckValues,
  selectProspectId,
  selectSignUpAcquisition,
  selectSignUpState,
} from "@portal/selectors/signUpSelectors";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import { FORM_ERROR } from "final-form";
import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

interface SSNFormValues {
  runCreditCheckType: RunCreditCheckType;
  ssn: string;
}

const initialValues = Object.freeze<SSNFormValues>({
  runCreditCheckType: RunCreditCheckType.RunCheck,
  ssn: "",
});

const ssnFormValidator = generateValidationErrorCollector<SSNFormValues>({
  ssn: [isValidSSN],
});

export const SignUpSSNRequiredPage = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const customerContactValues = useSelector(selectCreditCheckValues);
  const prospectId = useSelector(selectProspectId);
  const { acquisitionMedium } = useSelector(selectSignUpAcquisition);
  const flash = useRhFlash();
  const { depositAmount, ssnRequired, autopay, creditEvaluation } =
    useSelector(selectSignUpState);

  const { translate } = useTranslations();
  const {
    tSignUpSSNRequiredPageCreditScoreApiError,
    tSignUpSSNRequiredPageNext,
    tSignUpSSNRequiredPageSocialSecurityNumber,
    tSignUpSSNRequiredPageTimeForQuickCreditCheck,
    tSignUpSSNRequiredPageVerifyYourIdentity,
  } = translate(signUpSSNRequiredPageTranslations);

  const creditEvaluationDefinition = determineCreditEvaluationDefinition({
    creditEvaluation,
    depositAmount,
    ssnRequired,
  });

  const nextStep = useCallback(
    (outcome: string, newDepositAmount: number | null): SignUpStepType => {
      if (outcome === CreditScoreOutcome.manualReview) {
        return "call-us";
      } else if (newDepositAmount) {
        return "deposit";
      } else if (
        !autopay &&
        creditEvaluationDefinition ===
          CreditEvaluationDefinition.ApprovedNoDeposit
      ) {
        return "summary";
      } else {
        return "payment";
      }
    },
    [autopay, creditEvaluationDefinition]
  );

  const onSubmit = ({ runCreditCheckType, ssn }: SSNFormValues) => {
    if (runCreditCheckType === RunCreditCheckType.SkipCheck) {
      signUpClickNextStepHandler({
        nextStep: nextStep("", depositAmount),
        track: true,
      });
    } else {
      const contactValuesWithSSN: ProspectCreditCheckRequestType = {
        ...customerContactValues,
        acquisitionMedium,
        prospectId,
        ssn,
      };

      return prospectApi
        .creditScoreEvaluation(contactValuesWithSSN)
        .then(({ depositAmount: newDepositAmount, outcome, ssnProvided }) => {
          if (outcome) {
            const deposit = {
              depositAmount: newDepositAmount ?? 0,
              depositRequired: Boolean(newDepositAmount),
            };

            track({
              action: ActionType.clickedNextPage,
              category: "credit-check.creditCheckComplete",
              event: EventType.enrollmentClick,
              label: "Credit Worthiness",
              value: determineCreditEvaluationDefinition({
                creditEvaluation: outcome,
                depositAmount: newDepositAmount,
              }),
              ...deposit,
              ssnProvided,
            });
          }

          signUpClickNextStepHandler({
            nextStep: nextStep(outcome, newDepositAmount),
            signUpData: {
              creditEvaluation: outcome,
              depositAmount: newDepositAmount,
            },
            track: true,
          });
        })
        .catch((_error: RhApiError) => {
          flash.error(tSignUpSSNRequiredPageCreditScoreApiError);

          return { [FORM_ERROR]: [tSignUpSSNRequiredPageCreditScoreApiError] };
        });
    }
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpSSNRequiredPageTimeForQuickCreditCheck}
        subHeaderText={tSignUpSSNRequiredPageVerifyYourIdentity}
      />
      <Form<SSNFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={ssnFormValidator}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpSSNRequiredPageNext}
          >
            <LoggedOutFieldsLayout>
              <RhTextField
                name="ssn"
                type="text"
                autoComplete="ssn"
                placeholder="123-45-6789"
                inputMode="numeric"
                format={maskSSN}
                parse={unmaskSSN}
                InputProps={{
                  onFocus: (event) => {
                    trackEvent({
                      action: ActionType.focus,
                      label: event.target.id,
                    });
                  },
                }}
              >
                {tSignUpSSNRequiredPageSocialSecurityNumber}
              </RhTextField>
              {creditEvaluation === CreditScoreOutcome.manualReview ? null : (
                <RunCreditCheckRadioGroup
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    const {
                      target: { value },
                    } = event as unknown as React.ChangeEvent<HTMLInputElement>;
                    const label =
                      value === RunCreditCheckType.RunCheck
                        ? "runCreditRadioButton"
                        : "payDepositRadioButton";

                    trackEvent({
                      action: ActionType.click,
                      label,
                    });
                  }}
                />
              )}
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};
