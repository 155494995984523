import { FeatureFlagClientProvider } from "@common/context/featureFlagClient.context";
import { useFeatureFlagUserId } from "@portal-shared/hooks/useFeatureFlagUserId/useFeatureFlagUserId";
import {
  ActionType,
  GeneralEvents,
  track,
} from "@portal-shared/services/segment.service";
import React, { PropsWithChildren, useEffect } from "react";

interface GuestFeatureFlagClientProviderProps {}

export const GuestFeatureFlagClientProvider = (
  props: PropsWithChildren<GuestFeatureFlagClientProviderProps>
) => {
  const { children } = props;

  const featureFlagUserId = useFeatureFlagUserId();

  useEffect(() => {
    if (featureFlagUserId) {
      track({
        action: ActionType.userIdentified,
        category: "portal",
        event: GeneralEvents.identify,
        label: "User Identified",
        value: featureFlagUserId,
      });
    }
  }, [featureFlagUserId]);

  return (
    <FeatureFlagClientProvider featureFlagUserId={featureFlagUserId}>
      {children}
    </FeatureFlagClientProvider>
  );
};
