import { TranslationKey } from "@portal-shared/hooks/useRhIntl";

export enum AccountNavAnchorTag {
  billingPreferences = "billingPreferences",
  changePassword = "changePassword",
  communicationPreferences = "communicationPreferences",
  contactInfo = "contactInfo",
  languagePreference = "languagePreference",
  paymentMethods = "paymentMethods",
  productAddOns = "productAddOns",
  serviceInfo = "serviceInfo",
  yourPlan = "yourPlan",
}

interface MyAccountNavItemValue {
  anchorTag: string;
  translationId: TranslationKey;
}

export const myAccountNavItemValues: MyAccountNavItemValue[] = [
  {
    anchorTag: `#${AccountNavAnchorTag.serviceInfo}`,
    translationId: "MyAccountNav.serviceInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.yourPlan}`,
    translationId: "MyAccountNav.yourPlan",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.productAddOns}`,
    translationId: "MyAccountNav.productAddOns",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.contactInfo}`,
    translationId: "MyAccountNav.contactInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.languagePreference}`,
    translationId: "MyAccountNav.languagePreference",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.changePassword}`,
    translationId: "MyAccountNav.changePassword",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.communicationPreferences}`,
    translationId: "MyAccountNav.communicationPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.billingPreferences}`,
    translationId: "MyAccountNav.billingPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.paymentMethods}`,
    translationId: "MyAccountNav.paymentMethods",
  },
];
