import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledPaymentExtensionSuccessContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    width: 450px;
  }
`;

export const StyledImageContainer = styled.div`
  margin: ${rhSpacingPx(5)} 0 ${rhSpacingPx(3)};
  width: 150px;
`;

export const GreyedOutText = styled(RhTypography)`
  color: ${CSSVariables.COLOR_GREY_600};
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConfirmationTextContainer = styled.div`
  margin: ${rhSpacingPx(2.5)} 0;
`;

export const BackHomeButton = styled(RhButton)`
  &.MuiButtonBase-root {
    margin-bottom: ${rhSpacingPx(5)};
    width: 300px;
  }
`;
