import { keyframes } from "styled-components";

export const DialogFadeIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const DialogSlideInLeft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const DialogSlideInRight = keyframes`
  from {
    transform: translateX(-120%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const DialogSlideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const DialogSlideDown = keyframes`
  from {
    transform: translateY(-120%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
