import { rhWindow } from "@common/utils/rhWindow";
import {
  RH_HEADER_HEIGHT,
  UNIT_OF_SPACING,
} from "@design-system/theme/style.constant";

export const scrollToOffsetPosition = (elem: HTMLElement) => {
  const extraPadding = UNIT_OF_SPACING * 4;
  const appHeaderHeight = RH_HEADER_HEIGHT * UNIT_OF_SPACING;
  const headerOffset = appHeaderHeight + extraPadding;

  if (elem) {
    rhWindow.scrollTo({
      behavior: "smooth",
      left: 0,
      top: elem.offsetTop - headerOffset,
    });
  }
};
