import { StyledRhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem.styled";
import React, { MouseEventHandler, ReactNode } from "react";

interface RhRoundTabItemProps {
  className?: string;
  label?: string | ReactNode;
  onClick?: MouseEventHandler;
}

export const RhRoundTabItem = ({
  className = "",
  label,
  onClick,
}: RhRoundTabItemProps): JSX.Element => {
  return (
    <StyledRhRoundTabItem
      className={className}
      label={label}
      onClick={onClick}
    />
  );
};
