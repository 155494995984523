import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { NavHashLinkProps, genericHashLink } from "react-router-hash-link";

/**
 * A custom `NavHashLink` that preserves functionality from `react-router-hash-link` while
 * using the `react-router-dom` package to support React Router v6.
 *
 * @deprecated This was originally written to unblock our upgrade to React Router v6.
 * The underlying (`react-router-hash-link` package)[https://www.npmjs.com/package/react-router-hash-link] has not been updated since May 31, 2021.
 * Consider whether alternatives are viable before using this component where it is already not being used.
 */
export const NavHashLink = genericHashLink(
  Link
) as FunctionComponent<NavHashLinkProps>;
