import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OfferSnapshotFAQsContainer } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQs.styled";
import React from "react";

interface OfferSnapshotFAQsTitleWrapperProps {
  children: React.ReactNode;
  title: string;
}

export const OfferSnapshotFAQsTitleWrapper = (
  props: OfferSnapshotFAQsTitleWrapperProps
) => {
  const { children, title } = props;

  return (
    <OfferSnapshotFAQsContainer>
      <RhTypography variant="h3">{title}</RhTypography>
      {children}
    </OfferSnapshotFAQsContainer>
  );
};
