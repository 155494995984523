import { TypedEntries } from "@common/types/typeUtils";
import { OfferSnapshotLearnMore } from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMore";
import { OfferSnapshotThermostatRequiredModal } from "@portal-shared/components/OfferSnapshotThermostatRequiredModal/OfferSnapshotThermostatRequiredModal";
import { getSharedModalsByPriority } from "@portal-shared/components/SharedModalsManager/getSharedModalsByPriority";
import {
  SharedModalsAtomType,
  initialSharedModalsAtom,
} from "@portal-shared/components/SharedModalsManager/SharedModalsManager.atoms";
import { useSharedModals } from "@portal-shared/components/SharedModalsManager/useSharedModals";
import React, { ReactPortal } from "react";
import { createPortal } from "react-dom";

type ModalsMappedKeys = keyof typeof initialSharedModalsAtom;
type ModalsPriorityKeys = ReturnType<typeof getSharedModalsByPriority>[number];

type SameModalKeys = ModalsMappedKeys extends ModalsPriorityKeys
  ? ModalsPriorityKeys extends ModalsMappedKeys
    ? true
    : false
  : false;

// Dummy check to ensure modalsPriority has all modals
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: SameModalKeys = true;

export const SharedModalsManager = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modals, removeModal } = useSharedModals();

  const modalEntries = Object.entries(modals) as TypedEntries<typeof modals>;

  const modalsPrioritized = modalEntries
    .filter(([, value]) => value !== null)
    .sort((a, b) => {
      const modalsByPriority = getSharedModalsByPriority();

      return modalsByPriority.indexOf(a[0]) - modalsByPriority.indexOf(b[0]);
    });

  if (modalsPrioritized.length === 0) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalKey, modalProps] = modalsPrioritized[0];

  let Modal: JSX.Element | null;

  switch (modalKey) {
    case "offerSnapshotLearnMore": {
      const props = modalProps as NonNullable<
        SharedModalsAtomType["offerSnapshotLearnMore"]
      >;

      Modal = (
        <OfferSnapshotLearnMore
          handleClose={() => {
            props.onClose?.();
            removeModal("offerSnapshotLearnMore");
          }}
          offerSnapshotUUID={props.offerSnapshotUUID}
          onEnrollClick={props.onEnrollClick}
          selectPlanCtaText={props.selectPlanCtaText}
        />
      );

      break;
    }

    case "offerSnapshotThermostatRequired": {
      const props = modalProps as NonNullable<
        SharedModalsAtomType["offerSnapshotThermostatRequired"]
      >;

      Modal = (
        <OfferSnapshotThermostatRequiredModal
          handleClose={() => {
            removeModal("offerSnapshotThermostatRequired");
          }}
          onEnrollClick={props.onEnrollClick}
          offerSnapshot={props.offerSnapshot}
        />
      );

      break;
    }

    default: {
      // Ensure all modal cases have been covered
      modalKey satisfies never;

      Modal = null;
      break;
    }
  }

  if (Modal === null) {
    return null;
  }

  return createPortal(Modal, document.body) as ReactPortal;
};
