import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  StyledContainer,
  StyledHeader,
} from "@portal/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout.styled";
import { DevicesEnrollStepper } from "@portal/components/DevicesEnrollStepper/DevicesEnrollStepper";
import { DevicesEnrollStepType } from "@portal/routes/routePaths";
import React, { ReactNode } from "react";

interface DevicesEnrollPageLayoutProps {
  children?: ReactNode;
  containerMaxWidth?: number;
  description?: string | ReactNode;
  step: DevicesEnrollStepType;
  title?: string | ReactNode;
}

export const DevicesEnrollPageLayout = (
  props: DevicesEnrollPageLayoutProps
) => {
  const { step, children, containerMaxWidth, title, description } = props;

  return (
    <StyledContainer $maxWidth={containerMaxWidth}>
      <DevicesEnrollStepper step={step} />
      <StyledHeader>
        <RhTypography variant="h1">{title}</RhTypography>
        {description ? (
          <RhTypography variant="subtitle1">{description}</RhTypography>
        ) : null}
      </StyledHeader>
      {children}
    </StyledContainer>
  );
};
