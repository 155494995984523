import {
  StyledRadio,
  StyledSvgIcon,
} from "@design-system/components/RhRadioInput/RhRadioInput.styled";
import { ReactComponent as SelectedOption } from "@design-system/icons/SelectedOption.svg";
import { ReactComponent as UnselectedOption } from "@design-system/icons/UnselectedOption.svg";
import { RadioProps } from "@mui/material";
import React from "react";

export type RhRadioInputProps = RadioProps;

export const RhRadioInput = ({ value, ...restProps }: RhRadioInputProps) => {
  return (
    <StyledRadio
      disableRipple
      id={typeof value === "string" ? value : undefined}
      icon={
        <StyledSvgIcon
          component={UnselectedOption}
          viewBox="0 0 18 18"
          fontSize="small"
        />
      }
      checkedIcon={
        <StyledSvgIcon
          component={SelectedOption}
          viewBox="0 0 18 18"
          fontSize="small"
        />
      }
      value={value}
      {...restProps}
    />
  );
};
