import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const SavingsTagContainer = styled.aside`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  position: absolute;
  right: -35px;
  top: 0;

  transform: scale(0.8);
  transform-origin: top;
  width: min-content;

  & > svg {
    z-index: 2;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    transform: unset;
  }
`;

export const TextContainer = styled.div`
  background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: ${rhSpacingPx(0.5)};
  padding: ${rhSpacingPx(0.5)};
  padding-bottom: ${rhSpacingPx(1)};
  text-align: center;
  transform: translateY(-5px);
`;

export const Tag = styled.div`
  position: relative;
  transform: rotate(-10deg) translateY(-11px) translateX(-3px);
  width: 100%;

  &::before {
    background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    clip-path: polygon(
      0 100%,
      0.15192% 71.73648%,
      0.15192% 68.26352%,
      0.60307% 66.5798%,
      1.33975% 65%,
      31.33975% 5.35898%,
      32.33956% 3.93111%,
      33.57212% 2.69854%,
      35% 1.69873%,
      36.5798% 0.96206%,
      48.26352% 0.51091%,
      45% 0,
      50% 0,
      55% 0,
      61.73648% 0.51091%,
      63.4202% 0.96206%,
      64% 1.69873%,
      66.42788% 2.69854%,
      67.66044% 3.93111%,
      68.66025% 5.35898%,
      98.66025% 65%,
      99.39693% 66.5798%,
      99.84808% 68.26352%,
      99.84808% 71.73648%,
      100% 100%
    );
    content: "";
    display: inline-block;
    height: 20px;
    width: 100%;
  }

  &::after {
    background-color: white;
    border-radius: 1000px;
    content: "";
    display: inline-block;
    height: 12px;
    left: 24px;
    position: absolute;
    top: 4px;
    width: 12px;
    z-index: 5;
  }
`;
