import { ajax } from "@common/services/ajax";
import { SignUpProductUrls } from "@portal/api/signUpUrls";
import { ProductTierType } from "@portal/types/productTypes";

export const signUpApi = {
  products: {
    tiers: {
      list: () => ajax.get<ProductTierType[]>(SignUpProductUrls.list()),
    },
  },
};
