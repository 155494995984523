import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import {
  AddressFormType,
  CustomerUpdatePostDataType,
} from "@common/types/customerTypes";
import { legacyCustomerUrls } from "@portal/api/legacyCustomerUrls";
import { CustomerType } from "@portal/types/customerTypes";

export const legacyCustomerApi = {
  legacyUpdate: ({
    customerId,
    customerData,
  }: {
    customerData: Partial<CustomerType> & Partial<AddressFormType>;
    customerId: IdType;
  }): Promise<CustomerType> => {
    return ajax.patch<CustomerType, CustomerUpdatePostDataType>(
      legacyCustomerUrls.legacyUpdate(customerId),
      customerData
    );
  },
};
