import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdatePremisePaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: premiseApi.updatePaymentMethod,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: PremiseQueryKeys.paymentMethods(premiseId),
      });
    },
  });
};
