import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { EnergyUsageChartMissingTranslations } from "@portal/components/EnergyUsageChartMissing/EnergyUsageChartMissing.en.i18n";
import {
  EnergyUsageChartMissingContainer,
  EnergyUsageChartMissingText,
} from "@portal/components/EnergyUsageChartMissing/EnergyUsageChartMissing.styled";
import React from "react";

export const EnergyUsageChartMissing = () => {
  const { translate } = useTranslations();

  const { tEnergyUsageChartMissingDataWillAppear } = translate(
    EnergyUsageChartMissingTranslations
  );

  return (
    <EnergyUsageChartMissingContainer>
      <EnergyUsageChartMissingText variant="subtitle1" color="textSecondary">
        {tEnergyUsageChartMissingDataWillAppear}
      </EnergyUsageChartMissingText>
    </EnergyUsageChartMissingContainer>
  );
};
