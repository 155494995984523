import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { areaNotServiceableModalTranslations } from "@portal/components/AreaNotServiceableModal/AreaNotServiceableModal.en.i18n";
import {
  AreaNotServiceableInner,
  AreaNotServiceableModalSubtitle,
  AreaNotServiceableSubtitleContainer,
  OrSeparator,
  StyledAreaNotServiceableModal,
} from "@portal/components/AreaNotServiceableModal/AreaNotServiceableModal.styled";
import React from "react";

interface AreaNotServiceableModalConfirmationProps {
  onBackToHome: () => void;
  onTryAnotherZipCode: () => void;
}

export const AreaNotServiceableModalConfirmation = ({
  onBackToHome,
  onTryAnotherZipCode,
}: AreaNotServiceableModalConfirmationProps) => {
  const { translate } = useTranslations();
  const {
    tAreaNotServiceableModalYouAeOnTheList,
    tAreaNotServiceableModalWeWillLetYouKnow,
    tAreaNotServiceableModalBackToHome,
    tAreaNotServiceableModalTryAnotherZipCode,
    tAreaNotServiceableModalOr,
  } = translate(areaNotServiceableModalTranslations);

  return (
    <StyledAreaNotServiceableModal>
      <RhTypography variant="h1" align="center">
        {tAreaNotServiceableModalYouAeOnTheList}
      </RhTypography>
      <AreaNotServiceableSubtitleContainer>
        <AreaNotServiceableModalSubtitle variant="subtitle2" align="center">
          {tAreaNotServiceableModalWeWillLetYouKnow}
        </AreaNotServiceableModalSubtitle>
      </AreaNotServiceableSubtitleContainer>
      <AreaNotServiceableInner>
        <RhButton
          data-tracking-click={{
            event: "Prospect going back to marketing website",
          }}
          color="primary"
          variant="outlined"
          onClick={onBackToHome}
        >
          {tAreaNotServiceableModalBackToHome}
        </RhButton>
        <OrSeparator>- {tAreaNotServiceableModalOr} -</OrSeparator>
        <RhButton
          data-tracking-click={{
            event: "Prospect updating zip code",
          }}
          color="primary"
          variant="outlined"
          onClick={onTryAnotherZipCode}
        >
          {tAreaNotServiceableModalTryAnotherZipCode}
        </RhButton>
      </AreaNotServiceableInner>
    </StyledAreaNotServiceableModal>
  );
};
