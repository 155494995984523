import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledLinkRow = styled(RhAnchor)`
  align-items: center;
  border-bottom: ${borders[100]};
  display: flex;
  justify-content: flex-end;
  min-height: 53px;

  &:not(.MuiButton-root):hover {
    text-decoration: none;
  }

  &:hover {
    background-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const StyledInvoicesRowElement = styled(RhTypography)`
  &.MuiTypography-root {
    &:first-child {
      flex-basis: 145px;
      margin-right: auto;
      padding: 0;
      text-align: start;
    }

    &:last-child {
      flex-basis: 150px;
      flex-shrink: 1;
      padding: 0;
      text-align: end;
      @media (max-width: ${RhythmBreakpoints.SM}px) {
        flex-basis: 110px;
      }
    }

    flex-basis: 71px;
    flex-shrink: 1;
    text-align: end;
  }
`;
