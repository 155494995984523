import styled from "styled-components";

export const CartSelectedPlanTitle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  justify-content: flex-start;
`;

export const CartSelectedPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
