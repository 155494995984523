import { prospectApi } from "@enroll-data/api/prospectApi";
import { prospectQueryKeys } from "@enroll-data/queryKeys/prospectQueryKeys";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { useQuery } from "@tanstack/react-query";

export const useProspectQuery = () => {
  const {
    cookies: { rhProspectUuid },
  } = useEnrollCookies();

  const query = useQuery({
    enabled: rhProspectUuid !== undefined && rhProspectUuid !== "",
    queryFn: () => prospectApi.retrieve(),
    queryKey: prospectQueryKeys.retrieve(),
  });

  return query;
};
