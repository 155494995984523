import { formatCurrency } from "@common/utils/dataFormatters";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RedeemRewardsDialogContentProps } from "@portal/components/RedeemRewards/RedeemRewards";
import { StyledRedeemRewardsDialogBody } from "@portal/components/RedeemRewards/RedeemRewards.styled";
import React from "react";
import { useIntl } from "react-intl";

export const RewardRedemptionCTA = ({
  value,
  rewardPointBalance,
  onApplyCredit,
  onCancel,
}: RedeemRewardsDialogContentProps) => {
  const { formatMessage } = useIntl();
  const cancelCta = formatMessage({ id: "RedeemRewards.cancelCTA" });
  const applyCreditCta = formatMessage({
    id: "RedeemRewards.yesApplyCreditCTA",
  });

  return (
    <>
      <RhDialogTitle>
        {formatMessage(
          { id: "RedeemRewards.yourPointsAreWorth" },
          {
            rewardPointBalance: rewardPointBalance.toLocaleString(),
            value: formatCurrency(value),
          }
        )}
      </RhDialogTitle>
      <RhDialogContentWithButtons
        primaryButton={{
          "data-tracking-click": { event: "Customer applying reward credit" },
          label: applyCreditCta,
          onClick: onApplyCredit,
        }}
        secondaryButton={{
          "data-tracking-click": {
            event: "Customer canceling applying reward credit",
          },
          label: cancelCta,
          onClick: onCancel,
        }}
      >
        <StyledRedeemRewardsDialogBody
          variant="subtitle2"
          color="textSecondary"
        >
          {formatMessage({
            id: "RedeemRewards.wouldYouLikeToApplyCredit",
          })}
        </StyledRedeemRewardsDialogBody>
      </RhDialogContentWithButtons>
    </>
  );
};
