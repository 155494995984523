import { premiseApi } from "@common/api/premiseApi";
import { RhApiError } from "@common/types/errorTypes";
import { selectCustomerPremisePreference } from "@portal/selectors/customerPreferencesSelectors";
import { selectPremise } from "@portal/selectors/premiseSelectors";
import {
  premiseErrored,
  premiseFetch,
  premiseReceived,
  premiseRefetch,
  premiseRequested,
} from "@portal/slices/premiseSlice";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchPremiseWorker() {
  const { premise } = yield select(selectPremise);

  if (!premise) {
    yield call(refetchPremiseWorker);
  }
}

export function* refetchPremiseWorker() {
  const premiseId: SagaReturnType<typeof selectCustomerPremisePreference> =
    yield select(selectCustomerPremisePreference);

  if (premiseId) {
    yield put(premiseRequested());
    try {
      const premise: SagaReturnType<typeof premiseApi.retrieve> = yield call(
        premiseApi.retrieve,
        premiseId
      );

      yield put(premiseReceived(premise));
    } catch (err: unknown) {
      yield put(premiseErrored(err as RhApiError));
    }
  }
}

export function* premiseWatcher() {
  yield all([
    takeLatest(premiseFetch.type, fetchPremiseWorker),
    takeLatest(premiseRefetch.type, refetchPremiseWorker),
  ]);
}
