import { StyledContainer } from "@design-system/components/RhInnerCard/RhinnerCard.styled";
import classNames from "classnames";
import React, { FC, KeyboardEventHandler, MouseEventHandler } from "react";

interface RhInnerCardProps {
  className?: string;
  noBorder?: boolean;
  onClick?: MouseEventHandler | KeyboardEventHandler<HTMLDivElement>;
  onClickRole?: string;
}

export const RhInnerCard: FC<React.PropsWithChildren<RhInnerCardProps>> = ({
  children,
  className = "",
  noBorder = false,
  onClick,
  onClickRole = "link",
  ...props
}) => {
  const onClickProps = onClick
    ? {
        onClick: onClick as MouseEventHandler,
        onKeyPress: onClick as KeyboardEventHandler<HTMLDivElement>,
        role: onClickRole,
      }
    : {};

  return (
    <StyledContainer
      className={classNames(className)}
      $noBorder={noBorder}
      {...onClickProps}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};
