import { useAppCookies } from "@common/hooks/useAppCookies";

type AllKeysRequiredButAllowUndefined<T> = {
  [K in keyof Required<T>]: T[K] | undefined;
};
export interface PortalCookiesWithEnv {}

const defaultPortalCookiesWithEnvValues: AllKeysRequiredButAllowUndefined<PortalCookiesWithEnv> =
  {};

export interface PortalCookies extends PortalCookiesWithEnv {
  _fs_uid?: string; // FullStory Id
  django_language?: "en" | "es";
  rhRenewalReminderModal?: "show" | "hide";
  rhSwitchEBillToPaperlessModal?: "show" | "hidden";
  rhSwitchPrintToPaperlessModal?: "show" | "hide";
}

const defaultPortalCookiesValues: AllKeysRequiredButAllowUndefined<PortalCookies> =
  {
    _fs_uid: undefined,
    django_language: "en",
    rhRenewalReminderModal: "show",
    rhSwitchEBillToPaperlessModal: "show",
    rhSwitchPrintToPaperlessModal: "show",
    ...defaultPortalCookiesWithEnvValues,
  };

// Only used for any cookie use in th logged in experience
export const usePortalCookies = () => {
  return useAppCookies<PortalCookies>(
    defaultPortalCookiesValues,
    defaultPortalCookiesWithEnvValues
  );
};
