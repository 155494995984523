import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Fab } from "@mui/material";
import styled from "styled-components";

export const StyledRhDialogCloseButton = styled(Fab)`
  &.MuiButtonBase-root {
    background: ${CSSVariables.COLOR_WHITE};
    border: 1px solid ${CSSVariables.COLOR_PRIMARY_MEDIUM};

    box-shadow: none;
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    height: 40px;
    position: absolute;
    right: ${rhSpacingPx(3)};
    top: ${rhSpacingPx(3)};
    width: 40px;

    &:hover {
      background: ${CSSVariables.COLOR_PRIMARY_LIGHTEST};
    }

    @media (max-width: ${RhythmBreakpoints.XS}px) {
      position: fixed;
    }
  }

  .MuiSvgIcon-root {
    font-size: 1.71rem;
  }
`;
