export const yourCurrentPlanSectionTranslations = {
  tYourCurrentPlanSectionEnergyCharge: " ¢/kWh Energy Charge",
  tYourCurrentPlanSectionFixedRate: "Fixed rate",
  tYourCurrentPlanSectionMonthToMonth: "Month-to-month",
  tYourCurrentPlanSectionTimeDependentFixedRate: "Time-dependent fixed rates",
  tYourCurrentPlanSectionVariableRate: "Variable rate",
  tYourCurrentPlanSectionViewPriceBreakdown: "View price breakdown",
  tYourCurrentPlanSectionYourContractDocuments: "Your contract documents",
  tYourCurrentPlanSectionYourPlanDetails: "Your current plan details",
  tYourCurrentTermsOfService: "Your Terms of Service",
};
