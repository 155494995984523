import {
  StyledStepConnector,
  StyledStepLabel,
  StyledStepper,
  StyledStepperWrapper,
} from "@design-system/components/RhStepper/RhStepper.styled";
import { StepperKey } from "@design-system/components/RhStepper/rhStepperTypes";
import { Step, StepIconProps } from "@mui/material";
import React, { Key } from "react";

interface StepperProps<T> {
  activeStepIndex: number;
  stepperIcon: ({ completed, icon, active }: StepIconProps) => JSX.Element;
  stepperKeysInOrder: StepperKey<T>[];
}

export const RhStepper = <T,>(props: StepperProps<T>) => {
  const { activeStepIndex, stepperKeysInOrder, stepperIcon } = props;

  return (
    <StyledStepperWrapper>
      <StyledStepper
        activeStep={activeStepIndex}
        orientation="horizontal"
        alternativeLabel
        connector={<StyledStepConnector />}
      >
        {stepperKeysInOrder.map((step) => (
          <Step key={step.id as Key}>
            <StyledStepLabel
              StepIconComponent={stepperIcon}
              StepIconProps={{
                icon: step.id as string,
              }}
            >
              {step.label}
            </StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
    </StyledStepperWrapper>
  );
};
