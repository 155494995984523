import { ReactComponent as Inspect } from "@design-system/icons/Inspect.svg";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { plansEmptyStateTranslations } from "@portal/components/PlansPageEmptyState/PlansPageEmptyState.en.i18n";
import {
  PlansPageEmptyStateIconContainer,
  PlansPageEmptyStateText,
} from "@portal/components/PlansPageEmptyState/PlansPageEmptyState.styled";
import React from "react";

export function PlansPageEmptyState() {
  const { translate } = useTranslations();
  const { tThereAreNoPlans } = translate(plansEmptyStateTranslations);

  return (
    <PlansPageEmptyStateIconContainer>
      <Inspect />
      <PlansPageEmptyStateText>{tThereAreNoPlans}</PlansPageEmptyStateText>
    </PlansPageEmptyStateIconContainer>
  );
}
