import { useOktaGroups } from "@common/hooks/useOktaGroups";
import { isCustomer } from "@common/utils/authenticationHelpers";
import { useOktaAuth } from "@okta/okta-react";
import { signInPath } from "@portal-shared/routes/routePaths";
import { customerHomePath } from "@portal/routes/routePaths";
import React from "react";
import { Navigate } from "react-router-dom";

export const LandingPageRedirect = () => {
  const { authState } = useOktaAuth();
  const { groups } = useOktaGroups();

  if (!authState) {
    // await authState to be defined
    return null;
  }

  if (authState.isAuthenticated && isCustomer(groups)) {
    return <Navigate to={customerHomePath()} />;
  }

  return <Navigate to={signInPath()} />;
};
