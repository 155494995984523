import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as ContractChangesIcon } from "@design-system/icons/bill-factors/ContractChanges.svg";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { billFactorsTranslations } from "@portal/components/BillFactors/BillFactors.en.i18n";
import { StyledBillFactor } from "@portal/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorContractChangesProps {
  isVariableRate: boolean;
  previousContractEndDate: string;
}

export const BillFactorContractChanges = ({
  isVariableRate,
  previousContractEndDate,
}: BillFactorContractChangesProps) => {
  const { translateJsx, translate } = useTranslations();
  const { tBillFactorsContractChanges } = translate(billFactorsTranslations);
  const { tBillFactorsYourContract, tBillFactorsYourContractMtm } =
    translateJsx({
      tBillFactorsYourContract: {
        endDate: formatMonthDayYear(previousContractEndDate),
      },
      tBillFactorsYourContractMtm: {
        endDate: formatMonthDayYear(previousContractEndDate),
      },
    });

  return (
    <StyledBillFactor>
      <ContractChangesIcon />
      <RhTypography fontWeight="Bold">
        {tBillFactorsContractChanges}
      </RhTypography>
      <RhTypography variant="body2">
        {isVariableRate
          ? tBillFactorsYourContractMtm
          : tBillFactorsYourContract}
      </RhTypography>
    </StyledBillFactor>
  );
};
