export const documentHistoryTableTranslations = {
  tDocumentHistoryTableContractExpiration: "Contract Expiration Notice",
  tDocumentHistoryTableDeferredPayment: "Deferred Payment Plan Contract Letter",
  tDocumentHistoryTableDemandLetter: "Demand Letter",
  tDocumentHistoryTableDisconnectionNotice: "Disconnection Notice",
  tDocumentHistoryTableDocumentType: "Document Type",
  tDocumentHistoryTableDownload: "Download",
  tDocumentHistoryTableDownloadError: "Error downloading document at this time",
  tDocumentHistoryTableGeneratedDate: "Date Sent",
  tDocumentHistoryTableWelcomeKit: "Contract Documents",
  tDocumentHistoryTableWelcomeKitDescription:
    "(Electricity Facts Label, Terms of Service, Your Right As a Customer)",
};
