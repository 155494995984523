import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { offerSnapshotMiniCardTranslations } from "@portal/components/OfferSnapshotMiniCard/OfferSnapshotMiniCard.en.i18n";
import {
  OfferSnapshotMiniCardActions,
  OfferSnapshotMiniCardBody,
  OfferSnapshotMiniCardContent,
  OfferSnapshotMiniCardPriceSpan,
  OfferSnapshotMiniCardRoot,
  OfferSnapshotMiniCardSubtitle,
  OfferSnapshotMiniCardTitle,
  OfferSnapshotMiniCardTitleWrapper,
} from "@portal/components/OfferSnapshotMiniCard/OfferSnapshotMiniCard.styled";
import React from "react";

interface OfferSnapshotMiniCardProps {
  estimatedMonthlyUsage: number;
  offerSnapshot: OfferSnapshot;
  onLearnMoreClick: () => void;
  onSelectOfferSnapshot: (offerSnapshot: OfferSnapshot) => void;
}

export const OfferSnapshotMiniCard = ({
  offerSnapshot,
  onSelectOfferSnapshot,
  estimatedMonthlyUsage,
  onLearnMoreClick,
}: OfferSnapshotMiniCardProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(RhythmBreakpoints.XS));
  const { translateJsx, translate } = useTranslations();

  const { tOfferSnapshotMiniCardLearnMore, tOfferSnapshotMiniCardEnrollNow } =
    translate(offerSnapshotMiniCardTranslations);

  const price = formatCurrency(
    offerSnapshot.averageMonthlyPrice(estimatedMonthlyUsage),
    0
  );

  const { tOfferSnapshotMiniCardPricePerMonth } = translateJsx({
    tOfferSnapshotMiniCardPricePerMonth: {
      price,
      priceTag: (content: string) => (
        <OfferSnapshotMiniCardPriceSpan>
          {content}
        </OfferSnapshotMiniCardPriceSpan>
      ),
    },
  });

  return (
    <OfferSnapshotMiniCardRoot>
      <OfferSnapshotMiniCardContent>
        <OfferSnapshotMiniCardTitleWrapper>
          <OfferSnapshotMiniCardTitle>
            {offerSnapshot.title}
          </OfferSnapshotMiniCardTitle>
        </OfferSnapshotMiniCardTitleWrapper>
        <OfferSnapshotMiniCardBody>
          <OfferSnapshotMiniCardSubtitle>
            {tOfferSnapshotMiniCardPricePerMonth}
          </OfferSnapshotMiniCardSubtitle>
        </OfferSnapshotMiniCardBody>
      </OfferSnapshotMiniCardContent>
      <OfferSnapshotMiniCardActions>
        <RhButton
          data-tracking-click={{ event: "Prospect selected a plan" }}
          fullWidth={isDesktop}
          size="small"
          onClick={() => onSelectOfferSnapshot(offerSnapshot)}
          color="primary"
        >
          {tOfferSnapshotMiniCardEnrollNow}
        </RhButton>

        <RhButton
          data-tracking-click={{ event: "Prospect opening learn more modal" }}
          color="primary"
          size="small"
          variant="text"
          onClick={onLearnMoreClick}
        >
          {tOfferSnapshotMiniCardLearnMore}
        </RhButton>
      </OfferSnapshotMiniCardActions>
    </OfferSnapshotMiniCardRoot>
  );
};
