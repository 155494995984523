import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledContainer = styled.div`
  border-bottom: 2px solid ${CSSVariables.COLOR_GREY_100};
  border-top: 2px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};

  padding: ${rhSpacingPx(2)} 0;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    border-bottom: none;
    grid-area: 3 2;
    padding: none;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};

  a {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${rhSpacingPx(2)};
  }
`;
