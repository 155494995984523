import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const AtAGlanceCarouselContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${RhythmBreakpoints.MD}px) {
    overflow-x: scroll; /* Firefox */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;
