import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  RH_HEADER_HEIGHT,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { Grid } from "@mui/material";
import styled from "styled-components";

export const StyledGridContainer = styled(Grid)`
  position: relative;

  &.MuiGrid-root {
    margin-left: -12px;
    & > .MuiGrid-item {
      padding-left: 16px;
    }
  }

  @media (max-width: ${RhythmBreakpoints.SM}px) {
    justify-content: center;
  }

  & > .MuiGrid-item {
    padding: 0 ${rhSpacingPx(2.5)} ${rhSpacingPx(2.5)};

    @media (max-width: ${RhythmBreakpoints.XS}px) {
      padding: 0 ${rhSpacingPx(2)} ${rhSpacingPx(2.5)};
    }
  }
`;

export const StyledBillingSection = styled.div`
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledStickySection = styled.div`
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    position: sticky;
    top: ${rhSpacingPx(RH_HEADER_HEIGHT + 2.5)};

    & > div {
      max-width: 350px;
    }
  }
`;
