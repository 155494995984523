import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { borders } from "@design-system/constants/borders";
import { boxShadows } from "@design-system/constants/boxShadows";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { Card, CardContent, CardHeader, PaperProps } from "@mui/material";
import styled from "styled-components";

interface CardProps extends PaperProps {
  $noShadow?: boolean;
}

export const StyledCard = styled(Card)<CardProps>`
  &.MuiCard-root {
    border: 1px solid ${CSSVariables.COLOR_GREY_50};
    border-radius: 6px;
    box-shadow: ${(props) => (props.$noShadow ? "none" : boxShadows.default)};
    margin-bottom: ${rhSpacingPx(2.5)};
    padding: ${rhSpacingPx(2.5)};
    width: 100%;

    @media (min-width: ${RhythmBreakpoints.XS}px) {
      margin-right: ${rhSpacingPx(2.5)};
    }
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  &.MuiCardHeader-root {
    border-bottom: ${borders[100]};
    margin-bottom: ${rhSpacingPx(2.5)};
    padding: 0;
    padding-bottom: ${rhSpacingPx(1.25)};
  }

  & .MuiCardHeader-title {
    font-family: ${fontFamily};
    font-size: 16px;
    font-weight: ${FontWeight.Bold};
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 0 !important;
`;
