import { IdType } from "@common/types/apiTypes";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as DownloadIcon } from "@design-system/icons/DownloadIcon.svg";
import { rhSpacing } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import {
  UploadForm,
  UploadFormValues,
} from "@portal/components/ProductContractClaimManagement/FormUploadClaim/UploadForm";
import { useProductContractClaim } from "@portal/hooks/mutations/useProductContractClaim";
import { ProductContractType } from "@portal/types/productContractTypes";
import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacing(2)}px;
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-direction: row;
  }
`;

const DownloadContainer = styled.div`
  & > :first-child {
    margin-bottom: ${rhSpacing(1)}px;
  }
`;

const Divider = styled(RhDivider)`
  display: none;
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    display: unset;
  }
`;

interface FormUploadClaimProps {
  downloadUrl: string;
  premiseId: IdType;
  productContract: ProductContractType;
}

export const FormUploadClaim = ({
  productContract,
  premiseId,
  downloadUrl,
}: FormUploadClaimProps) => {
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const productContractClaimMutation = useProductContractClaim();

  const handleSubmit = (values: UploadFormValues) => {
    return productContractClaimMutation
      .mutateAsync(
        {
          claimFile: values.file[0],
          contractId: productContract.id,
          premiseId,
        },
        {
          onError: () => {
            flash.error(t("FormUploadClaim.uploadError"));
          },
          onSuccess: () => {
            flash.success(t("FormUploadClaim.uploadSuccess"));
          },
        }
      )
      .then(() => true)
      .catch(() => false);
  };

  return (
    <StyledContainer>
      <DownloadContainer>
        <RhTypography variant="body2" fontWeight="Bold">
          {t("FormUploadClaim.downloadTitle")}
        </RhTypography>

        <RhLinkButton
          data-tracking-click={{
            event: "Customer downloading product addon claims form",
          }}
          variant="outlined"
          color="primary"
          fullWidth={false}
          size="small"
          href={downloadUrl}
          target="_blank"
        >
          <DownloadIcon
            aria-hidden="true"
            style={{ marginRight: `${rhSpacing(1)}px` }}
          />
          {t("FormUploadClaim.download")}
        </RhLinkButton>
      </DownloadContainer>

      <Divider orientation="vertical" light flexItem />

      <UploadForm onSubmitSuccess={handleSubmit} />
    </StyledContainer>
  );
};
