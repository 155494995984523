import { AjaxStatus, AjaxStatuses } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { PremiseType } from "@common/types/premiseTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export type PremiseStateType = {
  data: PremiseType | null;
  error: RhApiError | null;
  requestStatus: AjaxStatus;
};

export const initialPremiseState = Object.freeze<PremiseStateType>({
  data: null,
  error: null,
  requestStatus: AjaxStatuses.Idle,
});

// Handlers

export const premiseErroredHandler: CaseReducer<
  PremiseStateType,
  PayloadAction<RhApiError>
> = (state, action) => ({
  data: state.data,
  error: action.payload,
  requestStatus: AjaxStatuses.Failure,
});

export const premiseReceivedHandler: CaseReducer<
  PremiseStateType,
  PayloadAction<PremiseType>
> = (state, action) => ({
  data: action.payload,
  error: null,
  requestStatus: AjaxStatuses.Success,
});

export const premiseRequestedHandler: CaseReducer<
  PremiseStateType,
  PayloadAction
> = (state) => ({ ...state, requestStatus: AjaxStatuses.Pending });

export const premiseUpdatedHandler: CaseReducer<
  PremiseStateType,
  PayloadAction<Partial<PremiseType>>
> = (state, action) => ({
  data: merge({}, state.data, action.payload),
  error: state.error,
  requestStatus: state.requestStatus,
});

const premiseReducers = {
  errored: premiseErroredHandler,
  received: premiseReceivedHandler,
  requested: premiseRequestedHandler,
  updated: premiseUpdatedHandler,
};

const premiseSlice = createSlice<PremiseStateType, typeof premiseReducers>({
  initialState: initialPremiseState,
  name: "premise",
  reducers: premiseReducers,
});

export const premiseReducer = premiseSlice.reducer;

export const premiseErrored = premiseSlice.actions.errored;
export const premiseReceived = premiseSlice.actions.received;
export const premiseRequested = premiseSlice.actions.requested;
export const premiseUpdated = premiseSlice.actions.updated;

export const premiseFetch = createAction(`${premiseSlice.name}/fetch`);
export const premiseRefetch = createAction(`${premiseSlice.name}/refetch`);
