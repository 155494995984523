export const offerSnapshotFAQsContractTranslations = {
  tOfferSnapshotFAQsContractCancelEarlyAnswer:
    "When you join Rhythm we make you a 30-Day Easy Energy Promise, which means if you don't like us, you can leave us early without the penalty. It's that easy.",
  tOfferSnapshotFAQsContractCancelEarlyAnswerPart2:
    "After that, we charge a cancellation fee of {etfAmount} for each remaining month in your contract. For example, if you had 6 months left on your contract, your cancellation fee would be {sixMonthEtfAmount}. This policy does not apply if you cancel your contract early because of a move.",
  tOfferSnapshotFAQsContractCancelEarlyQuestion:
    "What happens if I cancel my plan before the contract is up?",
  tOfferSnapshotFAQsContractWhatFeesAnswer:
    "We try to be upfront about the fees we charge so they don't come as a surprise to you. We charge late fees if your payment is past due or if there are insufficient funds in your account. If you leave us after your first 30 days, but before your contract is up, we charge cancellation fees (see our 30-Day Easy Energy Promise for more detail). And, if your wires provider charges fees for use of the power grid, we pass those fees along to you without markup. That's it. Those are the fees.",
  tOfferSnapshotFAQsContractWhatFeesQuestion:
    "What kind of fees can I expect with Rhythm?",
  tOfferSnapshotFAQsContractWhenContractEndsAnswer:
    "30 days before your contract expires (and monthly thereafter), we'll reach out to you to remind you about renewal.",
  tOfferSnapshotFAQsContractWhenContractEndsAnswerPart2:
    "Some energy companies take the end of customer contracts as an opportunity to jack-up rates. Not us. If you renew with us, we'll offer you a selection of affordable fixed rate plans to choose from. And, if you forget to renew, we'll switch you over to the same variable rate plan new customers have access to and your service will continue uninterrupted.",
  tOfferSnapshotFAQsContractWhenContractEndsQuestion:
    "What happens when my contract ends?",
};
