import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { red } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledCustomerHomeRenewalSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  padding-bottom: ${rhSpacingPx(2.5)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-direction: row;
    margin-right: ${rhSpacingPx(2.5)};
  }
`;

export const StyledWelcomeImageContainer = styled.div`
  display: none;
  padding: 0 ${rhSpacingPx(3)} 0 0;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    display: block;
  }
`;

export const StyledHeader = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      text-align: left;
    }
  }
`;

export const StyledSubheader = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;
    white-space: pre-line;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      text-align: left;
    }
  }
`;

export const StyledBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2.5)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    align-items: flex-start;
    gap: ${rhSpacingPx(2)};
  }
`;
export const StyledBoldText = styled(RhTypography).attrs({
  fontWeight: "Bold",
})`
  &.MuiTypography-root {
    color: ${red[500]};
    display: inline;
    font-size: 20px;
  }
`;

export const StyledRenewButton = styled(RhButton)<{ $isRed?: boolean }>`
  border-color: ${({ $isRed }) => $isRed && red[500]};
  color: ${({ $isRed }) => $isRed && red[500]};
  min-width: 286px;

  &:hover {
    background-color: ${({ $isRed }) => $isRed && red[100]};
    border-color: ${({ $isRed }) => $isRed && red[500]};
  }
`;
