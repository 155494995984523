import { StyledRhDialogCloseButton } from "@design-system/components/RhDialogCloseButton/RhDialogCloseButton.styled";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface Props {
  onClose: () => void;
}

export const RhDialogCloseButton: React.FC<Props> = ({ onClose }) => {
  return (
    <StyledRhDialogCloseButton
      color="default"
      onClick={onClose}
      aria-label="close"
    >
      <CloseIcon />
    </StyledRhDialogCloseButton>
  );
};
