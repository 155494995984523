import { RhButton } from "@design-system/components/RhButton/RhButton";
import styled from "styled-components";

export const StyledLayout = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`;

export const StyledButton = styled(RhButton)`
  &.MuiButton-root {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    max-width: 10rem;
  }
`;
