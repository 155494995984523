import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { createPaymentExtensionResponseAtom } from "@portal/components/PaymentExtension/PaymentExtension.atoms";
import { paymentExtensionSuccessTranslations } from "@portal/components/PaymentExtensionSuccess/PaymentExtensionSuccess.en.i18n";
import {
  BackHomeButton,
  ConfirmationTextContainer,
  GreyedOutText,
  PaymentInfoContainer,
  StyledImageContainer,
  StyledPaymentExtensionSuccessContainer,
} from "@portal/components/PaymentExtensionSuccess/PaymentExtensionSuccess.styled";
import { ReactComponent as PayBillSuccessCheck } from "@portal/images/PayBillSuccessCheck.svg";
import { homePath } from "@portal/routes/routePaths";
import { useAtomValue } from "jotai";
import React from "react";

export const PaymentExtensionSuccess = () => {
  const { translate, translateJsx } = useTranslations();
  const { amountExtended, amountPaid, extendToDate, paymentNumber } =
    useAtomValue(createPaymentExtensionResponseAtom);

  const {
    tPaymentExtensionSuccessBackHome,
    tPaymentExtensionSuccessTitle,
    tPaymentExtensionSuccessYouOweImageTitle,
  } = translate(paymentExtensionSuccessTranslations);

  const {
    tPaymentExtensionSuccessDownPayment,
    tPaymentExtensionSuccessConfirmation,
    tPaymentExtensionSuccessYouOwe,
  } = translateJsx({
    tPaymentExtensionSuccessConfirmation: { paymentNumber },
    tPaymentExtensionSuccessDownPayment: {
      amountPaid: <strong>{formatCurrency(amountPaid)}</strong>,
    },
    tPaymentExtensionSuccessYouOwe: {
      amountExtended: <strong>{formatCurrency(amountExtended)}</strong>,
      extendToDate: <strong>{formatMonthDayShortYear(extendToDate)}</strong>,
    },
  });

  return (
    <StyledPaymentExtensionSuccessContainer>
      <StyledImageContainer>
        <PayBillSuccessCheck title={tPaymentExtensionSuccessYouOweImageTitle} />
      </StyledImageContainer>
      <RhTypography variant="h1">{tPaymentExtensionSuccessTitle}</RhTypography>
      <PaymentInfoContainer>
        <GreyedOutText>{tPaymentExtensionSuccessDownPayment}</GreyedOutText>
        <GreyedOutText data-testid="extension-text">
          {tPaymentExtensionSuccessYouOwe}
        </GreyedOutText>
      </PaymentInfoContainer>
      <ConfirmationTextContainer>
        <GreyedOutText>{tPaymentExtensionSuccessConfirmation}</GreyedOutText>
      </ConfirmationTextContainer>
      <RhRouterLink to={homePath()} title={tPaymentExtensionSuccessBackHome}>
        <BackHomeButton
          data-tracking-click={{
            event:
              "Customer going home after successfully setting up payment extension",
          }}
        >
          {tPaymentExtensionSuccessBackHome}
        </BackHomeButton>
      </RhRouterLink>
    </StyledPaymentExtensionSuccessContainer>
  );
};
