import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@portal/forms/validators";

interface ForgotPasswordFormValues {
  email: string;
}

export const forgotPasswordFormValidator =
  generateValidationErrorCollector<ForgotPasswordFormValues>({
    email: [isRequired, isValidEmail],
  });
