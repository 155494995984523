import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CalendarIcon } from "@design-system/icons/CalendarIcon.svg";
import { ReactComponent as CheckIcon2 } from "@design-system/icons/CheckIcon2.svg";
import { ReactComponent as Person2Icon } from "@design-system/icons/Person2Icon.svg";
import { ReactComponent as SaveMoneyIcon } from "@design-system/icons/SaveMoneyIcon.svg";
import { ReactComponent as Shield } from "@design-system/icons/Shield.svg";
import { ReactComponent as TagIcon } from "@design-system/icons/TagIcon.svg";
import {
  StyledListItem,
  StyledUnorderedList,
} from "@portal/components/ProductTierCard/ProductTierCard.styled";
import { ProductTier } from "@portal/models/ProductTier.model";
import { ProductTierFeatureIconType } from "@portal/types/productTypes";
import React from "react";

const featureIconsMapping: Record<ProductTierFeatureIconType, JSX.Element> = {
  calendar: <CalendarIcon />,
  check: <CheckIcon2 />,
  guarantee: <Shield />,
  person_2: <Person2Icon />,
  price_tag: <TagIcon />,
  save_money: <SaveMoneyIcon />,
};

interface ProductTierFeaturesProps {
  productTier: ProductTier;
}

export const ProductTierFeatures = ({
  productTier,
}: ProductTierFeaturesProps) => {
  return (
    <StyledUnorderedList>
      {productTier.sortedFeatures.map((feature) => {
        return (
          <StyledListItem key={feature.priority}>
            {featureIconsMapping[feature.iconName]}
            <RhTypography>{feature.description}</RhTypography>
          </StyledListItem>
        );
      })}
    </StyledUnorderedList>
  );
};
