import styled from "styled-components";

export const StyledLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 3rem;
  text-align: center;

  & > * {
    margin: auto;
  }
`;
