import { StyledStepperIconContainer } from "@design-system/components/RhStepper/RhStepperIcon.styled";
import { StepperKey } from "@design-system/components/RhStepper/rhStepperTypes";
import { StepIconProps } from "@mui/material";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { signUpStepperTranslations } from "@portal/components/SignUpStepper/SignUpStepper.en.i18n";
import { signUpStepperIconMapping } from "@portal/components/SignUpStepper/SignUpStepperIconMapping";
import { SignUpStepperKeyType } from "@portal/components/SignUpStepper/signUpStepperTypes";
import React from "react";

export const SignUpStepperIcon = ({
  completed,
  icon,
  active,
}: StepIconProps) => {
  const { translate } = useTranslations();

  const {
    tSignUpStepperCompleted,
    tSignUpStepperThisStep,
    tSignUpStepperContact,
    tSignUpStepperPayment,
    tSignUpStepperPlans,
    tSignUpStepperSummary,
  } = translate(signUpStepperTranslations);

  const signUpSignUpStepperKeysInOrder: StepperKey<SignUpStepperKeyType>[] = [
    { id: "plans", label: tSignUpStepperPlans },
    { id: "contact", label: tSignUpStepperContact },
    { id: "payment", label: tSignUpStepperPayment },
    { id: "summary", label: tSignUpStepperSummary },
  ];

  // MUI recommends co-opting the icon prop if you need to lookup a custom SVG
  // that is: pass the icon NAME, then use that to look up your actual icon
  const iconName = icon as SignUpStepperKeyType;

  const completedText = tSignUpStepperCompleted;
  const thisStep = tSignUpStepperThisStep;
  const stepText =
    signUpSignUpStepperKeysInOrder.find((key) => key.id === iconName)?.label ??
    "";

  const completedIconTitle = `${stepText} ${completedText}`;
  const activeNumberTitle = `${thisStep}: ${stepText}`;
  const inactiveNumberTitle = stepText;

  const NumberIcon = signUpStepperIconMapping[iconName];
  const CompletedIcon = signUpStepperIconMapping.completed;

  let title;

  if (completed) {
    title = completedIconTitle;
  } else {
    title = active ? activeNumberTitle : inactiveNumberTitle;
  }

  return (
    <StyledStepperIconContainer $active={active} $completed={completed}>
      {completed ? (
        <CompletedIcon
          data-testid={`SignUpStepperIcon-${iconName}-completed`}
          title={title}
        />
      ) : (
        <NumberIcon
          data-testid={`SignUpStepperIcon-${iconName}-number`}
          title={title}
        />
      )}
    </StyledStepperIconContainer>
  );
};
