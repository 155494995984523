export const signUpEnrollmentPageTranslations = {
  tSignUpEnrollmentPageGetStartDatesError:
    "We weren't able to get available start dates. Try again later!",
  tSignUpEnrollmentPageHeader:
    "Are you moving to a new home or just switching energy providers?",
  tSignUpEnrollmentPageIsMoveIn: "Move In?",
  tSignUpEnrollmentPageMoveIn: "Moving homes",
  tSignUpEnrollmentPageSubmitCTA: "Next",
  tSignUpEnrollmentPageSwitch: "Switching providers",
};
