import { rhSpacingPx } from "@design-system/theme/spacing";
import { DialogTitle } from "@mui/material";
import styled from "styled-components";

export const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: ${rhSpacingPx(4)};
    text-align: center;
  }
`;
