import { FeatureFlagValues } from "@common/context/featureFlagClient.context";
import { EnrollOffersPage } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPage";
import React, { ReactNode } from "react";

interface FeatureFlagRouteShape {
  component: ReactNode;
  featureFlag: keyof FeatureFlagValues;
  path: string;
}

export const featureFlagRoutes: FeatureFlagRouteShape[] = [
  {
    component: <EnrollOffersPage />,
    featureFlag: "portalNewPlansPage",
    path: "/plans-v2",
  },
];
