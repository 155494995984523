import { RhModal } from "@design-system/components/RhModal/RhModal";
import { RhModalDialog } from "@design-system/components/RhModal/RhModal.styled";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-items: center;
  padding: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    max-width: 900px;
  }

  & > button {
    margin-top: ${rhSpacingPx(4)};
    width: 100%;

    @media (min-width: ${RhythmBreakpoints.XS}px) {
      width: 350px;
    }
  }

  & > h4 {
    width: 95%;
  }
`;

export const StyledRhModal = styled(RhModal)`
  ${RhModalDialog} {
    height: 100%;

    @media (min-width: ${RhythmBreakpoints.XS}px) {
      height: unset;
    }
  }
`;
