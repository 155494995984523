import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledReferAFriendDialog = styled(RhDialog)`
  & .MuiDialog-paper {
    padding: ${rhSpacingPx(4)};
    text-align: center;

    .MuiTypography-root {
      margin-bottom: ${rhSpacingPx(2)};
      margin-top: ${rhSpacingPx(1)};
    }
  }
`;

export const StyledReferAFriendDialogSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(2)};
    margin-top: ${rhSpacingPx(1)};
  }
`;
