import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledAddressContainer = styled.div`
  margin: auto;
  padding-bottom: 32px;
  text-align: center;
  width: ${LOGGED_OUT_FORM_WIDTH}px;
`;

export const StyledHeaderContainer = styled.div`
  padding-bottom: 32px;
`;
