import {
  LOGGED_OUT_FORM_WIDTH,
  RhythmBreakpoints,
  SMALL_MOBILE_LOGGED_OUT_FORM_WIDTH,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledLoggedOutForm = styled.form`
  @media (max-width: ${RhythmBreakpoints.XXS}px) {
    max-width: ${SMALL_MOBILE_LOGGED_OUT_FORM_WIDTH}px;
  }

  max-width: ${LOGGED_OUT_FORM_WIDTH}px;
  width: 100%;
`;
