import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import {
  RhSubmitButton,
  RhSubmitButtonProps,
} from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useFormState } from "react-final-form";
import { useIntl } from "react-intl";

export type PortalSubmitButtonProps = RhSubmitButtonProps;

export const PortalSubmitButton = ({
  children,
  ...props
}: PropsWithChildren<PortalSubmitButtonProps>) => {
  const { formatMessage } = useIntl();

  const { submitSucceeded, submitting } = useFormState({
    subscription: {
      submitSucceeded: true,
      submitting: true,
    },
  });

  const pending = submitting || submitSucceeded;
  const pendingMessage = formatMessage({ id: "PortalSubmitButton.submitting" });

  return (
    <RhSubmitButton {...props}>
      {pending ? (
        <>
          <RhCircularProgress
            marginBottom={0}
            marginTop={0}
            size={18}
            width="initial"
          />
          <Box marginLeft={1}>
            <RhTypography color="inherit" variant="inherit">
              {pendingMessage}
            </RhTypography>
          </Box>
        </>
      ) : (
        children
      )}
    </RhSubmitButton>
  );
};
