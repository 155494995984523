export const myAccountCommunicationPreferencesModalTranslations = {
  tMyAccountCommunicationPreferencesModalActionRequired: "Action required",
  tMyAccountCommunicationPreferencesModalCancelButtonText: "Cancel",
  tMyAccountCommunicationPreferencesModalConfirmButtonText: "Confirm",
  tMyAccountCommunicationPreferencesModalIfDisableWarningSubTitle:
    "The Average Price/kWh that you pay for electricity will go up.",
  tMyAccountCommunicationPreferencesModalIfDisableWarningTitle:
    "If you turn OFF Paperless you'll miss out on your {discountAmount}/month Auto Pay and Paperless credit...",
  tMyAccountCommunicationPreferencesModalLegalText:
    "I agree to receive my welcome packet, bills, contract documents, and contract-related notices (including my contract expiration notices and disconnect notices) via email. I understand I won't receive my bills via regular mail. I confirm the email address I provided is valid.",
  tMyAccountCommunicationPreferencesModalModalCloseLabel:
    "Close Communication Preferences",
  tMyAccountCommunicationPreferencesModalModalLabel:
    "Edit Communication Preferences",
  tMyAccountCommunicationPreferencesModalMovingToPaperless:
    "We are moving from eBill to fully Paperless.",
  tMyAccountCommunicationPreferencesModalPaperlessLabel: "Paperless",
  tMyAccountCommunicationPreferencesModalTitle:
    "Edit your communication preferences",
  tMyAccountCommunicationPreferencesModalUpdateFailure:
    "Failed to update communication preferences, please try again",
  tMyAccountCommunicationPreferencesModalUpdateSuccess:
    "Communication preferences updated",
  tMyAccountCommunicationPreferencesModalUpgradeToPaperless:
    "Upgrade to paperless for all communication and create a greener, brighter Texas.",
  tMyAccountCommunicationPreferencesModalWhenDisabledWarningTitle:
    "Please Turn ON Paperless to receive your {discountAmount}/month Auto Pay and Paperless credit.",
};
