export const offerSnapshotLearnMoreReasonsToBelieveTranslations = {
  tOfferSnapshotLearnMoreReasonsToBelieveAllRenewableDescription:
    "We make it a breeze to chip in and do your part for the planet.",
  tOfferSnapshotLearnMoreReasonsToBelieveAllRenewableTitle:
    "100% Renewable Energy",
  tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredDescription:
    "Get a {discountAmount}/month credit with Auto Pay & Paperless, resulting in a discounted price.",
  tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredTitle:
    "Auto Pay & Paperless required",
  tOfferSnapshotLearnMoreReasonsToBelieveBestPriceDescription:
    "When you renew your plan you will still get the same deal as a new customer.",
  tOfferSnapshotLearnMoreReasonsToBelieveBestPriceTitle:
    "Best Price Always Pledge",
  tOfferSnapshotLearnMoreReasonsToBelieveFixedRateDescription:
    "Pay the same fixed rate for electricity throughout your contract.",
  tOfferSnapshotLearnMoreReasonsToBelieveFixedRateSolarPlan:
    "Pay the same fixed rate for electricity throughout your contract. Your per kWh price includes an energy charge of {kwhRate} per kWh, TDSP charges, and a base charge of {baseCharge} per month. The solar buyback rate = {solarBuybackRate} per kWh",
  tOfferSnapshotLearnMoreReasonsToBelieveFixedRateTitle: "Fixed Rate",
  tOfferSnapshotLearnMoreReasonsToBelieveFixedRateWithBaseChargeDescription:
    "Pay the same fixed rate for electricity throughout your contract. Your per kWh price includes an energy charge of {kwhRate} per kWh, TDSP charges, and a base charge of {baseCharge} per month.",
  tOfferSnapshotLearnMoreReasonsToBelieveIncluded: "Included",
  tOfferSnapshotLearnMoreReasonsToBelieveMonthToMonthTitle: "Month-to-month",
  tOfferSnapshotLearnMoreReasonsToBelieveNotIncluded: "Not Included",
  tOfferSnapshotLearnMoreReasonsToBelieveSimplisafeTitle:
    "Home security bundle",
  tOfferSnapshotLearnMoreReasonsToBelieveSolarBuybackTitle: "Solar buyback",
  tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateTitle: "Fixed Rate",
  tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateWithBaseChargeDescription:
    "Pay the same fixed rate for electricity throughout your contract. A base charge of {baseCharge} per month will be applied.",
  tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateDescription:
    "Get full flexibility of your plan. A base charge of {baseCharge} per month will be applied.",
  tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateTitle:
    "Variable Rate",
  tOfferSnapshotLearnMoreReasonsToBelieveTestDriveDescription:
    "Try us for 30 days and if you don't love us, leave us penalty-free.",
  tOfferSnapshotLearnMoreReasonsToBelieveTestDriveTitle: "30-day Test Drive",
  tOfferSnapshotLearnMoreReasonsToBelieveThermostatDescription:
    "Get a {credit}/month bill credit when you connect a participating smart thermostat, resulting in a discounted price.",
  tOfferSnapshotLearnMoreReasonsToBelieveThermostatTitle:
    "Smart Thermostat Required",
  tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseGenerationRateDescription:
    "You'll know exactly the hours and prices of electricity and when you can shift your energy to off-peak hours. Savings can be a breeze. Your per kWh price includes an energy charge (see breakdown below), TDSP charges, and a base charge of {baseCharge} per month. The solar buyback rate can be located in the breakdown below.",
  tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateDescription:
    "You'll know exactly the hours and prices of electricity and when you can shift your energy to off-peak hours. Savings can be a breeze. Your per kWh price includes an energy charge (see breakdown below), TDSP charges, and a base charge of {baseCharge} per month.",
  tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateTitle:
    "Time-dependent fixed rates",
  tOfferSnapshotLearnMoreReasonsToBelieveTitle: "Plan details",
  tOfferSnapshotLearnMoreReasonsToBelieveVariableRateDescription:
    "Get full flexibility of your plan.",
  tOfferSnapshotLearnMoreReasonsToBelieveVariableRateTitle: "Variable Rate",
  tOfferSnapshotLearnMoreReasonsToBelieveVariableRateWithBaseChargeDescription:
    "Get full flexibility of your plan. Your per kWh price includes an energy charge of {kwhRate} per kWh, TDSP charges, and a base charge of {baseCharge} per month.",
};
