import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  ANNOUNCEMENT_HEIGHT,
  MAIN_CONTAINER_WIDTH,
  PORTAL_TOP_MARGIN,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledPortalPageLayout = styled.div`
  @media (min-width: ${RhythmBreakpoints.XXS}px) {
    margin-top: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT + PORTAL_TOP_MARGIN)};
  }

  margin-bottom: ${rhSpacingPx(9)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT)};
  max-width: ${MAIN_CONTAINER_WIDTH}px;
  width: 100%;
`;
