export const createPasswordPageTranslations = {
  tCreatePasswordPageCreateAPasswordToAccess:
    "Just create a password to access your account.",
  tCreatePasswordPageInvalidPassword:
    "Does not meet the requirements of the password policy.",
  tCreatePasswordPagePassword: "Password",
  tCreatePasswordPagePasswordMinimumRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  tCreatePasswordPageSubmitCTA: "Next",
  tCreatePasswordPageTwoFriendsHighFiving: "Two friends high fiving",
  tCreatePasswordPageUnknownOktaIssue:
    "We are having issues setting your password. Please try again.",
  tCreatePasswordPageWelcome: "Welcome!",
  tCreatePasswordPageWelcomeWithName: "You're signed up, {firstName}!",
};
