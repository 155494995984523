import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { WelcomePacketLink } from "@portal-shared/components/WelcomePacketLink/WelcomePacketLink";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { myAccountYourPlanCardTranslations } from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.en.i18n";
import {
  StyledLegalDocDescription,
  StyledLegalDocIcon,
  StyledLegalDocIconContainer,
  StyledLegalDocTitle,
  StyledLegalDocumentBody,
  StyledLegalDocumentWrapper,
} from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.styled";
import React from "react";

interface MyAccountLegalDocumentsProps {
  orderId: IdType;
  premiseId: IdType;
}

export const MyAccountLegalDocuments = ({
  premiseId,
  orderId,
}: MyAccountLegalDocumentsProps) => {
  const { translate } = useTranslations();

  const {
    tMyAccountYourPlanCardContractDocuments,
    tMyAccountYourPlanCardYourDocuments,
    tMyAccountYourPlanCardDocumentDescription,
  } = translate(myAccountYourPlanCardTranslations);

  const welcomePacketLinkProps = {
    dataTrackingClick: { location: "My Account Legal Documents Section" },
    orderId,
    premiseId,
  };

  return (
    <StyledLegalDocumentWrapper>
      <StyledLegalDocTitle>
        <RhTypography variant="subtitle2" fontWeight="Bold">
          {tMyAccountYourPlanCardYourDocuments}
        </RhTypography>
      </StyledLegalDocTitle>

      <StyledLegalDocumentBody>
        <StyledLegalDocIconContainer>
          <WelcomePacketLink {...welcomePacketLinkProps}>
            <StyledLegalDocIcon
              title={tMyAccountYourPlanCardContractDocuments}
            />
          </WelcomePacketLink>
        </StyledLegalDocIconContainer>
        <div>
          <WelcomePacketLink {...welcomePacketLinkProps}>
            <RhTypography variant="body2">
              {tMyAccountYourPlanCardContractDocuments}
            </RhTypography>
          </WelcomePacketLink>
          <StyledLegalDocDescription variant="body2" color="textSecondary">
            {tMyAccountYourPlanCardDocumentDescription}
          </StyledLegalDocDescription>
        </div>
      </StyledLegalDocumentBody>
    </StyledLegalDocumentWrapper>
  );
};
