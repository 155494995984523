import { IdType } from "@common/types/apiTypes";
import { noop } from "@common/utils/genericFunctions";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as PaperlessIcon } from "@design-system/icons/PaperlessIcon.svg";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { SwitchEBillToPaperlessModalTranslations } from "@portal/components/SwitchEBillToPaperlessModal/SwitchEBillToPaperlessModal.en.i18n";
import {
  SwitchEBillToPaperlessModalBackdropCircularProgress,
  SwitchEBillToPaperlessModalContainer,
  SwitchEBillToPaperlessModalModal,
  SwitchEBillToPaperlessModalPaperButton,
  SwitchEBillToPaperlessModalSubtitle,
  SwitchEBillToPaperlessModalTitle,
} from "@portal/components/SwitchEBillToPaperlessModal/SwitchEBillToPaperlessModal.styled";
import { usePremiseCommunicationPreferencesMutation } from "@portal/hooks/mutations/usePremiseCommunicationPreferences";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { premiseRefetch } from "@portal/slices/premiseSlice";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";

interface SwitchEBillToPaperlessModalProps {
  handleClose(): void;
  premiseId: IdType;
}
export const SwitchEBillToPaperlessModal = (
  props: SwitchEBillToPaperlessModalProps
) => {
  const { premiseId, handleClose } = props;
  const { translate } = useTranslations();
  const premiseCommunicationPreferenceMutation =
    usePremiseCommunicationPreferencesMutation();
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const { setCookie } = usePortalCookies();

  const {
    tSwitchEBillToPaperlessModalUpdateFailure,
    tSwitchEBillToPaperlessModalUpdateToPaperlessSuccess,
    tSwitchEBillToPaperlessModalUpdateToPaperSuccess,
  } = translate(SwitchEBillToPaperlessModalTranslations);

  const handleUpdateCommunicationPreferences = (paperless: boolean) => {
    premiseCommunicationPreferenceMutation.mutate(
      { paperless, premiseId },
      {
        onError: () => {
          flash.error(tSwitchEBillToPaperlessModalUpdateFailure);
        },
        onSuccess: () => {
          dispatch(premiseRefetch());
          const message = paperless
            ? tSwitchEBillToPaperlessModalUpdateToPaperlessSuccess
            : tSwitchEBillToPaperlessModalUpdateToPaperSuccess;

          flash.success(message);
          handleOnClose();
        },
      }
    );
  };

  const handleOnClose = () => {
    const sixHoursFromNow = dayjs().add(6, "hour").toDate();
    const { cookieDomain } = brandInformation;

    setCookie("rhSwitchEBillToPaperlessModal", "hidden", {
      domain: cookieDomain,
      expires: sixHoursFromNow,
    });
    handleClose();
  };

  const {
    tSwitchEBillToPaperlessModalActionRequired,
    tSwitchEBillToPaperlessModalEBillToPaperless,
    tSwitchEBillToPaperlessModalUpgradeToPaperless,
    tSwitchEBillToPaperlessModalSwitchToPaperlessButtonCta,
    tSwitchEBillToPaperlessModalAgreeToPaperlessDescription,
    tSwitchEBillToPaperlessModalStayOnEBillButtonCta,
  } = translate(SwitchEBillToPaperlessModalTranslations);

  const isSendingRequest = premiseCommunicationPreferenceMutation.isPending;

  return (
    <SwitchEBillToPaperlessModalModal
      disableBackgroundClose
      hideCloseButton
      ariaLabel={tSwitchEBillToPaperlessModalActionRequired}
      ariaCloseLabel=""
      handleClose={noop}
    >
      <SwitchEBillToPaperlessModalContainer>
        <PaperlessIcon aria-hidden width={48} height={48} />
        <RhTypography variant="subtitle2" color="textSecondary">
          {tSwitchEBillToPaperlessModalActionRequired}
        </RhTypography>
        <SwitchEBillToPaperlessModalTitle
          variant="h3"
          fontWeight={FontWeight.Bold}
        >
          {tSwitchEBillToPaperlessModalEBillToPaperless}
        </SwitchEBillToPaperlessModalTitle>
        <SwitchEBillToPaperlessModalSubtitle
          variant="subtitle2"
          color="textSecondary"
        >
          {tSwitchEBillToPaperlessModalUpgradeToPaperless}
        </SwitchEBillToPaperlessModalSubtitle>
        <RhButton
          data-tracking-click={{
            event: "Customer switching from e-bill to paperless",
          }}
          onClick={() => handleUpdateCommunicationPreferences(true)}
        >
          {tSwitchEBillToPaperlessModalSwitchToPaperlessButtonCta}
        </RhButton>

        <RhTypography variant="body2">
          {tSwitchEBillToPaperlessModalAgreeToPaperlessDescription}
        </RhTypography>

        <SwitchEBillToPaperlessModalPaperButton
          type="button"
          onClick={handleOnClose}
        >
          {tSwitchEBillToPaperlessModalStayOnEBillButtonCta}
        </SwitchEBillToPaperlessModalPaperButton>
        {isSendingRequest ? (
          <SwitchEBillToPaperlessModalBackdropCircularProgress />
        ) : null}
      </SwitchEBillToPaperlessModalContainer>
    </SwitchEBillToPaperlessModalModal>
  );
};
