import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { Invoice } from "@portal/models/Invoice.model";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceHistoryQueryProps {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useInvoiceHistoryQuery = (props: UseInvoiceHistoryQueryProps) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => premiseApi.invoiceHistory(premiseId),
    queryKey: PremiseQueryKeys.invoiceHistory(premiseId),
    select: (data) => ({
      ...data,
      results: data.results.map((invoice) => new Invoice(invoice)),
    }),
  });
};
