import { IdType, UUIDType } from "@common/types/apiTypes";
import { atom } from "jotai";

interface ContractAddedModal {
  ids: UUIDType[];
}

interface EnergyBreakdown {
  offerSnapshotUuid: UUIDType;
}

interface MultipleTdsp {
  prospectUUID: UUIDType;
  zipCode: string;
}

interface SwitchEBillToPaperless {
  premiseId: IdType;
}

interface SwitchPrintToPaperless {
  premiseId: IdType;
  showRewardsIncentive: boolean;
}

export interface ModalsAtomType {
  areaNotServiceable: true | null;
  contractsAdded: ContractAddedModal | null;
  disconnectDevice: true | null;
  energyBreakdown: EnergyBreakdown | null;
  multipleSignUpFlowTabs: true | null;
  multipleTdsp: MultipleTdsp | null;
  renewalReminder: true | null;
  switchEBillToPaperless: SwitchEBillToPaperless | null;
  switchPrintToPaperless: SwitchPrintToPaperless | null;
  updateDevicePremise: true | null;
}

export const initialModalsAtom: ModalsAtomType = {
  areaNotServiceable: null,
  contractsAdded: null,
  disconnectDevice: null,
  energyBreakdown: null,
  multipleSignUpFlowTabs: null,
  multipleTdsp: null,
  renewalReminder: null,
  switchEBillToPaperless: null,
  switchPrintToPaperless: null,
  updateDevicePremise: null,
};

export const modalsStateAtom = atom<ModalsAtomType>(initialModalsAtom);
