import { prospectApi } from "@common/api/prospectApi";
import { RhApiError } from "@common/types/errorTypes";
import { useMutation } from "@tanstack/react-query";

export const useTrackServiceUnavailableMutation = () => {
  return useMutation({
    mutationFn: prospectApi.trackServiceUnavailable,
    onError: (_error: RhApiError) => {},
  });
};
