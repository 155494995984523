export const deviceEnrollmentRefresherTranslations = {
  tDeviceEnrollmentRefresherConnect: "Connect",
  tDeviceEnrollmentRefresherConnectDescription:
    "Connect your smart thermostat to your Rhythm Energy account.",
  tDeviceEnrollmentRefresherCredits: "Get bill credits",
  tDeviceEnrollmentRefresherCreditsDescription:
    "You'll get {credit}/month in bill credits for opting into the program.",
  tDeviceEnrollmentRefresherStayConnected: "Stay connected",
  tDeviceEnrollmentRefresherStayConnectedDescription:
    "During periods of peak demand, we may make minor adjustments to your thermostat - you can override any adjustment, any time.",
  tDeviceEnrollmentRefresherTitle: "A refresher on how it works...",
};
