import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal/api/invoiceApi";
import { InvoiceQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceKwhUsageQueryOptions {
  invoiceId: IdType;
}

export const useInvoiceKwhUsageQuery = (
  options: UseInvoiceKwhUsageQueryOptions
) => {
  const { invoiceId } = options;

  const query = useQuery({
    queryFn: () => invoiceApi.usage(invoiceId),
    queryKey: InvoiceQueryKeys.usage(invoiceId),
  });

  return query;
};
