import {
  OFFER_CONTAINER_WIDTH_3CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { signUpBasePath } from "@enroll-utils/constants/routePaths";
import { useMediaQuery, useTheme } from "@mui/material";
import { CampaignPromoAnnouncement } from "@portal/components/CampaignPromoAnnouncement/CampaignPromoAnnouncement";
import { CartMobile } from "@portal/components/CartMobile/CartMobile";
import { EnrollReferralCodeAnnouncement } from "@portal/components/EnrollReferralCodeAnnouncement/EnrollReferralCodeAnnouncement";
import { SignUpBackButton } from "@portal/components/SignUpBackButton/SignUpBackButton";
import {
  StyledBackButtonContainer,
  StyledBackButtonWrapper,
  StyledFlexColumn,
  StyledSignUpPageLayoutContainer,
} from "@portal/components/SignUpPageLayout/SignUpPageLayout.styled";
import { SignUpStepper } from "@portal/components/SignUpStepper/SignUpStepper";
import { useIsCartSignUpStep } from "@portal/hooks/useIsCartSignUpStep";
import { selectProspectId } from "@portal/selectors/signUpSelectors";
import { ActionType, EventType, track } from "@portal/services/segment.service";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface SignUpFormPageProps {
  handleBackButtonClick?: () => void;
  hideBackButton?: boolean;
  isSummaryPage?: boolean;
  maxWidth?: number;
}

export const SignUpPageLayout: FC<
  React.PropsWithChildren<SignUpFormPageProps>
> = ({
  hideBackButton = false,
  handleBackButtonClick,
  children,
  isSummaryPage = false,
  maxWidth = OFFER_CONTAINER_WIDTH_3CARD,
}) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const isCartStep = useIsCartSignUpStep();
  const prospectId = useSelector(selectProspectId);
  const isInSignUp = pathname.includes(signUpBasePath());

  useEffect(() => {
    if (prospectId) {
      track({
        action: ActionType.prospectCreated,
        event: EventType.identify,
        id: prospectId,
        label: "Prospect Created",
      });
    }
  }, [prospectId]);

  const showMobileCart = isInSignUp && isMobile && isCartStep;

  return (
    <StyledSignUpPageLayoutContainer
      $isSummaryPage={isSummaryPage}
      $maxWidth={maxWidth}
    >
      <CampaignPromoAnnouncement />
      <EnrollReferralCodeAnnouncement />
      <SignUpStepper />
      <StyledFlexColumn>
        {children}
        {!hideBackButton && (
          <StyledBackButtonWrapper>
            <StyledBackButtonContainer>
              <SignUpBackButton handleClick={handleBackButtonClick} />
            </StyledBackButtonContainer>
          </StyledBackButtonWrapper>
        )}
      </StyledFlexColumn>
      {showMobileCart && <CartMobile />}
    </StyledSignUpPageLayoutContainer>
  );
};
