import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { AccountSummary } from "@portal/models/AccountSummary.model";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface UsePremiseAccountSummaryQueryProps {
  enabled?: boolean;
  premiseId: IdType;
}

export const usePremiseAccountSummaryQuery = (
  props: UsePremiseAccountSummaryQueryProps
) => {
  const { enabled, premiseId } = props;

  return useQuery({
    enabled,
    queryFn: () => premiseApi.accountSummary(premiseId),
    queryKey: PremiseQueryKeys.accountSummary(premiseId),
    select: (data) => new AccountSummary(data),
  });
};

export function useInvalidatePremiseAccountSummaryQuery() {
  const queryClient = useQueryClient();

  return (premiseId: IdType) => {
    return queryClient.invalidateQueries({
      queryKey: PremiseQueryKeys.accountSummary(premiseId),
    });
  };
}
