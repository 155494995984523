import { ajax } from "@common/services/ajax";
import { PaymentMethodType } from "@common/types/paymentMethodTypes";
import { GuestPayUrls } from "@portal-guest/api/guestPayUrls";
import {
  GuestPayAccountSummaryType,
  GuestPayCreatePaymentResponseType,
} from "@portal-guest/types/guestPayTypes";

export const guestPayApi = {
  accountSummaryByToken: (token: string): Promise<GuestPayAccountSummaryType> =>
    ajax.get(GuestPayUrls.accountSummaryByToken(token)),
  accountSummaryLookup: (bodyData: {
    accountNumber: string;
    zipCode: string;
  }): Promise<GuestPayAccountSummaryType> =>
    ajax.post(GuestPayUrls.accountSummaryLookup(), bodyData),
  createPayment: ({
    amount,
    token,
  }: {
    amount: string;
    token: string;
  }): Promise<GuestPayCreatePaymentResponseType> =>
    ajax.post(GuestPayUrls.createPayment(token), {
      amount,
    }),
  createPaymentMethod: ({
    token,
    paymentMethodId,
  }: {
    paymentMethodId: string;
    token: string;
  }): Promise<null> =>
    ajax.post(GuestPayUrls.createPaymentMethod(token), {
      paymentMethodId,
    }),

  deletePaymentMethod: ({ token }: { token: string }): Promise<null> =>
    ajax.destroy(GuestPayUrls.deletePaymentMethod(token)),
  getPaymentMethod: (token: string): Promise<PaymentMethodType> =>
    ajax.get(GuestPayUrls.getPaymentMethod(token)),
};
