export const deviceInformationCardTranslations = {
  tDeviceInformationCardAddress: "Address",
  tDeviceInformationCardChangeDeviceAddress: "Change Device Address",
  tDeviceInformationCardConnectDevice: "Connect Device",
  tDeviceInformationCardDeviceName: "Device Name",
  tDeviceInformationCardDeviceSettings: "Device Settings",
  tDeviceInformationCardDeviceStatus: "Device Status",
  tDeviceInformationCardDeviceType: "Device Type",
  tDeviceInformationCardDeviceTypeThermostat: "{brand} Thermostat",
  tDeviceInformationCardDisconnectDevice: "Disconnect Device",
  tDeviceInformationCardModelNumber: "Model Number",
  tDeviceInformationCardSubheader:
    "You'll get {credit} per month in bill credits for having a connected device.",
  tDeviceInformationCardTitle: "Device Information",
  tDeviceInformationCardYourDocuments: "Your Documents",
  tDeviceInformationCardYourDocumentsLinkText:
    "Device Management Program Terms",
};
