import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { Link } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export interface RhRouterLinkProps {
  title?: string;
  to: string;
}

const StyledLink = styled(Link)`
  &.MuiLink-root {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    cursor: pointer;
    text-decoration: none;
  }
` as typeof Link;

export const RhRouterLink = (props: PropsWithChildren<RhRouterLinkProps>) => {
  const { children, title = "", to } = props;

  return (
    <StyledLink component={RouterLink} title={title} to={to}>
      {children}
    </StyledLink>
  );
};
