import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { selectedDevicesWithPremiseAtom } from "@portal/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { StyledButtonContainer } from "@portal/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout.styled";
import { devicesEnrollSummaryTranslations } from "@portal/components/DevicesEnrollSummary/DevicesEnrollSummary.en.i18n";
import {
  StyledContainer,
  StyledSummaryBlock,
} from "@portal/components/DevicesEnrollSummary/DevicesEnrollSummary.styled";
import { SummaryInfo } from "@portal/components/SummaryInfo/SummaryInfo";
import { devicesBasePath } from "@portal/routes/routePaths";
import { useAtomValue } from "jotai";
import React from "react";

export const DevicesEnrollSummary = () => {
  const { translate } = useTranslations();
  const selectedDevicesWithPremises = useAtomValue(
    selectedDevicesWithPremiseAtom
  );

  const {
    tDevicesEnrollSummaryDeviceAddress,
    tDevicesEnrollSummaryDeviceModel,
    tDevicesEnrollSummaryDeviceName,
    tDevicesEnrollSummaryDeviceType,
    tDevicesEnrollSummarySubmit,
  } = translate(devicesEnrollSummaryTranslations);

  return (
    <StyledContainer>
      {selectedDevicesWithPremises.map((deviceWithPremise) => {
        const { deviceName, deviceModel, deviceBrand, deviceId } =
          deviceWithPremise.device;
        const {
          premise: { address: premiseAddress },
        } = deviceWithPremise;

        return (
          <StyledSummaryBlock key={deviceId}>
            <SummaryInfo
              label={tDevicesEnrollSummaryDeviceName}
              value={deviceName}
            />
            <SummaryInfo
              label={tDevicesEnrollSummaryDeviceModel}
              value={deviceModel}
            />
            <SummaryInfo
              label={tDevicesEnrollSummaryDeviceType}
              value={deviceBrand}
            />
            <SummaryInfo
              label={tDevicesEnrollSummaryDeviceAddress}
              value={
                <div>
                  {premiseAddress?.deliveryLine1}
                  <br />
                  {premiseAddress?.city},{premiseAddress?.state}
                  <br />
                  {premiseAddress?.zipCode}
                </div>
              }
            />
          </StyledSummaryBlock>
        );
      })}
      <StyledButtonContainer>
        <RhLinkButton
          data-tracking-click={{ event: "Customer enrolling thermostat" }}
          href={devicesBasePath()}
          label={tDevicesEnrollSummarySubmit}
          size="medium"
          fullWidth
        >
          {tDevicesEnrollSummarySubmit}
        </RhLinkButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
