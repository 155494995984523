import { appBrand } from "@common/services/getEnvApplicationSettings.service";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { GroupedCompetitorPlans } from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { CompetitorNames } from "@portal-shared/types/competitorTypes";
import { ChartData } from "chart.js";

interface BuildChartDataProps {
  competitorPlans: GroupedCompetitorPlans[];
  offerSnapshot: OfferSnapshot;
}

export const buildChartData = (props: BuildChartDataProps) => {
  const { offerSnapshot, competitorPlans } = props;
  const brandPlan = {
    averagePrice: offerSnapshot.displayPrice(),
    competitorName: appBrand as unknown as CompetitorNames,
    plans: [],
  } satisfies GroupedCompetitorPlans;

  return {
    datasets: [
      {
        backgroundColor: (context) => {
          const { chart } = context;

          const {
            ctx,
            chartArea,
            scales: { y },
          } = chart;

          // Rhythm is the first data in the list
          if (context.dataIndex === 0) {
            return brandTheme.colors.primary.main;
          }

          if (!chartArea) {
            return brandTheme.colors.grey[200];
          }

          const { top, bottom } = chartArea;

          const gradientSegment = ctx.createLinearGradient(0, bottom, 0, top);

          let border =
            (bottom - y.getPixelForValue(offerSnapshot.displayPrice())) /
            (bottom - top);

          if (border > 1) {
            border = 1;
          }
          gradientSegment.addColorStop(0, brandTheme.colors.grey[100]);
          gradientSegment.addColorStop(border, brandTheme.colors.grey[100]);
          gradientSegment.addColorStop(border, brandTheme.colors.warning.main);
          gradientSegment.addColorStop(1, brandTheme.colors.warning.main);

          return gradientSegment;
        },
        data: [brandPlan, ...competitorPlans],
        label: "Price",
        parsing: {
          xAxisKey: "competitorName",
          yAxisKey: "averagePrice",
        },
      },
    ],
    labels: [appBrand, ...competitorPlans.map((cp) => cp.competitorName)],
  } satisfies ChartData<"bar", GroupedCompetitorPlans[]>;
};
