import { IdType } from "@common/types/apiTypes";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { Utm, UtmMedium, UtmSource } from "@portal/constants/utm.constant";

export const SIGN_OUT_PATHNAME = "sign-out";
export const PRODUCT_ADDONS_OPT_IN_PATHNAME = "additional-services";
export const NOTIFICATIONS_PATHNAME = "notifications";
export const REWARDS_PATHNAME = "rewards";
export const PAY_BILL_PATHNAME = "pay-bill";
export const PAYMENT_EXTENSION_PATHNAME = "payment-extension";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";

export const HOME = "home";
export const WELCOME_PATHNAME = "welcome";
export const CREATE_PASSWORD = "create-password";
export const EXPIRED_PASSWORD = "expired-password";

export const REFERRAL_CODE_QUERY_PARAM = "referralCode";

export const DEVICES_PATHNAME = "devices";

export const BILL_BREAKDOWN_PATHNAME = "bill-breakdown";

export const DevicesEnrollSteps = [
  "choose-your-device-brand",
  "terms-and-conditions",
  "select-devices",
  "summary",
] as const;
export type DevicesEnrollStepType = (typeof DevicesEnrollSteps)[number];

export const signOutPath = () => {
  return `/${SIGN_OUT_PATHNAME}`;
};

export const signUpReferralUrl = (referralCode: string) => {
  const queryParams = new URLSearchParams({
    [REFERRAL_CODE_QUERY_PARAM]: referralCode,
    [Utm.SOURCE]: UtmSource.RAF,
    [Utm.MEDIUM]: UtmMedium.MY_ACCOUNT,
  });

  return `${brandInformation.urlsHomePage}/raf?${queryParams.toString()}`;
};

export const customerHomePath = () => {
  return `/${HOME}`;
};

export const billBreakdownPath = (invoiceId: IdType) => {
  return `/${BILL_BREAKDOWN_PATHNAME}/${invoiceId}`;
};

export const myAccountPath = () => {
  return "/account";
};

export const productAddOnsOptInPath = () => {
  return `/${PRODUCT_ADDONS_OPT_IN_PATHNAME}`;
};

export const payBillPath = () => {
  return `/${PAY_BILL_PATHNAME}`;
};

export const paymentExtensionPath = () => {
  return `/${PAYMENT_EXTENSION_PATHNAME}`;
};

export const rewardsPath = () => {
  return `/${REWARDS_PATHNAME}`;
};

export const notificationsPath = () => {
  return `/${NOTIFICATIONS_PATHNAME}`;
};

export const accountRenewalsPath = () => {
  return "/renew";
};

export const accountRenewalsSummaryPath = () => {
  return "/renew/summary";
};

export const payBillSuccessPath = () => {
  return `/${PAY_BILL_PATHNAME}/success`;
};

export const activatePath = (activationToken: string) => {
  return `/${WELCOME_PATHNAME}/${activationToken}`;
};

export const createPasswordPath = () => {
  return `/${CREATE_PASSWORD}`;
};

export const expiredPasswordPath = () => {
  return `/${EXPIRED_PASSWORD}`;
};

export const forgotPasswordPath = () => {
  return `/${FORGOT_PASSWORD}`;
};

export const resetPasswordPath = (recoveryToken: string) => {
  return `/${RESET_PASSWORD}/${recoveryToken}`;
};

export const homePath = (): string => {
  return "/";
};

export const accountCreationPendingPath = (): string => {
  return "/account-creation-pending";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};

export const devicesBasePath = () => `/${DEVICES_PATHNAME}`;

export const devicesEnrollPath = () => `${devicesBasePath()}/enroll`;

export const devicesEnrollStepPath = (step: DevicesEnrollStepType) =>
  `${devicesEnrollPath()}/${step}`;

export const devicesEnrollChooseYourBrandDeviceStep =
  (): DevicesEnrollStepType => "choose-your-device-brand";
export const devicesEnrollTermsStep = (): DevicesEnrollStepType =>
  "terms-and-conditions";
export const devicesEnrollSelectDevicesStep = (): DevicesEnrollStepType =>
  "select-devices";
export const devicesEnrollSummaryStep = (): DevicesEnrollStepType => "summary";
