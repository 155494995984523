import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const PortalPageLayoutStyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  margin-bottom: 40px;
  padding: 0 ${rhSpacingPx(3)};
`;

export const PortalPageLayoutStyledTitle = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 32px;
    font-weight: ${FontWeight.Semibold};
  }
`;

export const PortalPageLayoutStyledExploreTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(3)};
    margin-top: 60px;
    padding: 0 ${rhSpacingPx(5)};
  }
`;

export const PortalPageLayoutStyledTandemBike = styled.div`
  display: none;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: block;
    position: absolute;
    top: 260px;
    width: 195px;
    z-index: -1;
  }
`;

export const PortalPageLayoutStyledTandemBikeLeft = styled(
  PortalPageLayoutStyledTandemBike
)`
  left: -${rhSpacingPx(2)};
`;

export const PortalPageLayoutStyledTandemBikeRight = styled(
  PortalPageLayoutStyledTandemBike
)`
  right: -${rhSpacingPx(2)};
`;

export const PortalPageLayoutStyledPageBackground = styled.div`
  background: linear-gradient(
    180deg,
    ${CSSVariables.COLOR_PRIMARY_LIGHT} 0.02%,
    ${CSSVariables.COLOR_WHITE} 29.27%
  );
  height: 946px;
  left: 0;
  position: absolute;
  right: 0;
  top: 400px;
  width: 100%;
  z-index: -2;
`;
