export const MyAccountBillingPreferencesCardTranslations = {
  tMyAccountBillingPreferencesCardAutoPayDisabledWarning:
    "Enroll in Paperless & Auto Pay and enjoy your {discountAmount}/month credit!",
  tMyAccountBillingPreferencesCardAutoPaySetUpAutoPay:
    "Once you're set up, you won't have to submit payments manually.",
  tMyAccountBillingPreferencesCardAveragedBilling: "Average Billing",
  tMyAccountBillingPreferencesCardAveragedBillingUseAverageBilling:
    "Make budgeting even easier. We'll look at your last 12 months of usage and send you a bill for about the same amount every month.",
  tMyAccountBillingPreferencesCardError:
    "Customer billing preference failed to save. Please try again.",
  tMyAccountBillingPreferencesCardErrorFetching:
    "Error fetching data, please try again",
  tMyAccountBillingPreferencesCardPickYourOwnDateDescription:
    "Your bill will be due monthly on the day of your choosing. Once selected, this date cannot be changed.",
  tMyAccountBillingPreferencesCardPickYourOwnDateDisplayDay:
    "{day, selectordinal, =0 {#th} one {#st} two {#nd} few {#rd} other {#th}}, Monthly",
  tMyAccountBillingPreferencesCardPickYourOwnDateSelectDueDate:
    "Select Due Date",
  tMyAccountBillingPreferencesCardPickYourOwnDateTitle: "Pick Your Due Date",
  tMyAccountBillingPreferencesCardPreferencesUpdated:
    "Your billing preferences have been updated.",
  tMyAccountBillingPreferencesCardTitle: "Billing Preferences",
};
