import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { LOGGED_OUT_FORM_WIDTH } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { StepConnector, StepLabel, Stepper } from "@mui/material";
import styled, { css } from "styled-components";

export const StyledConnectorLine = styled.div`
  border: 0;
  height: 1px;
`;

export const StyledStepperWrapper = styled.div`
  margin: 0 auto 2rem;
  max-width: ${LOGGED_OUT_FORM_WIDTH}px;
  width: 100%;
`;

const commonStepperCss = css`
  & > .MuiStep-root:last-child > .MuiStepConnector-active {
    background: linear-gradient(
      to right,
      ${CSSVariables.COLOR_PRIMARY_MAIN},
      ${CSSVariables.COLOR_PRIMARY_MAIN} 50%,
      ${CSSVariables.COLOR_GREY_200} 50%,
      ${CSSVariables.COLOR_GREY_200}
    );
    background-color: transparent;
  }
  &
    > .MuiStep-root:last-child
    > .MuiStepLabel-root
    > .MuiStepLabel-labelContainer
    > .MuiStepLabel-label:not(.Mui-active) {
    color: ${CSSVariables.COLOR_GREY_200};
  }
  &
    > .MuiStep-root:last-child
    > .MuiStepLabel-iconContainer:not(.Mui-active)
    svg {
    color: ${CSSVariables.COLOR_GREY_200};
  }
`;

export const StyledStepper = styled(Stepper)`
  ${commonStepperCss}

  max-width: ${LOGGED_OUT_FORM_WIDTH}px;
  padding: 0;
  width: inherit;
`;

export const StyledStepConnector = styled(StepConnector)`
  background-color: ${CSSVariables.COLOR_GREY_200};

  max-width: ${LOGGED_OUT_FORM_WIDTH};
  padding: 0;
  width: inherit;

  &.Mui-active {
    background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};

    .MuiStepConnector-line {
      border-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }
  }

  &.Mui-completed {
    background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};

    .MuiStepConnector-line {
      border-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }
  }

  &.MuiStepConnector-line {
    border: 0;
    height: 1px;
  }

  &.MuiStepConnector-root {
    left: calc(-50% + ${rhSpacingPx(1)} - 1px);
    right: calc(50% + ${rhSpacingPx(1)} - 1px);
    top: 7px;

    ${commonStepperCss}
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  color: ${CSSVariables.COLOR_GREY_200};

  & .MuiStepLabel-label {
    color: ${CSSVariables.COLOR_GREY_200};
    font-family: ${fontFamily};
    font-size: 10px;
    letter-spacing: 0.01px;

    &.Mui-active {
      color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }

    &.MuiStepLabel-alternativeLabel {
      font-weight: 600;
      margin-top: 6px;
    }

    &.Mui-completed {
      color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }
  }
`;
