import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePaymentExtensionQualifiedQueryProps {
  premiseId: IdType;
}
export const usePaymentExtensionQualifiedQuery = (
  props: UsePaymentExtensionQualifiedQueryProps
) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.paymentExtensionsQualified(premiseId),
    queryKey: PremiseQueryKeys.paymentExtensionsQualified(premiseId),
  });
};
