export const OneClickRenewalApiUrls = {
  BASE: "/api/portal/renewal-tokens/" as const,
  allRenewableOfferSnapshots: (renewalToken: string) =>
    `${OneClickRenewalApiUrls.retrieve(
      renewalToken
    )}/all-renewable-offer-snapshots`,
  defaultPaymentMethod: (renewalToken: string) =>
    `${OneClickRenewalApiUrls.retrieve(renewalToken)}/default-payment-method`,
  renew: (renewalToken: string) =>
    `${OneClickRenewalApiUrls.BASE}${renewalToken}`,
  retrieve: (renewalToken: string) =>
    `${OneClickRenewalApiUrls.BASE}${renewalToken}`,
};
