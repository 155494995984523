import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { borders } from "@design-system/constants/borders";
import styled from "styled-components";

export const StyledInvoicesRow = styled(RhFlexBox)`
  align-items: center;
  border-bottom: ${borders[100]};
  display: flex;
  justify-content: flex-end;
  min-height: 53px;
`;
