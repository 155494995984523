import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import styled from "styled-components";

interface StepperIconContainerProps {
  $active?: boolean;
  $completed?: boolean;
}

export const StyledStepperIconContainer = styled.div<StepperIconContainerProps>`
  color: ${(props) =>
    props.$active || props.$completed
      ? CSSVariables.COLOR_PRIMARY_MAIN
      : CSSVariables.COLOR_GREY_200};
  height: 14px;
  margin: 0 auto;
  width: 14px;
`;
