export const offerSnapshotThermostatRequiredModalTranslations = {
  tOfferSnapshotThermostatRequiredModalConfirm: "I understand",
  tOfferSnapshotThermostatRequiredModalConnect:
    "Connect your device to your Rhythm Account.",
  tOfferSnapshotThermostatRequiredModalImportant:
    "IMPORTANT: Two things need to happen before you get your monthly bill credits on this plan:",
  tOfferSnapshotThermostatRequiredModalInstall:
    "Install your all-new Amazon Smart Thermostat.",
  tOfferSnapshotThermostatRequiredModalNevermind: "Pick another plan",
  tOfferSnapshotThermostatRequiredModalTitle:
    "You must have a participating smart thermostat connected to get the discounted rate.",
};
