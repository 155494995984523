import { rhSpacing } from "@design-system/theme/spacing";
import {
  PAGE_GUTTER,
  RH_HEADER_HEIGHT,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

interface StyledPortalSharedMainContainerProps {
  $announcementBannerHeight: number;
}
export const StyledPortalSharedMainContainer = styled.main<StyledPortalSharedMainContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh; /* 100% does nothing in flex world */
  overflow-x: visible;
  padding-left: ${PAGE_GUTTER}px;
  padding-right: ${PAGE_GUTTER}px;
  padding-top: ${({ $announcementBannerHeight }) =>
    `${rhSpacing(RH_HEADER_HEIGHT) + $announcementBannerHeight}px`};
  position: relative;

  @media (max-width: ${RhythmBreakpoints.XS}px) {
    overflow-x: hidden;
  }
`;
