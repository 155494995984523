import { capitalizeFirstLetterOfWords } from "@common/utils/stringFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as Warning } from "@design-system/icons/WarningSignRed.svg";
import { PortalCheckbox } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { devicesSelectorAtom } from "@portal/components/DeviceSelector/DeviceSelector.atoms";
import { disconnectDeviceModalTranslations } from "@portal/components/DisconnectDeviceModal/DisconnectDeviceModal.en.i18n";
import {
  StyledAgreementCheckboxLabel,
  StyledButtonGroup,
  StyledContainer,
  StyledWarningHeader,
} from "@portal/components/DisconnectDeviceModal/DisconnectDeviceModal.styled";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useDisconnectDeviceMutation } from "@portal/hooks/mutations/useDisconnectDevice.mutation";
import { usePremise } from "@portal/hooks/usePremise";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import React, { useState } from "react";

interface DisconnectDeviceModalProps {
  handleClose: () => void;
}

export const DisconnectDeviceModal = (props: DisconnectDeviceModalProps) => {
  const { handleClose } = props;

  const queryClient = useQueryClient();
  const flash = useRhFlash();
  const { premise } = usePremise();
  const currentSelectedDevice = useAtomValue(devicesSelectorAtom);
  const [agreedToDisconnect, setAgreedToDisconnect] = useState(false);
  const { translate, translateJsx } = useTranslations();
  const {
    tDisconnectDeviceModalTitle,
    tDisconnectDeviceModalCancel,
    tDisconnectDeviceModalConfirm,
    tDisconnectDeviceModalError,
  } = translate(disconnectDeviceModalTranslations);
  const disconnectDeviceMutation = useDisconnectDeviceMutation();

  if (!currentSelectedDevice || !premise) {
    return null;
  }

  const {
    tDisconnectDeviceModalAgreementText,
    tDisconnectDeviceModalScheduledDisconnectionNotice,
  } = translateJsx({
    tDisconnectDeviceModalAgreementText: {
      credit: premise.formattedThermostatManagementMonthlyDiscount,
    },
    tDisconnectDeviceModalScheduledDisconnectionNotice: {
      brand: capitalizeFirstLetterOfWords(currentSelectedDevice.deviceBrand),
    },
  });

  const invalidateAttachedDevicesQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: PremiseQueryKeys.attachedDevices(premise.id),
    });
  };

  const handleConfirm = async () => {
    try {
      await disconnectDeviceMutation.mutateAsync(
        currentSelectedDevice.smartThermostatId,
        {
          onSuccess: async () => {
            await invalidateAttachedDevicesQuery();

            flash.warning(tDisconnectDeviceModalScheduledDisconnectionNotice);

            return handleClose();
          },
        }
      );
    } catch (error) {
      flash.error(tDisconnectDeviceModalError);
    }
  };

  return (
    <RhModal
      ariaCloseLabel="Close"
      ariaLabel="Modal"
      handleClose={handleClose}
      overflowVisible
      size={ModalWidths.xs}
    >
      <StyledContainer>
        <StyledWarningHeader>
          <Warning aria-hidden />
          <RhTypography variant="h2">
            {tDisconnectDeviceModalTitle}
          </RhTypography>
        </StyledWarningHeader>
        <StyledAgreementCheckboxLabel
          onChange={() => setAgreedToDisconnect(!agreedToDisconnect)}
        >
          <PortalCheckbox checked={agreedToDisconnect} />
          <RhTypography>{tDisconnectDeviceModalAgreementText}</RhTypography>
        </StyledAgreementCheckboxLabel>
        <StyledButtonGroup>
          <RhButton
            disabled={!agreedToDisconnect}
            onClick={handleConfirm}
            fullWidth={false}
            data-tracking-click={{
              action: "clickedConfirmDisconnectDevice",
              event: "Confirmed device disconnect",
            }}
          >
            {tDisconnectDeviceModalConfirm}
          </RhButton>
          <RhButton
            variant="outlined"
            onClick={handleClose}
            data-tracking-click={{ event: "Close disconnect device modal" }}
          >
            {tDisconnectDeviceModalCancel}
          </RhButton>
        </StyledButtonGroup>
      </StyledContainer>
    </RhModal>
  );
};
