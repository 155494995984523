import { IdType } from "@common/types/apiTypes";

const PREMISE_BASE_URL = "/api/portal/premises";

export const premiseUrls = {
  accountSummary: (premiseId: IdType) =>
    `${premiseUrls.retrieve(premiseId)}/account-summary`,
  allRenewableOfferSnapshots: (premiseId: IdType) =>
    `${premiseUrls.retrieve(premiseId)}/all-renewable-offer-snapshots`,
  attachedDevices: (premiseId: IdType) =>
    `${premiseUrls.retrieve(premiseId)}/attached-devices`,
  billForecastSummary: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/bill-forecast-summary`,
  billingStatus: (id: IdType) => `${PREMISE_BASE_URL}/${id}/billing-status`,
  communicationPreferences: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/communication-preferences`,
  documents: (premiseId: IdType) =>
    `${premiseUrls.retrieve(premiseId)}/documents`,
  energyInvoices: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/invoices/list-energy`,
  invoiceHistory: (id: IdType) => `${PREMISE_BASE_URL}/${id}/invoice-history`,
  kwhUsageDay: (id: IdType) => `${PREMISE_BASE_URL}/${id}/kwh-usage-day`,
  kwhUsageSummary: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/kwh-usage-summary`,
  paymentExtensions: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/payment-extensions`,
  paymentExtensionsActive: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/payment-extensions/active`,
  paymentExtensionsQualified: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/payment-extensions/qualified`,
  paymentHistory: (id: IdType) => `${PREMISE_BASE_URL}/${id}/payment-history`,
  paymentMethod: (premiseId: IdType, paymentMethodId: string) =>
    `${premiseUrls.retrieve(premiseId)}/payment-method/${paymentMethodId}`,
  paymentMethods: (id: IdType): string =>
    `${PREMISE_BASE_URL}/${id}/payment-methods`,
  renewContract: (id: IdType) => `${PREMISE_BASE_URL}/${id}/renew-contract`,
  retrieve: (id: IdType) => `${PREMISE_BASE_URL}/${id}`,
  temperatureSummary: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/temperature-summary`,
  thermostatConnectionActivity: ({ premiseId }: { premiseId: IdType }) =>
    `${premiseUrls.retrieve(premiseId)}/thermostat-connection-activity/`,
  thermostatDemandResponseActivity: ({ premiseId }: { premiseId: IdType }) =>
    `${premiseUrls.retrieve(premiseId)}/thermostat-demand-response-activity/`,
  updateAutoPay: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/auto-pay`,
  updatePaymentMethod: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/update-payment-method`,
};
