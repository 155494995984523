import { ReactComponent as StepperCheckIcon } from "@design-system/icons/StepperCheckIcon.svg";
import { ReactComponent as StepperFourIcon } from "@design-system/icons/StepperFourIcon.svg";
import { ReactComponent as StepperOneIcon } from "@design-system/icons/StepperOneIcon.svg";
import { ReactComponent as StepperThreeIcon } from "@design-system/icons/StepperThreeIcon.svg";
import { ReactComponent as StepperTwoIcon } from "@design-system/icons/StepperTwoIcon.svg";
import { DevicesEnrollStepType } from "@portal/routes/routePaths";

export const devicesEnrollStepperIconMapping: Record<
  DevicesEnrollStepType | "completed",
  typeof StepperOneIcon
> = {
  "choose-your-device-brand": StepperOneIcon,
  completed: StepperCheckIcon,
  "select-devices": StepperThreeIcon,
  summary: StepperFourIcon,
  "terms-and-conditions": StepperTwoIcon,
};
