export const billingHistoryTableV2Translations = {
  tBillingHistoryTableV2BillBreakdown: "Bill Breakdown",
  tBillingHistoryTableV2BillDate: "Bill Date",
  tBillingHistoryTableV2Download: "Download",
  tBillingHistoryTableV2DueDate: "Due Date",
  tBillingHistoryTableV2ErrorDownloading:
    "We are having issues retrieving your bill. Please try again.",
  tBillingHistoryTableV2Invoice: "Invoice",
  tBillingHistoryTableV2ServicePeriod: "Service Period",
  tBillingHistoryTableV2TotalAmountDue: "Total Amount Due",
  tBillingHistoryTableV2TotalUsage: "Total Usage",
};
