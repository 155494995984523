import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledBillFactorsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: ${rhSpacingPx(2.5)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: start;
  }
`;

export const StyledBillFactor = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  position: relative;
  text-align: center;
  width: 200px;

  > svg {
    height: 48px;
    width: 48px;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    &:not(:nth-child(3n)):not(:last-child)::after {
      background-color: ${CSSVariables.COLOR_GREY_100};
      bottom: ${rhSpacingPx(2)};
      content: "";
      position: absolute;
      right: -12px;
      top: ${rhSpacingPx(2)};
      width: 1px;
    }

    padding: 0 ${rhSpacingPx(3)};
  }
`;
