import enJson from "@portal-shared/translations/en-base.json";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";

type EnJsonTypes = typeof enJson;

export type TranslationKey = keyof EnJsonTypes;

type FormatMessageType = ReturnType<typeof useIntl>["formatMessage"];
type TArgs = Parameters<FormatMessageType>;
type TValues = TArgs[1];

function getT(formatMessage: FormatMessageType) {
  function t(id: TranslationKey): string {
    return formatMessage({ id });
  }

  return t;
}

function getTJsx(formatMessage: FormatMessageType) {
  function tJsx(id: TranslationKey, values: TValues): ReactNode {
    return formatMessage({ id }, values) as ReactNode;
  }

  return tJsx;
}

export const useRhIntl = () => {
  const { formatMessage } = useIntl();
  const [tFunctions, setTFunctions] = useState({
    t: getT(formatMessage),
    tJsx: getTJsx(formatMessage),
  });

  useEffect(() => {
    setTFunctions({
      t: getT(formatMessage),
      tJsx: getTJsx(formatMessage),
    });
  }, [formatMessage]);

  return tFunctions;
};
