import { AppBrands } from "@common/enums/appBrands.enum";
import { appBrand } from "@common/services/getEnvApplicationSettings.service";
import { getPortalEnvApplicationSettings } from "@portal-shared/settings/getPortalEnvApplicationSettings";

const portalEnvApplicationSettings = getPortalEnvApplicationSettings();

const MARKETING_RHYTHM_URL = portalEnvApplicationSettings.marketingRhythmUrl;
const MARKETING_ENERGY_TEXAS_URL =
  portalEnvApplicationSettings.marketingEnergyTexasUrl;

const RHYTHM_FAQ_HOME = "https://support.gotrhythm.com/hc";
const rhythmInformation = {
  addressesPoBoxLine1: "PO BOX 735903",
  addressesPoBoxLine2: "Dallas, TX 75373",
  brandLLC: "Rhythm Ops, LLC",
  brandName: "Rhythm",
  cookieDomain: ".gotrhythm.com",
  emailsSupport: "support@gotrhythm.com",
  enrollmentEmailSupport: "enrollments@gotrhythm.com",
  phoneNumbersGeneralSales: "1-888-408-2836",
  phoneNumbersOpsBadDebt: "1-888-512-0283",
  phoneNumbersOpsDisplay: "1-888-7RHYTHM",
  phoneNumbersOpsNumber: "+18887749846",
  socialMediaFacebook: "https://www.facebook.com/rhythmenergy/",
  socialMediaInstagram: "https://www.instagram.com/rhythmenergy/",
  socialMediaLinkedIn: "https://www.linkedin.com/company/rhythmhq/",
  socialMediaTwitter: "https://twitter.com/GotRhythmHQ/",
  urlsAbout: `${MARKETING_RHYTHM_URL}/about`,
  urlsBlogConnectDevice: `${MARKETING_RHYTHM_URL}/blog/energy-saving-tips/how-to-connect-your-smart-thermostat`,
  urlsBlogFaqsHome: `${MARKETING_RHYTHM_URL}/faq`,
  urlsBlogPostsEnergySavingTips: `${MARKETING_RHYTHM_URL}/blog/sustainable-lifestyle/10-tips-to-reduce-energy-bills-this-summer`,
  urlsFaqsEtfReimbursement: `${RHYTHM_FAQ_HOME}/en-us/articles/360051908832-Will-Rhythm-reimburse-my-current-supplier-s-ETF-`,
  urlsFaqsHome: RHYTHM_FAQ_HOME,
  urlsFaqsOutages: `${RHYTHM_FAQ_HOME}/en-us/articles/360053151792-How-can-I-report-or-get-information-on-a-power-outage-`,
  urlsFaqsReferAFriend: `${RHYTHM_FAQ_HOME}/en-us/articles/360051909032-What-is-the-Refer-A-Friend-program-`,
  urlsFaqsRewards: `${RHYTHM_FAQ_HOME}/en-us/articles/360052366771-Rhythm-Rewards`,
  urlsFaqsSatisfyDeposit: `${RHYTHM_FAQ_HOME}/en-us/articles/360052046731-How-can-I-satisfy-a-deposit-`,
  urlsFaqsSolarBuyBack: `${MARKETING_RHYTHM_URL}/solar-buyback-plan-faq`,
  urlsHomePage: MARKETING_RHYTHM_URL,
  urlsPlansSolar: `${MARKETING_RHYTHM_URL}/solar`,
  urlsPrivacyPolicy: `${MARKETING_RHYTHM_URL}/privacy-notice`,
  urlsReferAFriend: `${MARKETING_RHYTHM_URL}/raf`,
  urlsTermsOfUse: `${MARKETING_RHYTHM_URL}/terms-of-use`,
};

const ENERGY_TEXAS_FAQ_HOME = "https://support.energytexas.com/hc";

const energyTexasInformation = {
  addressesPoBoxLine1: "PO BOX 735903",
  addressesPoBoxLine2: "Dallas, TX 75373",
  brandLLC: "Rhythm Ops, LLC",
  brandName: "Energy Texas",
  cookieDomain: ".energytexas.com",
  emailsSupport: "support@energytexas.com",
  enrollmentEmailSupport: "enrollments@energytexas.com",
  phoneNumbersGeneralSales: "1-800-837-4099",
  // TODO - Incorrect
  phoneNumbersOpsBadDebt: "1-888-512-0283",
  // TODO - Incorrect
  phoneNumbersOpsDisplay: "1-888-7RHYTHM",
  // TODO - Incorrect
  phoneNumbersOpsNumber: "+18887749846",
  socialMediaFacebook: "https://www.facebook.com/EnergyTexasCo/",
  socialMediaInstagram: "https://www.instagram.com/energytexasco/",
  socialMediaLinkedIn: "https://www.linkedin.com/company/energytexasco/",
  socialMediaTwitter: "https://x.com/EnergyTexasCo",
  urlsAbout: `${MARKETING_ENERGY_TEXAS_URL}/about`,
  // TODO - Broken
  urlsBlogConnectDevice: `${MARKETING_ENERGY_TEXAS_URL}/blog/energy-saving-tips/how-to-connect-your-smart-thermostat`,
  // TODO - Broken
  urlsBlogFaqsHome: `${MARKETING_ENERGY_TEXAS_URL}/faq`,
  // TODO - Broken
  urlsBlogPostsEnergySavingTips: `${MARKETING_ENERGY_TEXAS_URL}/blog/sustainable-lifestyle/10-tips-to-reduce-energy-bills-this-summer`,
  // TODO - Broken
  urlsFaqsEtfReimbursement: `${ENERGY_TEXAS_FAQ_HOME}/en-us/articles/360051908832-Will-Rhythm-reimburse-my-current-supplier-s-ETF-`,
  urlsFaqsHome: ENERGY_TEXAS_FAQ_HOME,
  // TODO - Broken
  urlsFaqsOutages: `${ENERGY_TEXAS_FAQ_HOME}/en-us/articles/360053151792-How-can-I-report-or-get-information-on-a-power-outage-`,
  // TODO - Broken
  urlsFaqsReferAFriend: `${ENERGY_TEXAS_FAQ_HOME}/en-us/articles/360051909032-What-is-the-Refer-A-Friend-program-`,
  // TODO - Broken
  urlsFaqsRewards: `${ENERGY_TEXAS_FAQ_HOME}/en-us/articles/360052366771-Rhythm-Rewards`,
  // TODO - Broken
  urlsFaqsSatisfyDeposit: `${ENERGY_TEXAS_FAQ_HOME}/en-us/articles/360052046731-How-can-I-satisfy-a-deposit-`,
  // TODO - Broken
  urlsFaqsSolarBuyBack: `${ENERGY_TEXAS_FAQ_HOME}/solar-buyback-plan-faq`,
  urlsHomePage: MARKETING_ENERGY_TEXAS_URL,
  // TODO - Broken
  urlsPlansSolar: `${MARKETING_ENERGY_TEXAS_URL}/solar`,
  urlsPrivacyPolicy: `${MARKETING_ENERGY_TEXAS_URL}/privacy-policy`,
  // TODO - Broken
  urlsReferAFriend: `${MARKETING_ENERGY_TEXAS_URL}/raf`,
  urlsTermsOfUse: `${MARKETING_ENERGY_TEXAS_URL}/terms-of-use`,
} satisfies typeof rhythmInformation;

const getBrandInformation = () => {
  if (appBrand === AppBrands.ENERGY_TEXAS) {
    return energyTexasInformation;
  }

  return rhythmInformation;
};

export const brandInformation = getBrandInformation();
