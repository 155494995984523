export const deviceFAQsCardTranslations = {
  tDeviceFAQsAdjustFaqBody1:
    "With the changing dynamics of the Texas power system, peak demand and grid strain are occurring in different parts of the day, and different months of the year, more than ever before. That said, most events are likely to occur during summer afternoons and evenings, as well as in the morning hours of the winter.",
  tDeviceFAQsAdjustFaqTitle: "When will Rhythm adjust my thermostat?",
  tDeviceFAQsCardTitle: "FAQs",
  tDeviceFAQsCompatibilityFaqBody1:
    "For now, just Honeywell and Amazon smart thermostats. Keep your eyes peeled for more devices we'll add soon.",
  tDeviceFAQsCompatibilityFaqTitle:
    "What kind of devices are compatible with this program?",
  tDeviceFAQsCreditsFaqBody1:
    "No. You get your monthly bill credits per household, regardless of the number of devices you connect.",
  tDeviceFAQsCreditsFaqTitle:
    "Will I get more credits if I connect more than one device?",
  tDeviceFAQsHowToConnectDevicesBody1:
    "Absolutely. Click <link>this</link> for detailed steps.",
  tDeviceFAQsHowToConnectDevicesTitle:
    "Can you give me step-by-step instructions on how to connect my device(s)?",
  tDeviceFAQsMaxControlTimesFaqsBody1:
    "There's not a cap, no. But we anticipate most events taking place in the summertime. Rhythm will call events in line with conditions on the power grid.",
  tDeviceFAQsMaxControlTimesFaqsTitle:
    "Is there a maximum number of times Rhythm can control my thermostat per month?",
  tDeviceFAQsMonthlyBillCreditFaqBody1:
    "It will be automatically applied to your monthly invoice.",
  tDeviceFAQsMonthlyBillCreditFaqTitle:
    "How do I receive my monthly bill credit?",
  tDeviceFAQsNotificationsFaqBody1:
    "Yes, you'll be sent notifications informing you of any time we intend to adjust your thermostat.",
  tDeviceFAQsNotificationsFaqTitle:
    "Will I get notified before Rhythm adjusts my thermostat?",
  tDeviceFAQsParticipationRequirementsFaqBody1:
    "You'll get your monthly bill credit if you meet participation requirements stated below:",
  tDeviceFAQsParticipationRequirementsFaqBody2:
    "1. Your device cannot be inactive for more than {hours} consecutive hours in a billing cycle.",
  tDeviceFAQsParticipationRequirementsFaqBody3:
    "2. You're only allowed to override up to {events} events per billing cycle.",
  tDeviceFAQsParticipationRequirementsFaqTitle:
    "What are the participation requirements for this program in order to receive {credit}/month bill credit?",
  tDeviceFAQsProgramFaqBody1:
    "1. Choose your smart thermostat device and connect your account.",
  tDeviceFAQsProgramFaqBody2:
    "2. During periods of high demand, we may make minor adjustments to your thermostat. You are able to override any adjustment and opt out of any event by simply changing the temperature.",
  tDeviceFAQsProgramFaqBody3:
    "3. You'll get your monthly bill credit if you meet participation requirements.",
  tDeviceFAQsProgramFaqBody4:
    "NOTE: To meet participation requirements, your device cannot be inactive for more than {hours} consecutive hours in a billing cycle, and you're only allowed to override up to {events} events per billing cycle.",
  tDeviceFAQsProgramFaqTitle: "What can I expect from this program?",
};
