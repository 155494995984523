export const billFactorsCardTranslations = {
  tBillFactorsCardALotOfFactors:
    "A lot of factors can affect your bill each month. These are the biggest ones.",
  tBillFactorsCardError:
    "Oops! Something went wrong while getting your previous bill data. Please try again later.",
  tBillFactorsCardNotMuchChanged: "Not much has changed since your last bill.",
  tBillFactorsCardTheresNothing: "There's nothing to compare at this moment.",
  tBillFactorsCardThisMightBe:
    "This might be because the necessary data isn't available. Please check back with the next invoice for a comparison.",
  tBillFactorsCardWhatChanged: "What changed from last billing cycle?",
  tBillFactorsCardYourBill:
    "Your bill and usage are on par with last billing cycle.",
};
