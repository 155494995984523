import { Premise } from "@common/models/Premise.model";
import { CommunicationPreferenceType } from "@common/types/premiseTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { PortalCard } from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { myAccountCommunicationPreferencesTranslations } from "@portal/components/MyAccountCommunicationPreferences/MyAccountCommunicationPreferences.en.i18n";
import { MyAccountCommunicationPreferencesModal } from "@portal/components/MyAccountCommunicationPreferencesModal/MyAccountCommunicationPreferencesModal";
import React, { useState } from "react";
import styled from "styled-components";

interface MyAccountCommunicationPreferencesProps {
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}

const ToggleContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MyAccountCommunicationPreferences = (
  props: MyAccountCommunicationPreferencesProps
) => {
  const { offerSnapshot, premise } = props;

  const { translate } = useTranslations();
  const [
    showCommunicationPreferencesModal,
    setShowCommunicationPreferencesModal,
  ] = useState(false);

  const {
    tMyAccountCommunicationPreferencesPaperStatus,
    tMyAccountCommunicationPreferencesPaperlessStatus,
    tMyAccountCommunicationPreferencesEBill,
    tMyAccountCommunicationPreferencesEditButton,
    tMyAccountCommunicationPreferencesPaperlessTitle,
  } = translate(myAccountCommunicationPreferencesTranslations);

  const communicationPreferenceMapping: Record<
    CommunicationPreferenceType,
    string
  > = {
    [CommunicationPreferenceType.EBILL]:
      tMyAccountCommunicationPreferencesEBill,
    [CommunicationPreferenceType.PRINT]:
      tMyAccountCommunicationPreferencesPaperStatus,
    [CommunicationPreferenceType.PAPERLESS]:
      tMyAccountCommunicationPreferencesPaperlessStatus,
  };

  const handleOpenModal = () => {
    setShowCommunicationPreferencesModal(true);
  };

  const handleCloseModal = () => {
    setShowCommunicationPreferencesModal(false);
  };

  const showPaperlessWarningText =
    offerSnapshot.isAutoPayPaperlessDiscountOffer &&
    premise.communicationPreference !== CommunicationPreferenceType.PAPERLESS;

  return (
    <>
      <PortalCard $noShadow>
        <ToggleContainer>
          <div>
            <RhTypography
              color="textPrimary"
              variant="subtitle2"
              fontWeight={FontWeight.Semibold}
            >
              {tMyAccountCommunicationPreferencesPaperlessTitle}
            </RhTypography>

            <RhTypography
              color={
                showPaperlessWarningText || premise.isOnLegacyEBill
                  ? "error"
                  : "textSecondary"
              }
              variant="body1"
            >
              {communicationPreferenceMapping[premise.communicationPreference]}
            </RhTypography>
          </div>

          <RhButton
            data-tracking-click={{
              event: "Customer opened communication preferences modal",
            }}
            size="small"
            onClick={handleOpenModal}
          >
            {tMyAccountCommunicationPreferencesEditButton}
          </RhButton>
        </ToggleContainer>
      </PortalCard>

      {showCommunicationPreferencesModal ? (
        <MyAccountCommunicationPreferencesModal
          premise={premise}
          offerSnapshot={offerSnapshot}
          handleClose={handleCloseModal}
        />
      ) : null}
    </>
  );
};
