import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal/api/invoiceApi";
import { InvoiceQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceOrdersQueryProps {
  invoiceId: IdType;
}

export const useInvoiceOrdersQuery = (props: UseInvoiceOrdersQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.orders(invoiceId),
    queryKey: InvoiceQueryKeys.orders(invoiceId),
  });
};
