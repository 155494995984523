import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledInnercard = styled(RhInnerCard)`
  margin-bottom: ${rhSpacingPx(1)};
`;

export const StyledOption = styled(RhRadioInput)`
  margin-right: ${rhSpacingPx(1.5)};
  padding: 0;
`;

export const StyledLabel = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_600};
  }
`;
