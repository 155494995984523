import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as PhoneIcon } from "@design-system/icons/PhoneIcon.svg";
import { rhSpacing } from "@design-system/theme/spacing";
import React from "react";
import styled from "styled-components";

interface PhoneClaimProps {
  phoneNumber: string;
}

const StyledPhoneClaimContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacing(1)}px;
`;

const StyledPhoneNumber = styled(RhAnchor)`
  font-size: 18px;
  font-weight: ${FontWeight.Semibold};
`;

export const PhoneClaim = ({ phoneNumber }: PhoneClaimProps) => {
  return (
    <StyledPhoneClaimContainer>
      <PhoneIcon aria-hidden="true" />
      <StyledPhoneNumber href={`tel:${phoneNumber}`}>
        {formatPhoneNumber(phoneNumber)}
      </StyledPhoneNumber>
    </StyledPhoneClaimContainer>
  );
};
