import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 800px;
  padding: ${rhSpacingPx(4)} 0;
  width: 100%;
`;

export const StyledDeviceSelectorWrapper = styled.div`
  display: flex;
  overflow: visible;
  padding-bottom: ${rhSpacingPx(1)};
  width: 100%;
`;

export const StyledDeviceSelectorAndButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(2)};
  padding: ${rhSpacingPx(2)} 0;
  width: 100%;

  @media screen and (min-width: 390px) {
    flex-flow: row nowrap;
  }
`;

export const StyledPageHeader = styled.header`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(2)};
  justify-content: flex-start;
  padding-bottom: ${rhSpacingPx(2)};
  width: 100%;
`;

export const StyledPageTitle = styled(RhTypography)`
  flex: 1;
  text-align: left;
`;

export const StyledAddNewContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

export const StyledNoAttachedDevicesHeader = styled.header`
  align-items: center;
  display: flex;
  flex-flow: column;
  gap: ${rhSpacingPx(2)};
  padding-bottom: ${rhSpacingPx(4)};
  text-align: center;
  width: 100%;

  > h1 {
    padding-top: ${rhSpacingPx(2)};
  }
`;

export const StyledNoAttachedDevicesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  gap: ${rhSpacingPx(6)};
  justify-content: center;
  text-align: center;
  width: 100%;
`;
