import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  StyledIconContainer,
  StyledIllustrationContainer,
  StyledLoggedOutPageHeaderContainer,
  StyledSubheaderContainer,
  StyledSubtitleContainer,
} from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader.styled";
import React, { ReactNode } from "react";

interface LoggedOutPageHeaderProps {
  headerText: string | JSX.Element;
  illustration?: ReactNode;
  subHeaderText?: string | JSX.Element;

  subheader?: {
    href: string;
    icon: React.ReactNode;
    target?: "_blank" | "_self";
  } | null;
}

export const LoggedOutPageHeader = ({
  headerText,
  subHeaderText,
  illustration,
  subheader,
}: LoggedOutPageHeaderProps) => {
  return (
    <StyledLoggedOutPageHeaderContainer>
      {illustration && (
        <StyledIllustrationContainer>
          {illustration}
        </StyledIllustrationContainer>
      )}
      <RhTypography variant="h1" align="center">
        {headerText}
      </RhTypography>
      {subHeaderText && (
        <StyledSubheaderContainer>
          {subheader?.icon && (
            <StyledIconContainer>{subheader.icon}</StyledIconContainer>
          )}
          <StyledSubtitleContainer variant="subtitle2" align="center">
            {subHeaderText}
          </StyledSubtitleContainer>
        </StyledSubheaderContainer>
      )}
    </StyledLoggedOutPageHeaderContainer>
  );
};
