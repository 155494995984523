import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import styled, { css } from "styled-components";

const cssVars = css`
  --purple: ${CSSVariables.COLOR_PRIMARY_MAIN};
  --lightPurple: ${CSSVariables.COLOR_PRIMARY_MEDIUM};
`;

export const RhStyledLinkButton = styled.button`
  ${cssVars}
  background: transparent;
  border: 0;
  color: var(--purple);
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: var(--lightPurple);
    text-decoration: underline;
  }
`;
