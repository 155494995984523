import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhInnerCard } from "@design-system/components/RhInnerCard/RhInnerCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { BILLING_PREFERENCE_DETAIL_MAX_WIDTH } from "@portal/components/PreferencesSwitchInput/PreferencesSwitchInput.styled";
import styled from "styled-components";

export const StyledPyoddButton = styled(RhButton)`
  &.MuiButton-root {
    align-self: flex-start;

    @media (max-width: ${RhythmBreakpoints.SM}px) {
      width: 100%;
    }
  }
`;

export const StyledPyoddMessage = styled(RhTypography)`
  &.MuiTypography-root {
    align-self: flex-start;

    @media (max-width: ${RhythmBreakpoints.SM}px) {
      width: 100%;
    }
  }
`;

export const StyledPyoddDetail = styled(RhTypography)`
  &.MuiTypography-root {
    max-width: ${BILLING_PREFERENCE_DETAIL_MAX_WIDTH};
  }
`;

export const StyledPyoddInnerCard = styled(RhInnerCard)`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @media (max-width: ${RhythmBreakpoints.SM}px) {
    flex-flow: column nowrap;
  }
`;
