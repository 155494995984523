export const devicesEnrollTermsFormTranslations = {
  tDevicesEnrollTermsDocumentLinkText: "Device Management Program Terms",
  tDevicesEnrollTermsError:
    "We are having trouble fetching your device authorization url",
  tDevicesEnrollTermsFormBack: "Back",
  tDevicesEnrollTermsFormCopy:
    "By checking this box you acknowledge reading and agreeing to our Device Management Program Terms.",
  tDevicesEnrollTermsFormNext: "Next",
  tDevicesEnrollTermsFormYourDocuments: "Your Documents",
};
