import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

const TermsUrl = "https://simplisafe.com/terms-of-use";
const PrivacyUrl = "https://simplisafe.com/privacy";

export const SimpliSafeLegalText = () => {
  const { translateJsx } = useTranslations();

  const { tSimpliSafeLegalTextByCheckingThisBox } = translateJsx({
    tSimpliSafeLegalTextByCheckingThisBox: {
      privacy: (chunk: string) => (
        <RhAnchor href={PrivacyUrl}>{chunk}</RhAnchor>
      ),
      terms: (chunk: string) => <RhAnchor href={TermsUrl}>{chunk}</RhAnchor>,
    },
  });

  return tSimpliSafeLegalTextByCheckingThisBox;
};
