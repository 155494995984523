import { rhSpacingPx } from "@design-system/theme/spacing";
import { Paper } from "@mui/material";
import styled from "styled-components";

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    margin-bottom: ${rhSpacingPx(2)};
    padding: ${rhSpacingPx(2)};
  }
`;
