import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import { StyledLoadingContainer } from "@portal/components/BillingSection/BillingSection.styled";
import React from "react";

export const BillingSectionSkeleton = () => (
  <StyledLoadingContainer>
    <Skeleton />
    <Skeleton />
    <Skeleton $width="10em" $height="3em" style={{ margin: "0 auto" }} />
    <Skeleton $height="3em" $rounded />
    <Skeleton $height="3em" $rounded />
  </StyledLoadingContainer>
);
