import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseFetchSinglePaymentMethodQueryProps {
  enabled: boolean;
  paymentMethodId: string;
  premiseId: IdType;
}

export const useFetchSinglePaymentMethodQuery = ({
  enabled,
  premiseId,
  paymentMethodId,
}: UseFetchSinglePaymentMethodQueryProps) => {
  const query = useQuery({
    enabled,
    queryFn: () =>
      premiseApi.fetchPaymentMethod({ paymentMethodId, premiseId }),
    queryKey: PremiseQueryKeys.fetchPaymentMethod(premiseId, paymentMethodId),
    select: (data) => new PaymentMethod(data),
  });

  return query;
};
