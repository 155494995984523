import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ProductContractTosLink } from "@portal-shared/components/ProductContractTosLink/ProductContractTosLink";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import {
  StyledLegalDocIcon,
  StyledLegalDocIconContainer,
  StyledLegalDocTitle,
  StyledLegalDocumentBody,
  StyledLegalDocumentWrapper,
} from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.styled";
import React from "react";

interface MyAccountAddOnsLegalDocumentsProps {
  tosUrl: string;
}

const TosLinks = ({ tosUrl }: { tosUrl: string }) => {
  const { t } = useRhIntl();
  const tosTitle = t("MyAccountAddOnsLegalDocuments.tos.title");
  const trackingLocation = "My Account Add-ons Legal Documents Section";

  return (
    <>
      <StyledLegalDocIconContainer>
        <ProductContractTosLink
          dataTrackingClick={{ location: trackingLocation }}
          tosUrl={tosUrl}
        >
          <StyledLegalDocIcon title={tosTitle} />
        </ProductContractTosLink>
      </StyledLegalDocIconContainer>
      <div>
        <ProductContractTosLink
          dataTrackingClick={{ location: trackingLocation }}
          tosUrl={tosUrl}
        >
          <RhTypography variant="body2">{tosTitle}</RhTypography>
        </ProductContractTosLink>
      </div>
    </>
  );
};

export const MyAccountAddOnsLegalDocuments = ({
  tosUrl,
}: MyAccountAddOnsLegalDocumentsProps) => {
  const { t } = useRhIntl();

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalShowViewTosLink } = featureFlagClient.getFlags([
    ["portalShowViewTosLink", false],
  ]);
  const shouldShowTosLink = portalShowViewTosLink.value;

  return (
    <StyledLegalDocumentWrapper>
      <StyledLegalDocTitle>
        <RhTypography variant="subtitle2" fontWeight="Bold">
          {t("MyAccountAddOnsLegalDocuments.yourDocuments")}
        </RhTypography>
      </StyledLegalDocTitle>

      <StyledLegalDocumentBody>
        {shouldShowTosLink ? (
          <TosLinks tosUrl={tosUrl} />
        ) : (
          <RhTypography variant="body2" color="textSecondary">
            {t("MyAccountAddOnsLegalDocuments.availableSoon")}
          </RhTypography>
        )}
      </StyledLegalDocumentBody>
    </StyledLegalDocumentWrapper>
  );
};
