import { RhCheckboxField } from "@design-system/components/RhCheckboxField/RhCheckboxField";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { borders } from "@design-system/constants/borders";
import { red, trueWhite } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import {
  LOGGED_OUT_CONTAINER_SPACING,
  LOGGED_OUT_FIELD_SPACING,
} from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import styled from "styled-components";

export const StyledSignUpSummaryPageWrapper = styled.div`
  padding: 0 1rem;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    max-width: 500px;
    padding: 0;
  }
`;

interface StyledFormWrapperProps {
  $hasSubmitErrors: boolean;
}

export const StyledFormWrapper = styled.form<StyledFormWrapperProps>`
  border: 2px solid transparent;
  border-color: transparent;
  border-color: ${({ $hasSubmitErrors }) =>
    $hasSubmitErrors ? red[700] : "transparent"};

  border-radius: 10px;
  margin: 0 ${rhSpacingPx(-2)};
  padding: ${rhSpacingPx(2)};
  position: relative;
`;

export const StyledByCheckingThisBox = styled(RhFlexBox)`
  align-items: flex-start;
`;

export const StyledCheckbox = styled(RhCheckboxField)`
  margin-right: 4px;
`;

export const StyledSignUpButtonContainer = styled.div`
  align-items: flex-start;
  background: ${trueWhite};
  border-top: ${borders[100]};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 0.75em 2em 1em;
  position: fixed;
  width: 100%;
  z-index: 999;
`;

export const StyledAgreeToTerms = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  margin: 0 auto;
  max-width: 300px;
  padding-bottom: ${rhSpacingPx(1)};

  & > div:first-of-type {
    height: 24px;
  }
`;

export const LoadingContainer = styled.div`
  margin-top: ${rhSpacingPx(3)};
`;

export const AllRightsReservedContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${rhSpacingPx(3)};
  max-width: 250px;
  text-align: left;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    max-width: 100%;
    text-align: center;
  }
`;

export const SimpliSafeContainer = styled.div`
  margin-top: ${rhSpacingPx(1)};
`;

export const SignUpSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
  margin-top: ${rhSpacingPx(LOGGED_OUT_CONTAINER_SPACING)};
`;
