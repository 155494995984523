export const oneClickRenewalSummaryFormTranslations = {
  tOneClickRenewalSummaryFormAutoPayDescription:
    "Turn on Auto Pay and enjoy hassle-free bill payments.",
  tOneClickRenewalSummaryFormAutoPayDisabledWarning:
    "Turn on Auto Pay & Paperless or you'll lose {discountAmount}/month discount and as a result the average price/kWh you pay for electricity will go up.",
  tOneClickRenewalSummaryFormAutoPayLabel: "Auto Pay",
  tOneClickRenewalSummaryFormAutoPayPaymentMethod:
    "We'll use payment method ending in {maskedNumber} for Auto Pay. You can update your payment method anytime in My Account.",
  tOneClickRenewalSummaryFormBillingPreferences: "Billing Preferences",
  tOneClickRenewalSummaryFormConfirmPaperlessDisabled:
    "Are you sure you want to turn off Paperless?",
  tOneClickRenewalSummaryFormError:
    "We are having issues renewing your contract. Please try again",
  tOneClickRenewalSummaryFormNoDiscountMessage:
    "You'll miss out on your {discountAmount} monthly discount and the average price/kWh you pay for electricity will go up.",
  tOneClickRenewalSummaryFormNoPaymentMethod:
    "We don't have a payment method on file. Please add a new payment method in My Account before you turn on Auto Pay.",
  tOneClickRenewalSummaryFormPaperless: "Paperless",
  tOneClickRenewalSummaryFormPaperlessDescription:
    "No paper. Good for the environment. Good for you.",
  tOneClickRenewalSummaryFormPaperlessLegalText:
    "I agree to receive my welcome packet, bills, contract documents, and contract-related notices (including my contract expiration notices and disconnect notices) via email. I understand I won't receive my bills via regular mail. I confirm the email address I provided is valid.",
  tOneClickRenewalSummaryFormPastDueWarning:
    "You will be charged your total past due balance today. To see the total amount due, please visit the Pay Bill page in your My Account.",
  tOneClickRenewalSummaryFormSignup: "Sign up for Contract",
  tOneClickRenewalSummaryFormSubmitting: "Submitting...",
  tOneClickRenewalSummaryFormTerms:
    "By checking this box, you agree to establish Rhythm as your retail electric provider, and you agree to the <tos>Terms of Service</tos>, <yrac>Your Rights as a Customer</yrac>, and <efl>Electricity Facts Label</efl> documents associated with your plan. You also authorize Rhythm to switch, establish, or change your service.",
};
