import { IdType } from "@common/types/apiTypes";

const INVOICE_BASE_URL = "/api/portal/invoices";

export const invoiceUrls = {
  compare: (invoiceId: IdType) =>
    `${invoiceUrls.retrieve(invoiceId)}/bill-explanation/compare`,
  factors: (invoiceId: IdType) =>
    `${invoiceUrls.retrieve(invoiceId)}/bill-explanation/factors`,
  orders: (invoiceId: IdType) => `${INVOICE_BASE_URL}/${invoiceId}/orders`,
  retrieve: (invoiceId: IdType) => `${INVOICE_BASE_URL}/${invoiceId}`,
  temperature: (invoiceId: IdType) =>
    `${invoiceUrls.retrieve(invoiceId)}/bill-explanation/temperature`,
  usage: (invoiceId: IdType) =>
    `${invoiceUrls.retrieve(invoiceId)}/bill-explanation/usage`,
};
