export enum PaymentMethodTypes {
  ACH = "ACH",
  CASH = "Cash",
  CHECK = "Check",
  CreditCard = "CreditCard",
}

export interface CreditCardRetrieveType {
  creditCardExpirationMonth: number;
  creditCardExpirationYear: number;
  creditCardMaskNumber: string;
  creditCardType: string;
  type: PaymentMethodTypes.CreditCard;
}

export interface ACHRetrieveType {
  achAccountNumberMask: string;
  achBankName: string;
  type: PaymentMethodTypes.ACH;
}

export type PaymentMethodRetrieveType =
  | CreditCardRetrieveType
  | ACHRetrieveType;
