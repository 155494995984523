import { RhButton } from "@design-system/components/RhButton/RhButton";
import { ReactComponent as LeftChevron } from "@design-system/icons/LeftChevron.svg";
import { SvgIcon } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

interface AddOnsOptInBackButtonProps {
  onClick: () => void;
}
const chevron = (
  <SvgIcon
    aria-hidden
    viewBox="5 0 47 90"
    fill="none"
    component={LeftChevron}
    fontSize="inherit"
  />
);

export const AddOnsOptInBackButton = ({
  onClick,
}: AddOnsOptInBackButtonProps) => {
  const { formatMessage } = useIntl();

  const backText = formatMessage({ id: "addOnsBackButtonText" });

  return (
    <RhButton
      data-tracking-click={{
        event: "Customer going back to previous step in Product addon flow",
      }}
      aria-label={backText}
      role="link"
      onClick={onClick}
      color="primary"
      variant="text"
      startIcon={chevron}
    >
      {backText}
    </RhButton>
  );
};
