import { useOktaAuth } from "@okta/okta-react";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { usePremise } from "@portal/hooks/usePremise";
import { useEffect } from "react";

export enum SwitchEBillToPaperlessModalStates {
  HIDDEN = "hidden",
  SHOW = "show",
}

export const useSwitchEBillToPaperlessModal = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated = false } = authState || {};
  const { premise } = usePremise();

  const {
    cookies: { rhSwitchEBillToPaperlessModal },
  } = usePortalCookies();

  const { addModal } = useModals();

  useEffect(() => {
    if (!isAuthenticated || !premise) {
      return;
    }

    if (
      premise.isOnLegacyEBill &&
      rhSwitchEBillToPaperlessModal === SwitchEBillToPaperlessModalStates.SHOW
    ) {
      addModal({
        switchEBillToPaperless: {
          premiseId: premise.id,
        },
      });
    }
  }, [addModal, isAuthenticated, premise, rhSwitchEBillToPaperlessModal]);

  return null;
};
