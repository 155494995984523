import { IdType, UUIDType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { ProductContractQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProductContractClaimOptions = (
  premiseId: IdType,
  productContractId: UUIDType
) => {
  return useQuery({
    queryFn: () =>
      portalApi.premise.productContract.claims.listOptions({
        premiseId,
        productContractId,
      }),
    queryKey: ProductContractQueryKeys.claims(premiseId, productContractId),
  });
};
