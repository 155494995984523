import { Grid } from "@mui/material";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { AccountHistoryPanel } from "@portal/components/AccountHistoryPanel/AccountHistoryPanel";
import { AdditionalServicesSection } from "@portal/components/AdditionalServicesSection/AdditionalServicesSection";
import { AtAGlanceSection } from "@portal/components/AtAGlanceSection/AtAGlanceSection";
import { BillingSection } from "@portal/components/BillingSection/BillingSection";
import { CustomerRewardsSection } from "@portal/components/CustomerRewardsSection/CustomerRewardsSection";
import { EnergyUsageSectionHandler } from "@portal/components/EnergyUsageSectionHandler/EnergyUsageSectionHandler";
import { ReferAFriendSection } from "@portal/components/ReferAFriendSection/ReferAFriendSection";
import { YourCurrentPlanSection } from "@portal/components/YourCurrentPlanSection/YourCurrentPlanSection";
import { usePremise } from "@portal/hooks/usePremise";
import { CustomerHomeNewSignupSection } from "@portal/pages/CustomerHomePage/CustomerHomeNewSignupSection/CustomerHomeNewSignupSection";
import { customerHomePageTranslations } from "@portal/pages/CustomerHomePage/CustomerHomePage.en.i18n";
import {
  StyledBillingSection,
  StyledGridContainer,
  StyledStickySection,
} from "@portal/pages/CustomerHomePage/CustomerHomePage.styled";
import { CustomerHomeRenewalSection } from "@portal/pages/CustomerHomePage/CustomerHomeRenewalSection/CustomerHomeRenewalSection";
import { Show500Page } from "@portal/utils/errors";
import React from "react";
import { Helmet } from "react-helmet-async";

export const CustomerHomePage = () => {
  const { translate } = useTranslations();

  const { tCustomerHomePageRhythmEnergy, tCustomerHomePagePageTitle } =
    translate(customerHomePageTranslations);

  const {
    premise,
    requestMonitor: premiseRequestMonitor,
    error: premiseError,
  } = usePremise();

  if (premiseRequestMonitor.didFail) {
    let message = "Could not fetch customer, account summary, and premise";

    if (premiseError) {
      message = `Could not fetch premise : ${premiseError.data?.errorCode}`;
    }
    throw new Show500Page(message);
  }

  // NOTE: It is unlikely this will *ever* be true.  It does make typescript happy.
  // We discussed messaging Sentry if we ever did get here but is that even worth it?
  if (!premise) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {`${tCustomerHomePageRhythmEnergy} - ${tCustomerHomePagePageTitle}`}
        </title>
      </Helmet>
      <PortalPageLayout>
        <StyledGridContainer container spacing={3} direction="row">
          <Grid item xs={12} md={8} sm={8}>
            {premise.inRenewalWindow ? (
              <CustomerHomeRenewalSection />
            ) : (
              <CustomerHomeNewSignupSection />
            )}
            <StyledBillingSection>
              <StyledStickySection>
                <BillingSection />
                <AdditionalServicesSection />
              </StyledStickySection>
            </StyledBillingSection>
            {premise.currentOrder && (
              <YourCurrentPlanSection
                order={premise.currentOrder}
                premise={premise}
              />
            )}
            <AtAGlanceSection />
            <CustomerRewardsSection />
            <EnergyUsageSectionHandler />
            <ReferAFriendSection />
            <AccountHistoryPanel />
          </Grid>
        </StyledGridContainer>
      </PortalPageLayout>
    </>
  );
};
