import {
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
} from "@design-system/components/RhCard/RhCard.styles";
import { CardProps } from "@mui/material";
import classNames from "classnames";
import React, { PropsWithChildren, ReactNode } from "react";

interface RhCardProps {
  className?: string;
  headerClassName?: string;
  noShadow?: boolean;
  subheader?: ReactNode;
  title?: ReactNode;
}

export const RhCard = React.forwardRef(
  (
    {
      children,
      className = "",
      headerClassName = "",
      noShadow = false,
      subheader = null,
      title = null,
      ...rest
    }: PropsWithChildren<RhCardProps> & Omit<CardProps, "title">,
    ref
  ) => {
    return (
      <StyledCard
        className={className}
        elevation={0}
        {...rest}
        $noShadow={noShadow}
      >
        {title && (
          <StyledCardHeader
            className={classNames(headerClassName)}
            title={title}
            subheader={subheader}
          />
        )}
        <StyledCardContent>{children}</StyledCardContent>
      </StyledCard>
    );
  }
);
