import { RhSelect } from "@design-system/components/RhSelect/RhSelect.styled";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  RH_HEADER_HEIGHT,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledBillBreakdownPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  justify-content: center;
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-direction: row;
    gap: ${rhSpacingPx(5)};
    text-align: start;
  }
`;

export const BillBreakdownForContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-direction: row;
    gap: ${rhSpacingPx(1.5)};
  }
`;

export const StyledRhSelect = styled(RhSelect)`
  padding: ${rhSpacingPx(1)};
  width: 230px;
`;

export const StyledStickySection = styled.div`
  @media (min-width: ${RhythmBreakpoints.SM}px) {
    height: 100%;
    position: sticky;
    top: ${rhSpacingPx(RH_HEADER_HEIGHT + 2.5)};

    & > div {
      max-width: 350px;
    }
  }
`;

export const BillBreakdownPageMainSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    width: 730px;
  }
`;
