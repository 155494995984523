export const myAccountPasswordChangeTranslations = {
  tMyAccountPasswordChangeConfirmNewPassword: "Retype New Password",
  tMyAccountPasswordChangeCurrentPassword: "Current Password",
  tMyAccountPasswordChangeMinimumRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  tMyAccountPasswordChangeNewPassword: "New Password",
  tMyAccountPasswordChangeNewPasswordsMustMatch: "New Passwords must match.",
  tMyAccountPasswordChangeNonOktaErrorUpdatingPassword:
    "We are having issues updating your password. Please try again.",
  tMyAccountPasswordChangeNotNewPassword:
    "New Password must be different from the old password",
  tMyAccountPasswordChangeOktaRequestFailed:
    "Does not meet the requirements of the password policy.",
  tMyAccountPasswordChangeSubmitButtonCta: "Save",
  tMyAccountPasswordChangeSuccess: "Password updated successfully",
  tMyAccountPasswordChangeTitle: "Change Password",
};
