import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledCommMediumContainer = styled.div`
  margin-bottom: ${rhSpacingPx(5)};
`;

export const StyledCommMediumHeader = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
`;

export const StyledNotificationsOptionRow = styled.div`
  align-items: center;
  border-top: ${borders[100]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${rhSpacingPx(2)} 0;

  &:last-child {
    border-bottom: ${borders[100]};
  }
`;

export const StyledNotificationsOptionText = styled(RhTypography)`
  &.MuiTypography-root {
    margin-right: ${rhSpacingPx(1.5)};
  }
`;
