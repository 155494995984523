import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import { SentryRoutes } from "@portal-shared/components/SentryRoutes/SentryRoutes";
import { EnrollIndexPage } from "@portal/pages/Enroll/EnrollIndexPage/EnrollIndexPage";
import { SignUpBillingPreferencesPage } from "@portal/pages/SignUpBillingPreferencesPage/SignUpBillingPreferencesPage";
import { SignUpConfirmAddressPage } from "@portal/pages/SignUpConfirmAddressPage/SignUpConfirmAddressPage";
import { SignUpContactPage } from "@portal/pages/SignUpContactPage/SignUpContactPage";
import { SignUpDepositRequiredPage } from "@portal/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage";
import { SignUpEnrollmentPage } from "@portal/pages/SignUpEnrollmentPage/SignUpEnrollmentPage";
import { SignUpNamePage } from "@portal/pages/SignUpNamePage/SignUpNamePage";
import { SignUpPaymentPage } from "@portal/pages/SignUpPaymentPage/SignUpPaymentPage";
import { SignUpPremisePage } from "@portal/pages/SignUpPremisePage/SignUpPremisePage";
import { SignUpProductTiersPage } from "@portal/pages/SignUpProductTiersPage/SignUpProductTiersPage";
import { SignUpProvideAdditionalInformationPage } from "@portal/pages/SignUpProvideAdditionalInformationPage/SignUpProvideAdditionalInformationPage";
import { SignUpReviewRequiredPage } from "@portal/pages/SignUpReviewRequiredPage/SignUpReviewRequiredPage";
import { SignUpSSNRequiredPage } from "@portal/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage";
import { SignUpSummaryPage } from "@portal/pages/SignUpSummaryPage/SignUpSummaryPage";
import React, { FC } from "react";
import { Route, useRoutes } from "react-router-dom";

const signUpSteps: {
  component: FC<React.PropsWithChildren<unknown>>;
  step: SignUpStepType;
}[] = [
  { component: SignUpReviewRequiredPage, step: "call-us" },
  {
    component: SignUpProvideAdditionalInformationPage,
    step: "provide-additional-information",
  },
  { component: SignUpNamePage, step: "name" },
  { component: SignUpContactPage, step: "contact" },
  { component: SignUpPremisePage, step: "address" },
  { component: SignUpConfirmAddressPage, step: "confirm-address" },
  { component: SignUpEnrollmentPage, step: "details" },
  { component: SignUpBillingPreferencesPage, step: "billing-preferences" },
  { component: SignUpPaymentPage, step: "payment" },
  { component: SignUpSSNRequiredPage, step: "credit-check" },
  { component: SignUpDepositRequiredPage, step: "deposit" },
  { component: SignUpSummaryPage, step: "summary" },
  { component: SignUpProductTiersPage, step: "add-ons" },
];

export const SignUpRoutes = () => {
  const routes = useRoutes(
    signUpSteps.map(({ step, component: Component }) => ({
      element: <Component />,
      path: step,
    }))
  );

  return (
    <SentryRoutes>
      <Route index element={<EnrollIndexPage />} />
      <Route path="*" element={routes} />
    </SentryRoutes>
  );
};
