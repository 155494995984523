import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import { GuestAndQuickPayPaymentAmountFields } from "@portal-guest/components/GuestAndQuickPayPaymentAmountFields/GuestAndQuickPayPaymentAmountFields";
import { GuestPayAddOrGetPaymentMethod } from "@portal-guest/components/GuestPayAddOrGetPaymentMethod/GuestPayAddOrGetPaymentMethod";
import { useGuestPayFormCreatePayment } from "@portal-guest/components/GuestPayForm/useGuestPayFormCreatePayment";
import { useGuestPayDeletePaymentMethodMutation } from "@portal-guest/mutations/useGuestPayDeletePaymentMethodMutation";
import { useGuestPayPaymentMethodQuery } from "@portal-guest/queries/useGuestPayPaymentMethodQuery";
import { guestPayBasePath } from "@portal-guest/routes/routePaths";
import {
  ActionType,
  GuestEvents,
  track,
} from "@portal-guest/services/segment.service";
import { GuestAndQuickPayFormValues } from "@portal-guest/types/GuestAndQuickPayTypes";
import { PaymentMethodRadioInput } from "@portal-shared/components/PaymentMethodRadioInput/PaymentMethodRadioInput";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled.form`
  width: 350px;
`;

const RestartFlowLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

const PaymentMethodContainer = styled.fieldset`
  border: unset;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  margin: ${rhSpacingPx(4)} 0 ${rhSpacingPx(2)} 0;
  padding: unset;

  & > legend {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

const useGuestPayFormTranslations = () => {
  const { t, tJsx } = useRhIntl();

  return {
    tFailureDeletingPaymentMethod: tJsx(
      "GuestPayForm.failureDeletingPaymentMethod",
      {
        link: (message) => (
          <RestartFlowLink to={guestPayBasePath()}>{message}</RestartFlowLink>
        ),
      }
    ),
    tPayFrom: t("GuestPayForm.payFrom"),
    tPaymentError: t("QuestPayForm.paymentError"),
    tSubmitPayment: t("GuestPayForm.submitPayment"),
    tSubmittingPayment: t("GuestPayForm.submittingPayment"),
  };
};

const getPaymentAmount = (data: GuestAndQuickPayFormValues) => {
  const { paymentType: submittedPaymentType } = data;

  if (submittedPaymentType === "total") {
    return data.totalBalance;
  }
  if (submittedPaymentType === "pastDue") {
    return data.pastDueBalance;
  }
  if (submittedPaymentType === "custom") {
    return data.customAmount;
  }
  throw new Error(
    "Unable to determine the Guest Pay payment amount from the form"
  );
};

interface GuestPayFormProps {
  isNoCheckOrAch: boolean;
  isNoCreditOrDebitCard: boolean;
  pastDueBalance: string;
  token: string;
  totalBalance: string;
}

export const GuestPayForm = (props: GuestPayFormProps) => {
  const {
    totalBalance,
    pastDueBalance,
    token,
    isNoCheckOrAch,
    isNoCreditOrDebitCard,
  } = props;
  const flash = useRhFlash();
  const {
    tFailureDeletingPaymentMethod,
    tPayFrom,
    tSubmitPayment,
    tSubmittingPayment,
  } = useGuestPayFormTranslations();
  const { createPayment, isPaying } = useGuestPayFormCreatePayment();
  const guestPayPaymentMethodQuery = useGuestPayPaymentMethodQuery({
    token,
  });

  const guestPayDeletePaymentMethodMutation =
    useGuestPayDeletePaymentMethodMutation();

  const paymentMethod = guestPayPaymentMethodQuery.isSuccess
    ? guestPayPaymentMethodQuery.data.paymentMethod
    : null;

  const formMethods = useForm<GuestAndQuickPayFormValues>({
    defaultValues: {
      customAmount: "",
      pastDueBalance,
      paymentType: "total",
      selectedPayment: paymentMethod?.id,
      totalBalance,
    },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid },
    watch,
  } = formMethods;

  useEffect(() => {
    if (paymentMethod?.id) {
      setValue("selectedPayment", paymentMethod?.id);
    }
  }, [paymentMethod?.id, setValue]);

  watch("customAmount");
  watch("paymentType");

  const onSubmit = handleSubmit((data) => {
    track({
      action: ActionType.click,
      event: GuestEvents.quickPay,
      label: "Guest Pay payment submitted",
    });
    const amount = getPaymentAmount(data);

    createPayment({ amount, token });
  });

  const amount = Number(getPaymentAmount(getValues()));

  const submitDisabled = amount < 1 || isPaying || !isValid || !paymentMethod;

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={onSubmit}>
        <GuestAndQuickPayPaymentAmountFields />
        {paymentMethod && (
          <PaymentMethodContainer>
            <RhTypography variant="subtitle1" component="legend">
              {tPayFrom}
            </RhTypography>
            <PaymentMethodRadioInput
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              isDeleting={guestPayDeletePaymentMethodMutation.isPending}
              onDeleteClick={() => {
                if (!guestPayDeletePaymentMethodMutation.isPending) {
                  guestPayDeletePaymentMethodMutation.mutate(
                    {
                      token,
                    },
                    {
                      onError: (error) => {
                        flash.error(<div>{tFailureDeletingPaymentMethod}</div>);
                      },
                    }
                  );
                }
              }}
            />
          </PaymentMethodContainer>
        )}
        <GuestPayAddOrGetPaymentMethod
          token={token}
          isNoCheckOrAch={isNoCheckOrAch}
          isNoCreditOrDebitCard={isNoCreditOrDebitCard}
        />

        <RhButton
          data-tracking-click={{
            event: "Guest Pay payment submitted",
          }}
          type="submit"
          color="primary"
          disabled={submitDisabled}
          fullWidth
        >
          {isPaying ? (
            <>
              <StyledCircularProgress color="inherit" size={14} />
              {tSubmittingPayment}
            </>
          ) : (
            tSubmitPayment
          )}
        </RhButton>
      </StyledForm>
    </FormProvider>
  );
};
