import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const FeatureList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const FeatureItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};

  & svg {
    height: 20px;
    width: 20px;
  }
`;
