import { PremiseStatus } from "@common/enums/premise.enum";
import { Premise } from "@common/models/Premise.model";
import { PremiseListItemType } from "@common/types/premiseTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import {
  StyledCheckIcon,
  StyledMapPinIcon,
  StyledPortalSelectorListItem,
  StyledPortalSelectorStatusIndicator,
  StyledPortalSelectorStatusText,
  StyledPortalSelectorSubtitle,
  StyledPortalSelectorTitle,
  StyledPortalSelectorTitleContainer,
} from "@portal/components/PortalSelector/PortalSelector.styled";
import { premiseSelectorTranslations } from "@portal/components/PremiseSelector/PremiseSelector.en.i18n";
import { premiseStatusColor } from "@portal/helpers/premiseStatusColor";
import React from "react";

interface PremiseProps {
  isInMenu?: boolean;
  isSelectedPremise?: boolean;
  premise: Premise | PremiseListItemType;
  showMapIcon?: boolean;
}

export const PremiseListItem = (props: PremiseProps) => {
  const { premise, showMapIcon, isSelectedPremise, isInMenu } = props;
  const { translate } = useTranslations();
  const {
    tPremiseSelectorActive,
    tPremiseSelectorInactive,
    tPremiseSelectorPending,
    tPremiseSelectorAccountNumber,
  } = translate(premiseSelectorTranslations);

  const premiseState = (status: PremiseStatus) => {
    if (status === PremiseStatus.ACTIVE) {
      return tPremiseSelectorActive;
    }

    if (status === PremiseStatus.PENDING) {
      return tPremiseSelectorPending;
    }

    return tPremiseSelectorInactive;
  };

  return (
    <StyledPortalSelectorListItem>
      {!isInMenu ? (
        <StyledPortalSelectorStatusIndicator
          $statusColor={premiseStatusColor(premise.status)}
          data-testid="PremiseSelector__StatusIndicator"
        />
      ) : null}

      {showMapIcon ? (
        <StyledMapPinIcon data-testid="PremiseSelector__MapIcon" />
      ) : null}

      <StyledPortalSelectorTitleContainer>
        <StyledPortalSelectorTitle $isSelected={Boolean(isSelectedPremise)}>
          {premise.serviceAddress?.deliveryLine1}
        </StyledPortalSelectorTitle>

        <StyledPortalSelectorSubtitle>
          {tPremiseSelectorAccountNumber} | {premise.billingAccountNumber}
        </StyledPortalSelectorSubtitle>

        {isInMenu ? (
          <StyledPortalSelectorStatusText
            $statusColor={premiseStatusColor(premise.status)}
          >
            {premiseState(premise.status)}
          </StyledPortalSelectorStatusText>
        ) : null}
      </StyledPortalSelectorTitleContainer>

      {isSelectedPremise && isInMenu ? <StyledCheckIcon /> : null}
    </StyledPortalSelectorListItem>
  );
};
