import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledMyAccountContactFields = styled.div`
  & > *,
  .MuiFormControl-root {
    padding-bottom: ${rhSpacingPx(2.5)};
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    & > *,
    .MuiFormControl-root {
      flex-basis: 50%;
      flex-grow: initial;
      padding-right: ${rhSpacingPx(2)};
    }
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledMyAccountContactLabel = styled.div`
  color: ${CSSVariables.COLOR_GREY_400};
  font-weight: ${FontWeight.Medium};
  position: absolute;
`;

export const StyledMyAccountContactReadOnlyValue = styled.div`
  color: ${CSSVariables.COLOR_GREY_400};
  font-size: 16px;
  margin-top: ${rhSpacingPx(3)};
  padding-bottom: ${rhSpacingPx(1.5)};
`;
