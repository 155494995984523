import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  DIALOG_BUTTON_WIDTH,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { DialogContent } from "@mui/material";
import styled from "styled-components";

export const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 8px 24px;
  }
`;

export const StyledButtonsContainer = styled(RhFlexBox)`
  @media (max-width: ${RhythmBreakpoints.XS}px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const StyledDialogButton = styled(RhButton)`
  &.MuiButton-root {
    margin-bottom: ${rhSpacingPx(1.5)};
    width: 100%;

    @media (min-width: ${RhythmBreakpoints.XS}px) {
      margin-bottom: 0;
      width: ${DIALOG_BUTTON_WIDTH}px;
    }
  }
`;
