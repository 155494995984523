import "jotai-devtools/styles.css";

import { ReactQueryDevTools } from "@common/components/ReactQueryDevTools";
import { Version } from "@common/components/Version/Version";
import { DevTools as JotaiDevTools } from "jotai-devtools";
import React from "react";

export const PortalDevTools = () => {
  return (
    <>
      <Version />
      <ReactQueryDevTools />
      <JotaiDevTools />
    </>
  );
};
