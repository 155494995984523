export const getLocale = (possibleLocale: string | null) => {
  if (!possibleLocale) {
    return "en";
  }
  if (possibleLocale !== "es" && possibleLocale !== "en") {
    return "en";
  }

  return possibleLocale;
};
