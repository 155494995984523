import { IdType } from "@common/types/apiTypes";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@portal-shared/constants/queryOptions.constants";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillForecastSummaryProps {
  premiseId: IdType;
}
export const useBillForecastSummary = (props: UseBillForecastSummaryProps) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.billForecastSummary(premiseId),
    queryKey: PremiseQueryKeys.billForecastSummary(premiseId),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });
};
