import { Order } from "@common/models/Order.model";
import { oneClickRenewalApi } from "@portal-guest/api/oneClickRenewalApi";
import { OneClickRenewalQueryKeys } from "@portal-guest/queryKeys/OneClickRenewalQueryKeys";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { useQuery } from "@tanstack/react-query";

interface UseOneClickRenewalQueryProps {
  enabled: boolean;
  renewalToken: string;
}

export const useOneClickRenewalQuery = ({
  enabled,
  renewalToken,
}: UseOneClickRenewalQueryProps) => {
  const query = useQuery({
    enabled,
    queryFn: () => oneClickRenewalApi.retrieve(renewalToken),
    queryKey: OneClickRenewalQueryKeys.oneClickRenewal(renewalToken),
    select: (data) => ({
      ...data,
      futureOrder: data.futureOrder ? new Order(data.futureOrder) : null,
      order: new Order(data.order),
      orderOfferSnapshot: new OfferSnapshot(data.orderOfferSnapshot),
    }),
  });

  return query;
};
