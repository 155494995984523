import { formatCurrency } from "@common/utils/dataFormatters";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  Container,
  StyledReconnectionMessaging,
} from "@portal-guest/components/GuestAndQuickPayConfirmation/GuestAndQuickPayConfirmation.styled";
import { ReactComponent as CheckLoungeChair } from "@portal-guest/images/CheckLoungeChair.svg";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { signInPath } from "@portal-shared/routes/routePaths";
import React from "react";

interface GuestAndQuickPayConfirmationProps {
  confirmationNumber: string;
  isDisconnected: boolean;
  remainingBalance: string;
}

export const GuestAndQuickPayConfirmation = (
  props: GuestAndQuickPayConfirmationProps
) => {
  const { confirmationNumber, isDisconnected, remainingBalance } = props;

  const { t, tJsx } = useRhIntl();

  return (
    <Container>
      <CheckLoungeChair />
      <RhTypography variant="h1">
        {t("GuestAndQuickPayConfirmation.title")}
      </RhTypography>
      <RhTypography variant="subtitle2" color="textSecondary">
        {tJsx("GuestAndQuickPayConfirmation.confirmation", {
          confirmationNumber,
        })}
      </RhTypography>
      <RhTypography variant="subtitle2" color="textSecondary">
        {tJsx("GuestAndQuickPayConfirmation.remainingBalance", {
          remainingBalance: formatCurrency(remainingBalance),
        })}
      </RhTypography>
      {isDisconnected && Number(remainingBalance) <= 0 ? (
        <StyledReconnectionMessaging>
          <RhTypography variant="subtitle2" color="textSecondary">
            {t("GuestAndQuickPayConfirmation.reconnectionRequestSent")}
          </RhTypography>
          <RhTypography variant="subtitle2" color="textSecondary">
            {t("GuestAndQuickPayConfirmation.reconnectionUpdates")}
          </RhTypography>
          <RhTypography
            variant="subtitle2"
            color="textSecondary"
            fontWeight={FontWeight.Semibold}
          >
            {t("GuestAndQuickPayConfirmation.reconnectionNoContactNeeded")}
          </RhTypography>
        </StyledReconnectionMessaging>
      ) : null}
      <RhLinkButton
        data-tracking-click={{ event: "Customer going to my account" }}
        href={signInPath()}
        color="primary"
      >
        {t("GuestAndQuickPayConfirmation.backToHome")}
      </RhLinkButton>
    </Container>
  );
};
