import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { boxShadows } from "@design-system/constants/boxShadows";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

interface PortalCardProps {
  $noMargin?: boolean;
  $noShadow?: boolean;
}

export const PortalCard = styled.div<PortalCardProps>`
  background-color: ${CSSVariables.COLOR_WHITE};
  border: 1px solid ${CSSVariables.COLOR_GREY_100};
  border-radius: 6px;
  box-shadow: ${(props) => (props.$noShadow ? "none" : boxShadows.default)};
  color: ${CSSVariables.COLOR_GREY_900};
  margin-right: ${(props) => (props.$noMargin ? 0 : rhSpacingPx(2.5))};
  overflow: hidden;
  padding: ${rhSpacingPx(2.5)};
  width: 100%;
`;

export const PortalCardHeader = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(0.5)};
  justify-content: flex-start;
  margin-bottom: ${rhSpacingPx(2)};
  padding-bottom: ${rhSpacingPx(2)};
`;

export const PortalCardTitle = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 16px;
    font-weight: ${FontWeight.Bold};
  }
`;

export const PortalCardSubheader = styled(RhTypography).attrs({
  variant: "body2",
})`
  &.MuiTypography-root {
    font-size: 14px;
  }
`;
