import { borders } from "@design-system/constants/borders";
import { theme } from "@design-system/theme/theme.styled";
import styled, { css } from "styled-components";

interface CartMobileContainerProps {
  cartOpen: boolean;
}

export const CartMobileContainer = styled.div<CartMobileContainerProps>`
  align-items: center;
  background-color: ${theme.palette.white};
  border-top: ${borders[100]};
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  left: 0;
  min-height: 75px;
  position: fixed;
  width: 100%;
  z-index: 99999;

  ${({ cartOpen }) =>
    cartOpen &&
    css`
      box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.1);
    `}
`;
