import { UUIDType } from "@common/types/apiTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PortalPriceScheduleBreakdown } from "@portal-shared/components/PortalPriceScheduleBreakdown/PortalPriceScheduleBreakdown";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import { EnergyBreakdownModalTranslations } from "@portal/components/EnergyBreakdownModal/EnergyBreakdownModal.en.i18n";
import {
  ModalContainer,
  StyledRhModal,
} from "@portal/components/EnergyBreakdownModal/EnergyBreakdownModal.styled";
import React from "react";

interface EnergyBreakdownModalProps {
  handleClose(): void;
  offerSnapshotUuid: UUIDType;
}

export const EnergyBreakdownModal = (props: EnergyBreakdownModalProps) => {
  const { offerSnapshotUuid, handleClose } = props;
  const { translate } = useTranslations();

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({ offerSnapshotUuid });

  if (offerSnapshotQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (offerSnapshotQuery.isError) {
    handleClose();
    return null;
  }

  const { data: offerSnapshot } = offerSnapshotQuery;

  if (!offerSnapshot.priceTierSnapshots) {
    handleClose();
    return null;
  }

  const {
    tEnergyBreakdownModalCloseButton,
    tEnergyBreakdownModalAriaLabel,
    tEnergyBreakdownModalAriaLabelClose,
    tEnergyBreakdownModalEnergySolarPriceBreakdown,
    tEnergyBreakdownModalEnergyPriceBreakdown,
  } = translate(EnergyBreakdownModalTranslations);

  return (
    <StyledRhModal
      ariaLabel={tEnergyBreakdownModalAriaLabel}
      ariaCloseLabel={tEnergyBreakdownModalAriaLabelClose}
      handleClose={handleClose}
      size={ModalWidths.auto}
      showPortalHeader={false}
    >
      <ModalContainer>
        <RhTypography variant="h4" align="center">
          {offerSnapshot.isTimeOfUseGeneration
            ? tEnergyBreakdownModalEnergySolarPriceBreakdown
            : tEnergyBreakdownModalEnergyPriceBreakdown}
        </RhTypography>
        <PortalPriceScheduleBreakdown offerSnapshot={offerSnapshot} />

        <RhButton
          data-tracking-click={{
            event: "Customer closed the Energy Breakdown modal",
          }}
          variant="contained"
          color="primary"
          size="small"
          fullWidth={false}
          onClick={handleClose}
        >
          {tEnergyBreakdownModalCloseButton}
        </RhButton>
      </ModalContainer>
    </StyledRhModal>
  );
};
