import { quickPayApi } from "@portal-guest/api/quickPayApi";
import { QuickPayQueryKeys } from "@portal-guest/queryKeys/quickPayQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useResendQuickPayTokenQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: quickPayApi.resend,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: QuickPayQueryKeys.BASE,
      });
    },
  });
};
