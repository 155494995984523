import { IdType } from "@common/types/apiTypes";
import { portalApi } from "@portal/api/api";
import { ProductContractQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UseCancelProductContractProps {
  premiseId: IdType;
}
export const useCancelProductContract = ({
  premiseId,
}: UseCancelProductContractProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: portalApi.premise.productContract.cancel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ProductContractQueryKeys.list.DEFAULT(premiseId),
      });
    },
  });
};
