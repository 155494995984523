import { isBot } from "@common/utils/isBot";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

export const useFingerPrintId = () => {
  const { data } = useVisitorData(
    { extendedResult: false },
    {
      immediate: !isBot(),
    }
  );

  return data?.visitorId;
};
