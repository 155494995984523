import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";

interface DocumentFilenameProps {
  documentType: string;
  generatedDate: string;
  premiseAccountNumber: IdType;
}

export const buildDocumentFilename = ({
  documentType,
  generatedDate,
  premiseAccountNumber,
}: DocumentFilenameProps) => {
  return `${premiseAccountNumber}-document-${documentType}-${generatedDate}.pdf`;
};

interface DocumentFileDownloadProps extends DocumentFilenameProps {
  url: string;
}

export const documentFileDownload = ({
  documentType,
  generatedDate,
  premiseAccountNumber,
  url,
}: DocumentFileDownloadProps) => {
  const filename = buildDocumentFilename({
    documentType,
    generatedDate,
    premiseAccountNumber,
  });

  return authenticatedFileDownload(url, filename, PDF_MIME_TYPE);
};
