import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const StyledForm = styled.form`
  width: 350px;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

export const StyledPaymentMethodContainer = styled.fieldset`
  border: unset;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  margin: ${rhSpacingPx(4)} 0 ${rhSpacingPx(2)} 0;
  padding: unset;

  & > legend {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const StyledPaymentButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rhSpacingPx(2)};
`;
