import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PortalCard } from "@portal-shared/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const AutoPayCard = styled(PortalCard)`
  align-items: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BalancedText = styled(RhTypography)`
  text-align: left;
  text-wrap: balance;
`;
