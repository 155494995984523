import { useOktaAuth } from "@okta/okta-react";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { usePremise } from "@portal/hooks/usePremise";
import { useEffect } from "react";

export const useRenewalReminderModal = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated = false } = authState || {};
  const { premise } = usePremise();
  const {
    cookies: { rhRenewalReminderModal },
  } = usePortalCookies();

  const { addModal } = useModals();

  useEffect(() => {
    if (!isAuthenticated || !premise) {
      return;
    }

    if (premise.inRenewalWindow && rhRenewalReminderModal === "show") {
      addModal({
        renewalReminder: true,
      });
    }
  }, [addModal, isAuthenticated, premise, rhRenewalReminderModal]);

  return null;
};
