import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledDescription = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_900};
    margin-top: ${rhSpacingPx(0.5)};
  }
`;

export const StyledPdfIconContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: ${rhSpacingPx(1.5)};
  transform: translateY(2px);
  width: 24px;
`;

export const StyledPdfIcon = styled(PDFIcon)`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
`;

export const LegalDocumentsContainer = styled(RhFlexBox)`
  gap: ${rhSpacingPx(2)};
`;

export const IconContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: ${rhSpacingPx(1.5)};
  transform: translateY(2px);
  width: 24px;
`;
