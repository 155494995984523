const quickPayBasePath = () => {
  return "/quick-pay-one-click";
};

export const quickPayPath = (token: string) => {
  return `${quickPayBasePath()}/${token}`;
};

export const quickPayConfirmationPath = () => {
  return `${quickPayBasePath()}/confirmation`;
};

export const guestPayBasePath = () => {
  return "/quick-pay";
};

export const guestPayAccountLookUpPath = () => {
  return guestPayBasePath();
};

export const guestPayAccountSummaryPath = () => {
  return `${guestPayAccountLookUpPath()}/account`;
};

export const guestPayConfirmationPath = () => {
  return `${guestPayAccountLookUpPath()}/confirmation`;
};

export const oneClickRenewalsBasePath = () => {
  return "/renew";
};

export const oneClickRenewalsPath = (renewalToken: string) => {
  return `${oneClickRenewalsBasePath()}/${renewalToken}`;
};

export const oneClickRenewalsSummaryPath = (renewalToken: string) => {
  return `${oneClickRenewalsBasePath()}/${renewalToken}/summary`;
};

export const guestBasePaths = [
  quickPayBasePath(),
  guestPayBasePath(),
  oneClickRenewalsBasePath(),
];
