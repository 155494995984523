export const signUpBillingPreferencesFormTranslations = {
  tSignUpBillingPreferencesFormAutoPayDescriptor:
    "The easiest way to never miss a payment.",
  tSignUpBillingPreferencesFormAutoPayLabel: "Turn on Auto Pay",
  tSignUpBillingPreferencesFormAutoPayPointsAndValue:
    "<pointsChunk>{points} points</pointsChunk> / {value} value {dash} one time",
  tSignUpBillingPreferencesFormAutopayPaperlessRequiredSubtitle:
    "You must enroll in both Paperless & Auto Pay to keep your {discountAmount} monthly discount and the average price/kWh on this plan.",
  tSignUpBillingPreferencesFormConfirmAutopayDisabled:
    "Are you sure you want to turn off Auto Pay?",
  tSignUpBillingPreferencesFormConfirmPaperlessDisabled:
    "Are you sure you want to turn off Paperless?",
  tSignUpBillingPreferencesFormEBillOnlyDescriptor:
    "No paper. Good for the environment. Good for you.",
  tSignUpBillingPreferencesFormEBillOnlyLabel: "Go Paperless",
  tSignUpBillingPreferencesFormEBillPointsAndValue:
    "<pointsChunk>{points} points</pointsChunk> / {value} value {dash} one time",
  tSignUpBillingPreferencesFormEarnRewards:
    "Rhythm Rewards allows you to earn points and reduce your bills. It's our way of saying thank you!",
  tSignUpBillingPreferencesFormLabel: "Billing Preferences",
  tSignUpBillingPreferencesFormLetsSetUpBilling: "Let's set up billing.",
  tSignUpBillingPreferencesFormLetsTalkRewards: "Let's talk rewards.",
  tSignUpBillingPreferencesFormNoDiscountMessage:
    "You'll miss out on your {discountAmount} monthly discount and the average price/kWh you pay for electricity will go up.",
  tSignUpBillingPreferencesFormPaperlessLegalText:
    "I agree to receive my welcome packet, bills, contract documents, and contract-related notices (including my contract expiration notices and disconnect notices) via email. I understand I won't receive my bills via regular mail. I confirm the email address I provided is valid.",
  tSignUpBillingPreferencesFormProspectUpdateError:
    "An error occurred, please try again later",
  tSignUpBillingPreferencesFormSubmitCTA: "Next",
};
