import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const MyAccountPasswordChangeFieldsContainer = styled.div`
  & > *,
  .MuiFormControl-root {
    margin-bottom: ${rhSpacingPx(2.5)};
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    width: calc(50% - ${rhSpacingPx(2)});
  }
`;

export const MyAccountPasswordChangePasswordRequirements = styled(RhTypography)`
  &.MuiTypography-root {
    margin-top: ${rhSpacingPx(2)};
  }
`;
