export enum CompetitorNames {
  DirectEnergy = "Direct Energy",
  GexaEnergy = "Gexa Energy",
  GreenMountain = "Green Mountain",
  Reliant = "Reliant",
  ShellEnergy = "Shell Energy",
  SparkEnergy = "Spark Energy",
  TXU = "Txu Energy",
}

export interface CompetitorPlan {
  competitorName: CompetitorNames;
  eflUrl: string;
  id: string;
  parsedDatetime: string;
  planName: string;
  price2000Kwh: string;
  termMonths: number;
  utility: string;
  zipCode: string;
}
