import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { ReactComponent as WomanUnplug } from "@portal-shared/images/WomanUnplug.svg";
import { authErrorTranslations } from "@portal/components/AuthError/AuthError.en.i18n";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(4)};
  max-width: 400px;
  text-align: center;
`;

const SubTitle = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_600};
  }
`;

export const AuthError = () => {
  const { translate, translateJsx } = useTranslations();

  const { tAuthIssuesLoginIn, tAuthGoogleSessionDoesNotMatch } = translate(
    authErrorTranslations
  );
  const { tAuthGoogleLogout, tAuthGoToSignInPage } = translateJsx({
    tAuthGoToSignInPage: {
      signInLink: (linkText: string) => (
        <RhAnchor href="/">{linkText}</RhAnchor>
      ),
    },
    tAuthGoogleLogout: {
      googleLogoutLink: (content: string) => (
        <RhAnchor href="https://accounts.google.com/Logout" target="_blank">
          {content}
        </RhAnchor>
      ),
    },
  });

  return (
    <LoggedOutPageLayout>
      <Container>
        <WomanUnplug aria-hidden />
        <RhTypography variant="h1">{tAuthIssuesLoginIn}</RhTypography>

        <SubTitle variant="subtitle2">
          {tAuthGoogleSessionDoesNotMatch}
        </SubTitle>
        <SubTitle variant="subtitle2">{tAuthGoogleLogout}</SubTitle>
        <SubTitle variant="subtitle2">{tAuthGoToSignInPage}</SubTitle>
      </Container>
    </LoggedOutPageLayout>
  );
};
