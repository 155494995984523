import { UUIDType } from "@common/types/apiTypes";
import { guestPayApi } from "@portal-guest/api/guestPayApi";
import { GuestPayQueryKeys } from "@portal-guest/queryKeys/guestPayQueryKeys";
import { GuestPayAccountSummaryType } from "@portal-guest/types/guestPayTypes";
import { QUERY_STALE_TIME_DEFAULT_MEDIUM } from "@portal-shared/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

export const useGuestPayAccountBalanceByTokenQuery = ({
  initialData = undefined,
  token = "",
}: {
  initialData?: GuestPayAccountSummaryType;
  token: UUIDType | undefined;
}) => {
  const query = useQuery<GuestPayAccountSummaryType>({
    enabled: Boolean(token),
    initialData,
    queryFn: () => guestPayApi.accountSummaryByToken(token),
    queryKey: GuestPayQueryKeys.accountBalance(token),
    // We don't want to refetch on error as this is considered isPending on refetch
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_MEDIUM,
  });

  return query;
};
