import { signUpApi } from "@portal/api/signUpApi";
import { SignUpQueryKeys } from "@portal/constants/querykeys.constants";
import { ProductTier } from "@portal/models/ProductTier.model";
import { useQuery } from "@tanstack/react-query";

export const useSignUpProductTiers = () => {
  return useQuery({
    queryFn: signUpApi.products.tiers.list,
    queryKey: SignUpQueryKeys.products.tiers.list(),
    select: (data) => data.map((tier) => new ProductTier(tier)),
    structuralSharing: false,
  });
};
