export const myAccountAutoPayModalTranslations = {
  tMyAccountAutoPayModalAutoPayLabel: "Auto Pay",
  tMyAccountAutoPayModalCancelButtonText: "Cancel",
  tMyAccountAutoPayModalConfirmButtonText: "Confirm",
  tMyAccountAutoPayModalIfDisableWarningSubTitle:
    "The Average Price/kWh that you pay for electricity will go up.",
  tMyAccountAutoPayModalIfDisableWarningTitle:
    "If you turn OFF Auto Pay you'll miss out on your {discountAmount}/month Auto Pay and Paperless credit...",
  tMyAccountAutoPayModalModalCloseLabel: "Close Auto Pay",
  tMyAccountAutoPayModalModalLabel: "Edit Auto Pay",
  tMyAccountAutoPayModalNextDueDate:
    "You will be charged your total balance due on your next due date",
  tMyAccountAutoPayModalNoPaymentMethods:
    "You have not set up a payment method yet. Please add a payment method to set up Auto Pay",
  tMyAccountAutoPayModalPastDueCharged:
    "You will be charged your total past due amount of {pastDueAmount} on {todaysDate}",
  tMyAccountAutoPayModalPaymentMethodLabel: "Select Payment Account",
  tMyAccountAutoPayModalTitle: "Edit Auto Pay",
  tMyAccountAutoPayModalUpdateFailure:
    "Failed to update Auto Pay, please try again",
  tMyAccountAutoPayModalUpdateSuccess: "Auto Pay updated",
  tMyAccountAutoPayModalWhenDisabledWarningTitle:
    "Please Turn ON Auto Pay to receive your {discountAmount}/month Auto Pay and Paperless credit.",
  tMyAccountAutoPayModalYouWillBeCharged:
    "You will be charged your total balance due on {dueDate}",
  tMyAccountAutoPayModalYourNextCharge: "Your next charge will be on {dueDate}",
};
