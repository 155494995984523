import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { grey } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalCard } from "@portal-shared/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledBillFactorsCard = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const StyledBillFactorsDivider = styled(RhDivider)`
  &.MuiDivider-root {
    background-color: ${grey[100]};
    margin: ${rhSpacingPx(1)} 0 ${rhSpacingPx(2)};
  }
`;

export const StyledNoFactorsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
