import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { CDN_URL } from "@portal-shared/settings/config";
import { Locale } from "@portal-shared/types/LocaleTypes";
import React, { PropsWithChildren } from "react";

interface YRACLinkProps {
  dataTrackingClick: {
    [key: string]: string;
    location: string;
  };
  locale: Locale;
  onClick?: () => void;
}

export const YRACLink = (props: PropsWithChildren<YRACLinkProps>) => {
  const { dataTrackingClick, onClick, locale, children } = props;

  return (
    <RhAnchor
      data-tracking-click={JSON.stringify({
        event: "YRAC download",
        ...dataTrackingClick,
      })}
      rel="noopener noreferrer"
      target="_blank"
      href={`${CDN_URL}/RhythmYRAC_${locale}.pdf`}
      onClick={onClick}
    >
      {children}
    </RhAnchor>
  );
};
