export const offerSnapshotLearnMoreUsageTableTranslations = {
  tOfferSnapshotLearnMoreUsageTableAutoPayPaperlessSubHeader:
    "with Auto Pay & Paperless",
  tOfferSnapshotLearnMoreUsageTableAverageMonthlyUsage: "Average Monthly Usage",
  tOfferSnapshotLearnMoreUsageTableAveragePrice: "Average Price per kWh",
  tOfferSnapshotLearnMoreUsageTableMonthlyBill:
    "Monthly bill at different usages",
  tOfferSnapshotLearnMoreUsageTableMonthlyEstimate: "Monthly Estimate",
  tOfferSnapshotLearnMoreUsageTableTimeOfUseDisclaimer:
    "*Average price calculation is based on usage profile over 12 months referred to the pricing components in the Energy Charge Breakdown below.",
};
