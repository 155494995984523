import styled from "styled-components";

export const StyledLoading = styled.div`
  align-items: center;
  display: flex;
  height: calc(100vh - 3rem);
  justify-content: center;
  text-align: center;
  width: 100%;

  & > * {
    margin: auto;
    transform: translateY(-3rem);
  }
`;
