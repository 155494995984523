export type PaymentExtensionFormValues = {
  downPayment: string;
  selectedPaymentId: string;
};

export enum PaymentExtensionSteps {
  confirmation = "confirmation",
  form = "form",
  success = "success",
}
