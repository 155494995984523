import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useProspectQuery } from "@enroll-data/hooks/queries/useProspect.query";
import {
  SignUpStepType,
  signUpBasePath,
  signUpStepPath,
} from "@enroll-utils/constants/routePaths";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useSignUpFlowTranslations } from "@portal/hooks/useSignUpFlow.en.i18n";
import { CREATE_PASSWORD, createPasswordPath } from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import {
  ActionType,
  EventType,
  TrackEventHandler,
  createTrackEvent,
} from "@portal/services/segment.service";
import {
  SignUpStateType,
  completedSignUp,
  setSignUpInfo,
} from "@portal/slices/signUpSlice";
import { camelize } from "humps";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export interface SignUpClickNextStepHandlerParams {
  nextStep?: SignUpStepType | typeof CREATE_PASSWORD;
  signUpData?: Partial<SignUpStateType>;
  track: boolean;
}

export type SignUpClickNextStepHandler = (
  params: SignUpClickNextStepHandlerParams
) => void;

export interface SignUpFlow {
  currentStep: SignUpStepType;
  signUpClickNextStepHandler: SignUpClickNextStepHandler;
  trackEvent: TrackEventHandler;
  visitedSteps: SignUpStepType[];
}

export const getSignUpStep = (pathname: string) => {
  return pathname.replace(/\/+$/, "").split("/").slice(-1)[0] as SignUpStepType;
};

export function useSignUpFlow(): SignUpFlow {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { visitedSteps } = useSelector(selectSignUpState);
  const { translate } = useTranslations();
  const { tUseSignUpFlowGeneralError } = translate(useSignUpFlowTranslations);

  const [goToPlansPage, setGoToPlansPage] = useState(false);

  const prospectQuery = useProspectQuery();
  const {
    cookies: {
      _ga: googleClientIdCookie,
      ajs_anonymous_id: segmentAnonymousIdCookie,
    },
  } = useEnrollCookies();

  const currentStep =
    pathname === signUpBasePath() ? "name" : getSignUpStep(pathname);

  useEffect(() => {
    if (
      currentStep === "address" &&
      goToPlansPage &&
      !prospectQuery.isRefetching
    ) {
      setGoToPlansPage(false);
      navigate("/sign-up/plans");
    }
  }, [currentStep, goToPlansPage, navigate, prospectQuery.isRefetching]);

  const trackingCategory = `onboarding.${camelize(currentStep)}`;

  const trackEvent = createTrackEvent({
    category: trackingCategory,
    event: EventType.enrollmentClick,
  });

  const signUpClickNextStepHandler: SignUpClickNextStepHandler = useCallback(
    ({ signUpData = {}, nextStep, track }) => {
      const trackNextPage = () =>
        trackEvent({
          action: ActionType.clickedNextPage,
          label: `${currentStep}NextPage`,
        });

      if (track) {
        trackNextPage();
      }

      const newVisitedSteps = nextStep &&
        nextStep !== CREATE_PASSWORD &&
        !visitedSteps.includes(nextStep) && [...visitedSteps, nextStep];

      const payload: Partial<SignUpStateType> = {
        ...signUpData,
        googleClientId: googleClientIdCookie,
        segmentAnonId: segmentAnonymousIdCookie,
        visitedSteps: newVisitedSteps || visitedSteps,
      };

      if (!signUpData.id) {
        // customer has been persisted
        dispatch(setSignUpInfo(payload));
      }
      let nextPath: string | null = null;

      if (nextStep === CREATE_PASSWORD) {
        nextPath = createPasswordPath();
      } else if (nextStep) {
        nextPath = signUpStepPath(nextStep);
      }

      if (nextStep === CREATE_PASSWORD && !signUpData.email) {
        flash.error(tUseSignUpFlowGeneralError);
      } else if (nextPath) {
        if (nextPath === "/sign-up/plans") {
          setGoToPlansPage(true);
        } else {
          navigate(nextPath);
        }
      }

      if (signUpData.id) {
        // You might ask yourself why this is like this, well dispatch and navigate can compete with each other
        // this ensures that the dispatch is the last step on sign up so we can first navigate to the CreatePasswordPage
        // we can't use 0 for the timeout as firefox won't respect that #firefoxLife
        // Carlos wrote this
        setTimeout(() => {
          dispatch(completedSignUp(payload));
        }, 100);
      }
    },
    [
      visitedSteps,
      googleClientIdCookie,
      segmentAnonymousIdCookie,
      trackEvent,
      currentStep,
      dispatch,
      flash,
      formatMessage,
      navigate,
    ]
  );

  return {
    currentStep,
    signUpClickNextStepHandler,
    trackEvent,
    visitedSteps,
  };
}
