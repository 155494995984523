import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledBold = styled.span`
  font-weight: ${FontWeight.Bold};
`;

export const ProvideAdditionalInformationPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  margin: ${rhSpacingPx(2)} 0;
  text-align: center;
  width: 100%;
`;
