import {
  StyledTableCell,
  StyledTableRow,
} from "@design-system/components/RhDayPicker/RhDayPicker.style";
import { EventKey } from "@design-system/enums/EventKey.enum";
import { Table, TableBody } from "@mui/material";
import React, { useState } from "react";

interface RhDayPickerProps {
  onDaySelected: (day: number) => void;
}

export const RhDayPicker = ({ onDaySelected }: RhDayPickerProps) => {
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  let day = 1;
  const rows: JSX.Element[] = [];

  for (let weekNumber = 1; weekNumber <= 4; weekNumber += 1) {
    const cells: JSX.Element[] = [];

    for (let dayNumber = 1; dayNumber <= 7; dayNumber += 1) {
      // Capture current value in loop of day as part of the closure
      const newDay = day;

      cells.push(
        <StyledTableCell
          selected={selectedDay === day}
          align="center"
          key={day}
          tabIndex={0}
          onKeyUp={(event) => {
            if (
              event.key === EventKey.SpaceBar ||
              event.key === EventKey.Enter
            ) {
              onDaySelected(newDay);
              setSelectedDay(newDay);
            }
          }}
          onClick={() => {
            onDaySelected(newDay);
            setSelectedDay(newDay);
          }}
        >
          {day}
        </StyledTableCell>
      );

      day += 1;
    }

    rows.push(<StyledTableRow key={weekNumber}>{cells}</StyledTableRow>);
  }

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
