import { UUIDType } from "@common/types/apiTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import { ProductPrice } from "@portal/models/ProductPrice.model";
import {
  ProductTierCategoryType,
  ProductTierFeatureType,
  ProductTierPresentationGroupType,
  ProductTierType,
} from "@portal/types/productTypes";

export class ProductTier {
  categories: ProductTierCategoryType[];
  description: string;
  features: ProductTierFeatureType[];
  icon: string;
  id: UUIDType;
  name: string;
  presentationGroups: ProductTierPresentationGroupType[];
  prices: ProductPrice[];
  productId: UUIDType;

  constructor(tier: ProductTierType) {
    this.categories = tier.categories;
    this.description = tier.description;
    this.features = tier.features;
    this.icon = tier.icon;
    this.id = tier.id;
    this.name = tier.name;
    this.presentationGroups = tier.presentationGroups;
    this.prices = tier.prices.map((price) => new ProductPrice(this, price));
    this.productId = tier.productId;
  }

  get sortedFeatures() {
    return this.features.sort((a, b) => a.priority - b.priority);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<
  ProductTierType,
  ProductTier
> = undefined;
