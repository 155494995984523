export const paymentExtensionFormTranslations = {
  tPaymentExtensionFormDisclaimer:
    "Rhythm offers Payment Extensions up to 7 days from when disconnection notice expires.",
  tPaymentExtensionFormDownPayment: "Down Payment Due Today",
  tPaymentExtensionFormExtendedBalance: "Extended Balance",
  tPaymentExtensionFormExtensionDueDate: "Extension Due Date",
  tPaymentExtensionFormMinimum: "Minimum {minimum}",
  tPaymentExtensionFormReviewPayment: "Review Payment Extension Details",
  tPaymentExtensionFormSubtitle:
    "You have a Total Past Due Balance of {pastDueBalance} with a Disconnection Date of <bold>{disconnectionDueDate}</bold>.",
  tPaymentExtensionFormTitle: "Payment Extension",
  tPaymentExtensionFormTotalPastDueBalance: "Total Past Due Balance",
};
