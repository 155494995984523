import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { additionalServicesProductAddonsTranslations } from "@portal/components/AdditionalServicesProductAddons/AdditionalServicesProductAddons.en.i18n";
import {
  StyledContainer,
  StyledLinkButton,
  StyledSVGWrapper,
} from "@portal/components/AdditionalServicesProductAddons/AdditionalServicesProductAddons.styled";
import { ReactComponent as TechnologyCloud } from "@portal/images/TechnologyCloud.svg";
import { productAddOnsOptInPath } from "@portal/routes/routePaths";
import React from "react";

export const AdditionalServicesProductAddons = () => {
  const { translate } = useTranslations();
  const {
    tAdditionalServicesProductAddonsButtonText,
    tAdditionalServicesProductAddonsSubTitle,
    tAdditionalServicesProductAddonsTitle,
  } = translate(additionalServicesProductAddonsTranslations);

  return (
    <StyledContainer>
      <StyledSVGWrapper>
        <TechnologyCloud aria-hidden />
      </StyledSVGWrapper>
      <RhTypography variant="h2">
        {tAdditionalServicesProductAddonsTitle}
      </RhTypography>
      <RhTypography variant="body1" color="textSecondary">
        {tAdditionalServicesProductAddonsSubTitle}
      </RhTypography>
      <StyledLinkButton
        data-tracking-click={{
          event: "Customer going to Product addons opt-in page",
        }}
        href={productAddOnsOptInPath()}
        color="primary"
      >
        {tAdditionalServicesProductAddonsButtonText}
      </StyledLinkButton>
    </StyledContainer>
  );
};
