import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const OfferSnapshotCardContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  justify-items: center;
  max-width: 960px;
  padding: 0 24px;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    grid-template-columns: 1fr 1fr 1fr;

    &:has(> :nth-child(2)):not(:has(> :nth-child(3))) {
      grid-template-columns: 1fr 1fr;
      max-width: 720px;
    }

    &:has(> :only-child) {
      grid-template-columns: 1fr;
      max-width: 360px;
    }
  }
`;
