import { RhStepper } from "@design-system/components/RhStepper/RhStepper";
import { StepperKey } from "@design-system/components/RhStepper/rhStepperTypes";
import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { signUpStepperTranslations } from "@portal/components/SignUpStepper/SignUpStepper.en.i18n";
import { SignUpStepperIcon } from "@portal/components/SignUpStepper/SignUpStepperIcon";
import { SignUpStepperKeyType } from "@portal/components/SignUpStepper/signUpStepperTypes";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import React from "react";

const StepIndexMap: Record<SignUpStepType, 1 | 2 | 3 | null> = {
  "add-ons": 3,
  address: 1,
  "billing-preferences": 2,
  "call-us": 2,
  "confirm-address": 1,
  contact: 1,
  "credit-check": 2,
  deposit: 2,
  details: 1,
  information: null,
  name: 1,
  payment: 2,
  "provide-additional-information": 1,
  summary: 3,
};

export const SignUpStepper = () => {
  const { currentStep } = useSignUpFlow();
  const { translate } = useTranslations();

  const {
    tSignUpStepperContact,
    tSignUpStepperPayment,
    tSignUpStepperPlans,
    tSignUpStepperSummary,
  } = translate(signUpStepperTranslations);

  const activeStepIndex = StepIndexMap[currentStep];

  const signUpSignUpStepperKeysInOrder: StepperKey<SignUpStepperKeyType>[] = [
    { id: "plans", label: tSignUpStepperPlans },
    { id: "contact", label: tSignUpStepperContact },
    { id: "payment", label: tSignUpStepperPayment },
    { id: "summary", label: tSignUpStepperSummary },
  ];

  return activeStepIndex ? (
    <RhStepper<SignUpStepperKeyType>
      activeStepIndex={activeStepIndex}
      stepperKeysInOrder={signUpSignUpStepperKeysInOrder}
      stepperIcon={SignUpStepperIcon}
    />
  ) : null;
};
