export const customerRewardsSectionTranslations = {
  tCustomerRewardsSectionAutoPay: "Sign up for Auto Pay",
  tCustomerRewardsSectionAutoPayPoints: "500 points",
  tCustomerRewardsSectionExplainer:
    "Take action. Get points. Here are a few ways:",
  tCustomerRewardsSectionFriend: "Refer a friend",
  tCustomerRewardsSectionFriendPoints: "10,000 points",
  tCustomerRewardsSectionLearnMore: "Learn more about Rhythm Rewards",
  tCustomerRewardsSectionLifetime: "{lifetime} lifetime Points earned",
  tCustomerRewardsSectionManWavingWithCoin: "Man waving and holding large coin",
  tCustomerRewardsSectionMoreDetails:
    "For more reward details, check out the <linkChunk>FAQ</linkChunk>.",
  tCustomerRewardsSectionPaperless: "Sign up for Paperless",
  tCustomerRewardsSectionPaperlessPoints: "500 points",
  tCustomerRewardsSectionPoints: "Points",
  tCustomerRewardsSectionRewards: "Rewards Balance",
  tCustomerRewardsSectionRewardsError:
    "Sorry, we could not access your rewards points",
  tCustomerRewardsSectionValue: "{value} cash value",
};
