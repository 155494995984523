import { premiseApi } from "@portal/api/premiseApi";
import {
  PremiseQueryKeys,
  customerQueryKeys,
} from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePremiseAutoPayUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: premiseApi.updateAutoPay,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: PremiseQueryKeys.retrieve(premiseId),
      });
      queryClient.invalidateQueries({
        queryKey: customerQueryKeys.me(),
      });
    },
  });
};
