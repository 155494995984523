export const offerSnapshotLearnMoreLegalDocumentsTranslations = {
  tOfferSnapshotLearnMoreLegalDocumentsEFLDescription:
    "This details your plan including the average electricity rate you'll pay.",
  tOfferSnapshotLearnMoreLegalDocumentsEFLTitle: "Electricity Facts Label",
  tOfferSnapshotLearnMoreLegalDocumentsTOSDescription:
    "This explains the terms and conditions that govern your electric service with Rhythm.",
  tOfferSnapshotLearnMoreLegalDocumentsTOSTitle: "Terms of Service",
  tOfferSnapshotLearnMoreLegalDocumentsTitle: "Contract Documents",
  tOfferSnapshotLearnMoreLegalDocumentsYRACDescription:
    "These are your rights based on the customer protection rules of the Public Utility Commission of Texas.",
  tOfferSnapshotLearnMoreLegalDocumentsYRACTitle: "Your Rights as a Customer",
};
