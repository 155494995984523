import { QuickPay } from "@portal-guest/components/QuickPay/QuickPay";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import React from "react";

export const QuickPayPage = () => {
  return (
    <LoggedOutPageLayout>
      <QuickPay />
    </LoggedOutPageLayout>
  );
};
