import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

interface StyledContainerProps {
  $noBorder: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  border: ${({ $noBorder }) => ($noBorder ? 0 : borders[100])};
  border-radius: ${({ $noBorder }) => ($noBorder ? 0 : "6px")};
  min-height: 100%;
  padding: ${rhSpacingPx(2.5)};
`;
