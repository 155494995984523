export const guestZuoraCardFormTranslations: Record<string, string> = {
  tGuestZuoraCardFormAuthorizationFailed:
    "We're having trouble connecting to the billing service. Please try again.",
  tGuestZuoraCardFormErrorAddingCard:
    "Unable to add card. Please verify your card information and try again.",
  "tGuestZuoraCardFormInlineFormError.expirationDateInvalid":
    "Please enter a valid expiration date.",
  "tGuestZuoraCardFormInlineFormError.expiredCard":
    "Your card is expired. Please add a different one or correct your information.",
  "tGuestZuoraCardFormInlineFormError.fieldEmpty":
    "Please complete all form fields.",
  "tGuestZuoraCardFormInlineFormError.genericDecline":
    "Your card was declined. Please try a different one.",
  "tGuestZuoraCardFormInlineFormError.insufficientFunds":
    "Your card was declined because of insufficient funds. Please try a different one or contact your bank.",
  "tGuestZuoraCardFormInlineFormError.lostCard":
    "Your card was declined. Please try a different one.",
  "tGuestZuoraCardFormInlineFormError.nameInvalid":
    "Name must match the name on the card.",
  "tGuestZuoraCardFormInlineFormError.numberInvalid":
    "Invalid card number. Please try again.",
  "tGuestZuoraCardFormInlineFormError.securityCodeInvalid":
    "Please enter a valid security code.",
  "tGuestZuoraCardFormInlineFormError.stolenCard":
    "Your card was declined. Please try a different one.",
  "tGuestZuoraCardFormInlineFormError.tooManyTries":
    "You have tried too many times to enter a card with invalid information. Please try again.",
  "tGuestZuoraCardFormInlineFormError.unknownError":
    "We were unable to add your card. Please try again.",
  "tGuestZuoraCardFormInlineFormError.zipCodeInvalid":
    "Please enter a valid Zip Code.",
};
