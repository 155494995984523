export const switchPrintToPaperlessModalTranslations = {
  tSwitchPrintToPaperlessModalClose: "Close",
  tSwitchPrintToPaperlessModalIncentiveSubTitle:
    "Get $5 in Rhythm Rewards when you go Paperless. Save green and go green.",
  tSwitchPrintToPaperlessModalIncentiveTitle:
    "Switch to Paperless. Get rewarded.",
  tSwitchPrintToPaperlessModalLabel: "Enroll in Paperless",
  tSwitchPrintToPaperlessModalLegalText:
    "I agree to receive my welcome packet, bills, contract documents, and contract-related notices (including my contract expiration notices and disconnect notices) via email. I understand I won't receive my bills via regular mail. I confirm the email address I provided is valid.",
  tSwitchPrintToPaperlessModalNoIncentiveSubTitle:
    "Get the exact same communication you're used to in a more timely manner without the paper. Win-win.",
  tSwitchPrintToPaperlessModalNoIncentiveTitle:
    "Enroll in Paperless and stay informed.",
  tSwitchPrintToPaperlessModalRemindMeLater: "Remind Me Later",
  tSwitchPrintToPaperlessModalSwitch: "Switch to Paperless",
  tSwitchPrintToPaperlessModalUpdateFailure:
    "Failed to update communication preferences, please try again",
  tSwitchPrintToPaperlessModalUpdateToPaperlessSuccess:
    "Communication preferences updated to fully Paperless",
};
