import { green, red } from "@design-system/theme/palette.colors";
import { DeviceStatus } from "@portal/types/devicesTypes";

export const deviceStatusColor = (status: DeviceStatus) => {
  if (status === DeviceStatus.Online) {
    return green.main;
  }

  return red.main;
};
