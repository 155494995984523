export const offerSnapshotFAQsSimpliSafeTranslations = {
  tOfferSnapshotFAQsSimpliSafeAfterSignUp:
    "After that, you'll get an email with a unique link taking you to a page to confirm your name and the address where you want your system shipped.",
  tOfferSnapshotFAQsSimpliSafeBeACustomer:
    "Be a customer for 30 days and pay your first bill.",
  tOfferSnapshotFAQsSimpliSafeOptIn:
    "When a Rhythm customer opts-in and signs up for one of our home security plans, they will receive a SimpliSafe Home Security system as well as 2 months of free 24/7 professional monitoring.",
  tOfferSnapshotFAQsSimpliSafeReceiveSimpliSafe:
    "When will Rhythm Energy customers receive their SimpliSafe Home Security system?",
  tOfferSnapshotFAQsSimpliSafeShipYourSystem:
    "SimpliSafe will ship your system directly to the address you provided.",
  tOfferSnapshotFAQsSimpliSafeSimpliSafeIs:
    "SimpliSafe is a wireless home security system engineered with a single focus: to protect. SimpliSafe has cut out the hidden fees, long-term contracts, and pesky wires to deliver superior home protection for every room, window, and door at a fair price.",
  tOfferSnapshotFAQsSimpliSafeSimplySecure:
    "Sign up on the Simply Secure 24 Plan or Simply Protect 24 Plan.",
  tOfferSnapshotFAQsSimpliSafeTitle: "Home Security",
  tOfferSnapshotFAQsSimpliSafeWhatIsSimpliSafe: "What is SimpliSafe?",
};
