import { UUIDType } from "@common/types/apiTypes";
import { RhStyledLinkButton } from "@design-system/components/RhStyledLinkButton/RhStyledLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { signUpStepPath } from "@enroll-utils/constants/routePaths";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { ProductTierFeatures } from "@portal/components/ProductTierCard/ProductTierFeatures";
import { ProductTier } from "@portal/models/ProductTier.model";
import { signUpSlice } from "@portal/slices/signUpSlice";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledProductTier = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

const StyledHeader = styled.header`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
`;

const StyledActions = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
`;

interface CartProductTierProps {
  closeCart: () => void;
  productTier: ProductTier;
}

export const CartProductTier: FC<CartProductTierProps> = ({
  productTier,
  closeCart,
}) => {
  const dispatch = useDispatch();
  const { t } = useRhIntl();

  const removePlan = (priceId: UUIDType) =>
    dispatch(signUpSlice.actions.removeProductPrice(priceId));

  return (
    <StyledProductTier>
      <StyledHeader>
        <RhTypography variant="h2" fontWeight="Regular" component="h2">
          {productTier.name}
        </RhTypography>

        <StyledActions>
          <NavLink to={signUpStepPath("add-ons")} onClick={closeCart}>
            {t("CartSelectedProductTiers.changePlan")}
          </NavLink>
          <RhStyledLinkButton
            type="button"
            onClick={() => removePlan(productTier.prices[0].id)}
          >
            {t("CartSelectedProductTiers.removePlanButton")}
          </RhStyledLinkButton>
        </StyledActions>
      </StyledHeader>

      <RhTypography variant="h2" fontWeight="Bold" component="p">
        {productTier.prices[0].formattedPricePerFrequency}
      </RhTypography>

      <ProductTierFeatures productTier={productTier} />
    </StyledProductTier>
  );
};
