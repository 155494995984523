export const signUpProvideAdditionalInformationPageTranslations = {
  tSignUpProvideAdditionalInformationPageGetSetUp:
    "Oops. We need some additional information.",
  tSignUpProvideAdditionalInformationPagePleaseContactCustomerCare:
    "Please contact our Customer Care Team at <phoneTag>{phone}</phoneTag> to complete your enrollment.",
  tSignUpProvideAdditionalInformationPagePleaseContactUs:
    "Please contact us at <phoneTag>{phone}</phoneTag> to complete your new enrollment.",
  tSignUpProvideAdditionalInformationPageSinceYouWere:
    "Since you were previously a Rhythm customer, we need to confirm a few things before getting you set up again.",
  tSignUpProvideAdditionalInformationPageThankYou:
    "Thank you for the info, but we need a bit more.",
  tSignUpProvideAdditionalInformationPageWelcomeBack: "Welcome back!",
};
