import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { Premise } from "@common/models/Premise.model";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as CircularMoneyIcon } from "@design-system/icons/CircularMoneyIcon.svg";
import { ReactComponent as PaperlessIcon } from "@design-system/icons/PaperlessIcon.svg";
import { EFLLink } from "@portal-shared/components/EFLLink/EFLLink";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { PortalCheckbox } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import { TOSLink } from "@portal-shared/components/TOSLink/TOSLink";
import { YRACLink } from "@portal-shared/components/YRACLink/YRACLink";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { PaymentMethodSelector } from "@portal/components/PaymentMethodSelector/PaymentMethodSelector";
import { renewalSummaryFormTranslations } from "@portal/components/RenewalSummaryForm/RenewalSummaryForm.en.i18n";
import {
  BillingPreferencesContainer,
  FinePrintContainer,
  PaymentMethodContainer,
  RenewalSummaryFormContainer,
  RenewalSummaryFormPortalToggle,
  StyledPlanDetailRow,
  StyledPlanDetailRowLabel,
  StyledPlanDetailRowValue,
  StyledTermsCheckboxLabel,
  StyledTermsContainer,
} from "@portal/components/RenewalSummaryForm/RenewalSummaryForm.styled";
import { usePremiseRenewContractMutation } from "@portal/hooks/mutations/usePremiseRenewContract.mutation";
import { useUpdatePremisePaymentMethodMutation } from "@portal/hooks/mutations/useUpdatePremisePaymentMethod.mutation";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremisePaymentMethodsQuery } from "@portal/hooks/queries/usePremisePaymentMethods.query";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
} from "@portal/services/segment.service";
import { billingDetailsRefetch } from "@portal/slices/billingDetailsSlice";
import { premiseRefetch } from "@portal/slices/premiseSlice";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

interface RenewalSummaryFormProps {
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}

export interface RenewalSummaryFormValues {
  autopay?: boolean;
  paperless?: boolean;
  paymentMethodId?: string;
  termsAccepted: boolean;
}

export const RenewalSummaryForm = (props: RenewalSummaryFormProps) => {
  const { offerSnapshot, premise } = props;

  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();

  const dispatch = useDispatch();

  const [locale] = useLocaleAtom();

  const paymentMethodsQuery = usePremisePaymentMethodsQuery({
    premiseId: premise.id,
  });

  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premise.id,
  });

  const renewContractMutation = usePremiseRenewContractMutation();
  const updatePaymentMethodMutation = useUpdatePremisePaymentMethodMutation();

  const defaultPayment = paymentMethodsQuery.data?.paymentMethods.find(
    (method) => method.defaultPaymentMethod === true
  );

  const formMethods = useForm<RenewalSummaryFormValues>({
    defaultValues: {
      autopay: offerSnapshot.isAutoPayPaperlessDiscountOffer
        ? true
        : premise.autopay,
      paperless: offerSnapshot.isAutoPayPaperlessDiscountOffer
        ? true
        : premise.isPaperless || premise.isOnLegacyEBill,
      paymentMethodId: defaultPayment?.id,
      termsAccepted: false,
    },
  });

  const { watch, register, handleSubmit, resetField } = formMethods;

  const { translate, translateJsx } = useTranslations();

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalRenewalPaperlessToggle, portalRenewalAutopayToggle } =
    featureFlagClient.getFlags([
      ["portalRenewalPaperlessToggle", false],
      ["portalRenewalAutopayToggle", false],
    ]);

  const [termsAccepted, selectedPaperless, selectedAutopay] = watch([
    "termsAccepted",
    "paperless",
    "autopay",
  ]);

  useEffect(() => {
    // If the defaultPayment changes, reset the field to auto-select it
    if (defaultPayment) {
      resetField("paymentMethodId", {
        defaultValue: defaultPayment.id,
      });
    }
  }, [defaultPayment, resetField]);

  useEffect(() => {
    // If we have no payment methods, reset the autopay field to false to be disabled
    if (
      paymentMethodsQuery.isSuccess &&
      paymentMethodsQuery.data.paymentMethods.length === 0
    ) {
      resetField("autopay", {
        defaultValue: false,
      });
    }

    // If the query fails fall back to setting autopay to false
    if (paymentMethodsQuery.isError) {
      resetField("autopay", {
        defaultValue: false,
      });
    }
  }, [
    paymentMethodsQuery.data?.paymentMethods.length,
    paymentMethodsQuery.isSuccess,
    resetField,
    paymentMethodsQuery.isError,
  ]);

  const {
    tRenewalSummaryFormSignup,
    tRenewalSummaryFormSubmitting,
    tRenewalSummaryFormBillingPreferences,
    tRenewalSummaryFormPaperless,
    tRenewalSummaryFormPaperlessDescription,
    tRenewalSummaryFormPaperlessLegalText,
    tRenewalSummaryFormAutoPayLabel,
    tRenewalSummaryFormAutoPayDescription,
    tRenewalSummaryFormConfirmAutopayDisabled,
    tRenewalSummaryFormConfirmPaperlessDisabled,
    tRenewalSummaryFormError,
    tRenewalSummaryFormAddAPaymentMethod,
    tRenewalSummaryFormPaymentMethodAddedSuccess,
    tRenewalSummaryFormPaymentMethodAddedError,
  } = translate(renewalSummaryFormTranslations);

  const trackingLocation = "Renewal Summary";

  const {
    tRenewalSummaryFormTerms,
    tRenewalSummaryFormNoDiscountMessage,
    tRenewalSummaryFormPastDueMessage,
  } = translateJsx({
    tRenewalSummaryFormNoDiscountMessage: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? "",
        2
      ),
    },
    tRenewalSummaryFormPastDueMessage: {
      pastDueAmount: formatCurrency(
        accountSummaryQuery.data?.pastDueBalance ?? 0
      ),
    },
    tRenewalSummaryFormTerms: {
      efl: (chunk: string) => (
        <EFLLink
          offerId={offerSnapshot.id}
          locale={locale}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </EFLLink>
      ),
      tos: (chunk: string) => (
        <TOSLink
          tosUrl={offerSnapshot.rhythmTosLink(locale)}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </TOSLink>
      ),
      yrac: (chunk: string) => (
        <YRACLink
          locale={locale}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </YRACLink>
      ),
    },
  });

  const showPaperlessDisabledWarning =
    offerSnapshot.autopayPaperlessMonthlyDiscount && !selectedPaperless;

  const showAutoPayDisabledWarning =
    offerSnapshot.autopayPaperlessMonthlyDiscount && !selectedAutopay;

  const handleRenewContract = (values: RenewalSummaryFormValues) => {
    renewContractMutation.mutate(
      {
        autopay: portalRenewalAutopayToggle.value ? values.autopay : undefined,
        offerSnapshotId: offerSnapshot.id,
        paperless: portalRenewalPaperlessToggle.value
          ? values.paperless
          : undefined,
        paymentMethodId:
          portalRenewalAutopayToggle.value && values.autopay
            ? values.paymentMethodId
            : undefined,
        premiseId: premise.id,
      },
      {
        onError: () => {
          flash.error(tRenewalSummaryFormError);
        },
        onSuccess: () => {
          track({
            action: ActionType.click,
            category: CategoryType.MyAccountRenewals,
            event: EventType.renewalClick,
            label: LabelType.ClickedRenewalSubmit,
            offerSnapshotId: offerSnapshot.id,
            offerSnapshotPrice: offerSnapshot.price,
            offerSnapshotTitle: offerSnapshot.title,
          });
          dispatch(billingDetailsRefetch);
        },
      }
    );
  };

  const showBillingPreferences =
    portalRenewalPaperlessToggle.value || portalRenewalAutopayToggle.value;

  const autoPayDisabled =
    !paymentMethodsQuery.data?.paymentMethods ||
    paymentMethodsQuery.data?.paymentMethods.length === 0;

  const handleZuoraResponseSuccess = (
    data: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ) => {
    updatePaymentMethodMutation.mutate(
      {
        isOneTimePayment: false,
        paymentMethodId: data.refId,
        premiseId: premise.id,
        setDefaultPaymentMethod:
          paymentMethodsQuery.data?.paymentMethods.length === 0,
      },
      {
        onError: () => {
          flash.error(tRenewalSummaryFormPaymentMethodAddedError);
        },
        onSuccess: () => {
          dispatch(premiseRefetch);
          dispatch(billingDetailsRefetch);
          flash.success(tRenewalSummaryFormPaymentMethodAddedSuccess);
        },
      }
    );
  };

  return (
    <FormProvider {...formMethods}>
      <RenewalSummaryFormContainer onSubmit={handleSubmit(handleRenewContract)}>
        {showBillingPreferences ? (
          <BillingPreferencesContainer>
            <StyledPlanDetailRow>
              <StyledPlanDetailRowLabel>
                <RhTypography color="textSecondary">
                  {tRenewalSummaryFormBillingPreferences}
                </RhTypography>
              </StyledPlanDetailRowLabel>
              {portalRenewalPaperlessToggle.value ? (
                <StyledPlanDetailRowValue>
                  <RenewalSummaryFormPortalToggle
                    inputProps={register("paperless")}
                    dataTrackingClick={{
                      event: "customer toggled paperless",
                      value: String(selectedPaperless),
                    }}
                  >
                    <PaperlessIcon aria-hidden />
                    <RhTypography
                      variant="body1"
                      fontWeight="Bold"
                      component="span"
                    >
                      {tRenewalSummaryFormPaperless}
                    </RhTypography>
                  </RenewalSummaryFormPortalToggle>

                  <FinePrintContainer>
                    {selectedPaperless ? (
                      <RhTypography variant="body2" color="textSecondary">
                        {tRenewalSummaryFormPaperlessLegalText}
                      </RhTypography>
                    ) : (
                      <RhTypography variant="body2" color="textSecondary">
                        {tRenewalSummaryFormPaperlessDescription}
                      </RhTypography>
                    )}

                    {showPaperlessDisabledWarning ? (
                      <>
                        <RhTypography variant="body2" color="error">
                          {tRenewalSummaryFormConfirmPaperlessDisabled}
                        </RhTypography>

                        <RhTypography variant="body2" color="error">
                          {tRenewalSummaryFormNoDiscountMessage}
                        </RhTypography>
                      </>
                    ) : null}
                  </FinePrintContainer>
                </StyledPlanDetailRowValue>
              ) : null}
            </StyledPlanDetailRow>
            {portalRenewalAutopayToggle.value ? (
              <>
                <StyledPlanDetailRow>
                  <StyledPlanDetailRowLabel />
                  <StyledPlanDetailRowValue>
                    <RenewalSummaryFormPortalToggle
                      dataTrackingClick={{
                        event: "customer toggled autopay",
                        value: String(selectedAutopay),
                      }}
                      inputProps={register("autopay", {
                        disabled: autoPayDisabled,
                      })}
                    >
                      <CircularMoneyIcon aria-hidden />
                      <RhTypography
                        variant="body1"
                        fontWeight="Bold"
                        component="span"
                      >
                        {tRenewalSummaryFormAutoPayLabel}
                      </RhTypography>
                    </RenewalSummaryFormPortalToggle>

                    <FinePrintContainer>
                      {paymentMethodsQuery.data?.paymentMethods.length === 0 ? (
                        <RhTypography variant="body2" color="textSecondary">
                          {tRenewalSummaryFormAddAPaymentMethod}
                        </RhTypography>
                      ) : (
                        <RhTypography variant="body2" color="textSecondary">
                          {tRenewalSummaryFormAutoPayDescription}
                        </RhTypography>
                      )}

                      {Number(accountSummaryQuery.data?.pastDueBalance) > 0 &&
                      selectedAutopay ? (
                        <RhTypography variant="body2" color="error">
                          {tRenewalSummaryFormPastDueMessage}
                        </RhTypography>
                      ) : null}

                      {showAutoPayDisabledWarning && !selectedAutopay ? (
                        <>
                          <RhTypography variant="body2" color="error">
                            {tRenewalSummaryFormConfirmAutopayDisabled}
                          </RhTypography>

                          <RhTypography variant="body2" color="error">
                            {tRenewalSummaryFormNoDiscountMessage}
                          </RhTypography>
                        </>
                      ) : null}
                    </FinePrintContainer>
                  </StyledPlanDetailRowValue>
                </StyledPlanDetailRow>

                {paymentMethodsQuery.isPending ? <RhCircularProgress /> : null}

                {paymentMethodsQuery.isSuccess ? (
                  <PaymentMethodContainer>
                    <PaymentMethodSelector
                      paymentMethods={paymentMethodsQuery.data.paymentMethods}
                      onPaymentMethodAdded={handleZuoraResponseSuccess}
                      paymentInputName="paymentMethodId"
                      showPaymentOptions={false}
                      showTitle={false}
                    />
                  </PaymentMethodContainer>
                ) : null}
              </>
            ) : null}
          </BillingPreferencesContainer>
        ) : null}

        <StyledTermsContainer>
          <StyledTermsCheckboxLabel>
            <PortalCheckbox {...register("termsAccepted")} />
            <RhTypography>{tRenewalSummaryFormTerms}</RhTypography>
          </StyledTermsCheckboxLabel>
        </StyledTermsContainer>
        <RhButton
          data-tracking-click={{
            event: "Customer attempting to renew contract",
          }}
          color="primary"
          disabled={!termsAccepted || renewContractMutation.isPending}
          type="submit"
        >
          {!renewContractMutation.isPending
            ? tRenewalSummaryFormSignup
            : tRenewalSummaryFormSubmitting}
        </RhButton>
      </RenewalSummaryFormContainer>
    </FormProvider>
  );
};
