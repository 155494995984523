import { quickPayApi } from "@portal-guest/api/quickPayApi";
import { QuickPayQueryKeys } from "@portal-guest/queryKeys/quickPayQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useQuickPayAddPaymentMethodMutation = (token: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: quickPayApi.addPaymentMethod,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QuickPayQueryKeys.paymentMethods(token),
      });
    },
  });

  return mutation;
};
