export const deviceDemandResponseActivityTableTranslations = {
  tDeviceDemandResponseActivityTableApproved: "Approved",
  tDeviceDemandResponseActivityTableCancelled: "Cancelled",
  tDeviceDemandResponseActivityTableDraft: "Pending Device Confirmation",
  tDeviceDemandResponseActivityTableDuration: "Duration",
  tDeviceDemandResponseActivityTableFinished: "Completed",
  tDeviceDemandResponseActivityTableOptedOut: "Opted Out",
  tDeviceDemandResponseActivityTablePhaseChanged: "Phase Changed",
  tDeviceDemandResponseActivityTableReady: "Ready",
  tDeviceDemandResponseActivityTableScheduled: "Scheduled",
  tDeviceDemandResponseActivityTableSent: "Sent",
  tDeviceDemandResponseActivityTableStartTime: "Start Date and Time",
  tDeviceDemandResponseActivityTableStarted: "In Progress",
  tDeviceDemandResponseActivityTableStatus: "Status",
  tDeviceDemandResponseActivityTableStatusAndDate: "Start Date and Status",
  tDeviceDemandResponseActivityTableThermostatAdjustments:
    "Thermostat Adjustments",
};
