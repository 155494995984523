import { DashedYYYYMMDD, IdType, UUIDType } from "@common/types/apiTypes";
import { ProductPriceTermPeriod } from "@portal/types/productTypes";

export enum ProductContractStatusFilter {
  ACTIVE = "active",
  EXPIRED = "expired",
}

export enum ProductContractFrequency {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
}

export interface ProductContractType {
  endDate: string;
  frequency: ProductContractFrequency;
  id: UUIDType;
  number: string;
  productName: string;
  rate: string;
  startDate: string;
  termLength: number;
  termPeriod: ProductPriceTermPeriod;
  tierDescription: string;
  tierName: string;
  tosUrl: string;
}

export interface CreateProductContractResponse {
  endDate: DashedYYYYMMDD;
  id: UUIDType;
  startDate: DashedYYYYMMDD;
}

export interface ProductContractCancelRequestType {
  contractId: UUIDType;
  endDate: string;
  premiseId: IdType;
}

export interface ClaimOptionContactMethodType {
  type: "PHONE" | "FORM";
  value: string;
}

export interface ClaimOptionType {
  contactMethods: ClaimOptionContactMethodType[];
  description: string;
  name: string;
}

export interface ProductContractClaimRequestType {
  claimFile: File;
  contractId: UUIDType;
  premiseId: IdType;
}

export interface ProductContractClaimOptionsRequestType {
  premiseId: IdType;
  productContractId: UUIDType;
}

export interface ProductContractCreateRequestType {
  priceID: UUIDType;
}
