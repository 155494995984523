import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { PaymentMethodRadioInput } from "@portal-shared/components/PaymentMethodRadioInput/PaymentMethodRadioInput";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { AddBankAccount } from "@portal/components/AddBankAccount/AddBankAccount";
import { AddPaymentCard } from "@portal/components/AddPaymentCard/AddPaymentCard";
import { paymentMethodSelectorTranslations } from "@portal/components/PaymentMethodSelector/PaymentMethodSelector.en.i18n";
import { PaymentMethodSelectorContainer } from "@portal/components/PaymentMethodSelector/PaymentMethodSelector.styled";
import { usePremise } from "@portal/hooks/usePremise";
import React from "react";

interface PaymentMethodSelectorProps {
  isOneTimePaymentMethodPending?: boolean;
  onPaymentMethodAdded: (
    achOrCardAddedResponse: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ) => void;
  oneTimePaymentMethod?: PaymentMethod;
  paymentInputName: string;
  paymentMethods: PaymentMethod[];
  showPaymentOptions?: boolean;
  showTitle?: boolean;
}

export const PaymentMethodSelector = ({
  isOneTimePaymentMethodPending,
  oneTimePaymentMethod,
  onPaymentMethodAdded,
  paymentInputName,
  paymentMethods,
  showPaymentOptions = true,
  showTitle = true,
}: PaymentMethodSelectorProps) => {
  const { translate } = useTranslations();
  const { premise } = usePremise();

  const { tPaymentMethodSelectorPaymentMethod } = translate(
    paymentMethodSelectorTranslations
  );

  return (
    <PaymentMethodSelectorContainer>
      {showTitle ? (
        <RhTypography variant="subtitle2" fontWeight={FontWeight.Semibold}>
          {tPaymentMethodSelectorPaymentMethod}
        </RhTypography>
      ) : null}

      {paymentMethods?.map((paymentMethod) => (
        <PaymentMethodRadioInput
          key={paymentMethod.id}
          name={paymentInputName}
          paymentMethod={paymentMethod}
        />
      ))}
      {oneTimePaymentMethod ? (
        <PaymentMethodRadioInput
          name={paymentInputName}
          paymentMethod={oneTimePaymentMethod}
          isOneTimePayment
        />
      ) : null}
      {isOneTimePaymentMethodPending ? <RhCircularProgress /> : null}
      {!premise?.isNoCreditOrDebitCard ? (
        <AddPaymentCard
          onCardAdded={onPaymentMethodAdded}
          showPaymentOptions={showPaymentOptions}
        />
      ) : null}
      {!premise?.isNoCheckOrAch ? (
        <AddBankAccount
          onBankAccountAdded={onPaymentMethodAdded}
          showPaymentOptions={showPaymentOptions}
        />
      ) : null}
    </PaymentMethodSelectorContainer>
  );
};
