import { billingApi } from "@common/api/billingApi";
import { useQuery } from "@tanstack/react-query";

export const useFetchRSASignatureQuery = (pageId: string) => {
  const query = useQuery({
    gcTime: 0,
    queryFn: () => billingApi.fetchRSASignature(pageId),
    queryKey: [`fetch-rsa-signature-${pageId}`],
    refetchOnWindowFocus: false,
  });

  return query;
};
