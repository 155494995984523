import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

const NOTIFICATIONS_CONTAINER_WIDTH = 525;

export const StyledNotificationsHeader = styled.header`
  margin-bottom: ${rhSpacingPx(3)};
  text-align: center;
`;

export const StyledNotificationsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledNotificationsCoulmn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${NOTIFICATIONS_CONTAINER_WIDTH}px;
`;
