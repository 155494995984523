export const offerSnapshotFAQsTwoMonthsFreeTranslations = {
  tOfferSnapshotFAQsTwoMonthsFreeBillCreditsOnCancelAnswer:
    "Unfortunately, you will not receive credit for the remaining period of your contract. So, if you cancel in year two, you will only receive credit for the first free month.",
  tOfferSnapshotFAQsTwoMonthsFreeBillCreditsOnCancelQuestion:
    "Will I receive bill credits if I cancel my contract prior to the end of the contract term?",
  tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesAnswer:
    "Yes, Rhythm will cover up to $150 in early termination fees from your old provider. You can learn more <link>here</link>.",
  tOfferSnapshotFAQsTwoMonthsFreeCoverPreviousCancellationFeesQuestion:
    "Will you cover my old provider's cancellation fees if I switch to this plan?",
  tOfferSnapshotFAQsTwoMonthsFreeHowDoesThisWorkAnswer:
    "Simple. You sign up for the Two Months Free 24 Plan, and you will get two free months in the form of bill credits in your Rhythm Rewards account over the next two years while you are under contract with Rhythm.",
  tOfferSnapshotFAQsTwoMonthsFreeHowDoesThisWorkQuestion:
    "How does the Two Months Free 24 Plan work?",
  tOfferSnapshotFAQsTwoMonthsFreeLeaveBeforeContractEndAnswer:
    "You will be charged $20 per month for each remaining month on your contract if you leave more than 30 days after you sign up for this Plan.",
  tOfferSnapshotFAQsTwoMonthsFreeLeaveBeforeContractEndQuestion:
    "What if I leave before my contract ends?",
  tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsAnswer:
    "Yes, both you and your friend could earn $100 in Rhythm Reward Points. See how it works <link>here</link>.",
  tOfferSnapshotFAQsTwoMonthsFreeReferAFriendCreditsQuestion:
    "Will I earn referral credit when I refer a friend to this plan?",
  tOfferSnapshotFAQsTwoMonthsFreeTitle: "Two Months Free",
  tOfferSnapshotFAQsTwoMonthsFreeWhenDoIReceiveCreditAnswer:
    "Bill credits, in an amount equal to your energy usage charge and TDSP charges from your bill related to your November 2023 consumption, will be automatically applied to the bill for your December 2023 energy consumption, and bill credits, in an amount equal to your energy usage charge and TDSP charges from your November 2024 monthly consumption, will be automatically applied to the bill for your December 2024 consumption. You must be current on all your bills for the bill credits to be applied. The bill credits applied will equal the amount you paid for your November 2023 and November 2024 energy and TDSP charges, but not taxes. These credits cannot be exchanged for cash or gift cards, have no cash value, and cannot be applied towards a deposit.",
  tOfferSnapshotFAQsTwoMonthsFreeWhenDoIReceiveCreditQuestion:
    "When will I receive the Rhythm Rewards credit for the 2 free months?",
  tOfferSnapshotFAQsTwoMonthsFreeWhoIsEligibleAnswer:
    "Yes! Well, almost. This plan is only available to residential customers who also meet the criteria outlined in the Energy Facts Label (EFL). For example, if you use or plan to use your residence as a bitcoin farm as part of your crypto business empire, you are not eligible to sign up for this plan. Sorry, crypto bros.",
  tOfferSnapshotFAQsTwoMonthsFreeWhoIsEligibleQuestion:
    "Is everyone eligible to sign up for this plan?",
};
