export const offerSnapshotFAQsDiscountPlanTranslations = {
  tOfferSnapshotFAQsDiscountPlanWhatDoIDoDescription:
    "You must be enrolled in both Auto Pay and Paperless before your first bill is generated. Simple as that.",
  tOfferSnapshotFAQsDiscountPlanWhatDoIDoTitle:
    "What do I have to do to get a {discountAmount} monthly credit",
  tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffDescription:
    "You will lose your {discountAmount} monthly Auto Pay and Paperless credit, which results in an increase in the Average Price/kWh your're paying for electricity: IMPORTANT: You must be enrolled in both Auto Pay and Paperless to qualify for the {discountAmount} monthly credit",
  tOfferSnapshotFAQsDiscountPlanWhatIfITurnOffTitle:
    "What if I turn off Auto Pay or Paperless",
  tOfferSnapshotFAQsDiscountTitle: "Auto Pay & Paperless",
};
