import { unmaskCurrency } from "@common/forms/currency.mask";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Grid } from "@mui/material";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { PaymentAmount } from "@portal/components/PaymentAmount/PaymentAmount";
import { AmountType } from "@portal/enums/AmountType.enum";
import { usePaymentMethods } from "@portal/hooks/billingHooks";
import { useCreatePaymentMutation } from "@portal/hooks/mutations/useCreatePayment.mutation";
import {
  useInvalidatePremiseAccountSummaryQuery,
  usePremiseAccountSummaryQuery,
} from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePremise } from "@portal/hooks/usePremise";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { BillSummary } from "@portal/pages/PayBillPage/BillSummary/BillSummary";
import { translations } from "@portal/pages/PayBillPage/PayBillPage.en.i18n";
import {
  StyledBillPageCenteredDiv,
  StyledBillPageForm,
  StyledPayBillPageWrapper,
  StyledPaymentMethodsSectionContainer,
} from "@portal/pages/PayBillPage/PayBillPage.styled";
import { PayBillPageFormValues } from "@portal/pages/PayBillPage/PayBillPageFormValuesType";
import { PayBillPagePaymentMethodsSection } from "@portal/pages/PayBillPage/PayBillPagePaymentMethodsSection";
import { payBillSuccessPath } from "@portal/routes/routePaths";
import { MyAccountEvents } from "@portal/services/segment.service";
import { Show500Page } from "@portal/utils/errors";
import { FORM_ERROR } from "final-form";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";

export const PayBillPage = () => {
  const navigate = useNavigate();

  const { translate } = useTranslations();
  const {
    tPayBillPageErrorMustSelectPaymentMethod,
    tPayBillPageErrorPayment,
    tPayBillPageMinimumPaymentError,
    tPayBillPageSubmitPayment,
    tPayBillPageUnableToCollect,
  } = translate(translations);

  const { paymentMethods } = usePaymentMethods();
  const flash = useRhFlash();
  const [oneTimePaymentMethodId, setOneTimePaymentMethodId] =
    useState<string>();

  const track = useTrackMyAccountEvents();

  const customer = useCustomerFromContext();

  const {
    premise,
    requestMonitor: premiseRequestMonitor,
    error: premiseError,
  } = usePremise();

  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    enabled: premise !== null,
    premiseId: premise?.id || "",
  });
  const invalidatePremiseAccountSummaryQuery =
    useInvalidatePremiseAccountSummaryQuery();

  const createPaymentMutation = useCreatePaymentMutation();

  if (accountSummaryQuery.isError || premiseRequestMonitor.didFail) {
    let message = "Could not fetch customer, account summary, and premise";

    if (accountSummaryQuery.isError) {
      message = "Could not fetch account summary";
    } else if (premiseError) {
      message = `Could not fetch premise : ${premiseError.data.errorCode}`;
    }
    throw new Show500Page(message);
  }

  if (accountSummaryQuery.isPending || premiseRequestMonitor.isWaiting) {
    return <RhCircularProgress />;
  }
  if (!customer || !premise) {
    return null;
  }

  const accountSummary = accountSummaryQuery.data;

  const shouldPaymentButtonBeDisabled = () => {
    if (createPaymentMutation.isPending) {
      return true;
    }

    if (oneTimePaymentMethodId) {
      return false;
    }

    if (!paymentMethods || paymentMethods.length <= 0) {
      return true;
    }
    return false;
  };

  const submitDisabled = shouldPaymentButtonBeDisabled();

  const getAmountFromAmountType = ({
    maskedCustomAmount,
    amountType,
  }: {
    amountType: AmountType;
    maskedCustomAmount: string;
  }) => {
    if (amountType === AmountType.Total) {
      return Number(accountSummary?.totalBalance ?? 0);
    }

    if (amountType === AmountType.PastDue) {
      return Number(accountSummary?.pastDueBalance ?? 0);
    }

    return Number(unmaskCurrency(maskedCustomAmount));
  };

  const handleCreatePayment = async ({
    maskedCustomAmount,
    selectedPaymentMethodId,
    amountType,
  }: PayBillPageFormValues) => {
    if (!selectedPaymentMethodId) {
      flash.error(tPayBillPageErrorMustSelectPaymentMethod);

      return { [FORM_ERROR]: [tPayBillPageMinimumPaymentError] };
    }
    const amount = getAmountFromAmountType({ amountType, maskedCustomAmount });

    if (amount === 0) {
      flash.error(tPayBillPageMinimumPaymentError);

      return { [FORM_ERROR]: [tPayBillPageMinimumPaymentError] };
    }

    const isOneTimePayment = selectedPaymentMethodId === oneTimePaymentMethodId;

    try {
      await createPaymentMutation.mutateAsync(
        {
          paymentData: {
            amount,
            isOneTimePayment,
            paymentMethodId: selectedPaymentMethodId,
          },
          premiseId: premise.id,
        },
        {
          onError: () => {
            flash.error(tPayBillPageErrorPayment);

            track({
              amount,
              event: MyAccountEvents.paymentSubmittedError,
              isOneTimePayment,
              label: "Submit payment error",
            });
          },
          onSuccess: () => {
            invalidatePremiseAccountSummaryQuery(premise.id);
            navigate(payBillSuccessPath());

            track({
              amount,
              event: MyAccountEvents.paymentSubmittedSuccess,
              isOneTimePayment,
              label: "Submit payment success",
            });
          },
        }
      );
    } catch (error) {
      return { [FORM_ERROR]: [tPayBillPageErrorPayment] };
    }
  };

  const defaultPaymentMethod = paymentMethods?.find(
    (paymentMethod) => paymentMethod.defaultPaymentMethod
  );

  const totalBalance = Number(accountSummary?.totalBalance ?? 0);
  const initialValues: PayBillPageFormValues = {
    amountType: totalBalance <= 0 ? AmountType.Custom : AmountType.Total,
    maskedCustomAmount: String(totalBalance > 0 ? totalBalance : 0),
    pastDueAmount: Number(accountSummary?.pastDueBalance ?? 0),
    selectedPaymentMethodId: defaultPaymentMethod?.id || "",
    totalAmount: totalBalance,
  };

  return (
    <PortalPageLayout>
      <StyledPayBillPageWrapper>
        <StyledBillPageCenteredDiv>
          <Grid container item xs={12} sm={6} md={4}>
            <BillSummary accountSummary={accountSummary} />
            {premise.isNoCheckOrAch && premise.isNoCreditOrDebitCard ? (
              <RhTypography variant="body1">
                {tPayBillPageUnableToCollect}
              </RhTypography>
            ) : (
              <Form<PayBillPageFormValues>
                onSubmit={handleCreatePayment}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                  <StyledBillPageForm onSubmit={handleSubmit}>
                    <PaymentAmount />
                    <StyledPaymentMethodsSectionContainer>
                      <PayBillPagePaymentMethodsSection
                        oneTimePaymentMethodId={oneTimePaymentMethodId}
                        setOneTimePaymentMethodId={setOneTimePaymentMethodId}
                      />
                    </StyledPaymentMethodsSectionContainer>
                    <RhSubmitButton
                      data-tracking-click={{
                        event: "Customer submitting payment",
                      }}
                      fullWidth
                      size="large"
                      disabled={submitDisabled}
                    >
                      {tPayBillPageSubmitPayment}
                    </RhSubmitButton>
                  </StyledBillPageForm>
                )}
              />
            )}
          </Grid>
        </StyledBillPageCenteredDiv>
      </StyledPayBillPageWrapper>
    </PortalPageLayout>
  );
};
