import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { guestZuoraAddBankAccountFormTranslations } from "@portal-guest/components/GuestZuoraAddBankAccountForm/GuestZuoraAddBankAccountForm.en.i18n";
import { GuestZuoraAddBankAccountFormError } from "@portal-guest/components/GuestZuoraAddBankAccountForm/GuestZuoraAddBankAccountFormError.service";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useFetchRSASignatureQuery } from "@portal-shared/queries/useFetchRSASignatureQuery";
import { getZuoraParams } from "@portal-shared/utils/baseZuoraParams.util";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";

interface GuestZuoraAddBankAccountFormProps {
  onFailure?(error: string): void;
  onSuccess(response: ZuoraAddPaymentResponseType): void;
  paymentOptions?: PaymentMethodOptionsType;
}

export const GuestZuoraAddBankAccountForm = ({
  onFailure,
  onSuccess,
  paymentOptions = {
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  },
}: GuestZuoraAddBankAccountFormProps) => {
  const [isZuoraFormLoading, setIsZuoraFormLoading] = useState(true);
  const { translate } = useTranslations();
  const translations = translate(guestZuoraAddBankAccountFormTranslations);
  const {
    tGuestZuoraAddBankAccountFormAuthorizationFailed,
    tGuestZuoraAddBankAccountFormErrorAddingBankAccount,
  } = translations;
  const flash = useRhFlash();
  const zuoraParams = getZuoraParams(dayjs().locale(), true);
  const fetchRSASignatureQuery = useFetchRSASignatureQuery(zuoraParams.id);

  const debounceErrorMessageCallback = debounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraAddBankAccountErrorService =
        new GuestZuoraAddBankAccountFormError(
          errorType,
          errorCode,
          errorDescription
        );

      const { error } = zuoraAddBankAccountErrorService;
      const errorMessage =
        translations[`tGuestZuoraAddBankAccountFormFormError.${error}`];

      flash.error(errorMessage);
    },
    250
  );

  const handleZuoraResponse = (response: ZuoraAddPaymentResponseType) => {
    if (!response.success) {
      onFailure?.(tGuestZuoraAddBankAccountFormErrorAddingBankAccount);

      return Promise.resolve();
    }

    onSuccess(response);

    return Promise.resolve();
  };

  useEffect(() => {
    if (fetchRSASignatureQuery.isPending) {
      return;
    }

    zuora.setEventHandler("onloadCallback", () => {
      setIsZuoraFormLoading(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...zuoraParams,
        ...fetchRSASignatureQuery.data,
      },
      {},
      handleZuoraResponse,
      debounceErrorMessageCallback
    );
  }, [fetchRSASignatureQuery.isPending]);

  if (fetchRSASignatureQuery.isError) {
    onFailure?.(tGuestZuoraAddBankAccountFormAuthorizationFailed);
    return null;
  }

  return (
    <>
      {isZuoraFormLoading && <RhCircularProgress marginBottom={4} />}
      <div id="zuora_payment" />
    </>
  );
};
