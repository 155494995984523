import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledPayBillSuccessPage = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPayBillSuccessPageInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledPayBillSuccessImageTitle = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
  width: 140px;
`;

export const StyledThankYou = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
`;

export const StyledYourRemainingBalance = styled.div`
  margin-bottom: ${rhSpacingPx(5)};
`;

export const StyledGoHomeCTAWrapper = styled.div`
  width: 100%;
`;

export const StyledReconnectionMessaging = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  margin-bottom: ${rhSpacingPx(5)};
  max-width: 75ch;
  text-align: center;
  text-wrap: balance;
`;
