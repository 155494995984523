export const translations = {
  tPayBillPageAmountDue: "Amount due",
  tPayBillPageErrorMustSelectPaymentMethod:
    "You must select a credit or debit card to submit a payment",
  tPayBillPageErrorPayment:
    "We were unable to process your payment. Please try again or pick a different payment method.",
  tPayBillPageMinimumPaymentError: "Amount must be greater than $0.",
  tPayBillPagePastDue: "Past due",
  tPayBillPagePayFrom: "Pay from",
  tPayBillPageSubmitPayment: "Submit Payment",
  tPayBillPageTitlePayBill: "Pay bill",
  tPayBillPageUnableToCollect:
    "Unable to collect ACH or Card payments, please proceed making a cash payment.",
};
