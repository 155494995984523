export const offerChargesTranslations = {
  tOfferChargesBaseCharge: "Base charge: {baseCharge} per month",
  tOfferChargesEnergyCharge: "Energy charge: {energyCharge} per kWh",
  tOfferChargesRate: "Avg price per kWh: {price} @2000 kWh",
  tOfferChargesRatePaperless:
    "Avg price per kWh with Auto Pay & Paperless: {price} @2000 kWh",
  tOfferChargesRatePaperlessAndThermostat:
    "Avg price per kWh with Auto Pay & Paperless and Connected Thermostat Credits: {price} @2000 kWh",
  tOfferChargesRateThermostat:
    "Avg price per kWh with Connected Thermostat Credits: {price} @2000 kWh",
  tOfferChargesSolarBuybackEnergyRate:
    "Solar Buyback Credit Rate: {solarCreditKwhRate} per kWh",
  tOfferChargesTdspDeliveryCharge: "{tdsp} Delivery Charge: {charge} per kWh",
  tOfferChargesTdspMonthlyCharge: "{tdsp} Base Charge: {charge} per month",
  tOfferChargesTitle: "Your plan includes",
};
