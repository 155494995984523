import { OneKeyAtMostOf } from "@portal-shared/types/oneKeyAtMostOfTypes";
import { PrimitiveAtom, useAtom } from "jotai";
import { useCallback } from "react";

export const useBaseModals = <T>(
  atom: PrimitiveAtom<T>,
  initialAtomState: T
) => {
  const [modalsAtom, setModalsAtom] = useAtom(atom);
  const addModal = useCallback(
    (data: OneKeyAtMostOf<T>) => {
      setModalsAtom((previousModal) => {
        return {
          ...previousModal,
          ...data,
        };
      });
    },
    [setModalsAtom]
  );

  const removeModal = useCallback(
    (modalKey: keyof T) => {
      setModalsAtom((previousModal) => {
        return {
          ...previousModal,
          [modalKey]: initialAtomState[modalKey],
        };
      });
    },
    [setModalsAtom]
  );

  return {
    addModal,
    modals: modalsAtom,
    removeModal,
  };
};
