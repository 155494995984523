import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsAmazonThermostatTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsAmazonThermostat/OfferSnapshotFAQsAmazonThermostat.en.i18n";
import { OfferSnapshotFAQsTitleWrapper } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React, { ReactNode } from "react";

export const OfferSnapshotFAQsAmazonThermostat = () => {
  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotFAQsAmazonThermostatAbout,
    tOfferSnapshotFAQsAmazonThermostatCanYou,
    tOfferSnapshotFAQsAmazonThermostatWhenWillIGet,
    tOfferSnapshotFAQsAmazonThermostatYouWillReceive,
    tOfferSnapshotFAQsAmazonThermostatYourSmart,
    tOfferSnapshotFAQsAmazonThermostatWillIReceive,
    tOfferSnapshotFAQsAmazonThermostatWhatHappens,
    tOfferSnapshotFAQsAmazonThermostatYouCanKeep,
    tOfferSnapshotFAQsAmazonThermostatWhoDoICall,
    tOfferSnapshotFAQsAmazonThermostatIAmOncor,
    tOfferSnapshotFAQsAmazonThermostatYouHaveToInstall,
  } = translate(offerSnapshotFAQsAmazonThermostatTranslations);

  const {
    tOfferSnapshotFAQsAmazonThermostatAbsolutely,
    tOfferSnapshotFAQsAmazonThermostatPleaseChat,
  } = translateJsx({
    tOfferSnapshotFAQsAmazonThermostatAbsolutely: {
      link: (message: ReactNode[]) => (
        <a
          href={brandInformation.urlsBlogConnectDevice}
          rel="noreferrer"
          target="_blank"
        >
          {message}
        </a>
      ),
    },
    tOfferSnapshotFAQsAmazonThermostatPleaseChat: {
      supportmail: () => (
        <a href={`mailto:${brandInformation.enrollmentEmailSupport}`}>
          {brandInformation.enrollmentEmailSupport}
        </a>
      ),
    },
  });

  return (
    <OfferSnapshotFAQsTitleWrapper
      title={tOfferSnapshotFAQsAmazonThermostatAbout}
    >
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatCanYou}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatAbsolutely}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWhenWillIGet}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYourSmart}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWillIReceive}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYouWillReceive}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWhatHappens}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYouCanKeep}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWhoDoICall}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatPleaseChat}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatIAmOncor}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYouHaveToInstall}
        </RhTypography>
      </PortalAccordion>
    </OfferSnapshotFAQsTitleWrapper>
  );
};
