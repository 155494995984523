import { ediApi } from "@common/api/ediApi";
import { UtilitySearchRequestType } from "@common/types/ediTypes";
import { PortalQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface SearchUtilitiesQueryOptions {
  enabled?: boolean;
  retry?: boolean;
}

interface SearchUtilitiesQueryProps {
  queryOptions?: SearchUtilitiesQueryOptions;
  searchOptions: UtilitySearchRequestType;
}

export const useSearchUtilitiesQuery = ({
  queryOptions,
  searchOptions,
}: SearchUtilitiesQueryProps) => {
  const query = useQuery({
    enabled: queryOptions?.enabled,
    queryFn: () => ediApi.utilities.search(searchOptions),
    queryKey: PortalQueryKeys.utilities.search(searchOptions),
    retry: queryOptions?.retry,
  });

  return query;
};
