import { quickPayApi } from "@portal-guest/api/quickPayApi";
import { useMutation } from "@tanstack/react-query";

export const useQuickPayCreatePaymentMutation = () => {
  const mutation = useMutation({
    mutationFn: quickPayApi.createPayment,
  });

  return mutation;
};
