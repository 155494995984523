import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledPaymentExtensionFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rhSpacingPx(6)} 0;
  width: 350px;
`;

export const PaymentExtensionFormTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  text-align: center;
  text-wrap: balance;
`;

export const PaymentExtensionFormDisclaimer = styled(RhTypography)`
  color: ${CSSVariables.COLOR_GREY_500};
`;

export const StyledPaymentExtensionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(2)};
`;

export const StyledPaymentExtensionFormRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledPaymentExtensionDownPaymentInput = styled.input`
  border: 1px solid ${CSSVariables.COLOR_GREY_100};
  border-radius: 4px;
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(1.5)};
  text-align: end;
  width: 90px;
`;

export const StyledPaymentExtensionMinimumText = styled(RhTypography).attrs({
  variant: "body3",
})`
  align-self: flex-end;
  color: ${CSSVariables.COLOR_GREY_400};
`;

export const StyledPaymentExtensionDueDate = styled(RhTypography)`
  color: ${CSSVariables.COLOR_GREY_400};
`;
