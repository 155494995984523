export const customerHomeNewSignupSectionTranslations = {
  tCustomerHomeNewSignupSectionCongratsOnTheMove:
    "Congrats on the move, {name}!",
  tCustomerHomeNewSignupSectionGivingYouThePower:
    "Your Rhythm Account gives you power over your power, with all your energy information in one place. It is another way we're making energy easier.",
  tCustomerHomeNewSignupSectionMakingTheSwitch:
    "We'll be making the switch in <bold>{days} days</bold>, starting <bold>{starting}</bold>.  In the meantime, make your energy yours.",
  tCustomerHomeNewSignupSectionMakingTheSwitchToday:
    "We'll be making the switch <bold>today</bold>.  In the meantime, make your energy yours.",
  tCustomerHomeNewSignupSectionMakingTheSwitchTomorrow:
    "We'll be making the switch <bold>tomorrow</bold>.  In the meantime, make your energy yours.",
  tCustomerHomeNewSignupSectionNewMoverWelcomeImageAltText:
    "man and woman unpacking stacked moving boxes",
  tCustomerHomeNewSignupSectionNewSwitcherWelcomeImageAltText:
    "Lady plugging an electric cord to a cloud in the sky",
  tCustomerHomeNewSignupSectionSendingPowerYourWay:
    "We'll be sending power your way in <bold>{days} days</bold>, starting <bold>{starting}</bold>.  In the meantime, make your energy yours.",
  tCustomerHomeNewSignupSectionSendingPowerYourWayToday:
    "We'll be sending power your way <bold>today</bold>.  In the meantime, make your energy yours.",
  tCustomerHomeNewSignupSectionSendingPowerYourWayTomorrow:
    "We'll be sending power your way <bold>tomorrow</bold>.  In the meantime, make your energy yours.",
  tCustomerHomeNewSignupSectionStandardWelcomeImageAltText:
    "Living room console table with windmill plant decor and picture frame",
  tCustomerHomeNewSignupSectionWelcome: "Welcome to Rhythm, {name}!",
  tCustomerHomeNewSignupSectionWelcomeBack: "Welcome back, {name}!",
};
