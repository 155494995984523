export const competitorPlanComparisonChartTranslations = {
  tCompetitorPlanComparisonChartAnnualSavings: "Annual Savings",
  tCompetitorPlanComparisonChartError: "Error fetching other competitors",
  tCompetitorPlanComparisonChartLastUpdated: "Last updated on {date}",
  tCompetitorPlanComparisonChartNoCompetitorPlans:
    "At this time, there are no plans to compare to with this term length from our top competitors.",
  tCompetitorPlanComparisonChartPerKwh: "per kWh",
  tCompetitorPlanComparisonChartViewCompetitors: "View competitor plans",
  tCompetitorPlanComparisonChartWithRhythm: "with Rhythm",
};
