import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import dayjs from "dayjs";

export const isValidAdultBirthday = (value: string): boolean => {
  const dob = dayjs(value, [DOB_DATE_FORMAT, ISO8601_DATE_FORMAT], true);

  if (!dob.isValid()) {
    return false;
  }

  const today = dayjs().startOf("day");
  const earliest18thBirthday = today.subtract(18, "year");

  if (dob.isAfter(earliest18thBirthday)) {
    return false;
  }

  return true;
};

export const thirtyDaysFromNow = () => dayjs().add(30, "day").toDate();
