import { EnrollmentStatus } from "@common/enums/customer.enum";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { usePremiseAccountSummaryQuery } from "@portal/hooks/queries/usePremiseAccountSummary.query";
import { usePremise } from "@portal/hooks/usePremise";
import { ReactComponent as PayBillSuccessCheck } from "@portal/images/PayBillSuccessCheck.svg";
import { translations } from "@portal/pages/PayBillSuccessPage/PayBillSuccessPage.en.i18n";
import {
  StyledGoHomeCTAWrapper,
  StyledPayBillSuccessImageTitle,
  StyledPayBillSuccessPage,
  StyledPayBillSuccessPageInner,
  StyledReconnectionMessaging,
  StyledThankYou,
  StyledYourRemainingBalance,
} from "@portal/pages/PayBillSuccessPage/PayBillSuccessPage.styled";
import { homePath } from "@portal/routes/routePaths";
import React from "react";

export const PayBillSuccessPage = () => {
  const { translate, translateJsx } = useTranslations();

  const { premise } = usePremise();
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premise?.id || "",
  });

  if (accountSummaryQuery.isPending) {
    return <RhCircularProgress />;
  }
  if (accountSummaryQuery.isError) {
    return null;
  }

  const balance =
    Number(accountSummaryQuery.data && accountSummaryQuery.data.totalBalance) ||
    0;

  const {
    tPayBillSuccessPageGoHomeCTA,
    tPayBillSuccessPagePayBillSuccessImageTitle,
    tPayBillSuccessPageReconnectionNoContactNeeded,
    tPayBillSuccessPageReconnectionRequestSent,
    tPayBillSuccessPageReconnectionUpdates,
    tPayBillSuccessPageThankYou,
  } = translate(translations);

  const { tPayBillSuccessPageYourRemainingBalance } = translateJsx({
    tPayBillSuccessPageYourRemainingBalance: {
      balance: formatCurrency(balance),
    },
  });

  const showReconnection =
    premise?.enrollmentStatus === EnrollmentStatus.DISCONNECTED && balance <= 0;

  return (
    <PortalPageLayout>
      <StyledPayBillSuccessPage>
        <StyledPayBillSuccessPageInner>
          <StyledPayBillSuccessImageTitle>
            <PayBillSuccessCheck
              title={tPayBillSuccessPagePayBillSuccessImageTitle}
            />
          </StyledPayBillSuccessImageTitle>
          <StyledThankYou>
            <RhTypography variant="h2">
              {tPayBillSuccessPageThankYou}
            </RhTypography>
          </StyledThankYou>
          <StyledYourRemainingBalance>
            <RhTypography variant="subtitle2">
              {tPayBillSuccessPageYourRemainingBalance}
            </RhTypography>
          </StyledYourRemainingBalance>
          {showReconnection ? (
            <StyledReconnectionMessaging>
              <RhTypography variant="subtitle2" color="textSecondary">
                {tPayBillSuccessPageReconnectionRequestSent}
              </RhTypography>
              <RhTypography variant="subtitle2" color="textSecondary">
                {tPayBillSuccessPageReconnectionUpdates}
              </RhTypography>
              <RhTypography
                variant="subtitle2"
                color="textSecondary"
                fontWeight={FontWeight.Semibold}
              >
                {tPayBillSuccessPageReconnectionNoContactNeeded}
              </RhTypography>
            </StyledReconnectionMessaging>
          ) : null}
          <StyledGoHomeCTAWrapper>
            <RhRouterLink to={homePath()} title={tPayBillSuccessPageGoHomeCTA}>
              <RhButton
                data-tracking-click={{
                  event:
                    "Customer going back to home after successfully paying",
                }}
                color="primary"
                fullWidth
              >
                {tPayBillSuccessPageGoHomeCTA}
              </RhButton>
            </RhRouterLink>
          </StyledGoHomeCTAWrapper>
        </StyledPayBillSuccessPageInner>
      </StyledPayBillSuccessPage>
    </PortalPageLayout>
  );
};
