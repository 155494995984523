export const areaNotServiceableModalTranslations = {
  tAreaNotServiceableModalBackToHome: "Back to Home",
  tAreaNotServiceableModalEmail: "Email",
  tAreaNotServiceableModalInvalidEmail: "Invalid email",
  tAreaNotServiceableModalOr: "or",
  tAreaNotServiceableModalSubheader: "But we’ll let you know when we do.",
  tAreaNotServiceableModalSubmit: "Sign Up for Updates",
  tAreaNotServiceableModalTitle: "Rhythm hasn’t made it out your way yet.",
  tAreaNotServiceableModalTryAnotherZipCode: "Try another zip code",
  tAreaNotServiceableModalWeWillLetYouKnow:
    "We’ll let you know when we’ve made it to your town!",
  tAreaNotServiceableModalYouAeOnTheList: "You're on the list",
};
