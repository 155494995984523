export const SwitchEBillToPaperlessModalTranslations = {
  tSwitchEBillToPaperlessModalActionRequired: "ACTION REQUIRED",
  tSwitchEBillToPaperlessModalAgreeToPaperlessDescription:
    "I agree to receive my welcome packet, bills, contract documents, and contract-related notices (including my contract expiration notices and disconnect notices) via email. I understand I won't receive my bills via regular mail. I confirm the email address I provided is valid.",
  tSwitchEBillToPaperlessModalEBillToPaperless:
    "We are moving from eBill to fully Paperless.",
  tSwitchEBillToPaperlessModalStayOnEBillButtonCta:
    "No, I prefer to stay on eBill.",
  tSwitchEBillToPaperlessModalSwitchToPaperButtonCta:
    "No, I prefer to switch to paper.",
  tSwitchEBillToPaperlessModalSwitchToPaperlessButtonCta:
    "Switch to fully Paperless",
  tSwitchEBillToPaperlessModalUpdateFailure:
    "Failed to update communication preferences, please try again",
  tSwitchEBillToPaperlessModalUpdateToPaperSuccess:
    "Communication preferences updated to paper",
  tSwitchEBillToPaperlessModalUpdateToPaperlessSuccess:
    "Communication preferences updated to fully Paperless",
  tSwitchEBillToPaperlessModalUpgradeToPaperless:
    "Upgrade to paperless for all communication and create a greener, brighter Texas.",
};
