import {
  StyledButtonsContainer,
  StyledDialogButton,
  StyledDialogContent,
} from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons.styled";
import { DialogContentProps } from "@mui/material";
import React, { FC } from "react";

interface ButtonProps {
  "data-tracking-click": {
    [key: string]: string;
    event: string;
  };
  isDisabled?: boolean;
  label: string;
  onClick(): void;
}

interface RhDialogContentWithButtonsProps extends DialogContentProps {
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
}

export const RhDialogContentWithButtons: FC<
  React.PropsWithChildren<RhDialogContentWithButtonsProps>
> = ({ children, primaryButton, secondaryButton, ...restProps }) => {
  return (
    <StyledDialogContent {...restProps}>
      {children}
      <StyledButtonsContainer justifyContent="space-between" marginY={3}>
        <StyledDialogButton
          data-tracking-click={secondaryButton["data-tracking-click"]}
          data-testid="RhDialogContentWithButtons__Button--secondary"
          onClick={secondaryButton.onClick}
          variant="outlined"
        >
          {secondaryButton.label}
        </StyledDialogButton>
        <StyledDialogButton
          data-tracking-click={primaryButton["data-tracking-click"]}
          data-testid="RhDialogContentWithButtons__Button--primary"
          color="primary"
          onClick={primaryButton.onClick}
          type="submit"
          disabled={primaryButton.isDisabled}
        >
          {primaryButton.label}
        </StyledDialogButton>
      </StyledButtonsContainer>
    </StyledDialogContent>
  );
};
