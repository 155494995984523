import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { FormControl, FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const StyledPaymentAmountWrapper = styled.div`
  border-bottom: ${borders[100]};
  margin-bottom: ${rhSpacingPx(3)};
  padding-bottom: ${rhSpacingPx(3)};
`;

interface Props {
  component: React.ElementType;
}

export const StyledPaymentAmountFormControl = styled(FormControl)<Props>`
  display: block;
`;

export const StyledPaymentAmountFormControlLabel = styled(FormControlLabel)<{
  $marginBottom?: string;
}>`
  &.MuiFormControlLabel-root {
    margin-bottom: ${({ $marginBottom }) => $marginBottom};
    margin-left: 0;
  }

  & .MuiTypography-root {
    font-family: ${fontFamily};
    font-size: 14px;
    line-height: 1.5;
  }
`;
