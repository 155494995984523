import { BrandLogo } from "@common/components/BrandLogo";
import { guestHeaderLogoTranslations } from "@portal-guest/components/GuestHeaderLogo/GuestHeaderLogo.en.i18n";
import { StyledLogoLink } from "@portal-guest/components/GuestHeaderLogo/GuestHeaderLogo.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";

interface GuestHeaderLogoProps {}

export const GuestHeaderLogo = (props: GuestHeaderLogoProps) => {
  const { translate } = useTranslations();
  const { tGuestHeaderLogoHome } = translate(guestHeaderLogoTranslations);

  const marketingUrl = brandInformation.urlsHomePage;

  return (
    <StyledLogoLink
      to={marketingUrl}
      external
      target="_self"
      aria-label={tGuestHeaderLogoHome}
    >
      <BrandLogo />
    </StyledLogoLink>
  );
};
