import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const ChartContainer = styled.div`
  min-height: 350px;
  width: 95%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    min-height: 475px;
  }
`;

export const NoCompetitorsChartOverlay = styled.div`
  align-items: center;
  background-image: linear-gradient(white, rgba(255, 255, 255, 0.85));
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: center;
  min-height: 475px;
  text-align: center;
  width: 100%;
`;

export const NoCompetitorsText = styled(RhTypography).attrs({
  fontWeight: FontWeight.Regular,
  variant: "h3",
})`
  &.MuiTypography-root {
    margin: 0 auto;
    max-width: 30ch;
  }
`;

export const ChartUpdatedTime = styled.p`
  padding-top: 1rem;
`;

export const ComparisonChartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
