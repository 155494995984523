import {
  OFFER_CONTAINER_WIDTH_1CARD,
  OFFER_CONTAINER_WIDTH_2CARD,
  OFFER_CONTAINER_WIDTH_3CARD,
  OFFER_CONTAINER_WIDTH_4CARD,
} from "@design-system/theme/style.constant";
import { StyledRhOffersGrid } from "@portal/components/RhOffersGrid/RhOffersGrid.styled";
import React, { FC } from "react";

interface RhOffersGridProps {
  limitMaxWidth?: boolean;
  noPadding?: boolean;
  offerCount?: number;
}

export const RhOffersGrid: FC<React.PropsWithChildren<RhOffersGridProps>> = ({
  limitMaxWidth = false,
  noPadding,
  offerCount,
  children,
}) => {
  const offersGridMaxWidth = (): number => {
    switch (offerCount) {
      case 1:
        return OFFER_CONTAINER_WIDTH_1CARD;
      case 2:
        return OFFER_CONTAINER_WIDTH_2CARD;
      case 4:
        return OFFER_CONTAINER_WIDTH_4CARD;
      default:
        return OFFER_CONTAINER_WIDTH_3CARD;
    }
  };

  return (
    <StyledRhOffersGrid
      maxWidth={limitMaxWidth ? offersGridMaxWidth() : undefined}
      noPadding={noPadding}
      offerCount={offerCount}
    >
      {children}
    </StyledRhOffersGrid>
  );
};
