import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useCreateProspectMutation } from "@enroll-data/hooks/mutations/useCreateProspect.mutation";
import { useProspectQuery } from "@enroll-data/hooks/queries/useProspect.query";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import {
  EnrollProspectType,
  ProspectCreateRequest,
} from "@enroll-utils/types/prospectTypes";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { featureFlagUserIdAtom } from "@portal-shared/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { getLocale } from "@portal-shared/utils/getBrowserLocale";
import { prospectReceived } from "@portal/slices/signUpSlice";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const ProspectContext = createContext<EnrollProspectType | null>(null);

interface ProspectProviderProps {}

const cleanProspectRequest = (
  obj: ProspectCreateRequest
): Partial<ProspectCreateRequest> => {
  const result: Partial<ProspectCreateRequest> =
    {} as Partial<ProspectCreateRequest>;

  Object.keys(obj).forEach((k: string) => {
    const key = k as keyof ProspectCreateRequest;
    const value = obj[key as keyof ProspectCreateRequest];

    if (value !== null && value !== undefined) {
      result[key] = value;
    }
  });

  return result;
};

interface ProspectProviderProps {
  createRetry?: number;
}

export const ProspectProvider = ({
  children,
  createRetry = 2,
}: PropsWithChildren<ProspectProviderProps>) => {
  const setFeatureFlagUserIdAtom = useSetAtom(featureFlagUserIdAtom);
  const [locale, setLocale] = useLocaleAtom();
  const [searchParams] = useSearchParams();

  const createProspectMutation = useCreateProspectMutation({
    mutationOptions: {
      retry: createRetry,
    },
  });
  const [prospect, setProspect] = useState<EnrollProspectType | null>(null);
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);
  const dispatch = useDispatch();
  const {
    cookies: {
      _ga: googleClientIdCookie,
      ajs_anonymous_id: segmentAnonIdCookie,
      rhFeatureFlagUserId,
      rhProspectUuid,
    },
    cookies,
    removeCookie,
  } = useEnrollCookies();

  useEffect(() => {
    if (rhProspectUuid) {
      setFeatureFlagUserIdAtom(rhProspectUuid);
    }
  }, [rhProspectUuid, setFeatureFlagUserIdAtom]);

  useEffect(() => {
    if (prospect) {
      setGoogleAnalyticsDataAtom({
        prospectOfferSnapshotUuid: prospect.offerSnapshotUuid || undefined,
        prospectUuid: prospect.uuid,
        type: "loggedOut",
      });
    } else {
      setGoogleAnalyticsDataAtom(RESET);
    }
  }, [prospect, setGoogleAnalyticsDataAtom]);

  useEffect(() => {
    if (
      rhProspectUuid ||
      Object.keys(cookies).length === 0 ||
      createProspectMutation.isPending
    ) {
      return;
    }

    const prospectCreateBody: ProspectCreateRequest = {
      acquisitionCampaign: searchParams.get("rh_campaign"),
      acquisitionContent: searchParams.get("rh_content"),
      acquisitionMedium: searchParams.get("rh_medium"),
      acquisitionSource: searchParams.get("rh_source"),
      featureFlagUserId:
        rhFeatureFlagUserId || searchParams.get("featureFlagUserId"),
      googleAnalyticsUserPseudoId: googleClientIdCookie,
      googleClientId: googleClientIdCookie,
      languagePreference: getLocale(searchParams.get("locale")) || locale,
      rcid: searchParams.get("rcid"),
      referralCode: searchParams.get("referralCode"),
      segmentAnonId: segmentAnonIdCookie,
      zipCode: searchParams.get("zipcode"),
    };

    const cleanedProspectCreateBody = cleanProspectRequest(prospectCreateBody);

    createProspectMutation.mutate(cleanedProspectCreateBody);
  }, [cookies]);

  const prospectQuery = useProspectQuery();

  useEffect(() => {
    if (prospectQuery.isError) {
      removeCookie("rhProspectUuid");
    }
  }, [prospectQuery.isError, removeCookie]);

  useEffect(() => {
    const prospectData = prospectQuery.data;

    if (prospectData) {
      setProspect(prospectData);

      dispatch(
        prospectReceived({
          ...prospectData,
          addressLine1: prospectData.addressLine1 ?? undefined,
          autopay: Boolean(prospectData.autopay),
          city: prospectData.city ?? undefined,
          eBillOnly: Boolean(prospectData.eBillOnly),
          phone: prospectData.phone || "",
          state: prospectData.state ?? undefined,
          unitNumber: prospectData.unitNumber ?? undefined,
          zipCode: prospectData.zipCode ?? undefined,
        })
      );

      if (prospectData.languagePreference) {
        setLocale(prospectData.languagePreference);
      }
    }
  }, [prospectQuery.data]);

  if (createProspectMutation.isError) {
    return <Error500Page />;
  }

  if (
    prospectQuery.isError ||
    prospectQuery.isPending ||
    createProspectMutation.isPending
  ) {
    return <RhCircularProgress />;
  }

  if (!rhProspectUuid) {
    return <RhCircularProgress />;
  }

  if (!prospect) {
    return <RhCircularProgress />;
  }

  return (
    <ProspectContext.Provider value={prospect}>
      {children}
    </ProspectContext.Provider>
  );
};
