export const PaymentMethodViewTranslations = {
  tPaymentMethodViewDefaultPayment: "Default payment method",
  tPaymentMethodViewDelete: "Delete",
  tPaymentMethodViewDeletePaymentApiError:
    "Unable to delete card. Please try again.",
  tPaymentMethodViewOneTimePayment: "One time payment method",
  tPaymentMethodViewPreferencesUpdated:
    "Your billing preferences have been updated.",
  tPaymentMethodViewSetAsDefault: "Set as Default",
  tPaymentMethodViewSwitchDefaultPaymentApiError:
    "Unable to save default payment method. Please try again.",
  tPaymentMethodViewTurnOffAutopay:
    "Please turn off autopay to delete this payment method.",
};
