import { RhApiError } from "@common/types/errorTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { invoiceFileDownload } from "@common/utils/invoiceFileDownload";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { StyledInvoicesRowElement } from "@portal/components/BillingHistoryTable/BillingHistoryTable.styled";
import {
  StyledBillInvoiceRow,
  StyledLateFeeMessage,
} from "@portal/components/BillInvoices/BillInvoices.styled";
import { usePremise } from "@portal/hooks/usePremise";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { Invoice } from "@portal/models/Invoice.model";
import { MyAccountEvents } from "@portal/services/segment.service";
import React from "react";

interface BillInvoicesProps {
  invoices: Invoice[];
}

export const BillInvoices = ({ invoices }: BillInvoicesProps) => {
  const { premise } = usePremise();
  const { t } = useRhIntl();
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();

  if (invoices.length === 0 || !premise) {
    return null;
  }

  const invoicesWithAmountDue = invoices.filter(
    (invoice) => invoice.balance > 0
  );

  const handleDownload = (invoice: Invoice) => {
    track({
      event: MyAccountEvents.downloadBill,
      label: "View PDF",
    });

    invoiceFileDownload({
      billingAccountNumber: premise.billingAccountNumber,
      invoiceDate: invoice.invoiceDate,
      invoiceId: invoice.id,
      premiseId: premise.id,
    })
      .then(noop)
      .catch((_error: RhApiError) => {
        flash.error(t("BillInvoices.errorViewingPDF"));
      });
  };

  return (
    <RhFlexBox data-testid="billInvoices__container" flexDirection="column">
      {invoicesWithAmountDue.map((invoice) => (
        <StyledBillInvoiceRow key={invoice.id}>
          <StyledInvoicesRowElement variant="body1" component="div">
            {invoice.formattedInvoiceDate}
            {invoice.lateFeeApplied ? (
              <StyledLateFeeMessage component="div" variant="body2">
                {t("BillingHistory.lateFeesIncluded")}
              </StyledLateFeeMessage>
            ) : null}
            {invoice.rewardCreditApplied ? (
              <StyledLateFeeMessage component="div" variant="body2">
                {t("BillingHistory.rewardCreditIncluded")}
              </StyledLateFeeMessage>
            ) : null}
          </StyledInvoicesRowElement>
          <RhAnchor
            rel="noopener noreferrer"
            onClick={() => handleDownload(invoice)}
            target="_self"
          >
            <RhTypography variant="body1" color="textSecondary">
              {t("BillInvoices.viewPdf")}
            </RhTypography>
          </RhAnchor>
          <StyledInvoicesRowElement variant="body1">
            {formatCurrency(invoice.balance)}
          </StyledInvoicesRowElement>
        </StyledBillInvoiceRow>
      ))}
    </RhFlexBox>
  );
};
