import { RhAnnouncement } from "@design-system/components/RhAnnouncement/RhAnnouncement";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import React from "react";

interface PortalAnnouncementProps {}

export const PortalAnnouncement = (props: PortalAnnouncementProps) => {
  const { content } = useRhAnnouncement();

  if (!content) {
    return null;
  }

  return <RhAnnouncement />;
};
