export const ZuoraAddBankAccountTranslations = {
  authorizationFailed:
    "We're having trouble connecting to the billing service. Please try again.",
  errorAddingBankAccount:
    "Unable to add bank account. Please verify your bank information and try again.",
  "formError.accountNumberInvalid": "Please enter a valid account number.",
  "formError.fieldEmpty": "Please complete all form fields.",
  "formError.genericError":
    "We were unable to add your bank account. Please try again.",
  "formError.routingNumberInvalid": "Please enter a valid routing number.",
};
