export const signUpPaymentPageTranslations = {
  tSignUpPaymentPageBankAccount: "Bank account",
  tSignUpPaymentPageCreditCardPlease: "Let's get you set up, shall we?",
  tSignUpPaymentPageCreditOrDebit: "Credit or debit",
  tSignUpPaymentPagePreferredPaymentMethodAutopayFalse:
    "Since you clicked on Automatic Pay, please provide which account you'd like linked.",
  tSignUpPaymentPageSkipPaymentInfo:
    "I prefer not to give payment info at this time.",
  tSignUpPaymentPageSubmitCta: "Next",
  tSignUpPaymentPageTakeCareOfThisNow:
    "Enter your payment info below and bask in the simplicity of paying your electric bill.",
};
