import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledRadioGroupWrap = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
`;

export const StyledMyAccountSubmitButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
