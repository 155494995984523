import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import {
  StyledBaseErrorPageContainer,
  StyledBodyContainer,
  StyledErrorCode,
  StyledErrorTitle,
  StyledIllustrationContainer,
  StyledReturnHomeLink,
} from "@portal-shared/pages/BaseErrorPage/BaseErrorPage.styled";
import React, { PropsWithChildren } from "react";

interface BaseErrorPageProps {
  code?: string;
  header: string;
  illustration?: JSX.Element;
  showLinkToHome: boolean;
}
export const BaseErrorPage = ({
  code,
  children,
  header,
  illustration,
  showLinkToHome,
}: PropsWithChildren<BaseErrorPageProps>) => {
  const withIllustration = !!illustration;
  const { t } = useRhIntl();
  const returnHome = t("BaseErrorPage.returnHome");

  return (
    <StyledBaseErrorPageContainer>
      {withIllustration && (
        <StyledIllustrationContainer $withIllustration={withIllustration}>
          {illustration}
        </StyledIllustrationContainer>
      )}
      <StyledBodyContainer $withIllustration={withIllustration}>
        {Boolean(code) && (
          <StyledErrorCode color="inherit" variant="h1">
            {code}
          </StyledErrorCode>
        )}
        <StyledErrorTitle variant="h1">{header}</StyledErrorTitle>
        {children}
        {showLinkToHome && (
          <StyledReturnHomeLink href="/">{returnHome}</StyledReturnHomeLink>
        )}
      </StyledBodyContainer>
    </StyledBaseErrorPageContainer>
  );
};
