import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalCheckboxLabel } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  text-align: center;
`;

export const StyledWarningHeader = styled.div`
  align-items: center;
  color: ${CSSVariables.COLOR_ERROR_MAIN};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  text-align: center;
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(1)};
  width: 100%;

  > * {
    flex: 1;
  }
`;

export const StyledAgreementCheckboxLabel = styled(PortalCheckboxLabel)`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: ${rhSpacingPx(1)};
  justify-content: flex-start;
  text-align: left;
`;
