import { ajax } from "@common/services/ajax";
import { UUIDType } from "@common/types/apiTypes";
import { PaymentMethodType } from "@common/types/paymentMethodTypes";
import { QuickPayUrls } from "@portal-guest/api/quickPayUrls";
import {
  QuickPayAccountBalanceType,
  QuickPayCreatePaymentRequestType,
  QuickPayCreatePaymentResponseType,
  QuickPayPaymentMethodType,
} from "@portal-guest/types/quickPayTypes";

export const quickPayApi = {
  addPaymentMethod: ({
    isOneTimePayment,
    token,
    paymentMethodId,
    setDefaultPaymentMethod,
  }: {
    isOneTimePayment?: boolean;
    paymentMethodId: string;
    setDefaultPaymentMethod?: boolean;
    token: string;
  }): Promise<null> =>
    ajax.put(QuickPayUrls.addPaymentMethod(token), {
      isOneTimePayment,
      paymentMethodId,
      setDefaultPaymentMethod,
    }),
  createPayment: ({
    token,
    paymentMethodId,
    amount,
    isOneTimePayment,
  }: QuickPayCreatePaymentRequestType): Promise<QuickPayCreatePaymentResponseType> =>
    ajax.post(QuickPayUrls.createPayment(token), {
      amount,
      isOneTimePayment,
      paymentMethodId,
    }),
  getAccountBalance: (token: UUIDType) =>
    ajax.get<QuickPayAccountBalanceType>(QuickPayUrls.getAccountBalance(token)),
  getPaymentMethod: (token: UUIDType, paymentMethodId: string) =>
    ajax.get<PaymentMethodType>(
      QuickPayUrls.getPaymentMethod(token, paymentMethodId)
    ),
  getPaymentMethods: (token: UUIDType) =>
    ajax.get<QuickPayPaymentMethodType>(QuickPayUrls.getPaymentMethods(token)),
  resend: (token: UUIDType): Promise<null> =>
    ajax.put(QuickPayUrls.resend(token), {}),
};
