import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { EnergyUsageSectionV2 } from "@portal/components/EnergyUsageSectionV2/EnergyUsageSectionV2";
import { usePremise } from "@portal/hooks/usePremise";
import React from "react";

export const EnergyUsageSectionHandler = () => {
  const { premise } = usePremise();

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalUsageChart } = featureFlagClient.getFlags([
    ["portalUsageChart", false],
  ]);

  if (
    !premise ||
    !premise.currentOrder ||
    !premise.meter ||
    !premise.meter.isSmt ||
    !portalUsageChart.value
  ) {
    return null;
  }

  return <EnergyUsageSectionV2 />;
};
