import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  justify-content: center;
  width: 100%;
`;

export const StyledRadioContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  padding: 2rem 0 0.5rem;
  width: 100%;
`;
