export const billFactorsTranslations = {
  tBillFactorsBillingCycle: "Billing Cycle",
  tBillFactorsBillingCycleIncreased:
    "The number of days on your billing cycle increased by {difference} days this month.",
  tBillFactorsBillingCycleShorter:
    "This month, your billing cycle was {difference} days shorter. When your meter gets read each month can fluctuate.",
  tBillFactorsColderDays: "Colder weather days impacted your bill this month.",
  tBillFactorsContractChanges: "Contract Changes",
  tBillFactorsDeliveryChargeChanges: "{utilityName} Delivery Charge Changes",
  tBillFactorsHotterDays: "Hotter weather days impacted your bill this month.",
  tBillFactorsRollover:
    "There's a rollover balance of {amount} on your account, which has been added to this bill.",
  tBillFactorsRolloverBalance: "Rollover Balance",
  tBillFactorsUsage: "Usage",
  tBillFactorsUsageLess:
    "This month, you used {usage} kWh less than last billing cycle.",
  tBillFactorsUsageMore:
    "This month, you used {usage} kWh more than last billing cycle.",
  tBillFactorsUtilityWhichControls:
    "{utilityName}, which controls your energy delivery charges, increased their rates by {percentage}%. These are reflected on your bill without markup.",
  tBillFactorsWeather: "Weather",
  tBillFactorsYourContract:
    "Your contract ended on {endDate}, and you are in a new contract.",
  tBillFactorsYourContractMtm:
    "Your contract ended on {endDate}, and you have been automatically enrolled in a month-to-month plan.",
};
