import { PremiseStatus } from "@common/enums/premise.enum";
import { IdType } from "@common/types/apiTypes";
import { PremiseListItemType } from "@common/types/premiseTypes";
import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { selectedDevicesWithPremiseAtom } from "@portal/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { devicePremiseSelectorTranslations } from "@portal/components/DevicePremiseSelector/DevicePremiseSelector.en.i18n";
import { StyledDevicePremiseMenuItem } from "@portal/components/DevicePremiseSelector/DevicePremiseSelector.styled";
import { DevicePremiseListItem } from "@portal/components/DevicePremiseSelector/DevicePremiseSelectorListItem";
import { NO_PREMISE_SELECTED } from "@portal/components/DevicesEnrollSelectDevicesForm/constants";
import {
  StyledChevronIconContainer,
  StyledPortalSelectorContainer,
  StyledPortalSelectorMenu,
  StyledPortalSelectorMenuContainer,
  StyledPortalSelectorMenuItem,
  StyledSelectedItemShown,
} from "@portal/components/PortalSelector/PortalSelector.styled";
import { useClickOutsideViewToggle } from "@portal/hooks/useClickOutsideViewToggle";
import { DeviceType, DeviceWithPremise } from "@portal/types/devicesTypes";
import { useAtom } from "jotai";
import React from "react";

interface DevicePremiseSelectorProps {
  device: DeviceType;
  premises: PremiseListItemType[] | undefined;
}

export const DevicePremiseSelector = (props: DevicePremiseSelectorProps) => {
  const { device, premises } = props;
  const { deviceId } = device;
  const { translate } = useTranslations();
  const [selectedDevicesWithPremise, setSelectedDevicesWithPremise] = useAtom(
    selectedDevicesWithPremiseAtom
  );
  const { ref, isOpen, setIsOpen } =
    useClickOutsideViewToggle<HTMLDivElement>();
  const selectorId = `DevicePremiseSelector-${deviceId}`;
  const menuId = `DevicePremiseSelectorMenu-${deviceId}`;

  const {
    tDevicePremiseSelectorDoNotAdd,
    tDevicePremiseSelectorSelectAddress,
  } = translate(devicePremiseSelectorTranslations);

  const selectedDevice = selectedDevicesWithPremise.find(
    (deviceAtom) => deviceAtom.device.deviceId === deviceId
  );

  const selectedPremise = premises?.find(
    (premise) => premise.id === selectedDevice?.premise?.id
  );

  const toggleDevicePremiseSelector = () => {
    setIsOpen((prev) => !prev);
  };

  if (!premises) {
    return null;
  }

  const activeOrPendingPremisesOnly = premises.filter(
    (premise) => premise.status !== PremiseStatus.INACTIVE
  );

  const handleSelectedPremise = (premiseId: IdType) => {
    setSelectedDevicesWithPremise((currentlySelectedDevices) => {
      const devicesToKeepSelected = currentlySelectedDevices.filter(
        (deviceWithPremise) => deviceWithPremise.device.deviceId !== deviceId
      );

      if (premiseId === NO_PREMISE_SELECTED) {
        return devicesToKeepSelected;
      }

      const premiseToAdd = premises.find((premise) => premise.id === premiseId);

      const deviceWithPremiseToAdd: DeviceWithPremise = {
        device,
        premise: {
          address: premiseToAdd?.serviceAddress,
          id: premiseId,
        },
      };

      return [...devicesToKeepSelected, deviceWithPremiseToAdd];
    });

    return toggleDevicePremiseSelector();
  };

  return (
    <StyledPortalSelectorContainer $minWidth="auto" ref={ref}>
      <StyledSelectedItemShown
        $hasMenuItems
        $minWidth="auto"
        aria-controls={menuId}
        aria-haspopup="true"
        aria-label={`Select address for ${device.deviceBrand} device ${device.deviceName}`}
        data-selected={Boolean(selectedDevice)}
        id={selectorId}
        onClick={toggleDevicePremiseSelector}
      >
        {selectedPremise ? (
          <DevicePremiseListItem premise={selectedPremise} />
        ) : (
          <div>{tDevicePremiseSelectorSelectAddress}</div>
        )}
        <StyledChevronIconContainer
          $isOpen={isOpen}
          data-testid="DevicePremiseSelector__ChevronDownIcon"
        >
          <ChevronDown height="10px" width="10px" />
        </StyledChevronIconContainer>
      </StyledSelectedItemShown>
      {isOpen ? (
        <StyledPortalSelectorMenuContainer
          id={menuId}
          aria-hidden={!isOpen}
          $minWidth="auto"
        >
          <StyledPortalSelectorMenu
            role="menu"
            aria-label={`premiseMenu-${deviceId}`}
          >
            <StyledPortalSelectorMenuItem role="menuitem">
              <StyledDevicePremiseMenuItem
                type="button"
                onClick={() => handleSelectedPremise(NO_PREMISE_SELECTED)}
              >
                {tDevicePremiseSelectorDoNotAdd}
              </StyledDevicePremiseMenuItem>
            </StyledPortalSelectorMenuItem>
            {activeOrPendingPremisesOnly.map((premise) => (
              <StyledPortalSelectorMenuItem
                key={premise.id}
                role="menuitem"
                $isSelected={selectedPremise?.id === premise.id}
              >
                <StyledDevicePremiseMenuItem
                  type="button"
                  aria-label={premise.serviceAddress?.deliveryLine1}
                  onClick={() => handleSelectedPremise(premise.id)}
                >
                  <DevicePremiseListItem
                    premise={premise}
                    isSelectedPremise={
                      selectedPremise?.id
                        ? premise.id === selectedPremise.id
                        : false
                    }
                    isInMenu
                  />
                </StyledDevicePremiseMenuItem>
              </StyledPortalSelectorMenuItem>
            ))}
          </StyledPortalSelectorMenu>
        </StyledPortalSelectorMenuContainer>
      ) : null}
    </StyledPortalSelectorContainer>
  );
};
