import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalCheckboxLabel } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import { PortalToggle } from "@portal-shared/components/PortalToggle/PortalToggle";
import styled from "styled-components";

export const RenewalSummaryFormContainer = styled.form`
  align-items: center;
  border-top: 1px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(4)};
  justify-content: center;

  padding: 0 ${rhSpacingPx(2)};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    width: 450px;
  }
`;

export const BillingPreferencesContainer = styled.div`
  border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};

  margin: ${rhSpacingPx(1)} 0;
  padding: ${rhSpacingPx(3)} 0;
  width: 100%;
`;

export const FinePrintContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const RenewalSummaryFormPortalToggle = styled(PortalToggle)`
  display: grid;
  gap: ${rhSpacingPx(2)};
  grid-template-columns: max-content 1fr min-content;
  padding-bottom: ${rhSpacingPx(1)};

  & > svg {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    height: 22px;
    width: 20px;
  }
`;

export const StyledTermsCheckboxLabel = styled(PortalCheckboxLabel)`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
`;

export const StyledTermsContainer = styled.div`
  padding-top: ${rhSpacingPx(2)};
  .MuiCheckbox-root {
    align-self: self-start;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    width: 450px;
  }
`;

export const StyledPlanDetailRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const StyledPlanDetailRowLabel = styled.div`
  flex: 1;
  max-width: 170px;
`;

export const StyledPlanDetailRowValue = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  margin-top: ${rhSpacingPx(2)};

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    padding-left: 20%;
  }
`;
