import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const MultiAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
`;

export const SimpliSafeList = styled.ul`
  list-style: disc;
  margin: 1rem 0;
  padding: 0 0 0 2rem;

  li {
    padding-bottom: 0.5rem;
  }
`;
