import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useState,
} from "react";

type Content = null | string | JSX.Element;

export enum AnnouncementSeverity {
  Error = "error",
  Info = "info",
  Notice = "notice",
}

export interface RhAnnouncementProviderContext {
  announceError(
    content: string | JSX.Element | ReactNode,
    dismissible?: boolean
  ): void;
  announceInfo(
    content: string | JSX.Element | ReactNode,
    dismissible?: boolean
  ): void;
  announceNotice(
    content: string | JSX.Element | ReactNode,
    dismissible?: boolean
  ): void;
  bannerRef: React.RefObject<HTMLDivElement> | null;
  clearAnnouncement(): void;
  content: Content;
  dismissible: boolean;
  setBannerRef: (ref: React.RefObject<HTMLDivElement>) => void;
  severity: AnnouncementSeverity;
}

export const RhAnnouncementContext =
  createContext<RhAnnouncementProviderContext>({
    announceError: () => () => undefined,
    announceInfo: () => () => undefined,
    announceNotice: () => () => undefined,
    bannerRef: null,
    clearAnnouncement: () => undefined,
    content: null,
    dismissible: false,
    setBannerRef: () => {},
    severity: AnnouncementSeverity.Error,
  });

export const RhAnnouncementProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [content, setContent] = useState<Content>(null);
  const [severity, setSeverity] = useState<AnnouncementSeverity>(
    AnnouncementSeverity.Error
  );
  const [dismissible, setDismissable] = useState<boolean>(false);
  const [bannerRef, setBannerRef] =
    useState<React.RefObject<HTMLDivElement> | null>(null);

  const errorFn = useCallback(
    (newContent: string | JSX.Element, newDismissable: boolean = false) => {
      setContent(newContent);
      setSeverity(AnnouncementSeverity.Error);
      setDismissable(newDismissable);
    },
    []
  );

  const noticeFn = useCallback(
    (newContent: string | JSX.Element, newDismissable: boolean = false) => {
      setContent(newContent);
      setSeverity(AnnouncementSeverity.Notice);
      setDismissable(newDismissable);
    },
    []
  );

  const infoFn = useCallback(
    (newContent: string | JSX.Element, newDismissable: boolean = false) => {
      setContent(newContent);
      setSeverity(AnnouncementSeverity.Info);
      setDismissable(newDismissable);
    },
    []
  );

  const clearAnnouncement = useCallback(() => {
    setContent(null);
    setDismissable(false);
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const actions: RhAnnouncementProviderContext = {
    announceError: errorFn,
    announceInfo: infoFn,
    announceNotice: noticeFn,
    bannerRef,
    clearAnnouncement,
    content,
    dismissible,
    setBannerRef,
    severity,
  };

  return (
    <RhAnnouncementContext.Provider value={actions}>
      {children}
    </RhAnnouncementContext.Provider>
  );
};
