import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { UUIDType } from "@common/types/apiTypes";
import { quickPayApi } from "@portal-guest/api/quickPayApi";
import { QuickPayQueryKeys } from "@portal-guest/queryKeys/quickPayQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseQuickPayPaymentMethodsProps {
  token: UUIDType;
}

export const useQuickPayPaymentMethodsQuery = (
  props: UseQuickPayPaymentMethodsProps
) => {
  const { token } = props;

  const query = useQuery({
    queryFn: () => quickPayApi.getPaymentMethods(token),
    queryKey: QuickPayQueryKeys.paymentMethods(token),
    // This prevents refetch on the zuora iFrame being added/removed
    refetchOnWindowFocus: false,
    // We don't want to refetch on error as this is considered isPending on refetch
    retry: false,
    select: (data) => {
      return {
        ...data,
        paymentMethods: data.paymentMethods.map(
          (paymentMethod) => new PaymentMethod(paymentMethod)
        ),
      };
    },
  });

  return query;
};
