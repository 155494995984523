import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import styled, { css } from "styled-components";

export const StyledMyAccountTable = styled.table`
  background-color: ${CSSVariables.COLOR_WHITE};
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`;

export const StyledTableHeader = styled.th`
  background-color: ${CSSVariables.COLOR_WHITE};
  border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};
  color: ${CSSVariables.COLOR_GREY_700};
  font-size: 0.6rem;
  font-weight: ${FontWeight.Regular};
  padding: 0.5rem;
  text-align: left;

  @media screen and (min-width: 500px) {
    font-size: 0.85rem;
    padding: 1rem 0.5rem;
  }
`;

export const StyledTableRow = styled.tr`
  border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};

  &:hover {
    background-color: ${CSSVariables.COLOR_GREY_50};
  }
`;

export const StyledTableCell = styled.td<{
  $isDownloadCell?: boolean;
  $noPadding?: boolean;
  $tableCellCenter?: boolean;
}>`
  font-size: 0.6rem;
  padding: 0.5rem;
  vertical-align: top;

  ${({ $isDownloadCell }) =>
    $isDownloadCell &&
    css`
      padding-right: 1rem;
      text-align: right;
    `}

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0 !important;
    `}

  ${({ $tableCellCenter }) =>
    $tableCellCenter &&
    css`
      vertical-align: middle;
    `}

  @media screen and (min-width: 500px) {
    font-size: 0.85rem;
    padding: 1rem 0.5rem;
  }
`;

export const StyledDownloadLink = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  font-family: inherit;
  font-size: inherit;
  padding: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledDownloadContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTablePaginationContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 1rem 1rem 0;
  text-align: center;
`;

export const StyledPrevNextButton = styled.button`
  background: none;
  border: 0;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  margin: 0;
  padding: 0;
  transform: translateY(1px);

  &:disabled {
    color: ${CSSVariables.COLOR_GREY_500};
    opacity: 0.25;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledPageButton = styled.button`
  appearance: none;
  background: 0;
  border: 0;
  border-radius: 1000px;
  color: ${CSSVariables.COLOR_BLACK};
  font-family: inherit;
  min-height: 24px;
  min-width: 24px;
  padding: 0.25rem;

  &:hover {
    background: ${CSSVariables.COLOR_GREY_50};
    cursor: pointer;
  }

  &.active {
    background: ${CSSVariables.COLOR_SECONDARY_LIGHT};
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    font-weight: ${FontWeight.Semibold};
    pointer-events: none;
  }
`;
