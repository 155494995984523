import { useAppCookies } from "@common/hooks/useAppCookies";

type AllKeysRequiredButAllowUndefined<T> = {
  [K in keyof Required<T>]: T[K] | undefined;
};
export interface GuestCookiesWithEnv {}

const defaultGuestCookiesWithEnvValues: AllKeysRequiredButAllowUndefined<GuestCookiesWithEnv> =
  {};

export interface GuestCookies extends GuestCookiesWithEnv {
  django_language?: "en" | "es";
}

const defaultGuestCookiesValues: AllKeysRequiredButAllowUndefined<GuestCookies> =
  {
    django_language: "en",
    ...defaultGuestCookiesWithEnvValues,
  };

// Only used for any cookie use in th logged in experience
export const useGuestCookies = () => {
  return useAppCookies<GuestCookies>(
    defaultGuestCookiesValues,
    defaultGuestCookiesWithEnvValues
  );
};
