import styled from "styled-components";

export const AvailabilityPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  justify-content: center;
  margin: 5rem auto 0;
  max-width: 400px;
  position: relative;
  text-align: center;
  z-index: 5;
`;
