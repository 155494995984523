import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { IconButton } from "@mui/material";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    &:active {
      background-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
      color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }

    &:hover,
    &:focus {
      background-color: ${CSSVariables.COLOR_PRIMARY_LIGHTEST};
      color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }
    color: ${CSSVariables.COLOR_GREY_600};
    font-size: 2.2rem;

    padding: 12px;
  }
`;
