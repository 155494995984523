export const signUpSummaryInfoTranslations = {
  tSignUpSummaryInfoAddOnMonthlyRate: "{termMonths} month contract",
  tSignUpSummaryInfoAddOnsLabel: "Add-ons",
  tSignUpSummaryInfoAddressLabel: "Address",
  tSignUpSummaryInfoAutoPayLabel: "Auto Pay",
  tSignUpSummaryInfoAutoPayPaperlessDiscountWarning:
    "Turn on Auto Pay & Paperless and enjoy your {discountAmount}/month credit!",
  tSignUpSummaryInfoAveragePriceRate: "Avg price per kWh: {price} @2000 kWh",
  tSignUpSummaryInfoAveragePriceRatePaperless:
    "Avg price per kWh with Auto Pay & Paperless: {price} @2000 kWh",
  tSignUpSummaryInfoBaseChargeLabel: "Base charge: {baseCharge} per month",
  tSignUpSummaryInfoBillingPreferencesLabel: "Billing Preferences",
  tSignUpSummaryInfoContactInfoLabel: "Contact Info",
  tSignUpSummaryInfoContractDocumentsLabel: "Contract Documents",
  tSignUpSummaryInfoContractDocumentsTitle: "Download contract documents",
  tSignUpSummaryInfoCreditCard: "Credit Card",
  tSignUpSummaryInfoDepositAlternativeLabel: "Deposit Alternative Fee",
  tSignUpSummaryInfoDepositLabel: "Deposit",
  tSignUpSummaryInfoElectricityFactsLabelLink: "Electricity Facts Label",
  tSignUpSummaryInfoEnergyChargeRate: "Energy charge: {energyCharge} per kWh",
  tSignUpSummaryInfoEsiIdLabel: "ESI ID",
  tSignUpSummaryInfoNameLabel: "Name",
  tSignUpSummaryInfoOffValue: "OFF",
  tSignUpSummaryInfoOnValue: "ON",
  tSignUpSummaryInfoPaperlessLabel: "Paperless",
  tSignUpSummaryInfoPaymentMethodLabel: "Payment Method",
  tSignUpSummaryInfoPlanLabel: "Plan Details",
  tSignUpSummaryInfoPlanMonthlyRate: "{termMonths} month contract",
  tSignUpSummaryInfoPromoCodeApplied: "Promo code applied!",
  tSignUpSummaryInfoPromoCodeLabel: "Promo Code",
  tSignUpSummaryInfoPromoCodeValue:
    "<pointsChunk>{points} points</pointsChunk> / {value} value",
  tSignUpSummaryInfoSolarBuybackRate:
    "Solar Buyback Credit Rate: {solarCreditKwhRate} per kWh",
  tSignUpSummaryInfoStartDateLabel: "Start Date",
  tSignUpSummaryInfoTdspDeliveryCharge:
    "{tdsp} Delivery Charge: {charge} per kWh",
  tSignUpSummaryInfoTdspMonthlyCharge: "{tdsp} Base Charge: {charge} per month",
  tSignUpSummaryInfoTermsOfServiceLink: "Terms of Service",
  tSignUpSummaryInfoUpdateBillingPreferencesLink:
    "Click here to update your preferences.",
  tSignUpSummaryInfoYourRightsAsACustomerLink: "Your Rights As A Customer",
};
