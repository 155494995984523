import {
  StyledCard,
  StyledCardLabel,
  StyledIconContainer,
  StyledLabelContainer,
  StyledRadioContainer,
} from "@design-system/components/RhIconRadioField/RhIconRadioField.styled";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { FormControl, FormLabel } from "@mui/material";
import React, { FocusEvent, ReactElement } from "react";
import { useField } from "react-final-form";

interface RhIconRadioFieldProps<T> {
  icon: ReactElement;
  label: string;
  name: string;
  onBlur?: (event: FocusEvent<HTMLButtonElement | HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement | MouseEvent>;
  value: T;
}

export const RhIconRadioField = <T extends string>({
  icon,
  label,
  name,
  onBlur,
  onClick,
  value,
}: RhIconRadioFieldProps<T>) => {
  const {
    input: { onBlur: rffOnBlur, ...input },
    meta,
  } = useField<T>(name, {
    type: "radio",
    value,
  });
  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  const handleBlur = (event: FocusEvent<HTMLButtonElement>) => {
    rffOnBlur(event);
    if (onBlur) {
      onBlur(event);
    }
  };

  const hasErrors = Boolean(meta.touched && hasValidationError);

  return (
    <FormControl style={{ flex: 1 }}>
      <FormLabel htmlFor={value}>
        <StyledCard
          checked={!!input.checked}
          $hasErrors={hasErrors}
          onMouseUp={onClick}
          elevation={input.checked ? 2 : 0}
        >
          <StyledIconContainer>{icon}</StyledIconContainer>
          <StyledLabelContainer>
            <StyledCardLabel variant="body1" fontWeight="Bold" align="center">
              {label}
            </StyledCardLabel>
          </StyledLabelContainer>
          <StyledRadioContainer>
            <RhRadioInput
              {...input}
              inputProps={{ "aria-checked": input.checked }}
              onBlur={handleBlur}
            />
          </StyledRadioContainer>
        </StyledCard>
      </FormLabel>
    </FormControl>
  );
};
