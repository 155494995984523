export const competitorPlanComparisonPlansModalTranslations = {
  tCompetitorPlanComparisonPlansModalEFL: "EFL",
  tCompetitorPlanComparisonPlansModalEnrollNow: "Enroll now",
  tCompetitorPlanComparisonPlansModalLastUpdated: "Last updated on {date}",
  tCompetitorPlanComparisonPlansModalLearnMore: "Learn More",
  tCompetitorPlanComparisonPlansModalPerKwh: "per kWh",
  tCompetitorPlanComparisonPlansModalPlanName: "Plan Name",
  tCompetitorPlanComparisonPlansModalPrice: "Price",
  tCompetitorPlanComparisonPlansModalProvider: "Provider",
  tCompetitorPlanComparisonPlansModalTitle:
    "Here's how we stack up against our top competitors.",
};
