import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { smartThermostatsUrls } from "@portal/api/smartThermostatsUrls";

export const smartThermostatsApi = {
  destroy: (smartThermostatsId: IdType) =>
    ajax.destroy(smartThermostatsUrls.destroy(smartThermostatsId)),
  swapPremise: ({
    newPremiseId,
    smartThermostatId,
  }: {
    newPremiseId: IdType;
    smartThermostatId: IdType;
  }) =>
    ajax.post(smartThermostatsUrls.swapPremise(smartThermostatId), {
      newPremiseId,
    }),
};
