import { premiseApi } from "@portal/api/premiseApi";
import { premiseRefetch } from "@portal/slices/premiseSlice";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

export const usePremiseRenewContractMutation = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: premiseApi.renewContract,
    onSuccess: () => {
      dispatch(premiseRefetch());
    },
  });
};
