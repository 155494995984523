import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledPlanDetailRow = styled.div`
  display: flex;
  gap: ${rhSpacingPx(2)};
  justify-content: space-between;
  width: 100%;
`;

export const StyledPlanDetailRowLabel = styled.div`
  flex: 1;
  max-width: 170px;
`;

export const StyledPlanDetailRowValue = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
`;

export const StyledPlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  padding: ${rhSpacingPx(4)} ${rhSpacingPx(2)};
  padding-bottom: 0;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    width: 450px;
  }
`;
