import { UUIDType } from "@common/types/apiTypes";
import { ProductTier } from "@portal/models/ProductTier.model";
import { ProductTierPresentationGroupType } from "@portal/types/productTypes";

export interface PresentationGroupWithTiers
  extends Omit<ProductTierPresentationGroupType, "tierPriority"> {
  productTiers: ProductTier[];
}

export function groupProductTiersByPresentationGroup(
  productTiers: ProductTier[]
) {
  // Get all presentation groups from tiers in flat array of ProductTierPresentationGroupType
  const presentationGroups = productTiers
    .map((tier) => tier.presentationGroups)
    .flat();

  const presentationGroupWithTiers: PresentationGroupWithTiers[] = [];
  const mappedGroupIds = new Set<UUIDType>();

  presentationGroups.forEach((presentationGroup) => {
    // If we have already used this ProductTierPresentationGroupType id then skip
    if (mappedGroupIds.has(presentationGroup.presentationGroupId)) {
      return;
    }

    mappedGroupIds.add(presentationGroup.presentationGroupId);

    // Get all tiers that have the presentationGroups presentationGroupId
    const relevantTiers = productTiers.filter((productTier) =>
      productTier.presentationGroups.find(
        (tierGroup) =>
          tierGroup.presentationGroupId ===
          presentationGroup.presentationGroupId
      )
    );

    if (relevantTiers.length > 0) {
      // Sort tiers within the PresentationGroupWithTiers by priority
      const sortedTiers = relevantTiers.sort((a, b) => {
        const aPriority = a.presentationGroups.find(
          (pg) =>
            pg.presentationGroupId === presentationGroup.presentationGroupId
        )?.tierPriority as number;

        const bPriority = b.presentationGroups.find(
          (pg) =>
            pg.presentationGroupId === presentationGroup.presentationGroupId
        )?.tierPriority as number;

        return aPriority - bPriority;
      });

      presentationGroupWithTiers.push({
        groupPriority: presentationGroup.groupPriority,
        icon: presentationGroup.icon,
        name: presentationGroup.name,
        presentationGroupId: presentationGroup.presentationGroupId,
        productTiers: sortedTiers,
      });
    }
  });

  // Finally sort all presentationGroups by groupPriority if groupPriority is present
  return presentationGroupWithTiers.sort((a, b) => {
    if (!a.groupPriority && !b.groupPriority) {
      return 0;
    }

    if (!a.groupPriority) {
      return 1;
    }

    if (!b.groupPriority) {
      return -1;
    }

    return a.groupPriority - b.groupPriority;
  });
}
