import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  text-align: center;
`;

export const StyledSVGWrapper = styled.div`
  svg {
    height: 4rem;
    width: 6rem;
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-flow: row-nowrap;
  gap: ${rhSpacingPx(1)};
  width: 100%;

  > * {
    flex: 1;
  }
`;
