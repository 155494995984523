/**
 * Modals by priority
 * i.e. if multipleTdsp and areaNotServiceable are to be shown,
 * areaNotServiceable will be shown first
 * when areaNotServiceable is closed, multipleTdsp modal will be shown
 */
export const getModalsByPriority = () => {
  return [
    "multipleSignUpFlowTabs",
    "multipleTdsp",
    "switchEBillToPaperless",
    "switchPrintToPaperless",
    "renewalReminder",
    "areaNotServiceable",
    "contractsAdded",
    "energyBreakdown",
    "updateDevicePremise",
    "disconnectDevice",
  ] as const;
};
