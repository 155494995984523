import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import styled from "styled-components";

export const StyledNoContractsCardContent = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledNoContractsCardContentMessage = styled(RhTypography).attrs({
  variant: "subtitle2",
})`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_500};
  }
`;
