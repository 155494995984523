import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { DialogContent } from "@mui/material";
import styled from "styled-components";

export const StyledDialogContainer = styled(DialogContent)`
  text-align: center;
`;

export const StyledDialogTitleWithIllustration = styled(RhDialogTitle)`
  padding: 0 ${rhSpacingPx(4)} ${rhSpacingPx(1)} !important;
`;

export const StyledRedeemRewardsDialogBody = styled(RhTypography)`
  &.MuiTypography-root {
    padding-bottom: ${rhSpacingPx(3)};
    text-align: center;
  }
`;

export const StyledLoadingDialogBody = styled(RhCircularProgress)`
  align-items: center;
  height: 290px;
  justify-content: center;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: ${rhSpacingPx(4)};
`;

export const StyledButtonContainer = styled.div`
  padding: ${rhSpacingPx(1)};
  width: 175px;
`;

export const StyledIllustrationContainer = styled.div`
  & > svg {
    width: 124px;
  }
  margin: auto;
  padding-top: ${rhSpacingPx(3)};
  width: 124px;
`;
