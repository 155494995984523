export const signUpContactPageTranslations = {
  tSignUpContactPageCreditCheckExplainer:
    "By providing your contact information, you acknowledge that Rhythm will seek to verify your identity and credit worthiness, including by running a soft credit check that does not impact your credit rating.",
  tSignUpContactPageCustomerExists: "Customer already exists",
  tSignUpContactPageErrorSendingContact:
    "We ran into an issue. Please try again.",
  tSignUpContactPageInvalidPhoneNumber: "Enter a valid US phone number.",
  tSignUpContactPageLetsKeepInTouch:
    "Hi {firstName}, how can we keep in touch?",
  tSignUpContactPageSubmitCta: "Next",
};
