import {
  StyledInnercard,
  StyledLabel,
  StyledOption,
} from "@design-system/components/RhRadioCardInput/RhRadioCardInput.styled";
import { RhRadioInputProps } from "@design-system/components/RhRadioInput/RhRadioInput";
import { Box, FormControl, FormLabel } from "@mui/material";
import React from "react";

type RhRadioCardInputProps = {
  inputId: string;
  label: string;
} & RhRadioInputProps;

export const RhRadioCardInput = ({
  inputId,
  label,
  ...restProps
}: RhRadioCardInputProps) => {
  return (
    <FormControl>
      <FormLabel htmlFor={inputId}>
        <StyledInnercard>
          <Box alignItems="center" display="flex">
            <StyledOption id={inputId} {...restProps} />
            <StyledLabel>{label}</StyledLabel>
          </Box>
        </StyledInnercard>
      </FormLabel>
    </FormControl>
  );
};
