import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsSolarTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsSolar/OfferSnapshotFAQsSolar.en.i18n";
import { OfferSnapshotFAQsTitleWrapper } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React, { ReactNode } from "react";
import styled from "styled-components";

const AccordionDetails = styled(RhTypography)`
  white-space: pre-line;
`;

interface OfferSnapshotFAQsSolarProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotFAQsSolar = (props: OfferSnapshotFAQsSolarProps) => {
  const {
    offerSnapshot,
    offerSnapshot: { isSolarBuybackPriceDifferential, isTimeOfUseGeneration },
  } = props;

  const { translate, translateJsx } = useTranslations();

  const {
    tOfferSnapshotFAQsSolarCreditNotOneToOneAnswer,
    tOfferSnapshotFAQsSolarWhatIsSolarBuyback,
    tOfferSnapshotFAQsSolarWhatIsSolarBuybackAnswer,
    tOfferSnapshotFAQsSolarWhoIsEligible,
    tOfferSnapshotFAQsSolarWhoIsEligibleAnswer,
    tOfferSnapshotFAQsSolarHowWillIReceiveCredits,
    tOfferSnapshotFAQsSolarHowWillIReceiveCreditsAnswer,
    tOfferSnapshotFAQsSolarDoesRhythmCoverFees,
    tOfferSnapshotFAQsSolarDoesRhythmCoverFeesAnswer,
    tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoneyAnswer,
    tOfferSnapshotFAQsSolarWillIEarnReferAFriend,
    tOfferSnapshotFAQsSolarWillIEarnReferAFriendAnswer,
    tOfferSnapshotFAQsSolarIdontHaveSolarPanels,
    tOfferSnapshotFAQsSolarIdontHaveSolarPanelsAnswer,
    tOfferSnapshotFAQsSolarNoPanelsYet,
    tOfferSnapshotFAQsSolarTimeOfUseTitle,
    tOfferSnapshotFAQsSolarBuybackTitle,
  } = translate(offerSnapshotFAQsSolarTranslations);

  const {
    tOfferSnapshotFAQsSolarNoPanelsYetAnswer,
    tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoney,
    tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitations,
    tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitationsAnswer,
    tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdowns,
    tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdownsAnswer,
  } = translateJsx({
    tOfferSnapshotFAQsSolarNoPanelsYetAnswer: {
      link: (message: ReactNode[]) => (
        <a
          href={brandInformation.urlsPlansSolar}
          rel="noreferrer"
          target="_blank"
        >
          {message}
        </a>
      ),
    },
    tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoney: {
      plan: offerSnapshot.title,
    },
    tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitations: {
      plan: offerSnapshot.title,
    },
    tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitationsAnswer: {
      plan: offerSnapshot.title,
    },
    tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdowns: {
      plan: offerSnapshot.title,
    },
    tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdownsAnswer: {
      baseCharge: offerSnapshot.formattedCurrencyBaseCharge,
    },
  });

  return (
    <>
      <OfferSnapshotFAQsTitleWrapper
        title={tOfferSnapshotFAQsSolarBuybackTitle}
      >
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarWhatIsSolarBuyback}</summary>
          <AccordionDetails>
            {tOfferSnapshotFAQsSolarWhatIsSolarBuybackAnswer}
          </AccordionDetails>
        </PortalAccordion>
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarWhoIsEligible}</summary>
          <AccordionDetails>
            {tOfferSnapshotFAQsSolarWhoIsEligibleAnswer}
          </AccordionDetails>
        </PortalAccordion>
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarHowWillIReceiveCredits}</summary>
          <AccordionDetails>
            {isTimeOfUseGeneration || isSolarBuybackPriceDifferential
              ? tOfferSnapshotFAQsSolarCreditNotOneToOneAnswer
              : tOfferSnapshotFAQsSolarHowWillIReceiveCreditsAnswer}
          </AccordionDetails>
        </PortalAccordion>
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarDoesRhythmCoverFees}</summary>
          <AccordionDetails>
            {tOfferSnapshotFAQsSolarDoesRhythmCoverFeesAnswer}
          </AccordionDetails>
        </PortalAccordion>
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarWillIEarnReferAFriend}</summary>
          <AccordionDetails>
            {tOfferSnapshotFAQsSolarWillIEarnReferAFriendAnswer}
          </AccordionDetails>
        </PortalAccordion>
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarIdontHaveSolarPanels}</summary>
          <AccordionDetails>
            {tOfferSnapshotFAQsSolarIdontHaveSolarPanelsAnswer}
          </AccordionDetails>
        </PortalAccordion>
        <PortalAccordion>
          <summary>{tOfferSnapshotFAQsSolarNoPanelsYet}</summary>
          <AccordionDetails>
            {tOfferSnapshotFAQsSolarNoPanelsYetAnswer}
          </AccordionDetails>
        </PortalAccordion>
      </OfferSnapshotFAQsTitleWrapper>

      {isTimeOfUseGeneration ? (
        <OfferSnapshotFAQsTitleWrapper
          title={tOfferSnapshotFAQsSolarTimeOfUseTitle}
        >
          <PortalAccordion>
            <summary>
              {tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdowns}
            </summary>
            <AccordionDetails>
              {tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdownsAnswer}
            </AccordionDetails>
          </PortalAccordion>
          <PortalAccordion>
            <summary>
              {tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitations}
            </summary>
            <AccordionDetails>
              {tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitationsAnswer}
            </AccordionDetails>
          </PortalAccordion>
          <PortalAccordion>
            <summary>
              {tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoney}
            </summary>
            <AccordionDetails>
              {tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoneyAnswer}
            </AccordionDetails>
          </PortalAccordion>
        </OfferSnapshotFAQsTitleWrapper>
      ) : null}
    </>
  );
};
