import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { ReactComponent as ChangingLightBulb } from "@portal-shared/images/ChangingLightBulb.svg";
import { BaseErrorPage } from "@portal-shared/pages/BaseErrorPage/BaseErrorPage";
import { error500PageTranslations } from "@portal-shared/pages/Error500Page/Error500Page.en.i18n";
import React from "react";

export const Error500Page = () => {
  const { translate } = useTranslations();

  const { tError500PageOops, tError500PageTechnicalDifficulties } = translate(
    error500PageTranslations
  );

  return (
    <BaseErrorPage
      header={tError500PageOops}
      illustration={<ChangingLightBulb />}
      showLinkToHome={false}
    >
      <RhTypography variant="subtitle1">
        {tError500PageTechnicalDifficulties}
      </RhTypography>
    </BaseErrorPage>
  );
};
