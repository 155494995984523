import { RhTooltip } from "@design-system/components/RhTooltip/RhTooltip";
import { ReactComponent as InformationIcon } from "@design-system/icons/InformationIcon.svg";
import React from "react";

interface EnergyUsageTooltipProps {
  ariaLabel: string;
  title: JSX.Element;
}

export const EnergyUsageTooltip = ({
  ariaLabel,
  title,
}: EnergyUsageTooltipProps) => {
  return (
    <RhTooltip content={title} ariaLabel={ariaLabel}>
      <InformationIcon />
    </RhTooltip>
  );
};
