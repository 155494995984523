import { PremiseStatus } from "@common/enums/premise.enum";
import { gold, green, red } from "@design-system/theme/palette.colors";

export const premiseStatusColor = (status: PremiseStatus) => {
  if (status === PremiseStatus.ACTIVE) {
    return green.main;
  }

  if (status === PremiseStatus.PENDING) {
    return gold.main;
  }

  return red.main;
};
