import { DUE_DATE_FORMAT } from "@common/constants/date.constant";
import { PremiseStatus } from "@common/enums/premise.enum";
import { IdType } from "@common/types/apiTypes";
import { AddressType } from "@common/types/customerTypes";
import { Invoice } from "@portal/models/Invoice.model";
import {
  BillingStatusType,
  BillingSummaryType,
} from "@portal/types/billingStatusTypes";
import { PaymentExtensionType } from "@portal/types/paymentExtensionTypes";
import dayjs from "dayjs";

export class BillingStatus {
  public readonly billingAccountId: IdType;
  public readonly billingSummary: BillingSummaryType;
  public readonly disconnectionDate: string | null;
  public readonly paymentExtensions: PaymentExtensionType[];
  public readonly serviceAddress: AddressType;
  public readonly status: PremiseStatus;

  public constructor(billingStatus: BillingStatusType) {
    this.billingAccountId = billingStatus?.billingAccountId;
    this.billingSummary = {
      ...billingStatus?.billingSummary,
      latestInvoice: billingStatus?.billingSummary.latestInvoice
        ? new Invoice(billingStatus?.billingSummary.latestInvoice)
        : null,
    };
    this.disconnectionDate = billingStatus?.disconnectionDate;
    this.billingSummary = billingStatus?.billingSummary;
    this.paymentExtensions = billingStatus?.paymentExtensions;
    this.serviceAddress = billingStatus?.serviceAddress;
    this.status = billingStatus?.status;
  }

  public get hasPaymentExtension(): boolean {
    return this.paymentExtensions.length > 0;
  }

  public get formattedDisconnectionDate(): string | null {
    return this.disconnectionDate
      ? dayjs(this.disconnectionDate).format(DUE_DATE_FORMAT)
      : null;
  }

  public get currentDueBalance(): number {
    return (
      Number(this.billingSummary.totalBalance) -
      Number(this.billingSummary.pastDueBalance)
    );
  }

  public get hasPastDueBalance(): boolean {
    return Number(this.billingSummary.pastDueBalance) > 0;
  }

  public get noBalanceDue(): boolean {
    return Number(this.billingSummary.totalBalance) <= 0;
  }

  public get formattedAccountDueDate(): string {
    return dayjs(this.billingSummary.accountDueDate).format(DUE_DATE_FORMAT);
  }

  public get formattedNextDueDate(): string {
    return dayjs(this.billingSummary.nextDueDate).format(DUE_DATE_FORMAT);
  }
}
