import { rhWindow } from "@common/utils/rhWindow";
import { browserLocaleAtom } from "@portal-shared/components/BrowserLocaleListener/browserLocaleAtom";
import { getLocale } from "@portal-shared/utils/getBrowserLocale";
import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";

export const BrowserLocaleListener = () => {
  const [browserLocaleAtomValue, setBrowserLocaleAtomValue] =
    useAtom(browserLocaleAtom);

  const handleLanguageChange = useCallback(() => {
    const browserLocale = getLocale(window.navigator?.language.slice(0, 2));

    if (browserLocale === browserLocaleAtomValue) {
      return;
    }
    setBrowserLocaleAtomValue(browserLocale);
  }, [browserLocaleAtomValue, setBrowserLocaleAtomValue]);

  useEffect(() => {
    rhWindow.addEventListener("languagechange", handleLanguageChange);

    return () => {
      rhWindow.removeEventListener("languagechange", handleLanguageChange);
    };
  }, [handleLanguageChange]);

  return null;
};
