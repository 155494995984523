import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { EnrollmentStatus, EnrollmentType } from "@common/enums/customer.enum";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@mui/material";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { ReactComponent as WomanUnplug } from "@portal-shared/images/WomanUnplug.svg";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { usePremise } from "@portal/hooks/usePremise";
import { ReactComponent as NewMoverWelcomeImage } from "@portal/images/NewMoverWelcomeImage.svg";
import { ReactComponent as StandardWelcomeImage } from "@portal/images/StandardWelcomeImage.svg";
import { customerHomeNewSignupSectionTranslations } from "@portal/pages/CustomerHomePage/CustomerHomeNewSignupSection/CustomerHomeNewSignupSection.en.i18n";
import {
  StyledCustomerHomeNewSignupSection,
  StyledHeader,
  StyledSubheader,
  StyledWelcomeImageContainer,
} from "@portal/pages/CustomerHomePage/CustomerHomeNewSignupSection/CustomerHomeNewSignupSection.styled";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import React from "react";

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const CustomerHomeNewSignupSection = () => {
  const { translate, translateJsx } = useTranslations();
  const customer = useCustomerFromContext();
  const { premise, requestMonitor: premiseRequestMonitor } = usePremise();

  if (premiseRequestMonitor.didFail || !premise) {
    return <RhCard noShadow />;
  }

  const serviceStartDate = dayjs(premise.currentOrder?.startDate);
  const today = dayjs().format(ISO8601_DATE_FORMAT);
  const daysUntilServiceStarts = serviceStartDate.diff(today, "day");
  const startingDate = serviceStartDate.format("dddd, MMMM D");

  const {
    tCustomerHomeNewSignupSectionNewMoverWelcomeImageAltText,
    tCustomerHomeNewSignupSectionNewSwitcherWelcomeImageAltText,
    tCustomerHomeNewSignupSectionStandardWelcomeImageAltText,
    tCustomerHomeNewSignupSectionGivingYouThePower,
  } = translate(customerHomeNewSignupSectionTranslations);

  const {
    tCustomerHomeNewSignupSectionCongratsOnTheMove,
    tCustomerHomeNewSignupSectionMakingTheSwitchToday,
    tCustomerHomeNewSignupSectionSendingPowerYourWayToday,
    tCustomerHomeNewSignupSectionWelcome,
    tCustomerHomeNewSignupSectionWelcomeBack,
    tCustomerHomeNewSignupSectionSendingPowerYourWayTomorrow,
    tCustomerHomeNewSignupSectionMakingTheSwitchTomorrow,
    tCustomerHomeNewSignupSectionSendingPowerYourWay,
    tCustomerHomeNewSignupSectionMakingTheSwitch,
  } = translateJsx({
    tCustomerHomeNewSignupSectionCongratsOnTheMove: {
      name: customer.firstName,
    },
    tCustomerHomeNewSignupSectionMakingTheSwitch: {
      bold: (str: string) => <strong>{str}</strong>,
      days: daysUntilServiceStarts,
      starting: startingDate,
    },
    tCustomerHomeNewSignupSectionMakingTheSwitchToday: {
      bold: (str: string) => <strong>{str}</strong>,
    },
    tCustomerHomeNewSignupSectionMakingTheSwitchTomorrow: {
      bold: (str: string) => <strong>{str}</strong>,
    },
    tCustomerHomeNewSignupSectionSendingPowerYourWay: {
      bold: (str: string) => <strong>{str}</strong>,
      days: daysUntilServiceStarts,
      starting: startingDate,
    },
    tCustomerHomeNewSignupSectionSendingPowerYourWayToday: {
      bold: (str: string) => <strong>{str}</strong>,
    },
    tCustomerHomeNewSignupSectionSendingPowerYourWayTomorrow: {
      bold: (str: string) => <strong>{str}</strong>,
    },
    tCustomerHomeNewSignupSectionWelcome: {
      name: customer.firstName,
    },
    tCustomerHomeNewSignupSectionWelcomeBack: {
      name: customer.firstName,
    },
  });

  const isNewSignUp = [
    EnrollmentStatus.ENROLLMENT_REQUESTED,
    EnrollmentStatus.ENROLLMENT_REQUESTED,
    EnrollmentStatus.PRE_ENROLL,
  ].includes(premise.enrollmentStatus);

  const isMoveIn = premise.enrollmentType === EnrollmentType.MOVE_IN;
  const welcomeImages: Record<string, () => JSX.Element> = {
    newMover: () => (
      <NewMoverWelcomeImage
        title={tCustomerHomeNewSignupSectionNewMoverWelcomeImageAltText}
      />
    ),
    newSwitcher: () => (
      <WomanUnplug
        title={tCustomerHomeNewSignupSectionNewSwitcherWelcomeImageAltText}
      />
    ),
    standard: () => (
      <StandardWelcomeImage
        title={tCustomerHomeNewSignupSectionStandardWelcomeImageAltText}
      />
    ),
  };

  let welcomeGreeting: string | JSX.Element =
    tCustomerHomeNewSignupSectionWelcomeBack;

  let welcomeImage: keyof typeof welcomeImages = "standard";

  if (isNewSignUp && isMoveIn) {
    welcomeGreeting = tCustomerHomeNewSignupSectionCongratsOnTheMove;
    welcomeImage = "newMover";
  } else if (isNewSignUp) {
    welcomeGreeting = tCustomerHomeNewSignupSectionWelcome;
    welcomeImage = "newSwitcher";
  }

  const constructCountdownMessage = () => {
    const startingDateToday = serviceStartDate.isToday();
    const startingDateTomorrow = serviceStartDate.isTomorrow();

    let countdownMessage;

    if (startingDateToday) {
      countdownMessage = isMoveIn
        ? tCustomerHomeNewSignupSectionSendingPowerYourWayToday
        : tCustomerHomeNewSignupSectionMakingTheSwitchToday;
    } else if (startingDateTomorrow) {
      countdownMessage = isMoveIn
        ? tCustomerHomeNewSignupSectionSendingPowerYourWayTomorrow
        : tCustomerHomeNewSignupSectionMakingTheSwitchTomorrow;
    } else {
      countdownMessage = isMoveIn
        ? tCustomerHomeNewSignupSectionSendingPowerYourWay
        : tCustomerHomeNewSignupSectionMakingTheSwitch;
    }
    return countdownMessage;
  };

  return (
    <StyledCustomerHomeNewSignupSection>
      <StyledWelcomeImageContainer>
        {welcomeImages[welcomeImage]()}
      </StyledWelcomeImageContainer>
      <Box>
        <StyledHeader variant="h1">{welcomeGreeting}</StyledHeader>
        <StyledSubheader>
          <RhTypography variant="subtitle2">
            {isNewSignUp && daysUntilServiceStarts >= 0
              ? constructCountdownMessage()
              : tCustomerHomeNewSignupSectionGivingYouThePower}
          </RhTypography>
        </StyledSubheader>
      </Box>
    </StyledCustomerHomeNewSignupSection>
  );
};
