import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import { ReactComponent as FacebookLogo } from "@common/images/FacebookLogo.svg";
import { ReactComponent as InstagramLogo } from "@common/images/InstagramLogo.svg";
import { ReactComponent as LinkedInLogo } from "@common/images/LinkedInLogo.svg";
import { ReactComponent as RhythmLogo } from "@common/images/RhythmLogoDarkPurple.svg";
import { ReactComponent as TwitterLogo } from "@common/images/TwitterLogo.svg";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Grid, Link, ListItem } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import {
  StyledAllRightsReserved,
  StyledAllRightsReservedContainer,
  StyledAllRightsReservedLoggedOut,
  StyledFlexPortalFooterColumn,
  StyledPortalFooter,
  StyledPortalFooterInner,
  StyledPortalFooterLogoRoot,
  StyledPortalFooterResourcesLink,
  StyledPortalFooterResourcesLinkList,
  StyledPortalFooterSocialContainer,
  StyledResourcesContainer,
  StyledRhythmLogoContainer,
  StyledUnauthenticatedContainer,
} from "@portal/components/PortalFooter/PortalFooter.styled";
import { customerHomePath } from "@portal/routes/routePaths";
import React from "react";

export const PortalFooter = () => {
  const { t } = useRhIntl();
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;

  const resources = t("PortalFooter.resources");
  const privacyPolicy = t("PortalFooter.privacyPolicy");
  const termsOfUse = t("PortalFooter.termsOfUse");
  const faq = t("PortalFooter.FAQ");
  const allRightsReserved = t("PortalFooter.allRightsReserved");

  return isAuthenticated ? (
    <StyledPortalFooter>
      <StyledPortalFooterInner>
        <StyledFlexPortalFooterColumn>
          <Grid container>
            <Grid item xs={12} md={2}>
              <StyledRhythmLogoContainer>
                <DynamicLink
                  to={customerHomePath()}
                  external={false}
                  aria-label={t("PortalFooter.home")}
                >
                  <RhythmLogo style={{ height: "54px", width: "140px" }} />
                </DynamicLink>
              </StyledRhythmLogoContainer>
            </Grid>
            <Grid item xs={12} md={2}>
              <StyledResourcesContainer>
                <RhTypography variant="h3">{resources}</RhTypography>
              </StyledResourcesContainer>
              <StyledPortalFooterResourcesLinkList dense>
                <ListItem>
                  <StyledPortalFooterResourcesLink
                    href={brandInformation.urlsPrivacyPolicy}
                    color="textPrimary"
                  >
                    <RhTypography>{privacyPolicy}</RhTypography>
                  </StyledPortalFooterResourcesLink>
                </ListItem>
                <ListItem>
                  <StyledPortalFooterResourcesLink
                    href={brandInformation.urlsTermsOfUse}
                    color="textPrimary"
                  >
                    <RhTypography>{termsOfUse}</RhTypography>
                  </StyledPortalFooterResourcesLink>
                </ListItem>
                <ListItem>
                  <StyledPortalFooterResourcesLink
                    href={brandInformation.urlsFaqsHome}
                    color="textPrimary"
                  >
                    <RhTypography>{faq}</RhTypography>
                  </StyledPortalFooterResourcesLink>
                </ListItem>
              </StyledPortalFooterResourcesLinkList>
            </Grid>
            <Grid item xs={false} md={5} />
            <Grid item xs={12} md={3}>
              <StyledPortalFooterLogoRoot>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaFacebook}
                    component="a"
                    aria-label={t("PortalFooter.facebook")}
                  >
                    <FacebookLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaTwitter}
                    component="a"
                    aria-label={t("PortalFooter.twitter")}
                  >
                    <TwitterLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaLinkedIn}
                    component="a"
                    aria-label={t("PortalFooter.linkedin")}
                  >
                    <LinkedInLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaInstagram}
                    component="a"
                    aria-label={t("PortalFooter.instagram")}
                  >
                    <InstagramLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
              </StyledPortalFooterLogoRoot>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <StyledAllRightsReservedContainer>
              <StyledAllRightsReserved
                color="textSecondary"
                align="center"
                variant="body1"
              >
                {allRightsReserved}
              </StyledAllRightsReserved>
            </StyledAllRightsReservedContainer>
          </Grid>
        </StyledFlexPortalFooterColumn>
      </StyledPortalFooterInner>
    </StyledPortalFooter>
  ) : (
    <StyledUnauthenticatedContainer>
      <StyledAllRightsReservedContainer>
        <StyledAllRightsReservedLoggedOut
          color="textSecondary"
          align="center"
          variant="body2"
        >
          {allRightsReserved}
        </StyledAllRightsReservedLoggedOut>
      </StyledAllRightsReservedContainer>
    </StyledUnauthenticatedContainer>
  );
};
