export const devicesEnrollSelectDevicesPageTranslations = {
  tDevicesEnrollSelectDevicesPageDescription:
    "IMPORTANT: You'll get the monthly bill credit per address, not device.",
  tDevicesEnrollSelectDevicesPageDescriptionNoDevices:
    "You either logged into the wrong account, or you haven't registered your device(s) yet. Please either register your device(s) or log in with the account linked to your device(s).",
  tDevicesEnrollSelectDevicesPageNoOauthCode:
    "No auth code was provided. Please try again.",
  tDevicesEnrollSelectDevicesPageStartOver: "Start over",
  tDevicesEnrollSelectDevicesPageTitle:
    "Select the address for each device you'd like to connect.",
  tDevicesEnrollSelectDevicesPageTitleNoDevices: "Oops. No devices were found.",
  tDevicesEnrollSelectDevicesPageTokenExpired:
    "Auth code has already been used. Please try again.",
};
