import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  ANNOUNCEMENT_HEIGHT,
  RH_HEADER_HEIGHT,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const RhAnnouncementContainer = styled("div")`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  justify-content: center;
  min-height: ${rhSpacingPx(ANNOUNCEMENT_HEIGHT)};
  padding: ${rhSpacingPx(0.5)} ${rhSpacingPx(2)};
  position: fixed;
  text-align: center;
  top: ${rhSpacingPx(RH_HEADER_HEIGHT)};
  width: 100%;
  z-index: 100;

  a {
    font-weight: ${FontWeight.Bold};
    text-decoration: underline;
  }

  &.error {
    background-color: ${CSSVariables.COLOR_ERROR_MAIN};
    color: ${CSSVariables.COLOR_WHITE};

    & a {
      color: ${CSSVariables.COLOR_WHITE};

      &:hover {
        color: ${CSSVariables.COLOR_WHITE};
      }
    }

    &:hover {
      color: ${CSSVariables.COLOR_GREY_100};
    }

    & svg {
      color: ${CSSVariables.COLOR_WHITE};

      &:hover {
        color: ${CSSVariables.COLOR_GREY_100};
      }
    }
  }

  &.info {
    background-color: ${CSSVariables.COLOR_INFO_LIGHT};
    color: ${CSSVariables.COLOR_INFO_DARK};

    & a {
      color: ${CSSVariables.COLOR_INFO_DARK};

      &:hover {
        color: ${CSSVariables.COLOR_INFO_MAIN};
      }
    }

    & svg {
      color: ${CSSVariables.COLOR_INFO_DARK};

      &:hover {
        color: ${CSSVariables.COLOR_INFO_MAIN};
      }
    }
  }

  &.notice {
    background-color: ${CSSVariables.COLOR_SUCCESS_LIGHT};
    color: ${CSSVariables.COLOR_SUCCESS_DARK};

    & a {
      color: ${CSSVariables.COLOR_SUCCESS_DARK};

      &:hover {
        color: ${CSSVariables.COLOR_SUCCESS_MAIN};
      }
    }

    & svg {
      color: ${CSSVariables.COLOR_SUCCESS_DARK};

      &:hover {
        color: ${CSSVariables.COLOR_SUCCESS_MAIN};
      }
    }
  }
`;

export const ClearButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: auto;
  margin-right: ${rhSpacingPx(2)};
  padding: 0;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  width: 0.75rem;
`;
