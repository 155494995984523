import { IdType } from "@common/types/apiTypes";
import { customerApi } from "@portal/api/customerApi";
import { customerQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseDeviceEligibilityInformationQueryProps {
  customerId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useDeviceEligibilityInformationQuery = (
  props: UseDeviceEligibilityInformationQueryProps
) => {
  const { customerId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => customerApi.deviceEligibilityInformation(),
    queryKey: customerQueryKeys.deviceEligibilityInformation(customerId),
  });
};
