import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhRadioLabel } from "@design-system/components/RhRadio/RhRadio.styled";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import React, { ChangeEvent, FC } from "react";

export interface RhRadioProps {
  checked?: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
}

export const RhRadio: FC<React.PropsWithChildren<RhRadioProps>> = ({
  children,
  name,
  value,
  handleChange,
  checked,
}) => (
  <RhFlexBox alignItems="center">
    <RhRadioInput
      id={value}
      name={name}
      value={value}
      onChange={handleChange}
      checked={checked}
    />
    <RhRadioLabel htmlFor={value}>{children}</RhRadioLabel>
  </RhFlexBox>
);
