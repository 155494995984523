import { KILOWATT_HOURS_ABBREV } from "@common/constants/other.constant";
import {
  formatCurrency,
  formatDollarsToCents,
} from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotLearnMoreUsageTableTranslations } from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreUsageTable/OfferSnapshotLearnMoreUsageTable.en.i18n";
import {
  StyledContainer,
  StyledTable,
} from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreUsageTable/OfferSnapshotLearnMoreUsageTable.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React from "react";

interface OfferSnapshotLearnMoreUsageTableProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotLearnMoreUsageTable = (
  props: OfferSnapshotLearnMoreUsageTableProps
) => {
  const { offerSnapshot } = props;
  const { translate } = useTranslations();
  const {
    tOfferSnapshotLearnMoreUsageTableAverageMonthlyUsage,
    tOfferSnapshotLearnMoreUsageTableAveragePrice,
    tOfferSnapshotLearnMoreUsageTableMonthlyBill,
    tOfferSnapshotLearnMoreUsageTableMonthlyEstimate,
    tOfferSnapshotLearnMoreUsageTableTimeOfUseDisclaimer,
    tOfferSnapshotLearnMoreUsageTableAutoPayPaperlessSubHeader,
  } = translate(offerSnapshotLearnMoreUsageTableTranslations);

  const {
    isTimeOfUse,
    isAutoPayPaperlessDiscountOffer,
    solarEligible,
    isTimeOfUseGeneration,
  } = offerSnapshot;

  const showMonthlyEstimateColumn = !(
    isTimeOfUse ||
    isTimeOfUseGeneration ||
    solarEligible
  );

  return (
    <StyledContainer>
      <RhTypography variant="h2">
        {tOfferSnapshotLearnMoreUsageTableMonthlyBill}
      </RhTypography>

      <StyledTable>
        <caption>{tOfferSnapshotLearnMoreUsageTableMonthlyBill}</caption>
        <thead>
          <tr>
            <th>{tOfferSnapshotLearnMoreUsageTableAverageMonthlyUsage}</th>
            <th>
              {tOfferSnapshotLearnMoreUsageTableAveragePrice}

              {isAutoPayPaperlessDiscountOffer ? (
                <RhTypography variant="body3">
                  {tOfferSnapshotLearnMoreUsageTableAutoPayPaperlessSubHeader}
                </RhTypography>
              ) : null}
            </th>
            {showMonthlyEstimateColumn ? (
              <th>{tOfferSnapshotLearnMoreUsageTableMonthlyEstimate}</th>
            ) : null}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>500 {KILOWATT_HOURS_ABBREV}</td>
            <td>{formatDollarsToCents(offerSnapshot.price500Kwh)}</td>
            {showMonthlyEstimateColumn ? (
              <td>
                {formatCurrency(offerSnapshot.monthlyEstimate500Kwh(), 0)}
              </td>
            ) : null}
          </tr>

          <tr>
            <td>1000 {KILOWATT_HOURS_ABBREV}</td>
            <td>{formatDollarsToCents(offerSnapshot.price1000Kwh)}</td>
            {showMonthlyEstimateColumn ? (
              <td>
                {formatCurrency(offerSnapshot.monthlyEstimate1000Kwh(), 0)}
              </td>
            ) : null}
          </tr>

          <tr>
            <td>2000 {KILOWATT_HOURS_ABBREV}</td>
            <td>{formatDollarsToCents(offerSnapshot.price2000Kwh)}</td>
            {showMonthlyEstimateColumn ? (
              <td>
                {formatCurrency(offerSnapshot.monthlyEstimate2000Kwh(), 0)}
              </td>
            ) : null}
          </tr>
        </tbody>
      </StyledTable>

      {isTimeOfUse ? (
        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreUsageTableTimeOfUseDisclaimer}
        </RhTypography>
      ) : null}
    </StyledContainer>
  );
};
