import { IdType } from "@common/types/apiTypes";
import { OfferSnapshotsSearchOptions } from "@portal-shared/types/offerSnapshotTypes";

export const OfferSnapshotQueryKeys = {
  list: (searchOptions?: OfferSnapshotsSearchOptions) => [
    "offer-snapshots",
    searchOptions,
  ],
  retrieve: (offerSnapshotId: IdType) => [
    "offerSnapshot",
    offerSnapshotId,
    "details",
  ],
};
