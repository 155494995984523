import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import {
  formatCurrency,
  formatDollarsToCents,
} from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as GrayX } from "@design-system/icons/GrayX.svg";
import { ReactComponent as GreenCheck } from "@design-system/icons/GreenCheck.svg";
import { offerSnapshotLearnMoreReasonsToBelieveTranslations } from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreReasonsToBelieve/OfferSnapshotLearnMoreReasonsToBelieve.en.i18n";
import {
  OfferSnapshotReasonsToBelieveTitle,
  ReasonToBelieveDescription,
  ReasonToBelieveRowTitle,
  ReasonsToBelieveLinkButton,
  ReasonsToBelieveRow,
  ReasonsToBelieveTable,
} from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreReasonsToBelieve/OfferSnapshotLearnMoreReasonsToBelieve.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { CampaignTypeEnum } from "@portal-shared/types/campaignTypes";
import React, { ReactNode, useCallback } from "react";

interface OfferSnapshotLearnMoreReasonsToBelieveProps {
  offerSnapshot: OfferSnapshot;
}

const Reason = (props: {
  description: ReactNode;
  included?: boolean;
  onClick?: () => void;
  title: string;
}) => {
  const { description, included = true, title, onClick } = props;

  return (
    <ReasonsToBelieveRow aria-label={title}>
      <ReasonToBelieveRowTitle>
        {onClick ? (
          <ReasonsToBelieveLinkButton type="button" onClick={onClick}>
            <RhTypography variant="body1">{title}</RhTypography>
          </ReasonsToBelieveLinkButton>
        ) : (
          <RhTypography variant="body1">{title}</RhTypography>
        )}
      </ReasonToBelieveRowTitle>
      <ReasonToBelieveDescription>
        <div>
          {included ? <GreenCheck aria-hidden /> : <GrayX aria-hidden />}
        </div>
        <RhTypography variant="body1">{description}</RhTypography>
      </ReasonToBelieveDescription>
    </ReasonsToBelieveRow>
  );
};

const usePriceRate = (props: OfferSnapshotLearnMoreReasonsToBelieveProps) => {
  const { offerSnapshot } = props;
  const { translate, translateJsx } = useTranslations();

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalSolarPlanShowAveragePrice } = featureFlagClient.getFlags([
    ["portalSolarPlanShowAveragePrice", false],
  ]);

  const {
    isMonthToMonth,
    solarEligible,
    formattedCurrencyBaseCharge,
    rhythmKwhRate,
    solarCreditKwhRate,
    hasBaseChargeAmount,
    isTimeOfUse,
    isShowEnergyRateOffer,
  } = offerSnapshot;

  const {
    tOfferSnapshotLearnMoreReasonsToBelieveVariableRateDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveVariableRateTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveFixedRateTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveFixedRateDescription,
  } = translate(offerSnapshotLearnMoreReasonsToBelieveTranslations);
  const {
    tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseGenerationRateDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveVariableRateWithBaseChargeDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveFixedRateSolarPlan,
    tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateWithBaseChargeDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveFixedRateWithBaseChargeDescription,
  } = translateJsx({
    tOfferSnapshotLearnMoreReasonsToBelieveFixedRateSolarPlan: {
      baseCharge: formattedCurrencyBaseCharge,
      kwhRate: formatDollarsToCents(rhythmKwhRate),
      solarBuybackRate: formatDollarsToCents(solarCreditKwhRate),
    },
    tOfferSnapshotLearnMoreReasonsToBelieveFixedRateWithBaseChargeDescription: {
      baseCharge: formattedCurrencyBaseCharge,
      kwhRate: formatDollarsToCents(rhythmKwhRate),
    },
    tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateWithBaseChargeDescription:
      {
        baseCharge: formattedCurrencyBaseCharge,
      },
    tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateDescription: {
      baseCharge: formattedCurrencyBaseCharge,
    },
    tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseGenerationRateDescription: {
      baseCharge: formattedCurrencyBaseCharge,
    },
    tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateDescription: {
      baseCharge: formattedCurrencyBaseCharge,
    },
    tOfferSnapshotLearnMoreReasonsToBelieveVariableRateWithBaseChargeDescription:
      {
        baseCharge: formattedCurrencyBaseCharge,
        kwhRate: formatDollarsToCents(rhythmKwhRate),
      },
  });

  const handleScroll = useCallback((elementId: string) => {
    document.getElementById(elementId)?.scrollIntoView();
  }, []);

  const showAverageRate =
    solarEligible &&
    !isShowEnergyRateOffer &&
    portalSolarPlanShowAveragePrice.value;

  if (isTimeOfUse) {
    return {
      description: showAverageRate
        ? tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseGenerationRateDescription
        : tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateDescription,
      onClick: () => handleScroll("price-schedule-breakdown"),
      title: tOfferSnapshotLearnMoreReasonsToBelieveTimeOfUseRateTitle,
    };
  }

  if (solarEligible && isMonthToMonth) {
    return {
      description:
        tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateDescription,
      title: tOfferSnapshotLearnMoreReasonsToBelieveSolarVariableRateTitle,
    };
  }

  if (solarEligible) {
    return {
      description: showAverageRate
        ? tOfferSnapshotLearnMoreReasonsToBelieveFixedRateSolarPlan
        : tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateWithBaseChargeDescription,
      title: tOfferSnapshotLearnMoreReasonsToBelieveSolarFixedRateTitle,
    };
  }

  if (isMonthToMonth && hasBaseChargeAmount) {
    return {
      description:
        tOfferSnapshotLearnMoreReasonsToBelieveVariableRateWithBaseChargeDescription,
      title: tOfferSnapshotLearnMoreReasonsToBelieveVariableRateTitle,
    };
  }

  if (isMonthToMonth) {
    return {
      description:
        tOfferSnapshotLearnMoreReasonsToBelieveVariableRateDescription,
      title: tOfferSnapshotLearnMoreReasonsToBelieveVariableRateTitle,
    };
  }

  if (hasBaseChargeAmount) {
    return {
      description:
        tOfferSnapshotLearnMoreReasonsToBelieveFixedRateWithBaseChargeDescription,
      title: tOfferSnapshotLearnMoreReasonsToBelieveFixedRateTitle,
    };
  }

  return {
    description: tOfferSnapshotLearnMoreReasonsToBelieveFixedRateDescription,
    title: tOfferSnapshotLearnMoreReasonsToBelieveFixedRateTitle,
  };
};

export const OfferSnapshotLearnMoreReasonsToBelieve = (
  props: OfferSnapshotLearnMoreReasonsToBelieveProps
) => {
  const { offerSnapshot } = props;
  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotLearnMoreReasonsToBelieveAllRenewableDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveAllRenewableTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveBestPriceDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveBestPriceTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveIncluded,
    tOfferSnapshotLearnMoreReasonsToBelieveMonthToMonthTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveNotIncluded,
    tOfferSnapshotLearnMoreReasonsToBelieveSimplisafeTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveSolarBuybackTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveTestDriveDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveTestDriveTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveThermostatTitle,
    tOfferSnapshotLearnMoreReasonsToBelieveTitle,
  } = translate(offerSnapshotLearnMoreReasonsToBelieveTranslations);

  const {
    autopayPaperlessMonthlyDiscount,
    isAutoPayPaperlessDiscountOffer,
    isMonthToMonth,
    isSimpliSafeOffer,
    isThermostatOffer,
    isTimeOfUse,
    solarEligible,
    thermostatManagementMonthlyDiscount,
  } = offerSnapshot;

  const isAcquisitionCampaign =
    offerSnapshot.campaignType === CampaignTypeEnum.Acquisition;

  const {
    tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredDescription,
    tOfferSnapshotLearnMoreReasonsToBelieveThermostatDescription,
  } = translateJsx({
    tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredDescription:
      {
        discountAmount: formatCurrency(autopayPaperlessMonthlyDiscount ?? ""),
      },
    tOfferSnapshotLearnMoreReasonsToBelieveThermostatDescription: {
      credit: formatCurrency(thermostatManagementMonthlyDiscount ?? ""),
    },
  });

  const {
    title: priceRateTitle,
    description: priceRateDescription,
    onClick: priceRateOnClick,
  } = usePriceRate({ offerSnapshot });

  return (
    <ReasonsToBelieveTable>
      <OfferSnapshotReasonsToBelieveTitle component="caption" variant="h2">
        {tOfferSnapshotLearnMoreReasonsToBelieveTitle}
      </OfferSnapshotReasonsToBelieveTitle>
      <tbody>
        {isThermostatOffer ? (
          <Reason
            title={tOfferSnapshotLearnMoreReasonsToBelieveThermostatTitle}
            description={
              tOfferSnapshotLearnMoreReasonsToBelieveThermostatDescription
            }
          />
        ) : null}

        {isAutoPayPaperlessDiscountOffer ? (
          <Reason
            title={
              tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredTitle
            }
            description={
              tOfferSnapshotLearnMoreReasonsToBelieveAutopayPaperlessRequiredDescription
            }
          />
        ) : null}

        <Reason
          title={priceRateTitle}
          description={priceRateDescription}
          onClick={priceRateOnClick}
        />
        <Reason
          title={tOfferSnapshotLearnMoreReasonsToBelieveBestPriceTitle}
          description={
            tOfferSnapshotLearnMoreReasonsToBelieveBestPriceDescription
          }
        />
        <Reason
          title={tOfferSnapshotLearnMoreReasonsToBelieveAllRenewableTitle}
          description={
            tOfferSnapshotLearnMoreReasonsToBelieveAllRenewableDescription
          }
        />
        {isAcquisitionCampaign ? (
          <Reason
            title={tOfferSnapshotLearnMoreReasonsToBelieveTestDriveTitle}
            description={
              tOfferSnapshotLearnMoreReasonsToBelieveTestDriveDescription
            }
          />
        ) : null}
        {isMonthToMonth && (
          <Reason
            title={tOfferSnapshotLearnMoreReasonsToBelieveMonthToMonthTitle}
            description={tOfferSnapshotLearnMoreReasonsToBelieveIncluded}
          />
        )}
        {!isTimeOfUse && (
          <Reason
            included={solarEligible}
            title={tOfferSnapshotLearnMoreReasonsToBelieveSolarBuybackTitle}
            description={
              solarEligible
                ? tOfferSnapshotLearnMoreReasonsToBelieveIncluded
                : tOfferSnapshotLearnMoreReasonsToBelieveNotIncluded
            }
          />
        )}
        {isSimpliSafeOffer && (
          <Reason
            title={tOfferSnapshotLearnMoreReasonsToBelieveSimplisafeTitle}
            description={tOfferSnapshotLearnMoreReasonsToBelieveIncluded}
          />
        )}
      </tbody>
    </ReasonsToBelieveTable>
  );
};
