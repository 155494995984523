import { Locale } from "@portal-shared/types/LocaleTypes";

export const convertTimeToHoursAndMinutes = (
  time: string,
  locale: Locale = "en"
) => {
  const parsedTime = Number(time);
  const hours = Math.floor(parsedTime / 60);
  const minutes = parsedTime % 60;
  let hourText = hours > 1 ? "hours" : "hour";

  if (locale === "es") {
    hourText = hours > 1 ? "horas" : "hora";
  }

  if (hours === 0) {
    return `${minutes} min`;
  }

  if (hours === 1 && minutes === 0) {
    return `1 ${hourText}`;
  }

  return `${hours} ${hourText} ${minutes} min`;
};
