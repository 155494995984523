import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { RhApiError } from "@common/types/errorTypes";
import { PremiseType } from "@common/types/premiseTypes";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";

interface PremiseSelectorType {
  error: RhApiError | null;
  premise: PremiseType | null;
  status: RequestMonitor;
}

export const selectPremise = ({
  premise,
}: PortalStoreState): PremiseSelectorType => {
  const { data, error, requestStatus } = premise;

  return {
    error,
    premise: data,
    status: new RequestMonitor(requestStatus),
  };
};
