import { guestPayApi } from "@portal-guest/api/guestPayApi";
import { GuestPayQueryKeys } from "@portal-guest/queryKeys/guestPayQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useGuestPayAddPaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: guestPayApi.createPaymentMethod,
    onSuccess: (_, { token }) => {
      queryClient.invalidateQueries({
        queryKey: GuestPayQueryKeys.getPaymentMethod(token),
      });
    },
  });

  return mutation;
};
