export const signUpSummaryPageTranslations = {
  tSignUpSummaryPageAcknowledgeCheckboxLabel:
    "Checkbox to acknowledge contract documents",
  tSignUpSummaryPageAcknowledgePTCCheckboxLabel:
    "Checkbox to acknowledge Power To Choose legal terms",
  tSignUpSummaryPageAgreeToTerms: "* Please agree to terms of service above",
  tSignUpSummaryPageAllDone: "Last step, {firstName}! Let's review.",
  tSignUpSummaryPageAllRightsReserved:
    "© Rhythm Ops, LLC, d/b/a Rhythm. All Rights Reserved. PUCT Certificate No. 10279.",
  tSignUpSummaryPageApiErrorSigningUpCustomer:
    "We are having issues signing you up. Please try refreshing this page.",
  tSignUpSummaryPageByCheckingThisBox:
    "By checking this box, you agree to establish Rhythm as your retail electric provider, and you agree to the <tos>Terms of Service</tos>, <yrac>Your Rights As A Customer</yrac>, and <efl>Electricity Facts Label</efl> documents associated with your plan. You also authorize Rhythm to switch, establish, or change your service.",
  tSignUpSummaryPageErrorGettingSelectedOffer:
    "Session has expired, <link>please reselect your plan</link>.",
  tSignUpSummaryPageGeneral:
    "Oops something went wrong! Please call our Customer Care Team at <phoneLink></phoneLink> so we can sort this out.",
  tSignUpSummaryPageIAccept: "I accept the",
  tSignUpSummaryPageSubmitCTA: "Sign Up for Rhythm",
  tSignUpSummaryPageSubmitCTAWithDeposit: "Make Payment And Sign Up",
  tSignUpSummaryPageTermsAndConditions: "Terms & Conditions",
  tSignUpSummaryPageTryDifferentPaymentMethod:
    "We are having issues processing your payment. <link>Please go back to the previous page and try again with a different one.</link>",
};
