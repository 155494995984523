import { StyledSwiperCarousel } from "@portal/components/SwiperCarousel/SwiperCarousel.styled";
import { SwiperContainerProps } from "@portal/types/swiperTypes";
import React, { useRef } from "react";
import { SwiperContainer, register } from "swiper/element/bundle";

register();

interface SwiperCarouselProps {
  children: React.ReactNode;
  hasPagination: boolean;
  swiperProps: SwiperContainerProps;
}

export const SwiperCarousel = (props: SwiperCarouselProps) => {
  const swiperElRef = useRef<SwiperContainer>(null);
  const { children, hasPagination, swiperProps } = props;

  return (
    <StyledSwiperCarousel $hasPagination={hasPagination}>
      <swiper-container ref={swiperElRef} {...swiperProps}>
        {children}
      </swiper-container>
    </StyledSwiperCarousel>
  );
};
