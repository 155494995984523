import { useOktaAuth } from "@okta/okta-react";
import { LoggedOutHeader } from "@portal/components/LoggedOutHeader/LoggedOutHeader";
import React from "react";

export const LoggedOutHeaderHoc = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;

  if (isAuthenticated) {
    return null;
  }

  return <LoggedOutHeader />;
};
