import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import { StyledRhRoundTabs } from "@design-system/components/RhRoundTabs/RhRoundTabs.styled";
import { TabsProps } from "@mui/material";
import React, { Children, FC, cloneElement, useState } from "react";

interface RhRoundTabsProps extends Omit<TabsProps, "value" | "classes"> {
  align?: "left" | "center";
  children: JSX.Element | JSX.Element[];
  className?: string;
  contained?: boolean;
  initialSelected?: number;
}

export const RhRoundTabs: FC<React.PropsWithChildren<RhRoundTabsProps>> = ({
  children,
  align = "center",
  className = "",
  initialSelected = 0,
  contained = false,
  ...restProps
}): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(initialSelected);

  return (
    <StyledRhRoundTabs
      className={className}
      value={tabIndex}
      {...restProps}
      $contained={contained}
    >
      {Children.map(children, (child: JSX.Element, index: number) => {
        if (child.type?.name !== RhRoundTabItem.name) {
          throw new Error(
            "RhRoundTabs only accepts RhRoundTabItem as children"
          );
        }

        return cloneElement(child, {
          className: index === tabIndex ? "selected" : "",
          onClick: () => {
            child.props?.onClick?.();
            setTabIndex(index);
          },
        });
      })}
    </StyledRhRoundTabs>
  );
};
