import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { AppSource } from "@portal/enums/QueryParamValues.enum";
import { enrollOffersPageHeaderTranslations } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPageHeader/EnrollOffersPageHeader.en.i18n";
import {
  EnrollOffersPageHeaderContainer,
  StyledHeader,
} from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPageHeader/EnrollOffersPageHeader.styled";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";

export const EnrollOffersPageHeader = () => {
  const { appSource } = useSelector(selectSignUpState);

  const comesFromEnergyTexas = appSource === AppSource.MarketingEnergyTexas;

  const { translate, translateJsx } = useTranslations();

  const {
    tEnrollOffersPageHeaderEnergyTexasDisclaimer,
    tEnrollOffersPageHeaderTitle,
  } = translate(enrollOffersPageHeaderTranslations);
  const { tEnrollOffersPageHeaderSubtitle } = translateJsx({
    tEnrollOffersPageHeaderSubtitle: {
      link: (text: string) => (
        <a href={brandInformation.urlsAbout} target="_blank" rel="noreferrer">
          {text}
        </a>
      ),
    },
  });

  return (
    <EnrollOffersPageHeaderContainer>
      <StyledHeader>
        {comesFromEnergyTexas ? (
          <RhTypography color="textSecondary">
            {tEnrollOffersPageHeaderEnergyTexasDisclaimer}
          </RhTypography>
        ) : null}
        <RhTypography variant="h1">{tEnrollOffersPageHeaderTitle}</RhTypography>
        <RhTypography variant="subtitle1">
          {tEnrollOffersPageHeaderSubtitle}
        </RhTypography>
      </StyledHeader>
    </EnrollOffersPageHeaderContainer>
  );
};
