import styled from "styled-components";

export const StyledPaymentsLoading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 5rem;

  & > * {
    margin: auto;
  }
`;
