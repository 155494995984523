import { premiseApi } from "@common/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreatePaymentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: premiseApi.payments.create,
    onSuccess: (_, requestData) => {
      queryClient.invalidateQueries({
        queryKey: PremiseQueryKeys.paymentMethods(requestData.premiseId),
      });
    },
  });
};
