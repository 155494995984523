import { CustomerStatus } from "@common/enums/customer.enum";
import { AccountSummaryType } from "@common/types/accountSummaryTypes";
import { IdType } from "@common/types/apiTypes";
import { PaymentExtensionType } from "@common/types/billingTypes";
import { AcquisitionCampaignType } from "@common/types/campaignTypes";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { AddressFormType, AddressType } from "@common/types/customerTypes";
import { DeferredPaymentPlanType } from "@common/types/deferredPaymentPlanTypes";
import { EDIMessageType, ValidInboundEDIType } from "@common/types/ediTypes";
import { MeterType } from "@common/types/meterTypes";
import { PaymentMethodType } from "@common/types/paymentMethodTypes";
import {
  ExternalPaymentFileType,
  UploadFidelityCashPaymentType,
} from "@common/types/paymentTypes";
import {
  PaymentHistoryType,
  WelcomePacketMetadataType,
} from "@common/types/premiseTypes";
import { HistoricalUsageType } from "@common/types/usageTypes";

export type { RewardsResponseType } from "@common/types/rewardsTypes";

export interface PaginationEndpointResponseType<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export enum HttpStatusCodes {
  HTTP_200_OK = 200,
  HTTP_201_CREATED = 201,
  HTTP_204_NO_CONTENT = 204,
  HTTP_302_FOUND = 302,
  HTTP_400_BAD_REQUEST = 400,
  HTTP_403_FORBIDDEN = 403,
  HTTP_404_NOT_FOUND = 404,
  HTTP_410_GONE = 410,
  HTTP_500_INTERNAL_SERVER_ERROR = 500,
  HTTP_503_SERVICE_UNAVAILABLE = 503,
}

export interface CustomerAvailableResponseType {
  status: "ok";
}

export interface CreatePaymentResponseType {
  paymentNumber: string;
}

export interface ZuoraAddPaymentResponseType {
  errorCode?: string;
  errorMessage?: string;
  redirectUrl: string;
  refId: string;
  responseFrom: string;
  signature: string;
  success: boolean;
  token: string;
}

export interface RSASignatureResponseType {
  key: string;
  signature: string;
  success: boolean;
  tenantId: string;
  token: string;
}

export interface RSASignatureErrorResponseType {
  processId: string;
  reasons: { code: number; message: string }[];
  success: boolean;
}

export interface ValidInboundEDIResponseType {
  valid: ValidInboundEDIType[];
}

export interface MeterAvailabilityResponseType {
  dunsNumber: string;
  esiId: string;
  serviceAddress: AddressFormType;
  utilityName: string;
}

export interface OffersAvailabilityResponseType {
  dunsNumber: string;
}

export interface TriggerEdiResponseType {
  success: boolean;
}
export interface GetSetPasswordStateTokenResponseType {
  stateToken: string;
}

export interface SetPasswordResponseType {
  sessionToken: string;
}

export interface RedeemRewardsResponseType {
  balance: number;
}

export type CreatePaymentExtensionResponseType = PaymentExtensionType;

export interface NotificationChannelsResponseType {
  emailMarketingChannel: string;
  smsMarketingChannel: string;
}

export interface NotificationMessagesResponseType {
  emailBillSpike: string;
  emailMarketingAndPromos: string;
  emailWeeklySummary: string;
  smsBillSpike: string;
  smsBillingAndPayments: string;
  smsWeeklySummary: string;
}

export interface PremiseUpdateResponseType {
  averagedBillingMonthlyCharge?: number;
  id: IdType;
  meter?: MeterType;
}

export type EnrollmentStatusesResponseType = {
  enrollmentStatuses: string[];
};

export type EnrollmentStatusUpdatedResponseType = {
  enrollmentStatus: string;
  id: IdType;
};

export interface PortalOneClickRenewalDefaultPaymentMethodResponseType {
  isPastDue: boolean;
  paymentMethod: PaymentMethodType | null;
}

export type PaymentListResponseType = PaymentHistoryType[];

export type EDIMessageListResponseType = EDIMessageType[];

export interface ChatAvailabilityResponseType {
  available: boolean;
}

export type WelcomePacketMetadataResponseType = WelcomePacketMetadataType;

export interface CreditScoreResponseType {
  additionalInformationRequired: boolean;
  depositAlternativeAmount: string | null;
  depositAmount: number | null;
  nosRequired: boolean;
  outcome: CreditScoreOutcome;
  result: "success" | "partial" | "fail";
  ssnProvided: boolean;
  ssnRequired: boolean;
}

export type AcquisitionCampaignListResponseType = AcquisitionCampaignType[];

export interface UpdateSubscriptionStatusResponseType {
  code: string;
}

export interface CustomerSearchType {
  email: string;
  fullName: string;
  id: IdType;
  meterCount: number;
  phone: string;
  primaryEsiId: string;
  primaryPremiseId: IdType;
  primaryServiceAddress: AddressType;
  status: CustomerStatus | null;
}
export type CustomerSearchResponseType = CustomerSearchType[];

export interface ReprocessInvlUsgmMessageRequestType {
  ediInvoiceMatchCrossReferenceNumber: string;
}

export type ReprocessInvlUsgmMessageResponseType =
  ReprocessInvlUsgmMessageRequestType;

export interface MoveEdiMessageRequestType {
  message: string;
  premise: IdType;
}

export interface CopyEdiMessageRequestType {
  ediFilePath: string;
  ediTransactionId: string;
  premise: IdType;
}

export type AccountSummaryResponseType = AccountSummaryType;

export type UsageHistoryResponseType = HistoricalUsageType[];

export type UploadFidelityCashPaymentResponseType =
  UploadFidelityCashPaymentType;

export type FidelityCashPaymentFileListResponseType =
  PaginationEndpointResponseType<ExternalPaymentFileType>;

export type DeferredPaymentPlanListResponseType = DeferredPaymentPlanType[];
