export const resetPasswordPageTranslations: Record<string, string> = {
  tResetPasswordPageErrorResettingPassword:
    "We are having issues changing your password. Please try again.",
  tResetPasswordPageInvalidPassword:
    "Does not meet the requirements of the password policy.",
  tResetPasswordPageNextCTA: "Next",
  tResetPasswordPagePassword: "Password",
  tResetPasswordPagePasswordMinimumRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  tResetPasswordPageTitle: "Create a new password",
};
