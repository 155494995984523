import { GuestFooterTranslations } from "@portal-guest/components/GuestFooter/GuestFooter.en.i18n";
import {
  GuestFooterAllRightsReservedContainer,
  GuestFooterAllRightsReservedLoggedOut,
  GuestFooterUnauthenticatedContainer,
} from "@portal-guest/components/GuestFooter/GuestFooter.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const GuestFooter = () => {
  const { translate } = useTranslations();

  const { tGuestFooterTranslationsAllRightsReserved } = translate(
    GuestFooterTranslations
  );

  return (
    <GuestFooterUnauthenticatedContainer>
      <GuestFooterAllRightsReservedContainer>
        <GuestFooterAllRightsReservedLoggedOut
          color="textSecondary"
          align="center"
          variant="body2"
        >
          {tGuestFooterTranslationsAllRightsReserved}
        </GuestFooterAllRightsReservedLoggedOut>
      </GuestFooterAllRightsReservedContainer>
    </GuestFooterUnauthenticatedContainer>
  );
};
