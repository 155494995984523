import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import { StyledBillEnergyTemperatureSkeleton } from "@portal/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.styled";
import React from "react";

export const BillEnergyUsageTemperatureCardSkeleton = () => (
  <StyledBillEnergyTemperatureSkeleton>
    <Skeleton />
    <Skeleton $height="20em" />
  </StyledBillEnergyTemperatureSkeleton>
);
