import { featureFlagUserIdAtom } from "@portal-shared/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { useFingerPrintId } from "@portal-shared/hooks/useFingerPrintId";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

const uuid = uuidV4();

export const useFeatureFlagUserId = () => {
  const featureFlagUserIdAtomValue = useAtomValue(featureFlagUserIdAtom);
  const fingerPrintId = useFingerPrintId();

  const [featureFlagUserId, setFeatureFlagUserId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (featureFlagUserIdAtomValue) {
      setFeatureFlagUserId(featureFlagUserIdAtomValue);

      return;
    }

    setFeatureFlagUserId(fingerPrintId || uuid);
  }, [featureFlagUserIdAtomValue, fingerPrintId]);

  return featureFlagUserId;
};
