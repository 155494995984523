import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import styled from "styled-components";

export const StyledTabletDownButton = styled(RhButton)`
  &.MuiButton-root {
    color: ${CSSVariables.COLOR_GREY_600};
    font-weight: ${FontWeight.Regular};
  }
`;
