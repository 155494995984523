import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal/api/invoiceApi";
import { InvoiceQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillBreakdownFactorsQueryProps {
  invoiceId: IdType;
}

export const useBillBreakdownFactorsQuery = (
  props: UseBillBreakdownFactorsQueryProps
) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.factors(invoiceId),
    queryKey: InvoiceQueryKeys.factors(invoiceId),
  });
};
