import { portalHelmetTranslations } from "@portal-shared/components/PortalHelmet/PortalHelmet.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { FAVICON_PATH } from "@portal-shared/settings/config";
import React from "react";
import { Helmet } from "react-helmet-async";

interface PortalFeatureFlagClientProviderProps {}

export const PortalHelmet = (props: PortalFeatureFlagClientProviderProps) => {
  const { translate } = useTranslations();

  const { tPortalHelmetTitle } = translate(portalHelmetTranslations);

  return (
    <Helmet>
      <title>{tPortalHelmetTitle}</title>
      <link rel="icon" type="image/x-icon" href={FAVICON_PATH} />
    </Helmet>
  );
};
