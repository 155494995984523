import { FontWeight } from "@design-system/enums/fontWeight.enum";
import styled, { css } from "styled-components";

const visibleStyles = css`
  color: red;
  font-weight: ${FontWeight.Bold};
  left: 5px;
  margin: 0;
  position: fixed;
  top: 0;
  white-space: nowrap;
  z-index: 1101;
`;

const hiddenStyles = css`
  display: none;
  height: 0;
  visibility: hidden;
  width: 0;
`;

export const StyledPre = styled.pre<{ $isVisible?: boolean }>`
  ${({ $isVisible }) => ($isVisible ? visibleStyles : hiddenStyles)}
`;
