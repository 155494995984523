import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@mui/material";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import { LoggedOutPageHeader } from "@portal/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { signUpReviewRequiredPageTranslations } from "@portal/pages/SignUpReviewRequiredPage/SignUpReviewRequiredPage.en.i18n";
import React from "react";

export const SignUpReviewRequiredPage = () => {
  const { translate, translateJsx } = useTranslations();
  const { tSignUpReviewRequiredPageGetSetUp } = translate(
    signUpReviewRequiredPageTranslations
  );

  const {
    tSignUpReviewRequiredPageQuestions,
    tSignUpReviewRequiredPageToStartService,
  } = translateJsx({
    tSignUpReviewRequiredPageQuestions: {
      phone: brandInformation.phoneNumbersOpsDisplay,
      phoneTag: (content: string) => (
        <RhAnchor
          href={`tel:${brandInformation.phoneNumbersOpsNumber}`}
          color="textSecondary"
        >
          {content}
        </RhAnchor>
      ),
    },
    tSignUpReviewRequiredPageToStartService: {
      email: brandInformation.enrollmentEmailSupport,
      emailTag: (content: string) => (
        <RhAnchor href={`mailto:${brandInformation.enrollmentEmailSupport}`}>
          {content}
        </RhAnchor>
      ),
    },
  });

  return (
    <SignUpPageLayout hideBackButton>
      <LoggedOutPageHeader headerText={tSignUpReviewRequiredPageGetSetUp} />
      <Box mt={LOGGED_OUT_CONTAINER_SPACING}>
        <Box mb={2}>
          <RhTypography
            variant="subtitle1"
            align="center"
            color="textSecondary"
          >
            {tSignUpReviewRequiredPageToStartService}
          </RhTypography>
        </Box>
        <Box mb={2}>
          <RhTypography
            variant="subtitle1"
            align="center"
            color="textSecondary"
          >
            {tSignUpReviewRequiredPageQuestions}
          </RhTypography>
        </Box>
      </Box>
    </SignUpPageLayout>
  );
};
