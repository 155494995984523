import { RhPasswordField } from "@design-system/components/RhPasswordField/RhPasswordField";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

export const PortalPasswordField: typeof RhPasswordField = (props) => {
  const { t } = useRhIntl();
  const hidePasswordLabel = t("PortalPasswordField.hidePassword");
  const showPasswordLabel = t("PortalPasswordField.showPassword");

  return (
    <RhPasswordField
      labels={{
        hidePassword: hidePasswordLabel,
        showPassword: showPasswordLabel,
      }}
      {...props}
    />
  );
};
