import styled from "styled-components";

export const StyledCartSelectedProductTiers = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.075);
  display: flex;
  flex-flow: column;
  gap: 0.75rem;
  padding: 2rem 0;
  width: 100%;
`;
