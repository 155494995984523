import { AppBrands } from "@common/enums/appBrands.enum";
import { EnvTypes } from "@common/enums/envTypes.enum";
import {
  appBrand,
  getEnv,
} from "@common/services/getEnvApplicationSettings.service";
import { energyTexasEnvApplicationSettings } from "@portal-shared/settings/energy-texas.env";
import { EnvSettings } from "@portal-shared/settings/envSettingsType";
import { rhythmEnvApplicationSettings } from "@portal-shared/settings/rhythm.env";

const envApplicationSettings: Record<
  AppBrands,
  Record<EnvTypes, EnvSettings>
> = {
  [AppBrands.ENERGY_TEXAS]: energyTexasEnvApplicationSettings,
  [AppBrands.RHYTHM]: rhythmEnvApplicationSettings,
};

export const getPortalEnvApplicationSettings = () => {
  const env = getEnv();

  return envApplicationSettings[appBrand][env];
};
