import { FeatureFlagValues } from "@common/context/featureFlagClient.context";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { Error404Page } from "@portal-shared/pages/Error404Page/Error404Page";
import { StyledLoading } from "@portal/components/FeatureFlagRoute/FeatureFlagRoute.styled";
import React, { ReactNode } from "react";

interface FeatureFlagRouteProps {
  component: ReactNode;
  featureFlag: keyof FeatureFlagValues;
}

export const FeatureFlagRoute = (props: FeatureFlagRouteProps) => {
  const { featureFlag, component: Component } = props;
  const { featureFlagClient } = useFeatureFlagClient();

  const fetchedFlags = featureFlagClient.getFlags([[`${featureFlag}`, false]]);
  const isFetchingFlags = featureFlagClient.isFetchingFlags();

  const flag = fetchedFlags[featureFlag];

  if (isFetchingFlags) {
    return (
      <StyledLoading>
        <RhCircularProgress />
      </StyledLoading>
    );
  }

  // If there is no flag or the flag is disabled, return a 404 page even though the http status is 200
  // If the path doesn't exist at all, it will get caught by our no match path in PortalRoutes
  if (!flag || !flag.value) {
    return <Error404Page />;
  }

  return <>{Component}</>;
};
