import { formatKwh } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  SliderOnChangeEvent,
  SliderOnChangeFunction,
  SliderOnChangeValueType,
} from "@design-system/types/domEventTypes";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal-shared/constants/offer.constant";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { monthlyUsageSliderTranslations } from "@portal/components/MonthlyUsageSliderV2/MonthlyUsageSliderV2.en.i18n";
import {
  DescriptionContainer,
  DescriptionImage,
  MonthlyUsageSliderContainer,
  StyledUsageSliderUnorderedList,
} from "@portal/components/MonthlyUsageSliderV2/MonthlyUsageSliderV2.styled";
import { MonthlyUsageSliderV2Input } from "@portal/components/MonthlyUsageSliderV2/MonthlyUsageSliderV2Input";
import { ReactComponent as House1000 } from "@portal/images/House1000.svg";
import { ReactComponent as House1500 } from "@portal/images/House1500.svg";
import { ReactComponent as House2000 } from "@portal/images/House2000.svg";
import { ReactComponent as House2500 } from "@portal/images/House2500.svg";
import { ReactComponent as House3000 } from "@portal/images/House3000.svg";
import { ReactComponent as House3500 } from "@portal/images/House3500.svg";
import { ReactComponent as House500 } from "@portal/images/House500.svg";
import React, { useState } from "react";

type DescriptionRecord = {
  Icon: JSX.Element;
  descriptor: string;
  preference: string;
};

interface MonthlyUsageSliderProps {
  onChange: SliderOnChangeFunction;
}

export const MonthlyUsageSliderV2 = ({ onChange }: MonthlyUsageSliderProps) => {
  const { translate } = useTranslations();

  const {
    tMonthlyUsageSliderV2HomeDescriptor1000,
    tMonthlyUsageSliderV2HomeDescriptor1500,
    tMonthlyUsageSliderV2HomeDescriptor2000,
    tMonthlyUsageSliderV2HomeDescriptor2500,
    tMonthlyUsageSliderV2HomeDescriptor3000,
    tMonthlyUsageSliderV2HomeDescriptor3500,
    tMonthlyUsageSliderV2HomeDescriptor500,
    tMonthlyUsageSliderV2HomePrefDescriptor1000,
    tMonthlyUsageSliderV2HomePrefDescriptor1500,
    tMonthlyUsageSliderV2HomePrefDescriptor2000,
    tMonthlyUsageSliderV2HomePrefDescriptor2500,
    tMonthlyUsageSliderV2HomePrefDescriptor3000,
    tMonthlyUsageSliderV2HomePrefDescriptor3500,
    tMonthlyUsageSliderV2HomePrefDescriptor500,
  } = translate(monthlyUsageSliderTranslations);

  const monthlyUsageSliderConfig: Record<string, DescriptionRecord> = {
    1000: {
      Icon: <House1000 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor1000,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor1000,
    },
    1500: {
      Icon: <House1500 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor1500,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor1500,
    },
    2000: {
      Icon: <House2000 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor2000,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor2000,
    },
    2500: {
      Icon: <House2500 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor2500,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor2500,
    },
    3000: {
      Icon: <House3000 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor3000,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor3000,
    },
    3500: {
      Icon: <House3500 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor3500,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor3500,
    },
    500: {
      Icon: <House500 title="House" />,
      descriptor: tMonthlyUsageSliderV2HomeDescriptor500,
      preference: tMonthlyUsageSliderV2HomePrefDescriptor500,
    },
  };

  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] = useState<number>(
    DEFAULT_AVERAGE_MONTHLY_USAGE
  );

  const handleChange: SliderOnChangeFunction = (
    event: SliderOnChangeEvent,
    value: SliderOnChangeValueType
  ) => {
    if (!Array.isArray(value)) {
      setEstimatedMonthlyUsage(value);
      onChange(event, value);
    }
  };

  const { Icon, descriptor, preference } =
    monthlyUsageSliderConfig[estimatedMonthlyUsage.toString()];

  return (
    <MonthlyUsageSliderContainer>
      <DescriptionContainer>
        <DescriptionImage>{Icon}</DescriptionImage>
        <div>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {formatKwh(estimatedMonthlyUsage, 0)}
          </RhTypography>
          <StyledUsageSliderUnorderedList>
            <li>
              <RhTypography variant="body1">{descriptor}</RhTypography>
            </li>
            <li>
              <RhTypography variant="body1">{preference}</RhTypography>
            </li>
          </StyledUsageSliderUnorderedList>
        </div>
      </DescriptionContainer>
      <MonthlyUsageSliderV2Input
        value={estimatedMonthlyUsage}
        onChange={handleChange}
      />
    </MonthlyUsageSliderContainer>
  );
};
