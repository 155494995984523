import { useBaseModals } from "@portal-shared/hooks/useBaseModals";
import {
  ModalsAtomType,
  initialModalsAtom,
  modalsStateAtom,
} from "@portal/components/ModalsManager/ModalsManager.atoms";

export const useModals = () => {
  return useBaseModals<ModalsAtomType>(modalsStateAtom, initialModalsAtom);
};
