export enum Utm {
  CAMPAIGN = "utm_campaign",
  MEDIUM = "utm_medium",
  SOURCE = "utm_source",
}

export enum UtmSource {
  RAF = "raf",
}

export enum UtmMedium {
  MY_ACCOUNT = "my-account",
}
