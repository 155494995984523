import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import { useEffect } from "react";

export const SignOut = () => {
  useEffect(() => {
    portalAuthClient.signOut();
  }, []);

  return null;
};
