import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { StyledPortalSharedMainContainer } from "@portal-shared/components/PortalSharedMainContainer/PortalSharedMainContainer.styled";
import React, { PropsWithChildren } from "react";

interface PortalSharedMainContainerProps {}

export const PortalSharedMainContainer = (
  props: PropsWithChildren<PortalSharedMainContainerProps>
) => {
  const { children } = props;
  // We need to figure out how much extra padding to put to the top of the app to accomodate the announcement banner,
  // if one is present. Since this is styled with MUI, we have to normalize the RH_HEADER_HEIGHT from a
  // theme.spacing multiple to a pixel value.
  const { bannerRef } = useRhAnnouncement();
  const AnnouncementBannerHeight = bannerRef?.current
    ? bannerRef.current.clientHeight
    : 0;

  return (
    <StyledPortalSharedMainContainer
      $announcementBannerHeight={AnnouncementBannerHeight}
    >
      {children}
    </StyledPortalSharedMainContainer>
  );
};
