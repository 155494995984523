import { PortalSharedProviders } from "@portal-shared/components/PortalSharedProviders/PortalSharedProviders";
import { initializePortalApp } from "@portal-shared/services/initializePortalApp";
import { PortalRoutes } from "@portal/routes/PortalRoutes";
import React from "react";
import { createRoot } from "react-dom/client";

const bootstrapApp = () => {
  initializePortalApp();

  const container = document.getElementById("root");

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <PortalSharedProviders>
      <PortalRoutes />
    </PortalSharedProviders>
  );
};

bootstrapApp();
