import { thirtyDaysFromNow } from "@common/utils/dateHelpers";
import { enrollOffersPath } from "@enroll-utils/constants/routePaths";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SignUpResume = () => {
  const { pathname, search } = useLocation();
  const { setCookie } = useEnrollCookies();
  const navigate = useNavigate();

  useEffect(() => {
    // Expected pathname like: `/sign-up/lead-generation/{uuid}...`
    const prospectUuidFromUrlPath = pathname.split("/")[3];

    if (prospectUuidFromUrlPath) {
      const { cookieDomain } = brandInformation;

      setCookie("rhProspectUuid", prospectUuidFromUrlPath, {
        domain: cookieDomain,
        expires: thirtyDaysFromNow(),
      });
    }

    navigate({ pathname: enrollOffersPath(), search }, { replace: true });
  }, [pathname]);

  return null;
};
