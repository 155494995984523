import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import React, { PropsWithChildren } from "react";

interface TOSLinkProps {
  dataTrackingClick: {
    [key: string]: string;
    location: string;
  };
  onClick?: () => void;
  tosUrl: string;
}

export const TOSLink = (props: PropsWithChildren<TOSLinkProps>) => {
  const { dataTrackingClick, onClick, tosUrl, children } = props;

  return (
    <RhAnchor
      data-tracking-click={JSON.stringify({
        event: "TOS download",
        ...dataTrackingClick,
      })}
      rel="noopener noreferrer"
      target="_blank"
      href={tosUrl}
      onClick={onClick}
    >
      {children}
    </RhAnchor>
  );
};
