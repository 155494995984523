import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { noop } from "@common/utils/genericFunctions";
import { welcomePacketDownload } from "@common/utils/welcomePacketDownload";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React, { MouseEventHandler, PropsWithChildren } from "react";

interface WelcomePacketLinkProps {
  dataTrackingClick: {
    [key: string]: string;
    location: string;
  };
  orderId: IdType;
  premiseId: IdType;
}

export const WelcomePacketLink = (
  props: PropsWithChildren<WelcomePacketLinkProps>
) => {
  const { dataTrackingClick, premiseId, orderId, children } = props;
  const flash = useRhFlash();
  const { t } = useRhIntl();

  const handleDownload = (): MouseEventHandler<HTMLAnchorElement> => {
    return (ev) => {
      ev.preventDefault();

      welcomePacketDownload(premiseId, orderId)
        .then(noop)
        .catch((_error: RhApiError) => {
          flash.error(t("LegalDocuments.errorDownloadingWelcomePacket"));
        });
    };
  };

  return (
    <RhAnchor
      data-tracking-click={JSON.stringify({
        event: "Welcome Packet Download",
        ...dataTrackingClick,
      })}
      href="#"
      rel="noopener noreferrer"
      onClick={handleDownload()}
      target="_self"
      key={premiseId}
    >
      {children}
    </RhAnchor>
  );
};
