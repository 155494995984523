export const paymentExtensionConfirmTranslations = {
  tPaymentExtensionConfirmBack: "Back",
  tPaymentExtensionConfirmDownPaymentDate: "Down Payment Date",
  tPaymentExtensionConfirmDownPaymentDue: "Down Payment Due Today",
  tPaymentExtensionConfirmError:
    "We were unable to process your payment. Please try again or pick a different payment method.",
  tPaymentExtensionConfirmExtendedBalance: "Extended Balance ",
  tPaymentExtensionConfirmExtensionDueDate: "Extension Due Date ",
  tPaymentExtensionConfirmPaymentMethod: "Payment method",
  tPaymentExtensionConfirmReviewAndSubmit:
    "Review and submit your Payment Extension",
  tPaymentExtensionConfirmSubmit: "Submit Payment Extension",
  tPaymentExtensionConfirmTermsAgree:
    "I agree to pay the Extended Balance by the specified Extension Due Date. I understand that failure to pay this amount may result in service interruption. I understand that by setting up a Payment Extension, I will still receive a Disconnect Notice and will be liable for Late Fees and Disconnect Notice Fees. I also understand that in setting up this Payment Extension, I am no longer eligible for any additional payment assistance for this amount.",
  tPaymentExtensionConfirmTotalPastDue: "Total Past Due Balance",
};
