import {
  AccountSummaryBalanceBreakDownType,
  AccountSummaryType,
} from "@portal/types/accountSummaryTypes";

export class AccountSummary {
  totalBalance: string;
  accountNumber: string;
  pastDueBalance: string;
  accountDueDate: string;
  nextDueDate: string;
  mostRecentBalance: string;
  balanceBreakdown: AccountSummaryBalanceBreakDownType;

  constructor(data: AccountSummaryType) {
    this.totalBalance = data.totalBalance;
    this.accountNumber = data.accountNumber;
    this.pastDueBalance = data.pastDueBalance;
    this.accountDueDate = data.accountDueDate;
    this.nextDueDate = data.nextDueDate;
    this.mostRecentBalance = data.mostRecentBalance;
    this.balanceBreakdown = data.balanceBreakdown;
  }
}
