import { IdType } from "@common/types/apiTypes";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { pricingFileUrls } from "@portal-shared/api/pricingApi";
import { AXIOS_BASE_URL } from "@portal-shared/settings/config";
import { Locale } from "@portal-shared/types/LocaleTypes";
import React, { PropsWithChildren } from "react";

interface EFLLinkProps {
  dataTrackingClick: {
    [key: string]: string;
    location: string;
  };
  locale: Locale;
  offerId: IdType;
  onClick?: () => void;
}

export const EFLLink = (props: PropsWithChildren<EFLLinkProps>) => {
  const { dataTrackingClick, offerId, onClick, locale, children } = props;

  return (
    <RhAnchor
      data-tracking-click={JSON.stringify({
        event: "EFL Download",
        ...dataTrackingClick,
      })}
      rel="noopener noreferrer"
      target="_blank"
      href={`${AXIOS_BASE_URL}${pricingFileUrls.offerSnapshotsEflUrl(
        offerId,
        locale
      )}`}
      onClick={onClick}
    >
      {children}
    </RhAnchor>
  );
};
