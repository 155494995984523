import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { PaymentMethodCard } from "@portal-shared/components/PaymentMethodCard/PaymentMethodCard";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

interface PaymentMethodRadioInputProps {
  isDeleting?: boolean;
  isOneTimePayment?: boolean;
  name?: string;
  onDeleteClick?: () => void;
  paymentMethod: PaymentMethod;
}

const StyledRadioInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const PaymentMethodRadioInput = (
  props: PaymentMethodRadioInputProps
) => {
  const {
    isDeleting,
    isOneTimePayment,
    name = "selectedPayment",
    paymentMethod,
    onDeleteClick,
  } = props;
  const { control, register } = useFormContext();

  const selectedPaymentId = useWatch({
    control,
    name,
  });

  return (
    <label htmlFor={`paymentMethod-${paymentMethod.id}`}>
      <StyledRadioInput
        type="radio"
        value={paymentMethod.id}
        id={`paymentMethod-${paymentMethod.id}`}
        {...register(name, { required: true })}
      />
      <PaymentMethodCard
        isDeleting={isDeleting}
        paymentMethod={paymentMethod}
        selected={selectedPaymentId === paymentMethod.id}
        onDeleteClick={onDeleteClick}
        isOneTimePayment={isOneTimePayment}
      />
    </label>
  );
};
