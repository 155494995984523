export const renewalReminderModalTranslations = {
  tRenewalReminderModalBestRate:
    "We'll do everything in our power to get you the absolute best rate.",
  tRenewalReminderModalCTA: "View Renewal Options",
  tRenewalReminderModalClose: "Remind Me Later",
  tRenewalReminderModalEnjoyElectricity:
    "Continue to enjoy uninterrupted electricity when you renew.",
  tRenewalReminderModalFixedRate:
    "Avoid getting rolled onto a variable-rate plan by locking in a fixed rate.",
  tRenewalReminderModalModalTitle: "Contract Renewal Reminder",
  tRenewalReminderModalSubTitle:
    "Your contract expires on {contractEndDate}. But lucky for you, renewing your contract takes seconds.",
  tRenewalReminderModalTitle: "{firstName}, your contract is up for renewal.",
  tRenewalReminderModalWhyRhythm: "Why renew with Rhythm Energy?",
};
