import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { Loader } from "@portal/components/Loader/Loader";
import { useDeviceEligibilityInformationQuery } from "@portal/hooks/queries/useDeviceEligibilityInformation";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { customerHomePath } from "@portal/routes/routePaths";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const DeviceManagementOutlet = () => {
  const customer = useCustomerFromContext();
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalDeviceManagement } = featureFlagClient.getFlags([
    ["portalDeviceManagement", false],
  ]);
  const {
    data: deviceEligibilityInformationData,
    isPending: dataIsPending,
    isError: dataIsError,
  } = useDeviceEligibilityInformationQuery({
    customerId: customer.id,
  });

  if (dataIsPending) {
    return <Loader />;
  }

  if (
    portalDeviceManagement.value &&
    deviceEligibilityInformationData?.thermostatEligible &&
    !dataIsError
  ) {
    return <Outlet />;
  }

  return <Navigate to={customerHomePath()} />;
};
