import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { orange } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Box, Dialog } from "@mui/material";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiDialog-paperFullScreen {
    background: linear-gradient(
      90deg,
      ${CSSVariables.COLOR_SECONDARY_MAIN},
      ${orange[400]}
    );
    border-radius: 10px 10px 0 0;
    margin-top: ${rhSpacingPx(10)};
    max-width: 70rem;
    overflow: visible;
    padding: 3px;
  }
`;

export const StyledContent = styled(Box)`
  background: ${CSSVariables.COLOR_WHITE};
  border-radius: 7px 7px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding: ${rhSpacingPx(8)};
  scroll-behavior: smooth;

  @media (max-width: ${RhythmBreakpoints.SM}px) {
    padding: ${rhSpacingPx(4)};
  }
`;
