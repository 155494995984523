export const GuestPayUrls = {
  BASE: "/api/portal/guest-pay" as const,
  accountSummaryByToken: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/account-summary`,
  accountSummaryLookup: () => `${GuestPayUrls.BASE}/account-summary`,
  createPayment: (token: string) => `${GuestPayUrls.BASE}/${token}/payments`,
  createPaymentMethod: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/payment-method`,
  deletePaymentMethod: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/payment-method`,
  getPaymentMethod: (token: string) =>
    `${GuestPayUrls.BASE}/${token}/payment-method`,
};
