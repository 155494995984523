export const translations = {
  tEnergyUsageSectionV2Average: "Average",
  tEnergyUsageSectionV2AverageTemp: "Average Temperature",
  tEnergyUsageSectionV2BillPeriodTab: "Current Billing Period",
  tEnergyUsageSectionV2BillToDate: "Bill to Date",
  tEnergyUsageSectionV2BillToDateTooltip:
    "Your <bold>Bill to Date</bold> is your current bill amount as of {date}. It combines your usage, plan, and a 5% tax estimate, and does not reflect your final balance.",
  tEnergyUsageSectionV2Both: "Both",
  tEnergyUsageSectionV2Consumption: "Consumption",
  tEnergyUsageSectionV2CostTooltipLabel: "Cost:",
  tEnergyUsageSectionV2DailyTab: "Past 30 Days",
  tEnergyUsageSectionV2DateHourTitle: "Date/Hour:",
  tEnergyUsageSectionV2DatePickerCancel: "Cancel",
  tEnergyUsageSectionV2DatePickerOk: "Ok",
  tEnergyUsageSectionV2DateTitle: "Date: ",
  tEnergyUsageSectionV2DefaultTierTitle: "All-day rate",
  tEnergyUsageSectionV2EarnedTooltipLabel: "Surplus Credit:",
  tEnergyUsageSectionV2EnergyGraphSectionLabel: "Energy Graph",
  tEnergyUsageSectionV2GenerationLabelTitle: "Surplus Generation",
  tEnergyUsageSectionV2GenerationTooltipLabel: "Surplus Generation:",
  tEnergyUsageSectionV2HideTemp: "Hide Temperature",
  tEnergyUsageSectionV2High: "High",
  tEnergyUsageSectionV2HighLow: "High/Low Temperature",
  tEnergyUsageSectionV2HighTemp: "High Temperature",
  tEnergyUsageSectionV2HourlyTab: "Hourly",
  tEnergyUsageSectionV2Low: "Low",
  tEnergyUsageSectionV2LowTemp: "Low Temperature",
  tEnergyUsageSectionV2MonthlyTab: "Monthly",
  tEnergyUsageSectionV2ProjectedBill: "Projected Bill",
  tEnergyUsageSectionV2ProjectedBillTooltip:
    "Your projected bill is generated once a day and is used to estimate your bill for a given bill period. Rhythm takes past historical usage, projected usage for this month, and contract information into account when generating this projection.",
  tEnergyUsageSectionV2SectionTitle: "Energy Usage",
  tEnergyUsageSectionV2Surplus: "Surplus",
  tEnergyUsageSectionV2SurplusCredits: "Surplus Credits",
  tEnergyUsageSectionV2Tap: "*Tap on the chart to see more details.",
  tEnergyUsageSectionV2Temperature: "Temperature",
  tEnergyUsageSectionV2TemperatureFahrenheit: "Temperature (°F)",
  tEnergyUsageSectionV2TotalCost: "Total Cost",
  tEnergyUsageSectionV2TotalCostTooltip:
    "This is calculated as Total Usage x Energy Charge Rate + Delivery Charges. It excludes monthly base charges.",
  tEnergyUsageSectionV2TotalEarnedTooltip:
    "Surplus credit is calculated at the end of your billing cycle based on your total surplus generation. You will earn solar buyback credits up to your monthly consumption (Total Usage).",
  tEnergyUsageSectionV2TotalGeneration: "Surplus Generation",
  tEnergyUsageSectionV2TotalUsage: "Total Usage",
  tEnergyUsageSectionV2TotalUsageTooltip:
    "Total usage may not always reflect what's on your monthly bill. This is because sometimes Rhythm receives incomplete interval data from your TDSP, so we estimate your usage for missing periods based on historical data from your meter and calculate your estimated monthly bill to display in a timely manner on this graph.",
  tEnergyUsageSectionV2UsageAxisTitle: "Usage (kWh)",
  tEnergyUsageSectionV2UsageLabelTitle: "Usage",
  tEnergyUsageSectionV2UsageTooltipLabel: "Usage:",
};
