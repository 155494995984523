export type Locale = "en" | "es";

export const allLocales: Record<Locale, Locale> = {
  en: "en",
  es: "es",
};

export const AllSupportedLocales: [Locale, Locale] = [
  allLocales.en,
  allLocales.es,
] as const;
