import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { atom, useAtom } from "jotai";

export const oneClickRenewalPageOfferSnapshotAtom = atom<OfferSnapshot | null>(
  null
);

export const useOneClickRenewalPageOfferSnapshotAtom = () => {
  return useAtom(oneClickRenewalPageOfferSnapshotAtom);
};
