import { legacyCustomerApi } from "@portal/api/legacyCustomerApi";
import { customerQueryKeys } from "@portal/constants/querykeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCustomerLegacyUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: legacyCustomerApi.legacyUpdate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: customerQueryKeys.BASE,
      });
    },
  });
};
