export const billTipsCardTranslations = {
  tBillTipsCardBeAFan:
    "Be a fan of ceiling fans. They use less energy than your AC and keep a nice breeze flowin'.",
  tBillTipsCardBlankets:
    "Blankets are your buddies. Snuggle up and watch your bills shrink.",
  tBillTipsCardCheckWindows:
    "Check windows and doors for drafts and make sure you're all sealed up.",
  tBillTipsCardIfYoureNotHomeAC:
    "If you’re not home, there’s no sense in blasting the air conditioning. Set it a bit warmer when you’re out and about.",
  tBillTipsCardIfYoureNotHomeHeat:
    "If you’re not home, there’s no sense in blasting the heat. Set it a bit colder when you’re out and about.",
  tBillTipsCardLed:
    "LED are your new BFFs, as they last longer, use less electricity, and are way better for the planet.",
  tBillTipsCardSealCold:
    "Seal off any gaps around doors and windows so that pesky cold can't get in.",
  tBillTipsCardSealHeat:
    "Seal off any gaps around doors and windows so that pesky heat can't get in.",
  tBillTipsCardSomeTips: "Some tips to reduce your next bill....",
  tBillTipsCardUnplug:
    "Unplug the vampires, meaning there's no point in keeping appliances like coffee makers and game consoles plugged in if not in use.",
};
