import "dayjs/locale/es";

import { AppBrands } from "@common/enums/appBrands.enum";
import { EnvTypes } from "@common/enums/envTypes.enum";
import {
  appBrand,
  getEnv,
} from "@common/services/getEnvApplicationSettings.service";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { intlErrorPath } from "@portal-shared/routes/routePaths";
import enBase from "@portal-shared/translations/en-base.json";
import enEnergyTexas from "@portal-shared/translations/en-energyTexas.json";
import enRhythm from "@portal-shared/translations/en-rhythm.json";
import esEnergyTexas from "@portal-shared/translations/es-energyTexas.json";
import esRhythm from "@portal-shared/translations/es-rhythm.json";
import { globalIntl } from "@portal-shared/translations/globalIntl";
import { Locale, allLocales } from "@portal-shared/types/LocaleTypes";
import { captureException as sentryCaptureException } from "@sentry/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IntlShape, createIntl } from "react-intl";
import { NavigateFunction, useNavigate } from "react-router-dom";

const translationsMap = {
  [allLocales.en + AppBrands.ENERGY_TEXAS]: {
    ...enBase,
    ...enRhythm,
    ...enEnergyTexas,
  },
  [allLocales.es + AppBrands.ENERGY_TEXAS]: {
    ...enBase,
    ...esRhythm,
    ...esEnergyTexas,
  },
  [allLocales.en + AppBrands.RHYTHM]: { ...enBase, ...enRhythm },
  [allLocales.es + AppBrands.RHYTHM]: { ...enBase, ...esRhythm },
};

export const getTranslation = (locale: Locale) => {
  return (
    translationsMap[locale + appBrand] ||
    translationsMap[allLocales.es + AppBrands.RHYTHM]
  );
};

const generateIntl = (
  locale: Locale,
  navigate: NavigateFunction
): IntlShape => {
  dayjs.locale(locale);

  const translation = getTranslation(locale);

  const intl = createIntl({
    locale,
    messages: translation,
    onError: (error) => {
      sentryCaptureException(error);

      if (getEnv() !== EnvTypes.production) {
        navigate(intlErrorPath());
      }
    },
  });

  Object.assign(globalIntl, intl);

  return intl;
};

export const useDynamicIntl = (): IntlShape => {
  const navigate = useNavigate();
  const [locale] = useLocaleAtom();
  const [intl, setIntl] = useState(generateIntl(locale, navigate));

  useEffect(() => {
    if (intl.locale === locale) {
      return;
    }
    const newIntl = generateIntl(locale, navigate);

    setIntl(newIntl);
  }, [locale]);

  return intl;
};
