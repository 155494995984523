import { formatToCents } from "@common/utils/dataFormatters";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { GroupedCompetitorPlans } from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { getLogoUrl } from "@portal-shared/components/CompetitorPlanComparisonChart/helpers";
import { Plugin } from "chart.js";

export const imageTickPlugin = () => {
  const baseImageWidth = 87;
  const baseImageHeight = 40;

  // In-memory cache for images
  const imageCache: { [key: string]: HTMLImageElement } = {};

  const preloadImages = (chartData: GroupedCompetitorPlans[]) => {
    chartData.forEach((groupedPlan) => {
      const src = getLogoUrl(groupedPlan.competitorName);

      if (!imageCache[src]) {
        const img = new Image();

        img.src = src;
        imageCache[src] = img;
      }
    });
  };

  return {
    beforeDraw: (chart, args, plugins) => {
      const {
        ctx,
        data,
        scales: { x },
      } = chart;

      // Adjust image size based on screen width
      const screenWidth = window.innerWidth;
      let imageWidth = baseImageWidth;
      let imageHeight = baseImageHeight;

      if (screenWidth < RhythmBreakpoints.XS) {
        imageWidth = 55;
        imageHeight = 25;
      }

      ctx.save();

      const chartData = data.datasets[0]
        .data as unknown as GroupedCompetitorPlans[];

      chartData.forEach((groupedPlan, index) => {
        const src = getLogoUrl(groupedPlan.competitorName);
        const imageForLabel = imageCache[src];

        if (imageForLabel) {
          const xPos = x.getPixelForValue(index) - imageWidth / 2;

          ctx.drawImage(
            imageForLabel,
            xPos,
            x.top + 8,
            imageWidth,
            imageHeight
          );
        }
      });

      ctx.restore();
    },
    beforeInit: (chart) => {
      const chartData = chart.data.datasets[0]
        .data as unknown as GroupedCompetitorPlans[];

      preloadImages(chartData);
    },
    id: "imageTickPlugin",
  } satisfies Plugin<"bar", GroupedCompetitorPlans[]>;
};

interface DrawLinePluginProps {
  lineValue: number;
}

export const drawLinePlugin = ({ lineValue }: DrawLinePluginProps) =>
  ({
    afterDatasetsDraw: (chart, args, pluginOptions) => {
      const { ctx } = chart;
      const { chartArea, scales } = chart;
      const { left, right } = chartArea;

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = brandTheme.colors.primary.main;
      ctx.setLineDash([10, 5]);

      const yCoord = scales.y.getPixelForValue(lineValue);

      ctx.moveTo(left, yCoord);
      ctx.lineTo(right, yCoord);

      ctx.stroke();
      ctx.restore();
    },
    id: "drawLinePlugin",
  }) satisfies Plugin<"bar", GroupedCompetitorPlans[]>;

interface DrawTextPluginProps {
  perKwhLabel: string;
}

export const drawTextPlugin = (props: DrawTextPluginProps) => {
  const { perKwhLabel } = props;

  return {
    afterDatasetsDraw: (chart, args, options) => {
      const { ctx } = chart;
      const dataset = chart.config.data.datasets[0];
      const meta = chart.getDatasetMeta(0);

      let text1FontSize = 16;
      let text2FontSize = 14;

      const screenWidth = window.innerWidth;

      if (screenWidth < RhythmBreakpoints.XS) {
        text1FontSize = 14;
        text2FontSize = 12;
      }

      meta.data.forEach((bar, index) => {
        const data = dataset.data[index] as unknown as GroupedCompetitorPlans;

        if (data) {
          const line1 = `${formatToCents(data.averagePrice)}`;
          const line2 = perKwhLabel;

          ctx.save();

          ctx.font = `bold ${text1FontSize}px ${fontFamily}`;
          const textWidth1 = ctx.measureText(line1).width;
          const centerX1 = bar.x - textWidth1 / 2;

          ctx.fillStyle = brandTheme.colors.black;
          ctx.fillText(line1, centerX1, bar.y - 22);

          ctx.font = `${text2FontSize}px ${fontFamily}`;
          const textWidth2 = ctx.measureText(line2).width;
          const centerX2 = bar.x - textWidth2 / 2;

          // eslint-disable-next-line prefer-destructuring
          ctx.fillStyle = brandTheme.colors.grey[500];
          ctx.fillText(line2, centerX2, bar.y - 7);

          ctx.restore();
        }
      });
    },
    id: "drawTextPlugin",
  } satisfies Plugin<"bar", GroupedCompetitorPlans[]>;
};
