// react-router suggested way to reset scroll position on different pages
// https://reactrouter.com/web/guides/scroll-restoration
import { rhWindow } from "@common/utils/rhWindow";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      rhWindow.scrollTo({ behavior: "auto", left: 0, top: 0 });
    }, 0);
  }, [pathname]);

  return null;
}
