export const devicesPageTranslations = {
  tDevicesPageAddNewButton: "Connect Device",
  tDevicesPageFAQsTitle: "FAQs About Devices",
  tDevicesPageNoAttachedDevicesSubtitle:
    "Help stabilize the Texas power grid through connected devices.",
  tDevicesPageNoAttachedDevicesTitle:
    "Earn {credit}/month in bill credits when you connect a participating smart thermostat.",
  tDevicesPageQueryError: "An error occurred while fetching your devices.",
  tDevicesPageTitlePlural: "Your Devices",
  tDevicesPageTitleSingular: "Your Device",
};
