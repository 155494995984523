import { StyledFormControlLabel } from "@design-system/components/RhCheckbox/RhCheckbox.styled";
import { Checkbox } from "@mui/material";
import React, { ReactNode, RefObject } from "react";

export interface RhCheckboxProps {
  "aria-label"?: string;
  autoFocus?: boolean;
  checked: boolean;
  className?: string | undefined;
  disabled?: boolean;
  label: string | ReactNode;
  name: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  ref?: RefObject<HTMLInputElement>;
}

export const RhCheckbox = ({
  autoFocus = false,
  className,
  disabled,
  name,
  label,
  checked,
  onChange,
  ref,
  "aria-label": ariaLabel,
}: RhCheckboxProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    onChange?.(event, isChecked);
  };

  return (
    <StyledFormControlLabel
      className={className}
      control={
        <Checkbox
          sx={{
            "&:hover": {
              backgroundColor: "rgba(69, 0, 165, 0.04)",
              boxShadow: "none",
            },
          }}
          disableRipple
          aria-label={ariaLabel}
          color="primary"
          autoFocus={autoFocus}
          disabled={disabled}
          id={name}
          inputRef={ref}
          name={name}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};
