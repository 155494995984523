import { Order } from "@common/models/Order.model";
import { AddressType } from "@common/types/customerTypes";
import {
  formatDollarsToCents,
  formatShorthandMonthDayYear,
} from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { renewalSummaryPlanInfoTranslations } from "@portal-shared/components/RenewalSummaryPlanInfo/RenewalSummaryPlanInfo.en.i18n";
import {
  StyledPlanDetailRow,
  StyledPlanDetailRowLabel,
  StyledPlanDetailRowValue,
  StyledPlanDetails,
} from "@portal-shared/components/RenewalSummaryPlanInfo/RenewalSummaryPlanInfo.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";

const SummaryRow = (props: PropsWithChildren<{ title: string }>) => {
  const { title, children } = props;

  return (
    <StyledPlanDetailRow>
      <StyledPlanDetailRowLabel>
        <RhTypography color="textSecondary">{title}</RhTypography>
      </StyledPlanDetailRowLabel>
      <StyledPlanDetailRowValue>{children}</StyledPlanDetailRowValue>
    </StyledPlanDetailRow>
  );
};

interface RenewalSummaryPlanInfoProps {
  currentOrder: Order;
  customerFullName?: string;
  offerSnapshot: OfferSnapshot;
  serviceAddress?: AddressType | null;
}

export const RenewalSummaryPlanInfo = (props: RenewalSummaryPlanInfoProps) => {
  const { currentOrder, customerFullName, offerSnapshot, serviceAddress } =
    props;

  const { translate, translateJsx } = useTranslations();
  const {
    tRenewalSummaryPlanInfoPlan,
    tRenewalSummaryPlanInfoContractEndDate,
    tRenewalSummaryPlanInfoContractStartDate,
    tRenewalSummaryPlanInfoName,
    tRenewalSummaryPlanInfoAddress,
  } = translate(renewalSummaryPlanInfoTranslations);
  const { tRenewalSummaryPlanInfoAveragePrice, tRenewalSummaryPlanInfoMonths } =
    translateJsx({
      tRenewalSummaryPlanInfoAveragePrice: {
        averagePrice: formatDollarsToCents(offerSnapshot.price2000Kwh),
      },
      tRenewalSummaryPlanInfoMonths: {
        months: offerSnapshot.termMonths,
      },
    });

  const { renewalStartDate } = currentOrder;

  const expectedEndDate = () => {
    return dayjs(renewalStartDate)
      .add(offerSnapshot.termMonths, "months")
      .toString();
  };

  return (
    <StyledPlanDetails>
      <SummaryRow title={tRenewalSummaryPlanInfoPlan}>
        <StyledPlanDetailRowLabel>
          <RhTypography fontWeight="Bold">{offerSnapshot.title}</RhTypography>
        </StyledPlanDetailRowLabel>
        <RhTypography>{tRenewalSummaryPlanInfoAveragePrice}</RhTypography>
        <RhTypography>{tRenewalSummaryPlanInfoMonths}</RhTypography>
      </SummaryRow>

      <SummaryRow title={tRenewalSummaryPlanInfoContractStartDate}>
        <RhTypography>
          {formatShorthandMonthDayYear(renewalStartDate)}
        </RhTypography>
      </SummaryRow>

      <SummaryRow title={tRenewalSummaryPlanInfoContractEndDate}>
        <RhTypography>
          {formatShorthandMonthDayYear(expectedEndDate())}
        </RhTypography>
      </SummaryRow>

      {customerFullName ? (
        <SummaryRow title={tRenewalSummaryPlanInfoName}>
          <RhTypography>{customerFullName}</RhTypography>
        </SummaryRow>
      ) : null}

      {serviceAddress ? (
        <SummaryRow title={tRenewalSummaryPlanInfoAddress}>
          <RhTypography>{serviceAddress.deliveryLine1}</RhTypography>
          <RhTypography>
            {serviceAddress.city}, {serviceAddress.state}
          </RhTypography>
          <RhTypography>{serviceAddress.zipCode}</RhTypography>
        </SummaryRow>
      ) : null}
    </StyledPlanDetails>
  );
};
