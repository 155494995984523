import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { documentHistoryTranslations } from "@portal/components/DocumentHistory/DocumentHistory.en.i18n";
import { StyledLoadingContainer } from "@portal/components/DocumentHistory/DocumentHistory.styled";
import { DocumentHistoryTable } from "@portal/components/DocumentHistoryTable/DocumentHistoryTable";
import { useDocumentHistoryQuery } from "@portal/hooks/queries/useDocumentHistory.query";
import { usePremise } from "@portal/hooks/usePremise";
import { CustomerHomeSectionInfoMessage } from "@portal/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage";
import { CustomerHomeSectionInfoMessageContainer } from "@portal/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage.styled";
import React from "react";

export const DocumentHistory = () => {
  const { translate } = useTranslations();
  const { premise } = usePremise();
  const premiseId = premise?.id ?? "";
  const {
    data: documentHistoryData,
    isPending,
    isError,
  } = useDocumentHistoryQuery({
    premiseId,
    queryOptions: {
      enabled: Boolean(premiseId),
    },
  });

  const { tDocumentHistoryError, tDocumentHistoryNoDocuments } = translate(
    documentHistoryTranslations
  );

  if (!premise || isPending) {
    return (
      <StyledLoadingContainer>
        <RhCircularProgress size={24} />
      </StyledLoadingContainer>
    );
  }

  if (isError) {
    return (
      <CustomerHomeSectionInfoMessageContainer>
        <CustomerHomeSectionInfoMessage>
          {tDocumentHistoryError}
        </CustomerHomeSectionInfoMessage>
      </CustomerHomeSectionInfoMessageContainer>
    );
  }

  if (!documentHistoryData || documentHistoryData?.length === 0) {
    return (
      <CustomerHomeSectionInfoMessageContainer>
        <CustomerHomeSectionInfoMessage>
          {tDocumentHistoryNoDocuments}
        </CustomerHomeSectionInfoMessage>
      </CustomerHomeSectionInfoMessageContainer>
    );
  }

  return (
    <DocumentHistoryTable
      documentHistory={documentHistoryData}
      premiseAccountNumber={premise.billingAccountNumber}
    />
  );
};
