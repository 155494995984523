import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledPayBillPageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledBillPageCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledBillPageForm = styled.form`
  width: 100%;
`;

export const StyledPaymentMethodsSectionContainer = styled.div`
  margin-bottom: ${rhSpacingPx(3)};
`;
