export const devicesEnrollSelectDevicesFormTranslations = {
  tDevicesEnrollSelectDevicesFormAddress: "Address",
  tDevicesEnrollSelectDevicesFormAlreadyConnected:
    "This device is already connected to another Rhythm account. Please contact support for assistance.",
  tDevicesEnrollSelectDevicesFormCancel: "Cancel",
  tDevicesEnrollSelectDevicesFormConnected: "Connected",
  tDevicesEnrollSelectDevicesFormConnectionStatus: "Connection Status",
  tDevicesEnrollSelectDevicesFormDeviceName: "Device Name",
  tDevicesEnrollSelectDevicesFormDeviceStatus: "Device Status",
  tDevicesEnrollSelectDevicesFormDeviceType: "Device Type",
  tDevicesEnrollSelectDevicesFormErrorSubmitting:
    "Error submitting your devices. Please try again.",
  tDevicesEnrollSelectDevicesFormModelNumber: "Model Number",
  tDevicesEnrollSelectDevicesFormSubmit: "Confirm & Connect Devices",
  tDevicesEnrollSelectDevicesFormSubmitting: "Connecting Devices...",
};
