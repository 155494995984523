import { Premise } from "@common/models/Premise.model";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { selectCustomerPremisePreference } from "@portal/selectors/customerPreferencesSelectors";
import { selectPremise } from "@portal/selectors/premiseSelectors";
import { premiseFetch } from "@portal/slices/premiseSlice";
import { useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePremise = () => {
  const activePremiseId = useSelector(selectCustomerPremisePreference);
  const { premise, status, error } = useSelector(selectPremise);
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);
  const dispatch = useDispatch();

  const premiseInstance = useMemo(() => {
    if (!premise) {
      return null;
    }

    const newPremiseInstance = new Premise(premise);

    setGoogleAnalyticsDataAtom({
      customerUuid: newPremiseInstance.customerUuid,
      premiseCurrentOrderUuid: newPremiseInstance.currentOrder?.uuid,
      premiseUuid: newPremiseInstance.uuid,
      type: "loggedIn",
    });

    return newPremiseInstance;
  }, [premise, setGoogleAnalyticsDataAtom]);

  useEffect(() => {
    if (activePremiseId) {
      dispatch(premiseFetch());
    }
  }, [activePremiseId, dispatch]);

  return {
    error,
    premise: premiseInstance,
    requestMonitor: status,
  };
};
