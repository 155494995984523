import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import styled from "styled-components";

export const StyledLogoLink = styled(DynamicLink)`
  width: 7rem;

  svg {
    filter: none !important;
  }
`;
