import { UUIDType } from "@common/types/apiTypes";

export const GuestPayQueryKeys = {
  BASE: ["guestPay"] as const,
  accountBalance: (token: UUIDType) =>
    [...GuestPayQueryKeys.BASE, token, "accountBalance"] as const,
  getPaymentMethod: (token: UUIDType) =>
    [...GuestPayQueryKeys.BASE, token, "paymentMethod"] as const,
  updatePaymentMethod: (token: UUIDType) =>
    [...GuestPayQueryKeys.BASE, token, "updatePaymentMethod"] as const,
};
