import {
  PortalToggleInput,
  PortalToggleLabel,
  PortalToggleSwitch,
} from "@portal-shared/components/PortalToggle/PortalToggle.styled";
import React, { ComponentPropsWithRef, PropsWithChildren } from "react";

interface PortalToggleProps {
  className?: string;
  dataTrackingClick: {
    [key: string]: string;
    event: string;
  };
  inputProps: ComponentPropsWithRef<"input">;
}

export const PortalToggle = (props: PropsWithChildren<PortalToggleProps>) => {
  const { inputProps, children, className, dataTrackingClick } = props;

  return (
    <PortalToggleLabel
      className={className}
      aria-disabled={inputProps.disabled}
    >
      {children}
      <PortalToggleInput
        data-tracking-click={JSON.stringify(dataTrackingClick)}
        {...inputProps}
        type="checkbox"
      />
      <PortalToggleSwitch />
    </PortalToggleLabel>
  );
};
