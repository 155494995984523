import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const EnrollErrorPageStyledLightSwitch = styled.div`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
`;

export const EnrollErrorPageErrorMessage = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;
