import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import {
  keepPreviousData as reactQueryKeepPreviousData,
  useQuery,
} from "@tanstack/react-query";

interface UseThermostatDemandResponseActivityQueryProps {
  premiseId: IdType;
  queryOptions: {
    enabled: boolean;
    keepPreviousData: boolean;
  };
  searchOptions: {
    limit: number;
    offset: number;
    thermostatId: IdType;
  };
}

export const useThermostatDemandResponseActivityQuery = (
  props: UseThermostatDemandResponseActivityQueryProps
) => {
  const {
    premiseId,
    queryOptions: { enabled, keepPreviousData } = {},
    searchOptions,
  } = props;

  return useQuery({
    enabled,
    placeholderData: keepPreviousData ? reactQueryKeepPreviousData : undefined,
    queryFn: () =>
      premiseApi.thermostatDemandResponseActivity({
        premiseId,
        searchOptions,
      }),
    queryKey: PremiseQueryKeys.thermostatDemandResponseActivity({
      premiseId,
      searchOptions,
    }),
  });
};
