export const AtAGlanceCarouselTranslations = {
  tAtAGlanceCarouselCtaChangeBillingDate: "Choose a date that works for you",
  tAtAGlanceCarouselCtaFaq: "Read more",
  tAtAGlanceCarouselCtaOptIn: "Opt-in to paperless",
  tAtAGlanceCarouselCtaSolarSetupBillingUsage: "Check out our FAQ",
  tAtAGlanceCarouselDueDatePicked: "You've picked your due date!",
  tAtAGlanceCarouselEnergySavingTips: "Energy saving tips are here!",
  tAtAGlanceCarouselOptInToPaperless:
    "Skip the paper bills and contract documents.",
  tAtAGlanceCarouselPickADueDate: "Pick Your Due Date",
  tAtAGlanceCarouselSignedUpForPaperless:
    "You're signed up for paperless bill and contract documents!",
  tAtAGlanceCarouselSolarSetupBillingUsage:
    "Questions on setup, billing, or usage?",
};
