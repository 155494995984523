import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { visuallyHidden } from "@design-system/theme/helpers/visuallyHidden";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const PortalToggleLabel = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
`;

export const PortalToggleSwitch = styled.div`
  background: ${CSSVariables.COLOR_GREY_200};
  border-radius: 35px;
  height: 22px;
  min-width: 42px;
  padding: ${rhSpacingPx(0.5)};
  position: relative;
  transition: 300ms all;

  &:before {
    background: white;
    border-radius: 35px;
    content: "";
    height: 20px;
    left: 1px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: 300ms all;
    width: 20px;
  }
`;

export const PortalToggleInput = styled.input.attrs({
  type: "checkbox",
})`
  ${visuallyHidden}

  &:checked + ${PortalToggleSwitch} {
    background: ${CSSVariables.COLOR_PRIMARY_MAIN};

    &:before {
      transform: translate(20px, -50%);
    }
  }

  &:disabled + ${PortalToggleSwitch} {
    background: ${CSSVariables.COLOR_GREY_100};
  }
`;
