import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { ProspectUpdateRequestType } from "@enroll-utils/types/prospectTypes";
import { AllSupportedLocales } from "@portal-shared/types/LocaleTypes";
import { OfferSnapshotFilterType } from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.types";
import { selectedOfferSnapshotFilterAtom } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPage.atoms";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useEnrollQueryParams = () => {
  const [searchParams] = useSearchParams();

  const { mutate } = useProspectUpdateMutation();

  const dispatch = useDispatch();

  const setSelectedOfferSnapshotFilter = useSetAtom(
    selectedOfferSnapshotFilterAtom
  );

  const {
    cookies: { rhFeatureFlagUserId },
  } = useEnrollCookies();

  useEffect(() => {
    if (searchParams.size === 0) {
      return;
    }

    // Non prospect fields
    if (searchParams.get("term_months_tab")) {
      setSelectedOfferSnapshotFilter(
        searchParams.get("term_months_tab") as OfferSnapshotFilterType
      );
    }

    if (searchParams.get("appSource")) {
      dispatch(
        setSignUpInfo({
          appSource: searchParams.get("appSource"),
        })
      );
    }
  }, [dispatch, searchParams, setSelectedOfferSnapshotFilter]);

  useEffect(() => {
    const languagePreference = (AllSupportedLocales as string[]).includes(
      searchParams.get("locale") || ""
    )
      ? searchParams.get("locale")
      : undefined;

    const prospectFields: ProspectUpdateRequestType = {
      acquisitionCampaign: searchParams.get("rh_campaign") || undefined,
      acquisitionContent: searchParams.get("rh_content") || undefined,
      acquisitionMedium: searchParams.get("rh_medium") || undefined,
      acquisitionSource: searchParams.get("rh_source") || undefined,
      featureFlagUserId:
        rhFeatureFlagUserId ||
        searchParams.get("featureFlagUserId") ||
        undefined,
      languagePreference,
      rcid: searchParams.get("rcid") || undefined,
      referralCode: searchParams.get("referralCode") || undefined,
      zipCode: searchParams.get("zipcode") || undefined,
    };

    const filteredParamsObject = Object.fromEntries(
      Object.entries(prospectFields).filter(([, value]) => value !== undefined)
    );

    if (Object.entries(filteredParamsObject).length > 0) {
      mutate({
        data: filteredParamsObject,
      });
    }
  }, [searchParams]);
};
