import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { deviceEnrollmentRefresherTranslations } from "@portal/components/DeviceEnrollmentRefresher/DeviceEnrollmentRefresher.en.i18n";
import {
  StyledContainer,
  StyledListItem,
  StyledListItemNumber,
  StyledListItemText,
  StyledNumberedList,
} from "@portal/components/DeviceEnrollmentRefresher/DeviceEnrollmentRefresher.styled";
import React, { ReactNode } from "react";

interface DeviceEnrollmentRefresherProps {
  credit: string | undefined;
}

interface RefresherStep {
  description: ReactNode;
  title: ReactNode;
}

export const DeviceEnrollmentRefresher = (
  props: DeviceEnrollmentRefresherProps
) => {
  const { credit } = props;

  const { translate, translateJsx } = useTranslations();
  const {
    tDeviceEnrollmentRefresherConnect,
    tDeviceEnrollmentRefresherConnectDescription,
    tDeviceEnrollmentRefresherCredits,
    tDeviceEnrollmentRefresherStayConnected,
    tDeviceEnrollmentRefresherStayConnectedDescription,
    tDeviceEnrollmentRefresherTitle,
  } = translate(deviceEnrollmentRefresherTranslations);

  const { tDeviceEnrollmentRefresherCreditsDescription } = translateJsx({
    tDeviceEnrollmentRefresherCreditsDescription: {
      credit,
    },
  });

  const refresherSteps: RefresherStep[] = [
    {
      description: tDeviceEnrollmentRefresherConnectDescription,
      title: tDeviceEnrollmentRefresherConnect,
    },
    {
      description: tDeviceEnrollmentRefresherStayConnectedDescription,
      title: tDeviceEnrollmentRefresherStayConnected,
    },
    {
      description: tDeviceEnrollmentRefresherCreditsDescription,
      title: tDeviceEnrollmentRefresherCredits,
    },
  ];

  return (
    <StyledContainer>
      <RhTypography component="h2" variant="h3" fontWeight={FontWeight.Regular}>
        {tDeviceEnrollmentRefresherTitle}
      </RhTypography>
      <StyledNumberedList>
        {refresherSteps.map(({ description, title }, index) => (
          <StyledListItem key={title?.toString()}>
            <StyledListItemNumber />
            <StyledListItemText>
              <RhTypography variant="h3">{title}</RhTypography>
              <RhTypography variant="body1">{description}</RhTypography>
            </StyledListItemText>
          </StyledListItem>
        ))}
      </StyledNumberedList>
    </StyledContainer>
  );
};
