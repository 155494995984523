import { UUIDType } from "@common/types/apiTypes";
import { quickPayApi } from "@portal-guest/api/quickPayApi";
import { QuickPayQueryKeys } from "@portal-guest/queryKeys/quickPayQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseQuickPayTokenProps {
  token: UUIDType;
}

export const useQuickPayAccountBalanceQuery = (
  props: UseQuickPayTokenProps
) => {
  const { token } = props;

  const query = useQuery({
    queryFn: () => quickPayApi.getAccountBalance(token),
    queryKey: QuickPayQueryKeys.accountBalance(token),
    // We don't want to refetch on error as this is considered isPending on refetch
    retry: false,
  });

  return query;
};
