import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { RhModalDialog } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const SwitchEBillToPaperlessModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
`;

export const SwitchEBillToPaperlessModalTitle = styled(RhTypography)`
  text-align: center;
  text-wrap: balance;
`;

export const SwitchEBillToPaperlessModalSubtitle = styled(RhTypography)`
  line-height: 1.3;
  text-align: center;
  text-wrap: balance;
`;

export const SwitchEBillToPaperlessModalPaperButton = styled.button`
  background: none;
  border: none;
  color: ${CSSVariables.COLOR_GREY_500};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
`;

export const SwitchEBillToPaperlessModalModal = styled(RhModal)`
  ${RhModalDialog} {
    padding: ${rhSpacingPx(2)};
    width: 500px;
  }
`;

export const SwitchEBillToPaperlessModalBackdropCircularProgress = styled(
  RhCircularProgress
)`
  background: rgb(255 255 255 / 75%);
  height: 100%;
  position: absolute;
`;
