import {
  StyledCell,
  StyledValueContainer,
} from "@portal/components/SignUpSummaryInfo/SignUpSummaryInfo.styled";
import React, { ReactNode } from "react";

interface SummaryValueCellProps {
  children: ReactNode;
}

export const SummaryValueCell = (props: SummaryValueCellProps) => {
  const { children } = props;

  return (
    <StyledValueContainer>
      <StyledCell variant="body1" component="div">
        {children}
      </StyledCell>
    </StyledValueContainer>
  );
};
