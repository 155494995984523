import { UUIDType } from "@common/types/apiTypes";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as CheckIcon } from "@design-system/icons/CheckIcon.svg";
import { useResendQuickPayTokenQuery } from "@portal-guest/queries/useResendQuickPayTokenQuery";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { ReactComponent as WomanUnplug } from "@portal-shared/images/WomanUnplug.svg";
import React, { useState } from "react";
import styled from "styled-components";

interface QuickPayExpiredTokenProps {
  token: UUIDType;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 400px;
  text-align: center;
`;

const SubTitle = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_600};
  }
`;

const SuccessButtonText = styled.span`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
`;

export const QuickPayExpiredToken = (props: QuickPayExpiredTokenProps) => {
  const { token } = props;
  const flash = useRhFlash();

  const { t } = useRhIntl();
  const [renewLinkSent, setRenewLinkSent] = useState(false);
  const resendQuickPayTokenMutation = useResendQuickPayTokenQuery();

  const handleClick = () => {
    resendQuickPayTokenMutation.mutate(token, {
      onError: (error) => {
        flash.error(t("QuickPayExpiredToken.resendFailure"));
        setRenewLinkSent(false);
      },
      onSuccess: () => {
        setRenewLinkSent(true);
      },
    });
  };

  const buttonDisabled =
    resendQuickPayTokenMutation.isPending ||
    resendQuickPayTokenMutation.isError ||
    renewLinkSent;

  return (
    <Container>
      <WomanUnplug aria-hidden />
      <RhTypography variant="h1">
        {t("QuickPayExpiredToken.expiredToken")}
      </RhTypography>

      <SubTitle variant="subtitle2">
        {t("QuickPayExpiredToken.explanation")}
      </SubTitle>

      <RhButton
        data-tracking-click={{
          event: "Customer sending a new quick pay email as token expired",
        }}
        disabled={buttonDisabled}
        onClick={handleClick}
        color="primary"
        fullWidth
      >
        {renewLinkSent ? (
          <SuccessButtonText>
            <CheckIcon aria-hidden />
            {t("QuickPayExpiredToken.linkSent")}
          </SuccessButtonText>
        ) : (
          <>{t("QuickPayExpiredToken.sendLink")}</>
        )}
      </RhButton>
    </Container>
  );
};
