import { useAuthenticatedUserGroups } from "@common/hooks/useAuthenticatedUserGroups";
import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { isCustomer } from "@common/utils/authenticationHelpers";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useOktaAuth } from "@okta/okta-react";
import { featureFlagUserIdAtom } from "@portal-shared/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { useCustomerQuery } from "@portal/hooks/queries/useCustomer.query";
import { Customer } from "@portal/models/Customer.model";
import { signOutPath } from "@portal/routes/routePaths";
import { customerPreferencesPremiseUpdated } from "@portal/slices/customerPreferencesSlice";
import { useSetAtom } from "jotai";
import React, { PropsWithChildren, createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const CustomerContext = createContext<Customer | null>(null);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CustomerProviderProps {}

export const CustomerProvider = ({
  children,
}: PropsWithChildren<CustomerProviderProps>) => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();
  const isAuthenticated = authState?.isAuthenticated || false;
  const { data: groups } = useAuthenticatedUserGroups();
  const setFeatureFlagUserIdAtom = useSetAtom(featureFlagUserIdAtom);
  const dispatch = useDispatch();
  const [, setLocale] = useLocaleAtom();

  const customerQuery = useCustomerQuery({
    queryOptions: { enabled: isAuthenticated },
  });

  useEffect(() => {
    const customer = customerQuery.data;

    if (customer) {
      dispatch(customerPreferencesPremiseUpdated(customer.premises[0].id));
      setLocale(customer.languagePreference);
      setFeatureFlagUserIdAtom(customer.featureFlagUserId);
    }
  }, [customerQuery.data, dispatch, setFeatureFlagUserIdAtom, setLocale]);

  if (groups === null) {
    return null;
  }

  if (!isCustomer(groups)) {
    return null;
  }

  if (customerQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (customerQuery.isError) {
    const { error } = customerQuery;

    if (error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
      navigate(signOutPath());
    }

    return <Error500Page />;
  }

  const customer = customerQuery.data;

  return (
    <CustomerContext.Provider value={customer}>
      {children}
    </CustomerContext.Provider>
  );
};
