import {
  ClearButton,
  RhAnnouncementContainer,
} from "@design-system/components/RhAnnouncement/RhAnnouncement.styled";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { ReactComponent as XCloseModal } from "@design-system/icons/XCloseModal.svg";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";

interface RhAnnouncementProps {
  className?: string;
}

export const RhAnnouncement = ({ className = "" }: RhAnnouncementProps) => {
  const { content, severity, dismissible, clearAnnouncement, setBannerRef } =
    useRhAnnouncement();

  const announcementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBannerRef(announcementRef);
  }, [setBannerRef]);

  return (
    <RhAnnouncementContainer
      className={classNames(className, severity)}
      ref={announcementRef}
    >
      {content}

      {dismissible && (
        <ClearButton
          type="button"
          aria-label="Dismiss"
          onClick={clearAnnouncement}
        >
          <span aria-hidden="true">
            <XCloseModal />
          </span>
        </ClearButton>
      )}
    </RhAnnouncementContainer>
  );
};
