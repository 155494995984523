import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  AcquisitionCampaignUrls,
  ProductContractUrls,
  ProductUrls,
} from "@portal/api/urls";
import type {
  ClaimOptionType,
  CreateProductContractResponse,
  ProductContractClaimOptionsRequestType,
  ProductContractClaimRequestType,
  ProductContractType,
} from "@portal/types/productContractTypes";
import {
  ProductContractCancelRequestType,
  ProductContractStatusFilter,
} from "@portal/types/productContractTypes";
import { ProductTierType } from "@portal/types/productTypes";

interface ProductContractListQueryParams {
  contractIds?: UUIDType[];
  status: ProductContractStatusFilter;
}

export const portalApi = {
  acquisitionCampaign: {
    retrieve: (slug: string) =>
      ajax.get<{
        phone: string;
        slug: string;
      }>(AcquisitionCampaignUrls.retrieveBySlug(slug)),
  },
  premise: {
    productContract: {
      cancel: ({
        premiseId,
        contractId,
        endDate,
      }: ProductContractCancelRequestType): Promise<null> =>
        ajax.patch(ProductContractUrls.cancel(premiseId), {
          contractId,
          endDate,
        }),
      claim: ({
        premiseId,
        contractId,
        claimFile,
      }: ProductContractClaimRequestType): Promise<null> => {
        const formData = new FormData();

        formData.append("contract_id", contractId);
        formData.append("file", claimFile);
        return ajax.postFile(ProductContractUrls.claims(premiseId), formData);
      },
      claims: {
        createClaim: ({
          premiseId,
          contractId,
          claimFile,
        }: ProductContractClaimRequestType): Promise<null> => {
          const formData = new FormData();

          formData.append("contract_id", contractId);
          formData.append("file", claimFile);
          return ajax.postFile(ProductContractUrls.claims(premiseId), formData);
        },
        listOptions: ({
          premiseId,
          productContractId,
        }: ProductContractClaimOptionsRequestType): Promise<
          ClaimOptionType[]
        > =>
          ajax.get(
            ProductContractUrls.claimOptions(premiseId, productContractId)
          ),
      },
      create: ({
        premiseId,
        priceId,
      }: {
        premiseId: IdType;
        priceId: UUIDType;
      }): Promise<CreateProductContractResponse> =>
        ajax.post<CreateProductContractResponse>(
          ProductContractUrls.create(premiseId),
          { priceId }
        ),
      list: (premiseId: IdType, params: ProductContractListQueryParams) => {
        const { status = ProductContractStatusFilter.ACTIVE } = params;

        return ajax.get<PaginationEndpointResponseType<ProductContractType>>(
          ProductContractUrls.list(premiseId),
          {
            params: {
              ...params,
              status,
            },
          }
        );
      },
    },
  },
  products: {
    tiers: {
      list: () => ajax.get<ProductTierType[]>(ProductUrls.list()),
    },
  },
};
