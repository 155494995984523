import { useOktaAuth } from "@okta/okta-react";
import { AwsConnectHelmet } from "@portal-shared/components/AwsConnectHelmet/AwsConnectHelmet";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface LoggedOutOnlyProps {
  redirectPath: string;
}

export const LoggedOutOnly = (props: LoggedOutOnlyProps) => {
  const { redirectPath } = props;
  const { authState } = useOktaAuth();

  if (!authState) {
    // Wait for authState to be initialized
    // before deciding if we should render a redirect
    // or the component
    return null;
  }

  if (authState.isAuthenticated) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <>
      <AwsConnectHelmet />
      <Outlet />
    </>
  );
};
