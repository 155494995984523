import { IdType } from "@common/types/apiTypes";
import { Locale } from "@portal-shared/types/LocaleTypes";

const BASE_PATH = "/api/pricing";

export const pricingFileUrls = {
  offerSnapshotsEflUrl: (id: IdType, locale: Locale) => {
    return `${BASE_PATH}/offer-snapshots/${id}/efl/?locale=${locale}`;
  },
};
