import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import React from "react";
import { useField } from "react-final-form";

export interface RhCheckboxFieldProps {
  "aria-label"?: string;
  autoFocus?: boolean;
  className?: string | undefined;
  disabled?: boolean;
  label: string;
  name: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const RhCheckboxField = ({
  autoFocus,
  className,
  disabled,
  name,
  label,
  onChange,
  "aria-label": ariaLabel,
}: RhCheckboxFieldProps) => {
  const {
    input: { onChange: rffOnChange, ...input },
  } = useField<boolean>(name, {
    type: "checkbox",
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    rffOnChange(event);
    if (onChange) {
      onChange(event, checked);
    }
  };

  return (
    <RhCheckbox
      aria-label={ariaLabel}
      className={className}
      autoFocus={autoFocus}
      disabled={disabled}
      label={label}
      name={name}
      checked={Boolean(input.checked)}
      onChange={handleChange}
    />
  );
};
