export enum FeatureFlagMetric {
  PortalCustomerCopiedOrSharedRafButton = "portal-customer-copied-or-shared-raf-button",
  PortalProspectAddedPaymentMethod = "portal-prospect-added-payment-method",
  PortalProspectEmailSubmitted = "portal-prospect-email-submitted",
  PortalProspectEnabledAutopay = "portal-prospect-enabled-autopay",
  PortalProspectEnrolled = "portal-prospect-enrolled",
  PortalProspectEnrolledWithProductAddOnPlan = "portal-prospect-enrolled-with-product-add-on-plan",
  PortalProspectMovingSwitchingSubmitted = "portal-prospect-moving-switching-submitted",
  PortalProspectOfferCardLearnMoreClicked = "portal-prospect-offer-card-learn-more-clicked",
  PortalProspectOfferPhoneNumberClicked = "portal-prospect-offer-phone-number-clicked",
  PortalProspectOpenedPlanInformationDialog = "portal-prospect-opened-plan-information-dialog",
  PortalProspectRewardsSubmitted = "portal-prospect-rewards-submitted",
  PortalProspectSelectedPlan = "portal-prospect-selected-plan",
  PortalProspectServiceAddressSubmitted = "portal-prospect-service-address-submitted",
  PortalProspectShowMoreOffersClicked = "portal-prospect-show-more-offers-clicked",
  PortalProspectSubmittedNameDOB = "portal-prospect-submitted-name-dob",
  PortalProspectZipCodeSubmitted = "portal-prospect-zip-code-submitted",
  PortalRemovedProductAddOnPlan = "portal-removed-product-add-on-plan",
  PortalSelectedProductAddOnPlan = "portal-selected-product-add-on-plan",
}
