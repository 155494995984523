import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { UUIDType } from "@common/types/apiTypes";
import { competitorUrls } from "@portal-shared/api/competitorUrls";
import { CompetitorPlan } from "@portal-shared/types/competitorTypes";

export interface CompetitorPlansParams {
  dunsNumber?: string;
  limit?: number;
  offerSnapshotId?: UUIDType;
}

export const competitorApi = {
  plans: ({
    dunsNumber,
    limit = 500,
    offerSnapshotId,
  }: CompetitorPlansParams) => {
    return ajax.get<PaginationEndpointResponseType<CompetitorPlan>>(
      competitorUrls.plans(),
      {
        params: {
          dunsNumber,
          limit,
          offerSnapshot: offerSnapshotId,
        },
      }
    );
  },
};
