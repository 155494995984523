import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import styled from "styled-components";

export const StyledUpdateZipCodeForm = styled.form`
  align-items: center;
  display: flex;
`;

export const LabelText = styled.span``;

export const PostFix = styled.span``;

export const StyledUpdateZipCodeLabel = styled.label`
  align-items: center;
  border-bottom: 2px solid ${CSSVariables.COLOR_PRIMARY_MAIN};
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};

  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: ${FontWeight.Semibold};

  svg {
    margin-right: ${rhSpacingPx(0.5)};
  }

  & > ${LabelText} {
    display: none;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    margin-right: ${rhSpacingPx(1.5)};
    svg {
      margin-right: ${rhSpacingPx(1)};
    }

    & > ${LabelText} {
      display: block;
      margin-right: 2px;
    }
  }
`;

export const StyledUpdateZipCodeInput = styled.input`
  border: none;
  color: ${CSSVariables.COLOR_GREY_900};
  font-family: ${fontFamily};
  font-size: 16px;
  font-weight: ${FontWeight.Regular};
  margin: ${rhSpacingPx(1)};
  width: 60px;
  &:focus {
    outline: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: ${CSSVariables.COLOR_GREY_200};
  }
`;

export const InputContainer = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
  &:before {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    content: ": ";
  }
`;

export const StyledUpdateZipCodeButton = styled(RhButton)`
  &.MuiButton-root {
    max-height: 40px;
  }
`;
