import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import dayjs from "dayjs";
import { useEffect } from "react";

export const useLanguagePreference = () => {
  const {
    cookies: { django_language: djangoLanguageCookie },
    setCookie,
  } = usePortalCookies();

  const [locale] = useLocaleAtom();

  useEffect(() => {
    if (locale !== djangoLanguageCookie) {
      const oneHunderEightyDaysFromNow = dayjs().add(180, "day").toDate();
      const { cookieDomain } = brandInformation;

      setCookie("django_language", locale, {
        domain: cookieDomain,
        expires: oneHunderEightyDaysFromNow,
      });
    }
  }, [djangoLanguageCookie, locale, setCookie]);
};
