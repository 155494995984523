import { rhSpacingPx } from "@design-system/theme/spacing";
import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledGuestAddPaymentCardRoot = styled(Box)`
  cursor: pointer;
  margin-bottom: ${rhSpacingPx(1.5)};
`;

export const StyledGuestAddPaymentCardContent = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;
