import { UUIDType } from "@common/types/apiTypes";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { atom } from "jotai";

interface OfferSnapshotLearnMore {
  offerSnapshotUUID: UUIDType;
  onClose?: () => void;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
  selectPlanCtaText: string;
}

interface OfferSnapshotThermostatRequired {
  offerSnapshot: OfferSnapshot;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
}

export interface SharedModalsAtomType {
  offerSnapshotLearnMore: OfferSnapshotLearnMore | null;
  offerSnapshotThermostatRequired: OfferSnapshotThermostatRequired | null;
}

export const initialSharedModalsAtom: SharedModalsAtomType = {
  offerSnapshotLearnMore: null,
  offerSnapshotThermostatRequired: null,
};

export const sharedModalsStateAtom = atom<SharedModalsAtomType>(
  initialSharedModalsAtom
);
