import { CreditScoreOutcome } from "@common/types/creditCheckTypes";

export enum CreditEvaluationDefinition {
  ApprovedNoDeposit = "Approved - No Deposit",
  ApprovedWithDeposit = "Approved - With Deposit",
  NotApprovedManuelReviewRequired = "Not Approved - Manual Review required",
  NotApprovedSsnRequestedOrMaxDeposit = "Not Approved - SSN Requested OR Max Deposit",
  unavailable = "Unavailable",
}

export const determineCreditEvaluationDefinition = ({
  creditEvaluation = CreditScoreOutcome.unavailable,
  depositAmount,
  ssnRequired,
}: {
  creditEvaluation: CreditScoreOutcome | null;
  depositAmount: number | null;
  ssnRequired?: boolean;
}): CreditEvaluationDefinition => {
  const depositRequired = Boolean(depositAmount);
  const approved = creditEvaluation === CreditScoreOutcome.approved;
  const manualReview = creditEvaluation === CreditScoreOutcome.manualReview;

  if (approved && !depositRequired) {
    return CreditEvaluationDefinition.ApprovedNoDeposit;
  }

  if (approved && depositRequired) {
    return CreditEvaluationDefinition.ApprovedWithDeposit;
  }

  if (manualReview && (depositRequired || ssnRequired)) {
    return CreditEvaluationDefinition.NotApprovedSsnRequestedOrMaxDeposit;
  }

  if (manualReview) {
    return CreditEvaluationDefinition.NotApprovedManuelReviewRequired;
  }

  return CreditEvaluationDefinition.unavailable;
};
