import {
  CompetitorPlansParams,
  competitorApi,
} from "@portal-shared/api/competitorApi";
import { CompetitorQueryKeys } from "@portal-shared/queryKeys/competitorQuerykeys";
import { useQuery } from "@tanstack/react-query";

interface UseCompetitorPlansQueryProps {
  queryOptions?: {
    enabled?: boolean;
  };
  searchOptions?: CompetitorPlansParams;
}
export const useCompetitorPlansQuery = (
  props: UseCompetitorPlansQueryProps
) => {
  const { searchOptions = {}, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => competitorApi.plans(searchOptions),
    queryKey: CompetitorQueryKeys.plans(searchOptions),
  });
};
