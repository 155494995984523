import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledPaymentExtensionConfirmContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  margin: ${rhSpacingPx(4)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    gap: ${rhSpacingPx(5)};
    margin-top: ${rhSpacingPx(5)};
    width: 430px;
  }
`;

export const StyledReviewContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
`;

export const ReviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

export const LabelText = styled(RhTypography)`
  color: ${CSSVariables.COLOR_GREY_500};
`;

export const TermsContainer = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const StyledTermsCheckbox = styled(RhCheckbox)`
  &.MuiFormControlLabel-root {
    align-items: flex-start;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const StyledSubmitButton = styled(RhButton)`
  &.MuiButton-root {
    min-width: 320px;
  }
`;
