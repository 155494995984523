import { IdType } from "@common/types/apiTypes";
import { ediApi } from "@enroll-data/api/ediApi";
import { ediQueryKeys } from "@enroll-data/queryKeys/ediQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseServiceStartDatesQueryProps {
  offerSnapshotId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}
export const useServiceStartDatesQuery = (
  props: UseServiceStartDatesQueryProps
) => {
  const { offerSnapshotId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => ediApi.getServiceStartDates(offerSnapshotId),
    queryKey: ediQueryKeys.getServiceStartDates(offerSnapshotId),
  });
};
