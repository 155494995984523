import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

const labelPercentageWidth = 38;
const valuePercentageWidth = 100 - labelPercentageWidth;

export const StyledSignUpSummaryInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const StyledLabelContainer = styled.div`
  flex-basis: ${labelPercentageWidth}%;
  margin-bottom: ${rhSpacingPx(0.5)};
  margin-inline-start: 0;
  overflow: hidden;
`;

export const StyledValueContainer = styled.div`
  flex-basis: ${valuePercentageWidth}%;
  gap: 1rem;
  margin-bottom: ${rhSpacingPx(2)};
  margin-inline-start: 0;
  overflow: hidden;
`;

export const StyledBillingPreference = styled.div`
  display: flex;
  gap: ${rhSpacingPx(1)};
`;

export const StyledBillingPreferenceValue = styled.span`
  color: ${CSSVariables.COLOR_GREY_500};
`;

export const StyledValueTitle = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 16px;
  }
`;

export const StyledCell = styled(RhTypography)`
  &.MuiTypography-root {
    word-break: break-word;
  }
`;

export const StyledPromoPoints = styled.span`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
`;

export const StyledRateWrapper = styled.div`
  padding-top: ${rhSpacingPx(1)};
`;

export const StyledPlanDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
`;

export const StyledPlanDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-bottom: ${rhSpacingPx(1)};
`;

export const StyledTier = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
  justify-content: flex-start;
`;

export const AutopayPaperlessDiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
  margin-top: ${rhSpacingPx(0.5)};

  & > a {
    font-weight: ${FontWeight.Medium};
  }
`;

export const ContractDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
`;
