/// <reference types="@types/segment-analytics" />
import { isBot } from "@common/utils/isBot";

export enum GeneralEvents {
  identify = "identify",
}

export enum RenewalEvents {
  renewalClick = "renewalClick",
}

export enum CategoryType {
  Renewals = "renewals",
}

export enum ActionType {
  click = "click",
  userIdentified = "userIdentified",
}
export enum LabelType {
  ClickedRenewalCompetitorChartSelectPlan = "Clicked Renewal Competitor Chart Select Plan",
  ClickedRenewalOfferCardLearnMore = "Clicked Renewal Offer Card Learn More",
  ClickedRenewalOfferCardRenewNow = "Clicked Renewal Offer Card Renew Now",
  ClickedRenewalSubmit = "Clicked Renewal Submit",
  ClickedRenewalsCta = "Clicked Renewals CTA",
}

export interface AnalyticsEvent {
  [key: string]: string | number | boolean;
  action: ActionType;
  category: string;
  event: RenewalEvents | GeneralEvents;
  label: string;
  value: string | boolean;
}

export type TrackEventHandler = (event: Partial<AnalyticsEvent>) => void;

export const track = (options: Partial<AnalyticsEvent>) => {
  if (isBot()) {
    return;
  }

  const { event, ...properties } = options;

  if (event) {
    window.FS?.event(event, properties);
    window.analytics?.track?.(event, properties);
    window.dataLayer?.push(options);
  }
};

interface GTMPageEventProps {
  event: string;
  page: string;
}

export const trackPage = (url?: string): void => {
  if (isBot() || !url) {
    return;
  }

  const pageEvent: GTMPageEventProps = {
    event: "pageview",
    page: url,
  };

  window.dataLayer?.push(pageEvent);

  window.analytics?.page();
};
