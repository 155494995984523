import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { BillingStatus } from "@portal/models/BillingStatus.model";
import { useQuery } from "@tanstack/react-query";

interface UseBillingStatusProps {
  premiseId: IdType;
}

export const useBillingStatus = (props: UseBillingStatusProps) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.billingStatus(premiseId),
    queryKey: PremiseQueryKeys.billingStatus(premiseId),
    select: (data) => new BillingStatus(data),
  });
};
