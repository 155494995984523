import { RhCard } from "@design-system/components/RhCard/RhCard";
import styled, { css } from "styled-components";

interface StyledCardHeaderProps {
  noMargin: boolean;
}

export const StyledCardHeader = styled(RhCard)<StyledCardHeaderProps>`
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `};
`;

export const StyledLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 3rem;
  text-align: center;

  & > * {
    margin: auto;
  }
`;
