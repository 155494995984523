import { AppBrands } from "@common/enums/appBrands.enum";
import { ReactComponent as EnergyTexasLogo } from "@common/images/EnergyTexasRhythm.svg";
import { ReactComponent as RhythmLogo } from "@common/images/RhythmLogoDarkPurple.svg";
import { appBrand } from "@common/services/getEnvApplicationSettings.service";
import React from "react";

export const BrandLogo = () => {
  switch (appBrand) {
    case AppBrands.RHYTHM: {
      return <RhythmLogo />;
    }
    case AppBrands.ENERGY_TEXAS: {
      return <EnergyTexasLogo />;
    }
    default: {
      return <RhythmLogo />;
    }
  }
};
