import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsDisclaimerTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsDisclaimer/OfferSnapshotFAQsDisclaimer.en.i18n";
import { DisclaimerContainer } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsDisclaimer/OfferSnapshotFAQsDisclaimer.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React from "react";

interface OfferSnapshotFAQsDisclaimerProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotFAQsDisclaimer = (
  props: OfferSnapshotFAQsDisclaimerProps
) => {
  const { offerSnapshot } = props;
  const { translate } = useTranslations();
  const { tOfferSnapshotFAQsDisclaimerTwoMonthsFree } = translate(
    offerSnapshotFAQsDisclaimerTranslations
  );

  const { isTwoFreeMonthsOffer } = offerSnapshot;

  return (
    <>
      {isTwoFreeMonthsOffer ? (
        <DisclaimerContainer>
          <RhTypography variant="body1" color="textSecondary">
            {tOfferSnapshotFAQsDisclaimerTwoMonthsFree}
          </RhTypography>
        </DisclaimerContainer>
      ) : null}
    </>
  );
};
