import { IdType } from "@common/types/apiTypes";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@portal-shared/constants/queryOptions.constants";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseTemperatureSummaryQueryOptions {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const usePremiseTemperatureSummaryQuery = (
  options: UsePremiseTemperatureSummaryQueryOptions
) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = options;

  const query = useQuery({
    enabled,
    queryFn: () => premiseApi.temperatureSummary({ premiseId }),
    queryKey: PremiseQueryKeys.temperatureSummary(premiseId),
    refetchOnWindowFocus: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });

  return query;
};
