import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremisePaymentMethodsQueryProps {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const usePremisePaymentMethodsQuery = (
  props: UsePremisePaymentMethodsQueryProps
) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => premiseApi.paymentMethods(premiseId || ""),
    queryKey: PremiseQueryKeys.paymentMethods(premiseId || ""),
  });
};
