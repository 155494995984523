import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as InformationIcon } from "@design-system/icons/InformationIcon.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledGuestPayAccountLookup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2.5)};
  max-width: 20rem;
`;

export const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(3)};
  width: 100%;
`;

export const StyledInformationIcon = styled(InformationIcon)`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};

  &:hover {
    color: ${CSSVariables.COLOR_PRIMARY_MEDIUM};
  }
`;

export const StyledGuestPayButton = styled(RhButton)`
  &.MuiButton-root {
    width: 100%;
  }
`;

export const OrSeparator = styled.span`
  color: ${CSSVariables.COLOR_GREY_200};
  font-size: 14px;
`;

export const StyledLabelContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(0.5)};
`;

export const StyledSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    max-width: 380px;
    text-align: center;
  }
`;
