const determineIsDeviceTouchScreen = (): boolean => {
  const { userAgent } = navigator;

  if ("maxTouchPoints" in navigator) {
    return navigator.maxTouchPoints > 0;
  }

  const pattern =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

  return pattern.test(userAgent);
};

export const isDeviceTouchScreen = determineIsDeviceTouchScreen();
