import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { ErrorBoundary } from "@sentry/react";
import React, { PropsWithChildren } from "react";

const FallBackComponent = () => <Error500Page />;

interface PortalErrorBoundaryProps {}
export const PortalErrorBoundary = (
  props: PropsWithChildren<PortalErrorBoundaryProps>
) => {
  const { children } = props;

  return <ErrorBoundary fallback={FallBackComponent}>{children}</ErrorBoundary>;
};
