import {
  ModalPlacement,
  ModalWidths,
  RhModalBackground,
  RhModalCloseButton,
  RhModalDialog,
  RhModalDialogInner,
  RhModalPageContainer,
} from "@design-system/components/RhModal/RhModal.styled";
import { ReactComponent as XCloseModal } from "@design-system/icons/XCloseModal.svg";
import React, { FC } from "react";

export interface RhModalProps {
  ariaCloseLabel: string;
  ariaLabel: string;
  className?: string;
  disableBackgroundClose?: boolean;
  handleClose: () => void;
  hideCloseButton?: boolean;
  modalId?: string;
  noPadding?: boolean;
  overflowVisible?: boolean;
  placement?: ModalPlacement;
  showPortalFooter?: boolean;
  showPortalHeader?: boolean; // This will allow us to position modal background and dialog below the portal nav header
  size?: ModalWidths;
  withGradientBorder?: boolean;
}

export const RhModal: FC<React.PropsWithChildren<RhModalProps>> = ({
  ariaLabel,
  ariaCloseLabel,
  children,
  disableBackgroundClose = false,
  handleClose,
  hideCloseButton = false,
  modalId = "",
  noPadding = false,
  overflowVisible = false,
  size = ModalWidths.sm,
  placement = ModalPlacement.PageCenter,
  showPortalHeader = true,
  showPortalFooter = false,
  withGradientBorder = false,
  className,
}) => {
  return (
    <RhModalPageContainer
      $placement={placement}
      $showPortalHeader={showPortalHeader}
      $showPortalFooter={showPortalFooter}
      className={className}
    >
      <RhModalBackground
        onClick={!disableBackgroundClose ? handleClose : undefined}
        $showPortalHeader={showPortalHeader}
        $showPortalFooter={showPortalFooter}
      />
      <RhModalDialog
        role="dialog"
        id={modalId}
        aria-label={ariaLabel}
        aria-modal="true"
        $withGradientBorder={withGradientBorder}
        $noPadding={noPadding}
        $size={size}
      >
        <RhModalDialogInner
          $noPadding={noPadding}
          $overflowVisible={overflowVisible}
        >
          {!hideCloseButton && (
            <RhModalCloseButton
              onClick={handleClose}
              aria-label={ariaCloseLabel ?? "Close modal"}
              data-testid="rhModalClose"
            >
              <span aria-hidden="true">
                <XCloseModal />
              </span>
            </RhModalCloseButton>
          )}
          {children}
        </RhModalDialogInner>
      </RhModalDialog>
    </RhModalPageContainer>
  );
};
