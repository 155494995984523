import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as PastDueIcon } from "@design-system/icons/bill-factors/PastDue.svg";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { billFactorsTranslations } from "@portal/components/BillFactors/BillFactors.en.i18n";
import { StyledBillFactor } from "@portal/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorPastDueProps {
  pastDueAmount: string;
}

export const BillFactorPastDue = ({
  pastDueAmount,
}: BillFactorPastDueProps) => {
  const { translateJsx, translate } = useTranslations();
  const { tBillFactorsRolloverBalance } = translate(billFactorsTranslations);
  const { tBillFactorsRollover } = translateJsx({
    tBillFactorsRollover: { amount: formatCurrency(pastDueAmount) },
  });

  return (
    <StyledBillFactor>
      <PastDueIcon />
      <RhTypography fontWeight="Bold">
        {tBillFactorsRolloverBalance}
      </RhTypography>
      <RhTypography variant="body2">{tBillFactorsRollover}</RhTypography>
    </StyledBillFactor>
  );
};
