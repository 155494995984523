export const offerSnapshotFAQsSolarTranslations = {
  tOfferSnapshotFAQsSolarBuybackTitle: "Solar Buyback",
  tOfferSnapshotFAQsSolarCreditNotOneToOneAnswer:
    "Rhythm will look at how much electricity the customer used (electricity for which the customer pays Rhythm), and then deduct the credit for surplus generation (electricity for which Rhythm credits the customer). Our solar buyback program credits have no cash value and can only be redeemed for Rhythm bill credits on the meter they were generated on, but can be rolled over each month. The export rate for the solar buyback credits will be at a different rate than your energy rate, and these credits can be used toward your energy rate only. They can’t be used toward non-commodity charges.",
  tOfferSnapshotFAQsSolarDoesRhythmCoverFees:
    "Does Rhythm cover early termination fees from previous solar companies?",
  tOfferSnapshotFAQsSolarDoesRhythmCoverFeesAnswer:
    "No, any early termination fee associated with leaving your previous provider is not covered for customers signing up for our solar buyback program. However, if you have a new solar installation at your home, your solar installer usually reimburses any early termination fees associated with changing your energy provider. So, we always recommend checking with them.",
  tOfferSnapshotFAQsSolarHowWillIReceiveCredits:
    "How will I receive my credits?",
  tOfferSnapshotFAQsSolarHowWillIReceiveCreditsAnswer:
    "Rhythm will look at how much electricity the customer used (electricity for which the customer pays Rhythm), and then deduct the credit for surplus generation (electricity for which Rhythm credits the customer). Our solar buyback program credits have no cash value and can only be redeemed for Rhythm bill credits on the meter they were generated on, but can be rolled over each month. The export rate for the solar buyback credits will be one-to-one with your energy rate, and these credits can be used toward electric bills. They cannot be used toward the base charge, TDSP charges, non-commodity charges, or taxes.",
  tOfferSnapshotFAQsSolarIdontHaveSolarPanels:
    "I don't have solar panels but want to go green with a solar energy plan. Does Rhythm Energy offer those?",
  tOfferSnapshotFAQsSolarIdontHaveSolarPanelsAnswer:
    "If you don't have solar panels but are interested in a solar energy plan, enter your zip code to sign up for one of our plans backed by 100% Texas solar. It only takes 60 seconds to sign up and make an impact. \n\n Or, if you're interested in learning more about the solar-powered potential of your home, we offer a FREE solar concierge who can give you an unbiased, honest assessment as to if your home is suited for solar and how much you could save.",
  tOfferSnapshotFAQsSolarNoPanelsYet: "No panels at home just yet?",
  tOfferSnapshotFAQsSolarNoPanelsYetAnswer:
    "We're providing a FREE, independent, solar concierge service. We'll give you an honest, customized assessment to see if solar is a good move for your home. Let our experts help you potentially save thousands by making sure you have the perfect solar system, installer, and quote. And be sure to check out our new solar calculator. \n <link>Learn more</link>",
  tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoney:
    "How can I make money and save the most with {plan}?",
  tOfferSnapshotFAQsSolarTimeOfUseGenerationHowCanIMakeMoneyAnswer:
    "Sell back energy when the grid is strained, and shift your energy on big appliances to off-peak hours. Simple as that.",
  tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitations:
    "Are there any limitations with {plan}?",
  tOfferSnapshotFAQsSolarTimeOfUseGenerationLimitationsAnswer:
    "Customers with either a solar system size exceeding 20 kW or customers that net produce for 6 consecutive months are not eligible for {plan}. See your EFL and terms of service for further details.",
  tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdowns:
    "What are the price breakdowns of {plan}?",
  tOfferSnapshotFAQsSolarTimeOfUseGenerationPriceBreakdownsAnswer:
    "This plan comes with a {baseCharge} base charge, as well as on-peak and off-peak pricing. \n On-peak: High-demand time when electricity costs more, usually during the early morning, evening, or both. \nUltra on-peak: Extra high-demand time when electricity costs more, like winter mornings and summer evenings. \nOff-peak: Low-demand time when electricity costs less and encompasses most daytime and overnight hours.",
  tOfferSnapshotFAQsSolarTimeOfUseTitle: "Time of Use",
  tOfferSnapshotFAQsSolarWhatIsSolarBuyback: "What is a solar buyback plan?",
  tOfferSnapshotFAQsSolarWhatIsSolarBuybackAnswer:
    "A solar buyback plan gives homeowners a way to make money on the excess power being generated from their solar panels. In other words, you can be compensated with bill credits for the excess solar power generated from your solar system.",
  tOfferSnapshotFAQsSolarWhoIsEligible:
    "Who is eligible for Rhythm Energy's solar buyback plan?",
  tOfferSnapshotFAQsSolarWhoIsEligibleAnswer:
    "Texas customers living in a competitive electricity market, with residential rooftop solar arrays less than 20 kW in capacity, are eligible for this solar buyback plan. Interested in learning more? Contact our Texas based Customer Care team at 1-888-987-0432.",
  tOfferSnapshotFAQsSolarWillIEarnReferAFriend:
    "Will I earn Refer A Friend bill credits if I refer a friend to a solar buyback plan?",
  tOfferSnapshotFAQsSolarWillIEarnReferAFriendAnswer:
    "No, but you can earn reward bill credits if you refer a friend to a non-Solar Buyback Plan. \nPlease note: Referred customer must enroll in a non-Solar Buyback Plan of 12 months or longer.",
};
