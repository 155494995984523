import { rhWindow } from "@common/utils/rhWindow";
import { useOktaAuth } from "@okta/okta-react";
import { signInPath } from "@portal-shared/routes/routePaths";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface RhSecurePortalRouteProps {}

export const RhSecurePortalRoute: FC<
  React.PropsWithChildren<RhSecurePortalRouteProps>
> = ({ children }) => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { isAuthenticated = false } = authState || {};

  useEffect(() => {
    if (authState && !isAuthenticated) {
      const originalUri = rhWindow.location.href.replace(
        rhWindow.location.origin,
        ""
      );

      oktaAuth.setOriginalUri(originalUri);

      navigate(signInPath());
    }
  }, [authState, isAuthenticated, navigate, oktaAuth]);

  if (!isAuthenticated) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
