import { theme } from "@design-system/theme/theme.styled";
import styled from "styled-components";

export const StyledWelcomeKitCellText = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  gap: 0.25rem;
  justify-content: flex-start;
`;

export const StyledWelcomeKitDescription = styled.span`
  color: ${theme.palette.grey[600]};
  font-size: 0.5rem;

  @media screen and (min-width: 500px) {
    font-size: 0.65rem;
  }
`;
