import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const ToggleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  margin-bottom: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(4)};
`;

export const ToggleRow = styled.div`
  display: flex;
  gap: ${rhSpacingPx(1.5)};
`;

export const ToggleLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
  white-space: pre-line;
`;
