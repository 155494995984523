import { formatCurrency, formatToCents } from "@common/utils/dataFormatters";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { fontFamily } from "@design-system/theme/typography";
import { GroupedCompetitorPlans } from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal-shared/constants/offer.constant";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { CompetitorNames } from "@portal-shared/types/competitorTypes";
import { ChartOptions } from "chart.js";

interface BuildChartOptionsProps {
  offerSnapshot: OfferSnapshot;
  tooltipText: string[];
}

export const buildChartOptions = (props: BuildChartOptionsProps) => {
  const { offerSnapshot, tooltipText } = props;

  return {
    interaction: {
      intersect: false,
      mode: "x",
    },
    layout: {
      padding: {
        bottom: 40,
      },
    },

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: brandTheme.colors.white,
        bodyAlign: "center",
        bodyColor: brandTheme.colors.black,
        bodyFont: {
          family: fontFamily,
          size: 16,
          weight: FontWeight.Regular,
        },
        bodySpacing: 0,
        borderColor: brandTheme.colors.primary.main,
        borderWidth: 2,
        callbacks: {
          label: (tooltipItem) => {
            const labelItems = [];
            const raw = tooltipItem.raw as unknown as GroupedCompetitorPlans;

            if (
              raw.competitorName !==
              (brandInformation.brandName as CompetitorNames)
            ) {
              const savings = formatCurrency(
                ((raw.averagePrice - offerSnapshot.displayPrice()) / 100) *
                  DEFAULT_AVERAGE_MONTHLY_USAGE *
                  12
              );

              labelItems.push(savings);
            }
            return labelItems;
          },
          title: (tooltipItems) => {
            const titleItems = [];

            if (
              tooltipItems.length > 0 &&
              tooltipItems[0].label !== brandInformation.brandName
            ) {
              titleItems.push(...tooltipText);
            }

            return titleItems;
          },
        },
        displayColors: false,
        filter(tooltipItem, data) {
          return tooltipItem.label !== brandInformation.brandName;
        },
        padding: 12,
        titleAlign: "center",
        titleColor: brandTheme.colors.black,
        titleFont: {
          family: fontFamily,
          size: 14,
          weight: FontWeight.Bold,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          color: "transparent",
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        grace: 5,
        ticks: {
          callback: (value, index, ticks) => {
            return formatToCents(value, true, 0);
          },
        },
      },
    },
  } satisfies ChartOptions;
};
