import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CalendarIcon } from "@design-system/icons/bill-factors/Calendar.svg";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { billFactorsTranslations } from "@portal/components/BillFactors/BillFactors.en.i18n";
import { StyledBillFactor } from "@portal/components/BillFactors/BillFactors.styled";
import React from "react";

interface BillFactorBillingCycleProps {
  differenceInDays: number;
}

export const BillFactorBillingCycle = ({
  differenceInDays,
}: BillFactorBillingCycleProps) => {
  const absDifference = Math.abs(Number(differenceInDays));

  const { translateJsx, translate } = useTranslations();
  const { tBillFactorsBillingCycle } = translate(billFactorsTranslations);
  const { tBillFactorsBillingCycleIncreased, tBillFactorsBillingCycleShorter } =
    translateJsx({
      tBillFactorsBillingCycleIncreased: { difference: absDifference },
      tBillFactorsBillingCycleShorter: {
        difference: absDifference,
      },
    });

  return (
    <StyledBillFactor>
      <CalendarIcon />
      <RhTypography fontWeight="Bold">{tBillFactorsBillingCycle}</RhTypography>
      <RhTypography variant="body2">
        {Number(differenceInDays) > 0
          ? tBillFactorsBillingCycleIncreased
          : tBillFactorsBillingCycleShorter}
      </RhTypography>
    </StyledBillFactor>
  );
};
