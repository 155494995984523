import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { paymentExtensionUnableToCollectTranslations } from "@portal/components/PaymentExtensionUnableToCollect/PaymentExtensionUnableToCollect.en.i18n";
import {
  StyledPaymentExtensionUnableToCollectContainer,
  StyledRhythmPaymentDetails,
} from "@portal/components/PaymentExtensionUnableToCollect/PaymentExtensionUnableToCollect.styled";
import { homePath } from "@portal/routes/routePaths";
import React from "react";

export const PaymentExtensionUnableToCollect = () => {
  const { translate } = useTranslations();
  const {
    tPaymentExtensionUnableToCollectAccountBlocked,
    tPaymentExtensionUnableToCollectBackHome,
    tPaymentExtensionUnableToCollectMakePayment,
    tPaymentExtensionUnableToCollectPaymentExtension,
  } = translate(paymentExtensionUnableToCollectTranslations);

  return (
    <StyledPaymentExtensionUnableToCollectContainer>
      <RhTypography variant="h1">
        {tPaymentExtensionUnableToCollectPaymentExtension}
      </RhTypography>
      <RhTypography>
        {tPaymentExtensionUnableToCollectAccountBlocked}
      </RhTypography>
      <StyledRhythmPaymentDetails>
        <RhTypography>
          {tPaymentExtensionUnableToCollectMakePayment}
        </RhTypography>
        <RhTypography>{brandInformation.brandLLC}</RhTypography>
        <RhTypography>{brandInformation.addressesPoBoxLine1}</RhTypography>
        <RhTypography>{brandInformation.addressesPoBoxLine2}</RhTypography>
      </StyledRhythmPaymentDetails>
      <RhLinkButton
        data-tracking-click={{ event: "Customer going to home" }}
        href={homePath()}
      >
        {tPaymentExtensionUnableToCollectBackHome}
      </RhLinkButton>
    </StyledPaymentExtensionUnableToCollectContainer>
  );
};
