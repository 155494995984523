export const billComparisonCardTranslations = {
  tBillComparisonCardError:
    "Oops! Something went wrong while getting your previous bill data. Please try again later.",
  tBillComparisonCardHeresThisBill: "Here's this bill compared to past bills.",
  tBillComparisonCardLastMonth: "Last month's bill",
  tBillComparisonCardLastYear: "Last year's bill",
  tBillComparisonCardMonthlykWhUsage: "MONTHLY kWh USAGE",
  tBillComparisonCardSeeExactly:
    "See exactly how much energy you used last year during this time, as well as last month.",
  tBillComparisonCardThisMonth: "This month's bill",
};
