import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { enrollReferralCodeAnnouncementTranslations } from "@portal/components/EnrollReferralCodeAnnouncement/EnrollReferralCodeAnnouncement.en.i18n";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { useEffect } from "react";

export const EnrollReferralCodeAnnouncement = () => {
  const { translate } = useTranslations();
  const { referralCode } = useProspectFromContext();
  const { announceNotice } = useRhAnnouncement();

  useEffect(() => {
    if (referralCode) {
      const { tEnrollReferralCodeAnnouncementReferralCodeApplied } = translate(
        enrollReferralCodeAnnouncementTranslations
      );

      announceNotice(tEnrollReferralCodeAnnouncementReferralCodeApplied);
    }
  }, [announceNotice, referralCode, translate]);

  return null;
};
