import { StyledDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { DialogTitleProps, useMediaQuery, useTheme } from "@mui/material";
import React, { FC } from "react";

interface RhDialogTitleProps extends DialogTitleProps {}

export const RhDialogTitle: FC<React.PropsWithChildren<RhDialogTitleProps>> = ({
  children,
  className,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const dialogTitleSize = isMobile ? "h4" : "h2";

  return (
    <StyledDialogTitle className={className}>
      <RhTypography component="div" variant={dialogTitleSize}>
        {children}
      </RhTypography>
    </StyledDialogTitle>
  );
};
