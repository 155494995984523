import { customerApi } from "@portal/api/customerApi";
import { customerQueryKeys } from "@portal/constants/querykeys.constants";
import { ResideoOauthType } from "@portal/types/resideoOauthTypes";
import { useQuery } from "@tanstack/react-query";

interface UseListResideoDevicesQueryProps extends ResideoOauthType {
  queryOptions: {
    enabled: boolean;
  };
}

export const useListResideoDevicesQuery = (
  props: UseListResideoDevicesQueryProps
) => {
  const { deviceBrand, oauthCode, queryOptions: { enabled } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => customerApi.listResideoDevices({ deviceBrand, oauthCode }),
    queryKey: customerQueryKeys.listResideoDevices({ deviceBrand, oauthCode }),
  });
};
