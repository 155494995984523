import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import {
  StyledAnchor,
  StyledNavLink,
} from "@design-system/components/RhHeader/RhHeader.styled";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { AccountMenu } from "@portal/components/MyAccountHeader/AccountMenu/AccountMenu";
import { myAccountHeaderTranslations } from "@portal/components/MyAccountHeader/MyAccountHeader.en.i18n";
import { PortalHeaderLogo } from "@portal/components/PortalHeaderLogo/PortalHeaderLogo";
import { PremiseSelector } from "@portal/components/PremiseSelector/PremiseSelector";
import { ReferAFriendDialog } from "@portal/components/ReferAFriendDialog/ReferAFriendDialog";
import { useDeviceEligibilityInformationQuery } from "@portal/hooks/queries/useDeviceEligibilityInformation";
import { useCustomerFromContext } from "@portal/hooks/useCustomerFromContext";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import {
  customerHomePath,
  devicesBasePath,
  myAccountPath,
  notificationsPath,
  rewardsPath,
  signOutPath,
} from "@portal/routes/routePaths";
import { ActionType, MyAccountEvents } from "@portal/services/segment.service";
import React, { useState } from "react";

export const MyAccountHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const { translate } = useTranslations();
  const customer = useCustomerFromContext();
  const [isReferAFriendDialogOpen, setIsReferAFriendDialogOpen] =
    useState(false);
  const track = useTrackMyAccountEvents();
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalDeviceManagement } = featureFlagClient.getFlags([
    ["portalDeviceManagement", false],
  ]);
  const {
    data: deviceEligibilityInformationData,
    isPending: deviceEligibleDataIsPending,
    isError: deviceEligibleDataIsError,
  } = useDeviceEligibilityInformationQuery({
    customerId: customer.id,
  });

  const {
    tMyAccountHeaderAccountSettings,
    tMyAccountHeaderDevices,
    tMyAccountHeaderHelp,
    tMyAccountHeaderHome,
    tMyAccountHeaderLogout,
    tMyAccountHeaderNotifications,
    tMyAccountHeaderReferAFriend,
    tMyAccountHeaderRewards,
  } = translate(myAccountHeaderTranslations);

  if (deviceEligibleDataIsPending) {
    return null;
  }

  const onOpenReferAFriend = () => {
    track({
      action: ActionType.click,
      event: MyAccountEvents.referAFriendClicked,
      label: "Clicked Refer a friend",
    });
    setIsReferAFriendDialogOpen(true);
  };

  const onCloseReferAFriend = () => {
    setIsReferAFriendDialogOpen(false);
  };

  const referAFriendLink = (
    <RhOutlineButton
      data-tracking-click={{ event: "Customer opening refer a friend modal" }}
      key="referAFriend"
      color="primary"
      onClick={onOpenReferAFriend}
      size="small"
      style={{ backgroundColor: "unset" }}
    >
      {tMyAccountHeaderReferAFriend}
    </RhOutlineButton>
  );

  const homeLink = (
    <StyledNavLink key={customerHomePath()} to={customerHomePath()}>
      {tMyAccountHeaderHome}
    </StyledNavLink>
  );
  const devicesLink =
    portalDeviceManagement.value &&
    deviceEligibilityInformationData?.thermostatEligible &&
    !deviceEligibleDataIsError ? (
      <StyledNavLink key={devicesBasePath()} to={devicesBasePath()}>
        {tMyAccountHeaderDevices}
      </StyledNavLink>
    ) : null;

  const rewardsLink = (
    <StyledNavLink key={rewardsPath()} to={rewardsPath()}>
      {tMyAccountHeaderRewards}
    </StyledNavLink>
  );

  const accountLink = (
    <StyledNavLink key={myAccountPath()} to={myAccountPath()}>
      {tMyAccountHeaderAccountSettings}
    </StyledNavLink>
  );

  const notificationsLink = (
    <StyledNavLink key={notificationsPath()} to={notificationsPath()}>
      {tMyAccountHeaderNotifications}
    </StyledNavLink>
  );

  const helpLink = (
    <StyledAnchor
      key={brandInformation.urlsFaqsHome}
      href={brandInformation.urlsFaqsHome}
      target="_blank"
      rel="noreferrer noopener"
    >
      {tMyAccountHeaderHelp}
    </StyledAnchor>
  );

  const logoutLink = (
    <StyledNavLink key={signOutPath()} to={signOutPath()}>
      {tMyAccountHeaderLogout}
    </StyledNavLink>
  );

  const premiseSelector = (
    <PremiseSelector key="premiseSelector" premises={customer?.premises} />
  );

  const baseLinks = [homeLink, devicesLink, rewardsLink, accountLink, helpLink];

  const accountMenu = (
    <AccountMenu
      key="PortalHeader__AccountMenu"
      customerName={customer.fullName}
    >
      {notificationsLink}
      {logoutLink}
    </AccountMenu>
  );

  const links = isMobile
    ? [
        premiseSelector,
        ...baseLinks,
        notificationsLink,
        referAFriendLink,
        logoutLink,
      ]
    : [...baseLinks, referAFriendLink, accountMenu];

  return (
    <>
      <RhHeader
        divideLastItem
        items={links}
        logo={<PortalHeaderLogo isAuthenticated />}
        premiseSelector={premiseSelector}
      />
      {isReferAFriendDialogOpen ? (
        <ReferAFriendDialog onClose={onCloseReferAFriend} />
      ) : null}
    </>
  );
};
