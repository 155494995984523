import { prospectUrls } from "@common/api/prospectUrls";
import { ajax } from "@common/services/ajax";
import { CreditScoreResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import {
  ProspectCreditCheckRequestType,
  ProspectTrackEnteredSignupPostDataType,
  ProspectTrackServiceUnavailablePostDataType,
} from "@common/types/prospectTypes";

export const prospectApi = {
  creditScoreEvaluation: <R = CreditScoreResponseType>(
    customerData: ProspectCreditCheckRequestType
  ): Promise<R> => {
    return ajax.post<R, Partial<CustomerType>>(
      prospectUrls.creditScoreEvaluation(),
      customerData
    );
  },
  trackEnteredSignup: (id: IdType | null, email: string): Promise<void> => {
    if (!id) {
      return Promise.resolve();
    }
    return ajax.post<void, ProspectTrackEnteredSignupPostDataType>(
      prospectUrls.trackEnteredSignup(id),
      { email }
    );
  },
  trackServiceUnavailable: ({
    email,
    id,
  }: {
    email: string;
    id: IdType | null;
  }): Promise<void> => {
    if (!id) {
      return Promise.resolve();
    }
    return ajax.post<void, ProspectTrackServiceUnavailablePostDataType>(
      prospectUrls.trackServiceUnavailable(id),
      { email }
    );
  },
  validatePhoneNumber: (phoneNumber: string) => {
    return ajax.post(prospectUrls.validatePhoneNumber(), {
      phone_number: phoneNumber,
    });
  },
};
