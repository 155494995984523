import { Grid } from "@mui/material";
import { StyledSignUpFieldsLayoutContainer } from "@portal/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.styled";
import React, { Children, FC } from "react";

interface SignUpFieldsLayoutProps {
  dense?: boolean;
  noMargin?: boolean;
}

export const SignUpFieldsLayoutContainer: FC<
  React.PropsWithChildren<SignUpFieldsLayoutProps>
> = ({ dense, noMargin = false, children }) => {
  return (
    <StyledSignUpFieldsLayoutContainer $noMargin={noMargin}>
      <Grid container direction="column" wrap="nowrap" spacing={dense ? 0 : 4}>
        {children}
      </Grid>
    </StyledSignUpFieldsLayoutContainer>
  );
};

export const LoggedOutFieldsLayout: FC<
  React.PropsWithChildren<SignUpFieldsLayoutProps>
> = ({ dense = false, noMargin = false, children }) => (
  <SignUpFieldsLayoutContainer dense={dense} noMargin={noMargin}>
    {Children.map(children, (child) => (
      <Grid item>{child}</Grid>
    ))}
  </SignUpFieldsLayoutContainer>
);
