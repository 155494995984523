import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const PortalAccordion = styled.details`
  @keyframes sweep {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  border-bottom: ${borders[100]};
  padding: ${rhSpacingPx(1)} 0;
  position: relative;

  & summary {
    align-items: center;
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    list-style-type: none;
    margin-right: ${rhSpacingPx(4)};
    padding: ${rhSpacingPx(1)} 0;

    &::after {
      border: solid ${CSSVariables.COLOR_PRIMARY_MAIN};
      border-width: 0 2px 2px 0;
      content: " ";
      display: inline-block;
      padding: 0 10px 10px 0;
      position: absolute;
      right: 16px;
      transform: rotate(-45deg);
      transition: transform 200ms ease-in-out;
    }
  }

  &[open] {
    padding-bottom: ${rhSpacingPx(2)};
  }

  &[open] summary {
    margin-bottom: ${rhSpacingPx(0.5)};
  }

  &[open] summary::after {
    transform: rotate(45deg);
  }

  &[open] summary ~ * {
    animation: sweep 300ms ease-in-out;
    margin-right: ${rhSpacingPx(4)};
  }
`;
