import { UUIDType } from "@common/types/apiTypes";
import { offerSnapshotsApi } from "@portal-shared/api/offerSnapshotsApi";
import { QUERY_STALE_TIME_DEFAULT_SHORT } from "@portal-shared/constants/queryOptions.constants";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { OfferSnapshotQueryKeys } from "@portal-shared/queryKeys/offerSnapshotQuerykeys";
import { useQuery } from "@tanstack/react-query";

interface UsePortalOfferSnapshotQueryProps {
  offerSnapshotUuid: UUIDType;
  queryOptions?: {
    enabled?: boolean;
  };
}
export const usePortalOfferSnapshotQuery = (
  props: UsePortalOfferSnapshotQueryProps
) => {
  const { offerSnapshotUuid, queryOptions: { enabled = true } = {} } = props;

  const query = useQuery({
    enabled,
    queryFn: () => offerSnapshotsApi.retrieve(offerSnapshotUuid),
    queryKey: OfferSnapshotQueryKeys.retrieve(offerSnapshotUuid),
    select: (data) => new OfferSnapshot(data),
    staleTime: QUERY_STALE_TIME_DEFAULT_SHORT,
  });

  return query;
};
