import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsSimpliSafeTranslations } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsSimpliSafe/OfferSnapshotFAQsSimpliSafe.en.i18n";
import {
  MultiAnswerContainer,
  SimpliSafeList,
} from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsSimpliSafe/OfferSnapshotFAQsSimpliSafe.styled";
import { OfferSnapshotFAQsTitleWrapper } from "@portal-shared/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const OfferSnapshotFAQsSimpliSafe = () => {
  const { translate } = useTranslations();
  const {
    tOfferSnapshotFAQsSimpliSafeAfterSignUp,
    tOfferSnapshotFAQsSimpliSafeBeACustomer,
    tOfferSnapshotFAQsSimpliSafeOptIn,
    tOfferSnapshotFAQsSimpliSafeReceiveSimpliSafe,
    tOfferSnapshotFAQsSimpliSafeShipYourSystem,
    tOfferSnapshotFAQsSimpliSafeSimpliSafeIs,
    tOfferSnapshotFAQsSimpliSafeSimplySecure,
    tOfferSnapshotFAQsSimpliSafeWhatIsSimpliSafe,
    tOfferSnapshotFAQsSimpliSafeTitle,
  } = translate(offerSnapshotFAQsSimpliSafeTranslations);

  return (
    <OfferSnapshotFAQsTitleWrapper title={tOfferSnapshotFAQsSimpliSafeTitle}>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsSimpliSafeWhatIsSimpliSafe}</summary>
        <MultiAnswerContainer>
          <RhTypography variant="body1">
            {tOfferSnapshotFAQsSimpliSafeSimpliSafeIs}
          </RhTypography>
          <RhTypography variant="body1">
            {tOfferSnapshotFAQsSimpliSafeOptIn}
          </RhTypography>
        </MultiAnswerContainer>
      </PortalAccordion>

      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsSimpliSafeReceiveSimpliSafe}</summary>
        <SimpliSafeList>
          <li>
            <RhTypography>
              {tOfferSnapshotFAQsSimpliSafeSimplySecure}
            </RhTypography>
          </li>
          <li>
            <RhTypography>
              {tOfferSnapshotFAQsSimpliSafeBeACustomer}
            </RhTypography>
          </li>
          <li>
            <RhTypography>
              {tOfferSnapshotFAQsSimpliSafeAfterSignUp}
            </RhTypography>
          </li>
          <li>
            <RhTypography>
              {tOfferSnapshotFAQsSimpliSafeShipYourSystem}
            </RhTypography>
          </li>
        </SimpliSafeList>
      </PortalAccordion>
    </OfferSnapshotFAQsTitleWrapper>
  );
};
