import { enrollOffersPath } from "@enroll-utils/constants/routePaths";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const EnrollIndexPage = () => {
  const location = useLocation();

  return (
    <Navigate
      to={{ pathname: enrollOffersPath(), search: location.search }}
      replace
    />
  );
};
