import { UUIDType } from "@common/types/apiTypes";

export const QuickPayQueryKeys = {
  BASE: ["quickPay"] as const,
  accountBalance: (token: UUIDType) =>
    [...QuickPayQueryKeys.BASE, token, "accountBalance"] as const,
  paymentMethod: (token: UUIDType, paymentMethodId: string) =>
    [
      ...QuickPayQueryKeys.BASE,
      token,
      "paymentMethod",
      paymentMethodId,
    ] as const,
  paymentMethods: (token: UUIDType) =>
    [...QuickPayQueryKeys.BASE, token, "paymentMethods"] as const,
  retrieve: (token: UUIDType) => [...QuickPayQueryKeys.BASE, token] as const,
};
