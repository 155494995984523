import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { deviceConnectionActivityTableTranslations } from "@portal/components/DeviceConnectionActivityTable/DeviceConnectionActivityTable.en.i18n";
import { DeviceConnectionEventTypes } from "@portal/types/devicesTypes";

export const useDeviceConnectionActivityEvent = () => {
  const { translate } = useTranslations();

  const {
    tDeviceConnectionActivityTableDeviceAuthorized,
    tDeviceConnectionActivityTableDeviceOffline,
    tDeviceConnectionActivityTableDeviceOnline,
    tDeviceConnectionActivityTableDeviceOptOut,
    tDeviceConnectionActivityTableDeviceUnauthorized,
  } = translate(deviceConnectionActivityTableTranslations);

  return {
    [DeviceConnectionEventTypes.Authorized]:
      tDeviceConnectionActivityTableDeviceAuthorized,
    [DeviceConnectionEventTypes.Offline]:
      tDeviceConnectionActivityTableDeviceOffline,
    [DeviceConnectionEventTypes.Online]:
      tDeviceConnectionActivityTableDeviceOnline,
    [DeviceConnectionEventTypes.OptOut]:
      tDeviceConnectionActivityTableDeviceOptOut,
    [DeviceConnectionEventTypes.Unauthorized]:
      tDeviceConnectionActivityTableDeviceUnauthorized,
  };
};
