export const guestZuoraAddBankAccountFormTranslations = {
  tGuestZuoraAddBankAccountFormAuthorizationFailed:
    "We're having trouble connecting to the billing service. Please try again.",
  tGuestZuoraAddBankAccountFormErrorAddingBankAccount:
    "Unable to add bank account. Please verify your bank information and try again.",
  "tGuestZuoraAddBankAccountFormFormError.accountNumberInvalid":
    "Please enter a valid account number.",
  "tGuestZuoraAddBankAccountFormFormError.fieldEmpty":
    "Please complete all form fields.",
  "tGuestZuoraAddBankAccountFormFormError.genericError":
    "We were unable to add your bank account. Please try again.",
  "tGuestZuoraAddBankAccountFormFormError.routingNumberInvalid":
    "Please enter a valid routing number.",
};
