import { AjaxStatus, AjaxStatuses } from "@common/types/apiTypes";
import { RhApiError, RhError } from "@common/types/errorTypes";
import { PaymentMethodType } from "@common/types/paymentMethodTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";

const defaultPaymentFirst = (paymentMethod: PaymentMethodType) =>
  !paymentMethod.defaultPaymentMethod ? 1 : -1;

const updatePaymentMethodsWithNewDefault = (
  PaymentMethods: PaymentMethodType[],
  paymentMethodId: string
) =>
  PaymentMethods.map((PaymentMethod) =>
    PaymentMethod.id === paymentMethodId
      ? { ...PaymentMethod, defaultPaymentMethod: true }
      : { ...PaymentMethod, defaultPaymentMethod: false }
  );

export type BillingDetailsStateType = {
  data: PaymentMethodType[] | null;
  error: RhError | null;
  requestStatus: AjaxStatus;
};

export const initialBillingDetailsState =
  Object.freeze<BillingDetailsStateType>({
    data: null,
    error: null,
    requestStatus: AjaxStatuses.Idle,
  });

// Handlers
export const fetchPaymentMethodsHandlers: CaseReducer<
  BillingDetailsStateType,
  PayloadAction
> = (state, action) => {
  return {
    ...state,
    requestStatus: AjaxStatuses.Pending,
  };
};

export const fetchedPaymentMethodsHandler: CaseReducer<
  BillingDetailsStateType,
  PayloadAction<PaymentMethodType[]>
> = (state, action) => {
  const mutablePayload = [...action.payload];

  return {
    data: mutablePayload.sort(defaultPaymentFirst),
    error: null,
    requestStatus: AjaxStatuses.Success,
  };
};

export const failedPaymentMethodsHandler: CaseReducer<
  BillingDetailsStateType,
  PayloadAction<RhApiError>
> = (state, action) => {
  return {
    data: state.data,
    error: action.payload,
    requestStatus: AjaxStatuses.Failure,
  };
};

export const paymentMethodSetAsDefaultHandler: CaseReducer<
  BillingDetailsStateType,
  PayloadAction<Pick<PaymentMethodType, "id">>
> = (state, { payload }) => {
  const statePaymentMethods = state.data ?? [];
  const updatedPaymentMethods = updatePaymentMethodsWithNewDefault(
    [...statePaymentMethods],
    payload.id
  ).sort(defaultPaymentFirst);

  return {
    ...state,
    data: updatedPaymentMethods,
  };
};

const billingDetailsReducers = {
  errored: failedPaymentMethodsHandler,
  paymentMethodDefaultUpdated: paymentMethodSetAsDefaultHandler,
  received: fetchedPaymentMethodsHandler,
  requested: fetchPaymentMethodsHandlers,
};

const billingDetailsSlice = createSlice<
  BillingDetailsStateType,
  typeof billingDetailsReducers
>({
  initialState: initialBillingDetailsState,
  name: "billingDetails",
  reducers: billingDetailsReducers,
});

export const billingDetailsReducer = billingDetailsSlice.reducer;
export const billingDetailsRequested = billingDetailsSlice.actions.requested;
export const billingDetailsReceived = billingDetailsSlice.actions.received;
export const billingDetailsErrored = billingDetailsSlice.actions.errored;
export const paymentMethodSetAsDefault =
  billingDetailsSlice.actions.paymentMethodDefaultUpdated;

export const billingDetailsFetch = createAction(
  `${billingDetailsSlice.name}/fetch`
);
export const billingDetailsRefetch = createAction(
  `${billingDetailsSlice.name}/refetch`
);
export const paymentMethodSetAsDefaultRequest = createAction<
  Pick<PaymentMethodType, "id">
>(`${billingDetailsSlice.name}/request`);
